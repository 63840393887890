@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_helpers.scss';

/* MODAL 1*/
.modal1 {
  display: grid;
  grid-gap: 28px;
  grid-template-columns: repeat(4, 1fr);
  margin: 30px auto;
}

.dropdownWidth {
  grid-column: 1 / 3;
}

.mediumWidth {
  grid-column: 1 / 3;
}

.fullWidth {
  grid-column: 1 / 4;
}

.headerText {
  color: $gray-900;
  line-height: 1.1;
}

.city {
  grid-column: 1 / 3;
}

.state {
  grid-column: 3;
  min-width: 72px;
}

.zip {
  grid-column: 4;
  min-width: 72px;
}

.productLinesM1 {
  grid-column: 1;
  grid-row: 8;
}

.uomM1 {
  grid-column: 2;
  grid-row: 9;
}

.gtLiteParticipantM1 {
  grid-column: 1/3;
  grid-row: 9;
  color: $gray-900;
  grid-row: 10;
}

.ownershipTypeM1 {
  grid-column: 1;
  grid-row: 11;
}

.editWeights {
  grid-column: 1/3;
  grid-row: 11;
  margin-left: 14px;
  color: $gray-900;
  grid-row: 12;
}

.editWeightsSwitchContainer {
  margin-top: -7px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.gtLiteParticipantSwitchContainer {
  margin-top: -7px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.editWeightsSwitchLabel {
  font-size: 1.6rem !important;
}

.gtLiteParticipantSwitchLabel {
  font-size: 1.6rem !important;
}

.editWeightsNotAllowed {
  cursor: not-allowed;
}

.gtLiteParticipantNotAllowed {
  cursor: not-allowed;
}

/* MODAL 2*/
.modal2 {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: minmax(auto, 700px) 1fr;
  color: $gray-900; // TODO refactor to addModal (issue comes from add modal card)
}

.title {
  grid-column: 1 / 3;
  grid-row: 1;

  :global(h3) {
    font-family: $font-family-bold;
    margin-top: 5px;
    margin-bottom: 18px;
  }
}

.map {
  grid-column: 1 / span 3;
  grid-row: 2 / 7;
}

/* MAP GEOZONE MODAL */
.geozoneModal {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 28px;
  padding-bottom: 35px;
  color: $gray-900; // TODO refactor to addModal (issue comes from add modal card)
}

.geozoneTitle {
  grid-column: 1 / span 3;
  grid-row: 1;

  :global(h3) {
    font-family: $font-family-bold;
    padding: 0 !important;
    line-height: 1 !important;
    margin-block-start: 0 !important;
  }

  p {
    margin-bottom: 8px;
  }
}

.reviewTitleText {
  margin-bottom: 4px !important;
}

.geozoneMap {
  grid-column: 1 / span 3;
  grid-row: 3 / 7;

  label:after {
    content: ' *';
    color: $darkred;
  }
}

.plantSummaryModal {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
  padding-bottom: 10px;
  color: $gray-900;
}

.mapSummary {
  grid-column: 2 / span 2;
  grid-row: 2 / 7;
  height: $card-map-height;
}

.externalId {
  grid-column: 1;
  grid-row: 2;
  @extend %ifValueEmpty;
  margin-top: 20px;
}

.externalId,
.mapSummary {
  margin-top: 20px;
}

.name {
  grid-column: 1;
  grid-row: 3;
}

.address {
  grid-column: 1;
  grid-row: 4;

  :global(p) {
    white-space: pre-wrap;
    word-break: break-word;
  }
}

.region {
  grid-column: 1;
  grid-row: 5;
}

.latitude {
  grid-column: 1;
  grid-row: 6;
}

.longitude {
  grid-column: 1;
  grid-row: 7;
}

.plantsAddModal {
  @extend %adminModalWidthLarge;
}

.productLinesM2 {
  grid-column: 1;
  grid-row: 8;
}

.uomM2 {
  grid-column: 1;
  grid-row: 9;
}

.LoadTimeIndex {
  grid-column: 2;
  grid-row: 7 / span 2;
  margin-top: 1.8rem;
  width: 300px;
}

.gtLiteParticipantM2 {
  grid-column: 1;
  grid-row: 10;
}

.ownershipTypeM2 {
  grid-column: 1;
  grid-row: 11;
}

.scaleHostAddressM2 {
  grid-column: 1;
  grid-row: 12;
}

.editWeightsRead {
  grid-column: 1;
  grid-row: 13;
}

.geozoneBoundariesOptions {
  grid-column: 1 / span 3;
  grid-row: 2;
  margin-top: -40px;
}

.inlineStatusIcon {
  line-height: 1;
  display: inline-block;
}

.statusIconArrow {
  margin-left: -1px;
  margin-right: -3px;
  font-size: 1.6rem;
  transform: translateY(2px);
}

.statusIconDot {
  margin-left: -1px;
  margin-right: -1px;
  font-size: 1.8rem;
  transform: translateY(3px);
}

.listedStatusIcon {
  font-size: 1.6rem;
  margin: 0 5px 0 0;
  display: inline-block;
  transform: translateY(2.5px);
}

.statusIconToJob {
  color: $orange;
}

.statusIconInQueue {
  color: $aqua;
}

.statusIconReturn {
  color: $gray-600;
}

.geozonesIndex {
  grid-row: 7 / span 2;
  grid-column: 3;
}

.text {
  font-size: 1.3rem;
  display: block;
}

.asterisk {
  color: #e92851;
  font-family: $font-family-bold;
  font-size: 1.8rem;
  display: inline-block;
  transform: translateY(3px);
  margin-left: 0.3rem;
  line-height: 0.5;
}

.uom {
  margin-left: 3rem;
}

.productLines {
  font-size: 16px !important;
  font-weight: 400 !important;
  margin: 4px !important;
  font-family: $font-family-regular !important;
  color: #151f28;
  grid-row: 8;
}

.radioBtnSize {
  height: 10px;
  width: 10px;
  margin-left: 13px !important;
}

.radioBtn {
  color: $gray-900 !important;
  padding: 12px !important;
  margin-left: 10px !important;
  @extend .radioBtnSize;

  :global(.MuiSvgIcon-root) {
    font-size: 2.4rem !important;
  }
}

.radioBtnSelected {
  color: $blue !important;
  @extend .radioBtnSize;
}

.radioLabel {
  font-size: 16px !important;
  font-weight: 400 !important;
  margin: 4px !important;
  font-family: $font-family-regular !important;
  color: $gray-900;
}

.uomLabel {
  margin-top: 0.8rem;
}

.LoadedTimeLabel {
  margin-left: 1.5rem !important;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  width: 300px;
}

.loadedTimeSwitch {
  margin-left: 1rem;
}

.loadTimeSelect {
  background: #f4f7f9 !important;
  box-sizing: border-box;
  margin-left: 1.5rem !important;
  margin-bottom: 0.8rem;
  width: 85%;
  height: 40px
}

.labelWithTooltip {
  display: flex;
  align-items: center;
  width: 140px;
}

.tooltipIcon {
  margin-left: 0.5rem;
  cursor: pointer;
  color: lightgray;
  font-size: 1.8rem !important;
}

.LoadTimeIndex p[title="Not set"] {
  color: #a5abaf;
}