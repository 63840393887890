.wrapper {
  position: relative;
  max-width: 300px;
}

.button {
  width: 35px;
  height: 35px;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(76, 88, 98, 0.25);
  background-color: white;
  border: none;
  cursor: pointer;
  transition: 0.2s background ease, 0.2s box-shadow ease;
}
.button.isOpen {
  box-shadow: inset 0 1px 3px 0 #c8cdd0;
  background: #f4f7f9;
}
.button.isOpen .icon {
  color: #008EF3;
}
.button:hover {
  background: #f4f7f9;
}

.paper {
  overflow: visible !important;
}

.formGroup {
  padding: 20px 20px 10px;
}

label.formControlLabel {
  margin-top: -4px;
}
label.formControlLabel > span > span:first-of-type {
  position: absolute;
  padding: 9px;
  top: 0;
  left: 0;
  color: white;
  border-radius: 50%;
}
label.formControlLabel > span > span:first-of-type[class*=Mui-checked] {
  color: #008EF3;
}

.indentedFormControlLabel {
  padding-left: 16px !important;
  height: 28px !important;
}

.formControlSectionEnd {
  margin: -4px 0 7px 0;
}

.header {
  width: 100%;
  height: 50px;
  background: #008EF3;
  border-radius: 0.3rem 0.3rem 0 0;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 2rem;
}

.headerText {
  font-size: 1.3rem;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  line-height: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: white;
  letter-spacing: 0.2rem;
}

.subtitle {
  font-size: 1.3rem;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  text-overflow: ellipsis;
  color: #151F28;
  margin: -2px 0 4px 0;
  line-height: 1;
}

.topDivider {
  margin: 3px 0 12px 0;
}

.arrow {
  display: block;
  height: 25px;
  width: 25px;
  background-color: #008EF3;
  border: inherit;
  position: absolute;
  top: -12.5px;
  left: 20px;
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  transform: rotate(135deg);
  border-radius: 0 0 0 5px;
}

.dot {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: #E92851;
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
}

.resetButton {
  margin: 20px;
  width: calc(100% - 40px);
}

.icon {
  font-size: 20px;
  color: #878e93;
  transition: 0.2s color ease;
}

.controlLabel span {
  font-family: "PublicSans Regular", Helvetica, Arial, sans-serif;
  margin-left: -2px;
}

.checkboxControlLabel span {
  font-family: "PublicSans Regular", Helvetica, Arial, sans-serif;
  margin-left: 6px;
}

.controlLabelDisabled {
  color: #151F28 !important;
  opacity: 0.5 !important;
}

.controlLabelCheckboxPlants {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 189px;
}
.controlLabelCheckboxPlants span {
  font-size: 1.6rem;
  font-family: "PublicSans Regular", Helvetica, Arial, sans-serif;
}

.controlCheckboxPlants[class*=MuiCheckbox-colorPrimary] {
  color: #636b71;
  margin-right: 10px;
}
.controlCheckboxPlants[class*=Mui-checked] svg {
  color: #008EF3;
}

.datePickerContainer {
  padding-left: 16px !important;
  margin: 6px 0 10px 0;
}
.datePickerContainer > div > div > div > input {
  font-size: 16px !important;
}

.endDateMessage {
  font-size: 1.3rem;
  line-height: 1;
  font-family: "PublicSans Italic", Helvetica, Arial, sans-serif;
  color: #878e93;
  display: inline-block;
  margin-top: 4px;
  transform: translateY(2px);
}

.datePickerDisabled {
  opacity: 0.4;
  pointer-events: none;
}

.list {
  display: grid;
  max-height: 200px;
  width: 100%;
  overflow-y: auto;
}

.listContainer {
  max-height: 212px;
}
.listContainer ul {
  max-width: 260px;
}

