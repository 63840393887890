@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';

$padding: 16px;
$formLabelHeight: 28px;
$headerHeight: 50px;
$arrowRounding: 5px;
$arrowSize: 25px;

.wrapper {
  position: relative;
  max-width: 300px;
}

.button {
  width: 35px;
  height: 35px;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(76, 88, 98, 0.25);
  background-color: $white;
  border: none;
  cursor: pointer;
  transition: 0.2s background ease, 0.2s box-shadow ease;

  &.isOpen {
    box-shadow: inset 0 1px 3px 0 $gray-300;
    background: $gray-100;

    .icon {
      color: $primary;
    }
  }

  &:hover {
    background: $gray-100;
  }
}

.paper {
  overflow: visible !important; // this allows the arrow to stick out above the Popover element
}

.formGroup {
  padding: 20px 20px 10px;
}

label.formControlLabel {
  margin-top: -4px;

  >span>span:first-of-type {
    position: absolute;
    padding: 9px;
    top: 0;
    left: 0;
    color: $white;
    border-radius: 50%;

    &[class*="Mui-checked"] {
      color: #008EF3;
    }
  }
}

.indentedFormControlLabel {
  padding-left: $padding !important;
  height: $formLabelHeight !important;
}

.formControlSectionEnd {
  margin: -4px 0 7px 0;
}

.header {
  width: 100%;
  height: $headerHeight;
  background: $blue;
  border-radius: 0.3rem 0.3rem 0 0;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 2rem;
}

.headerText {
  font-size: 1.3rem;
  font-family: $font-family-bold;
  text-transform: uppercase;
  line-height: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: white;
  letter-spacing: 0.2rem;
}

.subtitle {
  font-size: 1.3rem;
  font-family: $font-family-bold;
  text-overflow: ellipsis;
  color: $gray-900;
  margin: -2px 0 4px 0;
  line-height: 1;
}

.topDivider {
  margin: 3px 0 12px 0;
}

.arrow {
  display: block;
  height: $arrowSize;
  width: $arrowSize;
  background-color: $blue;
  border: inherit;
  position: absolute;
  top: -$arrowSize * 0.5;
  left: 20px;
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  transform: rotate(135deg);
  border-radius: 0 0 0 $arrowRounding;
}

.dot {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: $red;
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
}

.resetButton {
  margin: 20px;
  width: calc(100% - 40px);
}

.icon {
  font-size: 20px;
  color: $gray-500;
  transition: 0.2s color ease;
}

.controlLabel {
  span {
    font-family: $font-family-regular;
    margin-left: -2px;
  }
}

.checkboxControlLabel {
  span {
    font-family: $font-family-regular;
    margin-left: 6px;
  }
}

.controlLabelDisabled {
  color: $gray-900 !important;
  opacity: 0.5 !important;
}

.controlLabelCheckboxPlants {
  span {
    font-size: 1.6rem;
    font-family: $font-family-regular;
  }

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 189px;
}

.controlCheckboxPlants {
  &[class*='MuiCheckbox-colorPrimary'] {
    color: $gray-600;
    margin-right: 10px;
  }

  &[class*='Mui-checked'] {
    svg {
      color: $primary;
    }
  }
}

.datePickerContainer {
  padding-left: $padding !important;
  margin: 6px 0 10px 0;

  // necessary because issue with material ui Tabs introducing multiple
  //  muiButtonBase styles that override muiIconButton styles
  // seems to be occuring when multiple date pickers are in view
  >div {
    >div {
      >div {
        >input {
          font-size: 16px !important;
        }
      }
    }
  }
}

.endDateMessage {
  font-size: 1.3rem;
  line-height: 1;
  font-family: $font-family-italic;
  color: $gray-500;
  display: inline-block;
  margin-top: 4px;
  transform: translateY(2px);
}

.datePickerDisabled {
  opacity: 0.4;
  pointer-events: none;
}

.list {
  display: grid;
  max-height: 200px;
  width: 100%;
  overflow-y: auto;
}

.listContainer {
  max-height: 212px;

  ul {
    max-width: 260px;
  }
}