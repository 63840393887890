@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.list {
  margin: 0 auto;
  width: 100%;
  max-width: 650px;
  border-radius: $border-radius;
  overflow: hidden;
  box-shadow: $boxshadow-medium;
  background-color: #ffffff;

  a:last-of-type div {
    border-bottom: none !important;
  }
}

.wrapper {
  padding: 60px 31px;
  position: relative;
  height: calc(#{$window-height} - #{$header-height});
  background: $gray-100;
  overflow-y: auto;
}

.headerAndContainer {
  height: 100%;
}