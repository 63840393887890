@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.wrapper {
  display: flex;
  align-items: center;

  .dateRange {
    margin-right: 12px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transform: translateY(-1px);

    span {
      font-family: $font-family-bold;
      font-size: 1.3rem;
      line-height: 1;
      padding: 0;
      color: $gray-900;
      text-align: right;
      white-space: nowrap;
    }
  }

  @media (max-width: $breakpoint-small) {
    .dateRange {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  @media (max-width: $breakpoint-xsmall) {
    .dateRange {
      display: none;
    }
  }

  .dateRangeBlock {
    display: block;
  }

  .dateField {
    margin: 0 auto;
    overflow: hidden;
    width: 152px;

    >div {
      >label {
        display: none;
      }

      // necessary because issue with material ui Tabs introducing multiple
      // muiButtonBase styles that override muiIconButton styles
      // seems to be occuring when both date pickers are in view
      >div {
        >div {
          >div {
            >button {
              padding: 12px !important;
            }
          }

          >input {
            font-size: 16px !important;
          }
        }
      }
    }
  }

  .spacing {
    display: inline-block;
    margin: 0 10px;
  }
}