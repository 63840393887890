@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';

$big: 2.7rem;
$small: 1.1rem;
$position: 1.3rem;
$transformX: 0.9rem;
$transformY: 1.1rem;
$headerCenterTop: 1.2rem;

.infoBoxWrapper {
  overflow: visible !important;
}

.infoBox {
  overflow: visible !important;
  min-width: 400px;
  width: fit-content;
  filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.4));
}

.arrowTop {
  @extend .smallArrow;
  top: -$transformY !important;
  transform: rotate(135deg) translate($transformX, $transformY) !important;
  background: $gray-800 !important;
}

.arrowBottom {
  @extend .bigArrow;
  bottom: -$position !important;
  transform: rotate(-45deg) translate(-$transformX, -$transformY) !important;
  background: $white !important;
}

.arrowLeftTop {
  @extend .smallArrow;
  top: $headerCenterTop;
  left: 0 !important;
  transform: rotate(45deg) translate(-$transformX, $transformX) !important;
  background: $gray-800 !important;
}

.arrowRightTop {
  @extend .smallArrow;
  top: $headerCenterTop;
  right: 0 !important;
  transform: rotate(-135deg) translate(-$transformX, $transformX) !important;
  background: $gray-800 !important;
}

.arrowLeftBottom {
  @extend .bigArrow;
  left: 0 !important;
  transform: rotate(45deg) translate(-$transformX, $transformX) !important;
  background: $white !important;
}

.arrowRightBottom {
  @extend .bigArrow;
  right: 0 !important;
  top: 50%;
  transform: rotate(-135deg) translate(-$transformX, $transformX) !important;
  background: $white !important;
}

.arrow {
  content: '';
  position: absolute;
  width: 2.7rem;
  height: 2.7rem;
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  border-radius: 0 0 0 5px;
  transform-origin: 50% 50% !important;
}

.bigArrow {
  @extend .arrow;
  border-width: $big;
}

.smallArrow {
  @extend .arrow;
  border-width: $small;
}