.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.sidebarContainer,
.sidebarContainerWithTickets,
.flex-center,
.flex-horizontal-center,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.containerStyle {
  display: grid;
  width: 320px;
  height: 100vh;
  grid-template-columns: 100% 0;
}
.containerStyle[data-showing=yes] {
  width: 640px;
  grid-template-columns: 50% 50%;
}
.containerStyle[data-expanded=true] {
  width: 100vw;
  grid-template-columns: 320px 1fr;
}
@media (max-width: 640px) {
  .containerStyle[data-expanded=true] {
    grid-template-columns: 100% 100%;
  }
  .containerStyle[data-expanded=true][data-showing=yes] {
    translate: -100% 0;
  }
}
.containerStyle[data-expanded=false] {
  transition: width 200ms;
}

.sidebarContainer,
.sidebarContainerWithTickets {
  overflow: hidden !important;
}

.sidebarContainer {
  height: 100%;
}

.sidebarContainerWithTickets {
  height: calc(55% + 55px);
}

[data-expanded=false] .panel {
  top: 0 !important;
  height: 55px !important;
  width: 52px !important;
  right: 88px !important;
  background-color: transparent !important;
  box-shadow: none !important;
  z-index: 9999 !important;
}

.panel i {
  color: white !important;
  font-size: 16px !important;
  margin: 5px !important;
}

.panel:hover i {
  color: #c8cdd0 !important;
  margin: 5px !important;
}

@media (max-width: 1340px) {
  .overlayPanelOpen {
    position: fixed;
    top: 0px;
    left: 302px;
    right: 0;
    height: 100vh !important;
    background: rgba(0, 0, 0, 0.4);
    z-index: -1 !important;
  }
}

