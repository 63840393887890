@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.wrapper {
  display: flex;
  flex-wrap: wrap;

  >div {
    flex-grow: 1 !important;
    height: auto !important;
    @extend %ifValueEmpty;
    width: 100% !important;

    &:last-of-type {
      overflow: auto !important;
    }
  }
}

.btnContainer {
  opacity: 1 !important;
}

.btnContainerDisabled {
  opacity: 0.4 !important;
}

.regionContainer {
  overflow: visible;
  border-bottom: solid 1px $gray-300;
}

.belongsToGroupsContainer {
  word-wrap: break-word;
  overflow: hidden;
}

.text {
  font-size: 16px;
  word-wrap: break-word;
  white-space: normal;
  line-height: 1;
}

.disabledText {
  font-size: 16px;
  color: $gray-600;
}

.marginLeft {
  margin-left: 5px !important;
}

.permissionsLabel {
  overflow-x: auto;
  padding: 3px 2px;
  font-size: 16px;
  color: $gray-600;
  border-bottom: 1px solid $gray-300;
}

.headerContainer {
  background: $white;
  padding: 0;
}

.headerRow {
  padding: 0 !important;
  border-bottom: 1px solid $gray-200 !important;
}

.headerStyle {
  font-size: 1.3rem !important;
  color: $gray-900 !important;
  font-weight: inherit !important;
  text-align: left !important;
  border: none !important;
  background: $white !important;
  line-height: 1 !important;
  padding: 0 0 13px 17px !important;
}

.borderLeft {
  border: 1px solid $gray-200;
  border-right: none;
}

.borderRight {
  border: 1px solid $gray-200;
  border-left: none;
}

.column {
  padding: 0 0 0 16px !important;
}

.row {
  height: 45px;
}

.cellText {
  font-size: 16px;
  line-height: 1;
  letter-spacing: normal;
  word-wrap: break-word;
  white-space: normal;
}

.emptyState {
  width: 100%;
  height: 580px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circleLoader {
  border: 8px solid $gray-200;
  border-left-color: $green;
  animation: loader-spin 1.2s infinite linear;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  transform: translateX(8px);
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.redBoldText {
  color: $red;
  font-family: $font-family-bold;
  font-size: 16px;
}

.blueText {
  color: $darkblue;
  font-family: $font-family-bold;
  font-size: 16px;
}

.greenText {
  color: $darkgreen;
  font-family: $font-family-bold;
  font-size: 16px;
}

.disabledRegionName {
  font-weight: bolder;
  font-family: $font-family-bold;
}

.disabledClickIcon {
  pointer-events: auto !important;
  cursor: not-allowed !important;
}

.regionsTabContainer {
  overflow-x: auto;
  border-bottom: 1px solid $gray-300;
  margin-top: -2rem;

  .btnContainer {
    opacity: 1 !important;
  }

  .btnContainerDisabled {
    opacity: 0.4 !important;
  }
}

.lockPopper {
  z-index: 9999 !important;
  opacity: 1 !important;
  margin-left: -20px;
}

.lockTooltip {
  background: $info !important;
  padding: 0.6rem 1rem !important;
  font-size: 1.3rem !important;
  line-height: 1.3 !important;
  white-space: nowrap;
  border-radius: $border-radius !important;
  pointer-events: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 6px;
    pointer-events: none;
    border-right-color: $info;
    top: 100%;
    right: 44%;
    transform: translateY(0%) rotate(-90deg);
  }
}