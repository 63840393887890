.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-center,
.flex-horizontal-center,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.docContainer {
  padding: 22px;
  width: 522px;
}

.pdfLabel {
  font-weight: bold;
  font-size: 13px;
  color: #151F28;
  margin: 0 0 13px -1px;
  padding: 0;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
}

.pdfDoc {
  padding: 0;
  display: flex;
  align-items: center;
}

.pdfDoc i {
  font-size: 16px;
  line-height: 16px;
  margin-right: 7px;
}

.pdfDoc span {
  font-size: 16px;
  line-height: 16px;
  color: #151F28;
  transform: translateY(-1px);
}

.errorPdfDoc {
  padding: 0;
  display: flex;
  align-items: center;
}

.errorPdfDoc i {
  font-size: 16px;
  line-height: 16px;
  margin-right: 7px;
}

.errorPdfDoc span {
  font-size: 16px;
  line-height: 16px;
  color: #BD0029;
  transform: translateY(-1px);
}

.errorList {
  display: flex;
  flex-direction: column;
  margin: 2px 0 -2px 0;
}

.listWarning {
  font-size: 1px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.listWarningText {
  font-size: 14px !important;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  margin-top: 10px;
  transform: translateY(-1px);
}

.listWarningTextNextLine {
  font-size: 14px !important;
  font-family: "PublicSans Regular", Helvetica, Arial, sans-serif;
  margin: 7px 3px 0 -1px;
}

.warningIcon {
  font-size: 16px;
  margin: 10px 6px 0 0 !important;
  color: #BD0029;
}

.textRed {
  margin-bottom: 3px;
  color: #BD0029;
  font-family: "PublicSans Regular", Helvetica, Arial, sans-serif;
  font-size: 13px;
}

.chooseFileButton {
  padding: 0.1rem 1.3rem 0.35rem 1.3rem;
  min-height: 4rem;
  min-width: 4rem;
  font-family: "PublicSans Regular", Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  background: #f4f7f9;
  color: #0A63F3;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15), 0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 3px 1px -2px rgba(0, 0, 0, 0.07) !important;
  transition: 0.3s !important;
  margin-top: -1px;
}
.chooseFileButton:hover {
  background: #E2F8FF !important;
}
.chooseFileButton:focus {
  outline: none;
}

.chooseNewFileButton {
  padding: 0.1rem 1.3rem 0.35rem 1.3rem;
  min-height: 4rem;
  min-width: 4rem;
  font-family: "PublicSans Regular", Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  background: #f4f7f9;
  color: #0A63F3;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15), 0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 3px 1px -2px rgba(0, 0, 0, 0.07) !important;
  transition: 0.3s !important;
  margin-top: 17px;
}
.chooseNewFileButton:hover {
  background: #E2F8FF !important;
}
.chooseNewFileButton:focus {
  outline: none;
}

.chooseNewFileButtonWhenHasError {
  padding: 0.1rem 1.3rem 0.35rem 1.3rem;
  min-height: 4rem;
  min-width: 4rem;
  font-family: "PublicSans Regular", Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  background: #f4f7f9;
  color: #0A63F3;
  transition: 0.3s !important;
  margin-top: 17px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15), 0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 3px 1px -2px rgba(0, 0, 0, 0.07) !important;
}
.chooseNewFileButtonWhenHasError:hover {
  background: #E2F8FF !important;
}
.chooseNewFileButtonWhenHasError:focus {
  outline: none;
}

.chooseFileButton:disabled {
  background-color: #f4f7f9;
  color: #878e93;
  cursor: not-allowed;
  box-shadow: none;
}

.chooseNewFileButton:disabled {
  background-color: #f4f7f9;
  color: #878e93;
  cursor: not-allowed;
  box-shadow: none;
}

.actions {
  border-top: 1px solid #e1e4e7;
  padding: 11px 22px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.actions span {
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  font-weight: bold;
}

.actions span i {
  margin-right: 5px;
  color: #F66A00;
  font-size: 1.6rem;
  padding-bottom: 2px;
}

.actions span span {
  color: #151F28;
}

.cancelButton {
  margin-right: 22px;
}

.cancelButton:disabled {
  color: #a5abaf;
  cursor: default;
}

.addReleaseDocButton:disabled {
  background-color: #a5abaf;
  color: white;
  box-shadow: inset 0 0 0 1px #878e93;
  cursor: not-allowed;
}

.fileUploader {
  display: none;
  visibility: hidden;
}

.fileUploadError {
  color: #BD0029;
}

