.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-center,
.flex-horizontal-center,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

/* READ STYLES */
.paper {
  max-height: calc(100% - 5.4rem) !important;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  transition: none !important;
  transform: unset !important;
  overflow-y: auto;
  width: 100%;
}

.voided {
  background-image: url("../../../../assets/img/void-ticket-sign.svg");
  background-repeat: no-repeat;
  background-position: 50%;
}

.ticketDetailsBody {
  grid-template-columns: 40% 29.5% auto;
  display: inline-grid;
  grid-gap: 0 60px;
  padding: 35px 61px;
  width: 100%;
  font-size: 1.4rem;
}
.ticketDetailsBody > div {
  margin-left: 0;
  margin-bottom: 0px;
  padding: 0 2px 0 2px;
  align-self: flex-start;
}
.ticketDetailsBody > div:nth-of-type(3) {
  margin-bottom: 40px;
}
.ticketDetailsBody > div:nth-of-type(4) {
  margin-top: -85px;
}
.ticketDetailsBody > div:nth-of-type(5) {
  margin-top: -30px;
}

.moveRight {
  word-wrap: normal !important;
  align-items: center;
  display: flex;
  justify-content: left;
  justify-self: end;
  margin-bottom: -2px !important;
}

.regularLine {
  border: 0.5px solid #c8cdd0 !important;
  margin-bottom: 20px;
}

.bottom30 {
  margin-bottom: 30px !important;
}

.bottom20 {
  margin-bottom: 20px !important;
}

.bottom15 {
  margin-bottom: 15px !important;
}

.bottom10 {
  margin-bottom: 10px !important;
}

.bottom0 {
  margin-bottom: 0px !important;
}

.top20 {
  margin-top: 20px !important;
}

.top15 {
  margin-top: 15px !important;
}

.top25 {
  margin-top: 25px !important;
}

.top30 {
  margin-top: -30px !important;
}

.top40 {
  margin-top: 40px !important;
}

.top5 {
  margin-top: 5px !important;
}

.top70 {
  margin-top: 70px !important;
}

.topPad2 {
  padding-top: 2px !important;
}

.bottomPad10 {
  padding-bottom: 10px !important;
}

.left12 {
  margin-left: 12px !important;
}

.regularFont {
  font-size: 1.4rem;
  font-family: "PublicSans Regular", Helvetica, Arial, sans-serif;
  word-wrap: break-word;
  margin-bottom: -5px;
  line-height: normal;
}

.boldFont {
  color: #151F28;
  font-weight: 700;
  font-size: 1.4rem;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  padding-top: 2px;
}

.customer p {
  line-height: normal;
}

.driver {
  align-self: left;
}

.orderNumber {
  color: #0A63F3;
  background-color: initial;
  font-size: 16px;
  height: 24px;
  padding: 0;
  border: none;
}

.orderNumber:hover {
  cursor: pointer;
  border-bottom: 1.8px solid #0A63F3;
}

.order {
  align-self: left;
}

.orderScaleTrax {
  align-self: left;
}

.weightTable {
  font-size: 1.5rem;
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 30px;
}
.weightTable td,
.weightTable th {
  padding: 10px 10px 10px 0px;
}
.weightTable tr:first {
  width: 66%;
}

.weightTableLabel {
  text-align: left;
  color: #151F28;
  font-size: 14px;
  line-height: 1.14;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
}

.weightTableValue {
  text-align: right;
}

.scaleType {
  margin-top: 20px;
}

.scaleName {
  margin-top: 20px;
}

.statusesRMTicket {
  align-self: left;
  /*  margin-top: 25px; */
  margin-bottom: 40px;
}

.statusesNONRMTicket {
  align-self: left;
  margin-bottom: 40px;
}

.material {
  align-self: flex-start;
}

.gridTwoColumn {
  grid-template-columns: 50% 50%;
  display: grid;
  margin-bottom: -5px;
}

.gridThreeColumn {
  grid-template-columns: 33% 33% 33%;
  display: grid;
  margin-bottom: -5px;
}

.gridFourColumn {
  grid-template-columns: 25% 25% 25% 25%;
  display: grid;
  margin-bottom: -5px;
}

.gridFiveColumn {
  grid-template-columns: 40% 60%;
  display: grid;
  margin-bottom: -5px;
}

.gridOneThreeColumn {
  grid-template-columns: 25% 75%;
  display: grid;
  margin-bottom: -5px;
}

.materialNum {
  align-self: start;
}

.mixDescription {
  align-self: center;
}

.uom {
  align-self: center;
}

.quantity {
  align-self: center;
}

.loadNum {
  align-self: center;
}

.loadNumReadyMix {
  align-self: center;
}

.slump {
  align-self: center;
  margin-left: -30px;
}

.weight {
  align-self: left;
}

.notes {
  align-self: center;
  margin-top: 40px;
}

.manuallyEditedWeights {
  color: #878e93;
  font-size: 1.3rem;
  font-style: italic;
  line-height: 1;
  margin-left: -2px;
}

.weightValue {
  line-height: 1 !important;
  margin: 4px 0 3px 0 !important;
}

.projectName {
  font-size: 1.5rem !important;
  margin: -3px 0 0 0 !important;
}

.customerUnavailable {
  color: #878e93 !important;
  font-style: italic !important;
}

.ticketDetailsHeader {
  visibility: hidden;
  font-size: 0;
}

.additionalProductsTable th {
  font-family: "PublicSans Regular", Helvetica, Arial, sans-serif;
  color: #878e93;
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  padding: 6px 0;
}

.additionalProductsTable td {
  margin: 0;
  padding: 8px 0 8px 2px;
}

.additionalProductsTable {
  margin-bottom: 16px !important;
}

.icon i {
  margin-right: 8px;
}

.notAvailable {
  color: #a5abaf;
}

.ticketOpen {
  font-size: 1.4rem;
  font-family: "PublicSans Regular", Helvetica, Arial, sans-serif;
  font-style: italic;
  color: #00884F;
  word-wrap: break-word;
  margin-bottom: -5px;
  line-height: normal;
}

.ticketClosedBy {
  font-size: 1.4rem;
  font-family: "PublicSans Regular", Helvetica, Arial, sans-serif;
  font-style: italic;
  word-wrap: break-word;
  margin-bottom: -5px;
  line-height: normal;
}

@media print {
  .ticketDetailsBody {
    padding: 32px 30px;
  }
  .ticketDetailsHeader {
    border-bottom: 2px solid #151F28;
    margin: 26px 30px 0 30px;
    visibility: visible;
  }
}

