@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.containerStyle {
  display: grid;
  width: 320px;
  height: 100vh;
  grid-template-columns: 100% 0;

  &[data-showing="yes"] {
    width: 640px;
    grid-template-columns: 50% 50%;
  }

  &[data-expanded="true"] {
    width: 100vw;
    grid-template-columns: 320px 1fr;

    @media (max-width: 640px) {
      grid-template-columns: 100% 100%;

      &[data-showing="yes"] {
        translate: -100% 0;
      }
    }
  }

  &[data-expanded="false"] {
    transition: width 200ms;
  }
}

.sidebarContainer,
.sidebarContainerWithTickets {
  @extend %flex;
  overflow: hidden !important;
}

.sidebarContainer {
  height: 100%;
}

.sidebarContainerWithTickets {
  height: calc(55% + 55px);
}

[data-expanded="false"] .panel {
  top: 0 !important;
  height: 55px !important;
  width: 52px !important;
  right: 88px !important;
  background-color: transparent !important;
  box-shadow: none !important;
  z-index: 9999 !important;
}

.panel i {
  color: $white !important;
  font-size: 16px !important;
  margin: 5px !important;
}

.panel:hover i {
  color: $gray-300 !important;
  margin: 5px !important;
}

@media (max-width: $breakpoint-xlarge) {
  .overlayPanelOpen {
    position: fixed;
    top: 0px;
    left: 302px;
    right: 0;
    height: 100vh !important;
    background: rgba(0, 0, 0, 0.4);
    z-index: -1 !important; // stacks under dialog which is 1400
  }
}