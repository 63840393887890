.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-center,
.flex-horizontal-center,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.margin-0 {
  margin: 0 !important;
}

.margin-top-0 {
  margin-top: 0;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-right-20 {
  margin-right: 20px;
}

.margin-right-30 {
  margin-right: 30px;
}

.margin-right-40 {
  margin-right: 40px;
}

.margin-left-5 {
  margin-left: 5px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-left-30 {
  margin-left: 30px;
}

.margin-left-40 {
  margin-left: 40px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

.wrap-words {
  word-break: initial;
  line-height: 1.1;
}

.nowrap-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

.pre-wrap {
  white-space: pre-wrap;
}

.height-100 {
  height: 100%;
}

.icon-md {
  font-size: 1.8rem;
}

.icon-lg {
  font-size: 2rem;
}

.icon-xl {
  font-size: 3rem;
}

.icon-people-margin {
  position: relative;
  top: 2px;
  margin-right: 3px;
}

.underline {
  text-decoration: underline;
}

.clickable {
  cursor: pointer;
}

.contentContainer {
  padding: 20px 24px 27px 24px;
  background-color: white;
  overflow: auto;
  display: table-caption;
  margin: -113px -1px 20px 0;
  width: 43.4rem;
}
.contentContainer input[type=text].tt-input {
  min-height: 2rem;
}

.resultCount {
  margin: 12px;
  display: flex;
  justify-content: space-between;
}

.floatClear {
  clear: both;
}

.smallFont {
  font-size: 14px;
  line-height: 20px;
  color: #151F28;
}

.textButton {
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  border: none;
  background-color: white;
  color: #0A63F3;
  cursor: pointer;
  padding-left: 10px;
}

.clearSearch {
  font-size: 14px;
  font-weight: bold;
  border: none;
  background-color: white;
  color: #0A63F3;
  cursor: pointer;
  padding-left: 10px;
}

.notApplicable {
  color: #a5abaf;
}

.checkboxUnchecked {
  color: #636b71 !important;
}

.checkboxChecked {
  color: #008EF3 !important;
}

.sort {
  height: 50%;
  display: flex;
  align-items: center;
  margin-left: 3px;
}

.cellTruck {
  overflow: hidden;
}

.truckText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.deactivatedText {
  color: #636b71;
  font-size: 11px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

