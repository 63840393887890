.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-center,
.flex-horizontal-center,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.wrapperDetails {
  grid-template-columns: repeat(5, 1fr);
}

.wrapperEdit {
  grid-template-columns: repeat(5, 1fr);
}

.checklistsName {
  grid-column-start: 1;
  grid-column-end: 4;
}
.checklistsName p {
  font-size: 3rem !important;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif !important;
  line-height: 1 !important;
  margin: -5px 0 -20px 0 !important;
  display: inline-block;
  transform: translateY(1px);
}

.checklistsProductLines {
  grid-column-start: 1;
  grid-row: 2;
}

.checklistsOptions {
  grid-column-start: 2;
  grid-row: 2;
}

.checklistsWhenPresent {
  grid-column: 3/5;
  grid-row: 2;
}

.optionsCtr {
  white-space: nowrap;
}

.optionItemCtr {
  display: inline-block;
  margin-right: 8px;
}

.checklistDetails {
  grid-row-start: 2;
}

.name {
  grid-row: 1;
  grid-column: 1/3;
}

.regions {
  grid-row: 1;
  grid-column: 3/5;
}

.productLines {
  grid-row: 2;
  grid-column-start: 1;
}

.optionsFormats {
  grid-row: 2;
  grid-column-start: 2;
}

.whenToPresent {
  grid-row: 2;
  grid-column: 3/5;
}

.iconClose {
  color: #E92851 !important;
  font-size: 14px;
  margin-right: 3px;
}

.iconCheck {
  color: #00AC4A !important;
  font-size: 14px;
  font-weight: bold;
  margin-right: 3px;
  margin-left: 3px;
}

@media (max-width: 1280px) {
  .name {
    grid-column: 1/4;
  }
  .regions {
    grid-row: 2;
    grid-column: 1/4;
    margin-bottom: 30px;
  }
  .productLines {
    grid-row: 3;
    grid-column-start: 1;
  }
  .optionsFormats {
    grid-row: 3;
    grid-column-start: 2;
  }
  .whenToPresent {
    grid-row: 3;
    grid-column: 3/6;
  }
}
@media (max-width: 1160px) {
  .regions {
    margin: -10px 0 10px 0;
  }
  .productLines {
    grid-column: 1/2;
  }
  .optionsFormats {
    grid-row: 4;
    grid-column: 1/2;
  }
  .whenToPresent {
    grid-row: 5;
    grid-column: 1/4;
  }
}
@media (max-width: 1060px) {
  .name,
  .regions,
  .whenToPresent {
    grid-column: 1/5;
  }
}

