@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';
@import '../../../../styles/OrderStatuses.scss';

.wrapper {
  width: 100%;
  border-bottom: solid 1px $gray-200;
  background: white;
  padding: 10px 0 10px 2px;
  display: grid;
  grid-template-columns: 60px 1fr 69px;
  transition: $transition;

  &:hover {
    background: $gray-100;

    .notificationArrowIcon {
      transform: translateX(7px);
      color: $darkblue;
    }
  }
}

.icon {
  align-self: center;
  justify-self: center;
  color: $gray-500;
}

.notificationTypeIcon {
  font-size: 2.4rem;
}

.notificationArrowIcon {
  transition: $transition;
  transform: translateX(2px);
}

.title {
  font-size: 16px;
  color: $gray-900;
  margin: 1px 0 0 0;
}

.description {
  display: inline-block;
  transform: translateY(-4px);
  font-size: 1.3rem;
  color: $gray-900;
  margin: 0;
  margin-right: 5px;

  b {
    font-family: $font-family-bold;
    display: inline-block;
    transform: translateY(4px);
    max-width: 36rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 3px;
  }
}

@media (max-width: $breakpoint-small) {
  .description b {
    max-width: 31rem;
  }
}

@media (max-width: $breakpoint-xsmall) {
  .description b {
    max-width: 27.5rem;
  }
}