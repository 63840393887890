.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-center,
.flex-horizontal-center,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.container :global(.ReactTable) {
  padding-bottom: 39px;
}
.container .tabsContainer {
  border-bottom: 1px solid #c8cdd0;
  margin-top: -10px;
}
.container .headerContainer {
  position: relative;
}
.container .checkboxContainer {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 3px;
}
.container .checkboxTitle {
  line-height: 1;
  color: #151F28;
  font-weight: normal;
  font-stretch: normal;
  text-transform: capitalize;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  font-size: 1.2rem;
}
.container .checkboxLabel {
  line-height: 1;
  color: #151F28;
  font-weight: bolder;
  font-stretch: normal;
  text-transform: capitalize;
  font-family: "PublicSans Regular", Helvetica, Arial, sans-serif;
  font-size: 1.4rem;
  margin-left: -5px;
}
.container .checkboxLabelContainer {
  margin: 0 0 0 1px !important;
}

@media (max-width: 1455px) {
  .checkboxTitle {
    display: none;
  }
  .checkboxLabel {
    margin-left: -8px !important;
  }
}

