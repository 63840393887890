@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.container {
  height: calc(100% - 40px) !important;
}

.paper {
  height: 100%;
  border-radius: 3px 3px 0 0 !important;
  box-shadow: none !important;
  background-color: $gray-100 !important;

  >div {
    padding-bottom: 65px;
  }
}

.header {
  background-color: $white;
  padding: 23px 0 0px 35px;
  margin: 0;

  h2 {
    margin: 0;
    padding: 0;
    font-size: 24px;
    font-family: $font-family-bold;
    color: $gray-900;
  }
}