@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_helpers.scss';

.productLinesContainer {
  margin-left: 31px;
  margin-bottom: 5px;

  .singleProductLine {
    color: $white;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
}

.productLines {
  color: $gray-400 !important;
}

.radioBtnSize {
  height: 10px;
  width: 10px;
  margin-left: 13px !important;
}

.radioBtn {
  color: $gray-600 !important;
  padding: 12px !important;
  margin-left: 10px !important;
  @extend .radioBtnSize;

  :global(.MuiSvgIcon-root) {
    font-size: 2.4rem !important;
  }
}

.radioBtnSelected {
  color: $blue !important;
  @extend .radioBtnSize;
}

.radioLabel {
  font-size: 16px !important;
  font-weight: 400 !important;
  margin: 4px !important;
  color: $white;
}

.radioLabelDisabled {
  color: $gray-600 !important;
  cursor: not-allowed !important;
}

.radioBtnDisabled {
  color: $gray-600 !important;
}