@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.card {
  display: flex;
  min-height: 100px;
  width: 100%;
  max-width: 1000px;
  margin: 10px 40px;
  padding: 10px 30px;
  border-radius: $border-radius;
  box-shadow:
    0px 1px 5px 0px rgba(0, 0, 0, 0.15),
    0px 2px 2px 0px rgba(0, 0, 0, 0.09),
    0px 3px 1px -2px rgba(0, 0, 0, 0.07) !important;
  background: white;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  @extend %flexVerticalCenter;
}

.text {
  display: flex;
  flex: 0 1 100%;
  min-width: 40%;
  flex-direction: column;
  overflow-wrap: break-word;
  padding-right: 10px;
}

.primaryText {
  font-family: $font-family-bold;
  font-size: 16px;
}

.secondaryText {
  font-family: $font-family-regular;
  font-size: 14px;
}

.paddingLef {
  padding-left: 5px;
}

.featureButtons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;

  :global(.MuiSwitch-colorPrimary.Mui-checked) {
    :global(.MuiSwitch-thumb) {
      color: $blue;
    }
  }

  :global(.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track) {
    background-color: $blue;
  }
}

.switchLabel {
  font-family: $font-family-regular !important;
  font-size: 1.4rem !important;
}