@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_helpers.scss';
@import '../../../styles/geotrax_vars.scss';

.mapPanelContent {
  overflow: hidden;
  margin-top: $panelContentMarginOffset;
  display: flex;
  flex-flow: column;
  height: calc(100vh - 150px);
  width: 24rem;
}

.regionSelect {
  padding: 0 3.2rem 0.3rem 3.2rem;
  margin-bottom: 1.3rem;
  display: flex;
  flex-direction: column;

  .label {
    font-size: 1.3rem;
    color: $gray-400;
    font-family: $font-family-bold;
    margin: 0 0 6px 0 !important;
    padding: 0;
    line-height: 1;
  }

  .singleRegion {
    color: $white;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
}

.selectContainer {
  width: 100% !important;
}

// Region select dropdown
.selectField {
  height: 4rem !important;
  box-sizing: border-box !important;
  padding: 0.4rem 3.3rem 0.2rem 1.3rem !important;
  background: $gray-800 !important;
  box-shadow: $button-shadow-dark;
  border-radius: $border-radius !important;
  transition: $transition;
  color: white !important;
  font-size: 1.6rem !important;
  line-height: 3.25rem !important;

  &:hover {
    background: $gray-700 !important;
  }

  &.disabled:hover {
    background-color: $gray-800 !important;
  }

  &:focus,
  &:global(.open) {
    box-shadow: $button-shadow-dark, inset 0 0 0 2px $blue !important;
    background: $gray-800 !important;
  }

  svg {
    display: none;
  }
}

.arrowIcon {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 2rem;
  line-height: 3.8rem;
  pointer-events: none;
  color: white;
}

.arrowHidden {
  display: none !important;
}

.disabled {
  -webkit-text-fill-color: $white !important;
  background: $gray-800 !important;
  box-shadow: none !important;
  cursor: not-allowed !important;

  * {
    cursor: not-allowed !important;
  }

  svg {
    display: none;
  }
}

.menuStyle {
  margin-top: 5px !important;
  background: $gray-800 !important;
  border-radius: $border-radius !important;
  transform-origin: 50% 0 !important;
  box-shadow: 0 0 9px #101010 !important;
}

.menuItem {
  color: $gray-100 !important;
  display: flex !important;
  padding: 6px 16px !important;
  justify-content: flex-start !important;
  transition: $transition;

  &:hover {
    cursor: pointer;
    background: $gray-700 !important;
  }
}

.menuItemSelected {
  background: #004478 !important;

  &:hover {
    background: #004478 !important;
    cursor: default;
  }
}

.folder {
  background: $gray-800;
  border-bottom: solid 1px $gray-700;
}

.reportsBtn {
  width: 100%;
  font-size: 1.6rem;
  background: transparent;
  color: $white;
  border: transparent;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  line-height: 1;
  height: 4rem;
  transition: $transition;
  margin: -7px 0 -5px 0;

  &:hover {
    cursor: pointer;
    color: $blue;
  }

  &:active {
    color: $darkblue;

    .reportsBtnIcon {
      color: $darkblue;
    }
  }
}

.reportsBtnIcon {
  padding-right: 6px;
  color: $blue;
  transform: translateX(-1px);
}

.reportsModal {
  width: 26%;
  min-width: 500px !important;
}

.reportsBtnContainer {
  margin: 2px 0 17px 0;
  padding: 0 3.2rem;
}

.productLineTabsContainer {
  padding: 0 3.2rem;
  margin-bottom: 1.5rem;
}

.mapPanelLowerContent {
  overflow-y: auto;
  flex: 1;
  @include niceScrollbar;
}

.productLinesContainer {
  margin-left: 31px;
  margin-bottom: 5px;
}

.productLines {
  color: $gray-400 !important;
}

.radioBtnSize {
  height: 10px;
  width: 10px;
  margin-left: 13px !important;
}

.radioBtn {
  color: $gray-600 !important;
  padding: 12px !important;
  margin-left: 10px !important;
  @extend .radioBtnSize;

  :global(.MuiSvgIcon-root) {
    font-size: 2.4rem !important;
  }
}

.radioBtnSelected {
  color: $blue !important;
  @extend .radioBtnSize;
}

.radioLabel {
  font-size: 16px !important;
  font-weight: 400 !important;
  margin: 4px !important;
  color: $white;
}