.composeMessage {
  padding: 2.2rem;
  background: #e1e4e7;
}
.composeMessage textarea {
  background: white !important;
  border: 1px solid #c8cdd0;
  resize: none;
  height: 180px;
  box-sizing: border-box;
}

.footer {
  margin-top: 2.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.characters {
  font-size: 1.3rem;
  line-height: 1;
}

.maxCharcount {
  font-weight: 700;
  color: #E92851;
}

.textareaContainer {
  position: relative;
  width: 100%;
  max-width: 400px;
}

.textAreaWithIcon {
  width: 100%;
  padding-right: 30px !important;
}

.iconKeyboardArrowDown {
  font-size: 24px;
  /* Adjust the size as needed */
  width: 24px;
  /* Optional: Adjust width */
  height: 24px;
  /* Optional: Adjust height */
  color: #636B71;
}

.textareaIcon {
  position: absolute;
  right: 10px;
  /* Adjust positioning as needed */
  top: 8%;
  transform: translateY(-50%);
  cursor: pointer;
  /* Change cursor to pointer */
}

.popper {
  z-index: 1300;
  overflow: hidden !important;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15), 0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 3px 1px -2px rgba(0, 0, 0, 0.07) !important;
  margin-bottom: 1px !important;
  border-radius: 3px;
}

