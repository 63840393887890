@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_helpers.scss';
@import '../../shared/admin/AdminCard.module.scss';

.wrapper {
  display: flex;
  flex-wrap: wrap;

  >div {
    height: auto !important;
    @extend %ifValueEmpty;
    width: calc(33.33% - 20px);

    &:first-of-type {
      width: 100% !important;

      p {
        font-family: $font-family-bold;
      }
    }
  }

  .buttonClass {
    margin-bottom: -2rem !important;
    transform: translateY(-0.4rem) !important;
    text-align: left !important;
  }

  p {
    margin-top: -4px !important;
  }

  &.editWrapper {
    >div {

      &:not(:last-of-type),
      &:first-of-type,
      &:last-of-type {
        width: 100% !important;
        max-width: 500px;
      }
    }

    .fullName {
      display: none !important;
    }
  }
}

@media (max-width: $breakpoint-admindetails) {
  .wrapper {
    >div {

      &:not(:last-of-type),
      &:first-of-type,
      &:last-of-type {
        width: 100% !important;
      }
    }
  }
}