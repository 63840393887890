.infoBoxWrapper {
  overflow: visible !important;
}

.infoBox {
  overflow: visible !important;
  min-width: 400px;
  width: fit-content;
  filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.4));
}

.arrowTop {
  top: -1.1rem !important;
  transform: rotate(135deg) translate(0.9rem, 1.1rem) !important;
  background: #2A353E !important;
}

.arrowBottom {
  bottom: -1.3rem !important;
  transform: rotate(-45deg) translate(-0.9rem, -1.1rem) !important;
  background: white !important;
}

.arrowLeftTop {
  top: 1.2rem;
  left: 0 !important;
  transform: rotate(45deg) translate(-0.9rem, 0.9rem) !important;
  background: #2A353E !important;
}

.arrowRightTop {
  top: 1.2rem;
  right: 0 !important;
  transform: rotate(-135deg) translate(-0.9rem, 0.9rem) !important;
  background: #2A353E !important;
}

.arrowLeftBottom {
  left: 0 !important;
  transform: rotate(45deg) translate(-0.9rem, 0.9rem) !important;
  background: white !important;
}

.arrowRightBottom {
  right: 0 !important;
  top: 50%;
  transform: rotate(-135deg) translate(-0.9rem, 0.9rem) !important;
  background: white !important;
}

.arrow, .smallArrow, .arrowTop, .arrowLeftTop, .arrowRightTop, .bigArrow, .arrowBottom, .arrowLeftBottom, .arrowRightBottom {
  content: "";
  position: absolute;
  width: 2.7rem;
  height: 2.7rem;
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  border-radius: 0 0 0 5px;
  transform-origin: 50% 50% !important;
}

.bigArrow, .arrowBottom, .arrowLeftBottom, .arrowRightBottom {
  border-width: 2.7rem;
}

.smallArrow, .arrowTop, .arrowLeftTop, .arrowRightTop {
  border-width: 1.1rem;
}

