@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';

@mixin beforeDot {
  content: '\A';
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: $gray-400;
  display: inline-block;
  margin-left: 2px;
  margin-right: 4px;
  position: relative;
  top: 1px;
}

.orderStatus {
  font-family: $font-family-bold;
  font-size: 13px;
  line-height: 16px;
  color: $gray-900;
  display: inline-block;
  transform: translate(-1px, -4px);
  margin: 0;
  padding: 0;
  white-space: nowrap;

  &:before {
    @include beforeDot();
  }
}

.willCall {
  &:before {
    background-color: $blue !important;
  }
}

.ship {
  &:before {
    background-color: $green !important;
  }
}

.hold {
  &:before {
    background-color: $yellow !important;
  }
}

.fob {
  &:before {
    background-color: $purple !important;
  }
}

.canceled {
  &:before {
    background-color: $red !important;
  }
}