@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

label {
  color: $gray-900;
  font-size: 14px;
  font-family: $font-family-regular !important;
  font-weight: bold;
}

.dropdown {
  width: 140px;
  margin-right: 10px;
  margin-top: -2px;

  >div {
    margin-top: 8px;
  }
}