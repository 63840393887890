@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_helpers.scss';

.wrapper {
  display: flex;
  flex-wrap: wrap;

  >div {
    flex-grow: 1 !important;
    height: auto !important;

    &:not(:last-of-type) {
      width: 43% !important;
    }

    &:first-of-type,
    &:last-of-type {
      width: 100% !important;
    }

    p:first-of-type {
      margin-bottom: -2rem !important;
      transform: translateY(-.4rem) !important;
    }

    p:not(:first-of-type) {
      margin-top: 1.4rem !important;
    }
  }

  &.editWrapper {
    >div {

      &:not(:last-of-type),
      &:first-of-type,
      &:last-of-type {
        width: 100% !important;
        max-width: 500px;
      }
    }

    p:first-of-type {
      margin-bottom: -2rem !important;
      margin-right: 5rem;
      transform: translateY(-.4rem) !important;
    }

    p:not(:first-of-type) {
      margin-top: 1.4rem !important;
    }

    .name {
      display: none !important;
    }

    .addressGroup {
      margin-top: -5rem;

      &>div {
        margin-top: 5rem !important;
      }
    }
  }
}

.wrapper {
  >div {

    &:not(:last-of-type),
    &:first-of-type,
    &:last-of-type {
      width: 100% !important;
    }
  }
}

.city {
  width: 261px;
}

.state,
.zip,
.address {
  position: relative;
  margin-bottom: -200px;
}

.state {
  width: 118px;
  bottom: 110px;
  left: 282px;
}

.zip {
  width: 156px;
  bottom: 21px;
  left: 422px;
}

.address {
  bottom: 3.1em;
}