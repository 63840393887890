@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.adminContainer {
  width: 100%;
  height: 100%;
  overflow: auto;
  background: $gray-100;
  padding: 30px 30px 60px 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.addFeatureFlagModal {
  width: 75%;
  max-width: 1000px !important;
}

.featureFlagContainer {
  height: 100% !important;
}