@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.filters {
  display: flex;
  align-items: center;
  ;
  margin-bottom: 20px;
  justify-content: space-between;
}

.dateRangePickerWrapper {
  max-width: 400px;
}