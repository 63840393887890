@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';

.csvContainer {
  .modalContent {
    padding: 25px;

    .csvForm {
      display: flex;
      flex-direction: column;
    }

    .csvInputTitle {
      font-family: $font-family-bold;
      font-size: 13px;
      color: $gray-900;
      font-weight: bold;
    }

    .cardRadio {
      margin-bottom: 10px;
    }

    .fileNameHeader {
      margin: 10px 0 0 0;
      padding: 0;
      line-height: 1;
    }

    .fileName {
      margin: 5px 0 13px 0 !important;
      color: $gray-900;
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .fileNameError {
      margin: 5px 0 15px 0 !important;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      & * {
        color: $darkred !important;
      }
    }

    .fileNameText {
      line-height: 1;
      margin-bottom: 4px;
    }
  }

  .featureFooter {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 12px 24px;
    border-top: 1px solid $gray-200;
  }

  .textRed {
    margin-bottom: 3px;
    color: $darkred;
    font-family: $font-family-regular;
    font-size: 13px;
  }
}

.input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputLabel {
  width: fit-content;
  margin: 0;
  padding: 7px 15px;
  height: 4rem;
  font-size: 1.6rem;
  cursor: pointer;
  border-radius: $border-radius;
}

.errorList {
  display: flex;
  flex-direction: column;
  margin: -13px 0 4px 0;
}

.listWarning {
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.listWarningText {
  font-size: 13px;
  font-family: $font-family-bold;
  color: $gray-900;
  margin-bottom: 3px;
}

.unorderedList {
  font-size: 13px;
  list-style: outside disc;
  padding-left: 18px;
  color: $gray-900;
  margin-top: 3px;
}

.errorItem:not(:first-child) {
  margin-top: 30px;
}

.csvIcon {
  margin: 9px 6px 10px 1px;
  color: $gray-600;
}

.warningIcon {
  font-size: 16px;
  margin: 0 3px 0 1px;
  color: $darkred;
}