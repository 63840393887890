@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.detailHeader {
  background: white;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15),
    0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 3px 1px -2px rgba(0, 0, 0, 0.07) !important;
  clip-path: inset(0px 0px -20px 0px) !important;
  padding: 7px 20px 10px 20px;
  width: 100%;
  z-index: 4;
  height: 62px !important;
}

.asterisk {
  color: #e92851;
  font-family: $font-family-bold;
  font-size: 1.8rem;
  display: inline-block;
  transform: translateY(3px);
  margin-left: 0.3rem;
  line-height: 0.5;
}

.label {
  font-weight: bolder;
  line-height: 1;
  font-size: 1.3rem;
  font-family: $font-family-bold;
  color: #151f28;
  padding-bottom: 5px;
}

.formField {
  margin-bottom: 30px;
}

.loading {
  min-width: 300px;
  min-height: 200px;
  overflow: hidden;
  align-items: center;
}

.loadingText {
  overflow: hidden;
  font-size: 24px;
  font-weight: bolder;
  text-align: center;
  padding-bottom: 25px;
}

.spinner {
  overflow: hidden;
  padding-bottom: 15px;
  margin-left: 105px;
}

.buttons {
  width: 250px;
  display: flex;
  justify-content: space-between;
}

.addQtyBtn {
  border-radius: 100px !important;
  background-color: #f4f7f9 !important;
  min-width: 0px !important;
  min-height: 0px !important;
  width: 36px !important;
  height: 36px !important;
  margin: 0px 0px 0px 0px !important;
  padding: 0px 0px 0px 0px !important;
  line-height: 18px;
}

.addQtyBtn i {
  font-size: 10px !important;
  color: blue !important;
  display: block;
}

.printQuantityDiv {
  display: flex;
  margin-top: 10px;
  align-items: center;
}

.printCountQty {
  height: 12px !important;
  font-size: 12px;
  font-weight: 800;
  line-height: 1;
  margin-right: 6px;
}

.printCount {
  height: 12px !important;
  margin-left: 12px;
  font-size: 12px;
  font-weight: 800;
  line-height: 1;
  margin-right: 12px;
}

.bodyWrapper {
  flex-grow: 1;
  overflow-y: auto;
}

.cardWrapper {
  padding: 40px 100px;
  position: relative;
  background: $gray-100;
}

.ticketNumber {
  width: 146px;
  height: 20px;
  font-size: 14px;
  font-weight: bolder;
  font-stretch: normal;
  font-style: normal;
  padding-left: 11px;
  line-height: 1.43;
  letter-spacing: normal;
}

.goBack {
  width: 146px;
  height: 20px;
  font-size: 14px;
  font-weight: bolder;
  font-stretch: normal;
  font-style: normal;
  padding-top: 3px;
  padding-left: 11px;
  line-height: 1.43;
  letter-spacing: normal;
  color: #0a63f3;
}

.goBackText {
  cursor: pointer;
}

.backArrow {
  cursor: pointer;
  padding-right: 5px;
  padding-left: 0px;
  letter-spacing: 2px;
  height: 7px !important;
  transition: $transition;
  color: #0a63f3;

  &:hover {
    opacity: 0.6;
  }
}

.printButton {
  width: 115px;
  height: 36px;
  margin: 8px 0 0 8px;
  padding: 0;
  text-align: center;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.07), 0 2px 2px 0 rgba(0, 0, 0, 0.09),
    0 1px 5px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $gray-100;

  &:hover {
    background-color: #e2f8ff;
  }
}

.printSection {
  padding: 14px 16px;
}

.printIconStyle {
  height: 15px;
}

.printButtonText {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  padding-left: 6px;
  line-height: 1.43;
  letter-spacing: normal;
  color: blue; //#0a63f3
}

.paper {
  max-height: calc(100% - 55px) !important;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  transition: none !important;
  transform: unset !important;
  overflow: hidden;
  width: 100%;
  height: 100%;
}