.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-center,
.flex-horizontal-center,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.ticketContentContainer {
  height: calc(100% - 5.4rem) !important;
}

.routeInfoContainer {
  display: flex;
  width: 100%;
  height: 100% !important;
}

.routeInfoSidebar {
  flex-basis: 395px;
  flex-grow: 0;
  flex-shrink: 0;
  height: 100% !important;
  border-radius: 0 !important;
  z-index: 1;
  padding: 34px;
  overflow-y: auto;
}

.titleValue {
  padding-left: calc(1.6rem + 10px);
}

.markerIcon {
  color: #434c53 !important;
}

.ticketRouteHeader {
  visibility: hidden;
  display: none;
  font-size: 0;
}

.mapContainer {
  height: 100%;
  width: 100%;
}

@media print {
  .ticketRouteHeader {
    visibility: visible;
    display: block;
    border-bottom: 2px solid #151F28;
    margin: 26px 30px 20px 30px;
  }
  .routeInfoContainer {
    padding-left: 30px !important;
    padding-right: 30px !important;
    overflow: auto !important;
    height: auto !important;
  }
  .routeInfoSidebar {
    padding: 0 20px 0 0;
    flex-basis: 270px;
  }
  .routeInfoSidebar button {
    padding: 0 10px 0 10px;
    height: 27px;
  }
  .mapContainer {
    height: 400px !important;
  }
}

