.geotraxPagination {
  width: 400px;
  height: 45px;
  z-index: 1;
  display: flex;
  align-items: stretch;
  justify-content: center;
  padding: 3px;
  margin: 20px auto;
}
.geotraxPagination :global(.-center) {
  margin: 0;
  display: flex;
}

.paginationPrevLabel::after {
  font-family: "icomoon";
  content: "\e909";
  color: #0A63F3;
  font-size: 12px;
}

.paginationNextLabel::after {
  font-family: "icomoon";
  content: "\e90d";
  color: #0A63F3;
  font-size: 12px;
}

.geotraxPaginationBtn[disabled].paginationNextLabel::after {
  color: #BD0029;
}

.geotraxPaginationBtn[disabled] {
  background: #c8cdd0;
  cursor: default;
}

.geotraxPageBtn {
  font-size: 1.6rem;
  outline: none;
  border: none;
  background-color: transparent;
  border-radius: 3px;
  cursor: pointer;
  padding: 7px 12px 11px 12px;
  width: 4rem !important;
  height: 4rem !important;
  transition: 0.3s !important;
  background: #f4f7f9;
  color: #0A63F3;
}
.geotraxPageBtn:hover {
  background: #e1e4e7;
}
.geotraxPageBtn:focus {
  outline: none;
}
.geotraxPageBtn:disabled {
  cursor: default !important;
}
.geotraxPageBtn:disabled:hover {
  background-color: transparent !important;
  cursor: default !important;
}
.geotraxPageBtn:disabled .paginationNextLabel::after,
.geotraxPageBtn:disabled .paginationPrevLabel::after {
  display: none !important;
}

.geotraxPageBtnActive {
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
  outline: none;
  border-left: none;
  border-top: none;
  border-right: none;
  background-color: transparent;
  border-bottom: 2px solid #F66A00;
  padding: 7px 12px 9px 12px;
  background: #f4f7f9;
  width: 100%;
  height: 100%;
  position: relative;
}

.paginationButton {
  display: inline-block;
  height: 4rem;
  width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginationEllipse {
  display: inline-block;
}

.ellipse {
  display: inline-block;
  font-size: 15px;
  outline: none;
  border: none;
  background-color: transparent;
  margin: 4px 0;
  padding: 4px 12px;
}

