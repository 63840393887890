@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';

.scalesAddModal {
  @extend %adminModalWidth;
  max-width: 520px !important;
}

.tareExpirationDays {
  margin-bottom: 40px;
}

.tareExpirationDays>div {
  width: 170px !important;
  max-width: 170px !important;
}

.label {
  display: block;
  margin: 0 0 0.8rem 0;
  line-height: 1;
  font-size: 1.3rem;
  font-family: $font-family-bold;
  color: $gray-900;
  white-space: nowrap;
}

.asterisk {
  color: $red;
  font-family: $font-family-bold;
  font-size: 1.8rem;
  display: inline-block;
  transform: translateY(3px);
  margin-left: 0.3rem;
  line-height: 0.5;
}

.licensePlate {
  display: inline-block;
  min-width: 72px;
  margin-right: 30px;
}

.licenseState {
  display: inline-block;
  min-width: 72px;
  vertical-align: top;
}