@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.plusButton {
  display: flex !important;
  margin-right: 20px;
  box-shadow: none !important;
  justify-content: flex-end;
  background: transparent;
  float: right;
  border: none;
}

.textRight {
  float: right;
  text-align: right;
}

.textRed {
  color: $darkred;
}

.textTitle {
  font-weight: bold;
}

.marginRight {
  margin-right: 20px !important;
}

.marginLeft {
  margin-left: 5px !important;
}

.marginTop {
  margin-top: 25px;
  margin-bottom: 5px;
}

.descInput {
  margin-top: 25px !important;
  width: 90%;
}

.featureFooter {
  margin-top: 24px;
  @extend %flexSpaceBetween;
  @extend %flexVerticalCenter;
  position: relative !important;
  padding: 12px 24px;
  font-size: 16px;
  border-top: 1px solid $gray-200;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  padding: 2.2rem;
  background: #e9edf0;
}

.titleInput {
  width: 50%;
  padding: 0 24px;
  margin-top: 25px !important;
}

.descInput {
  width: 100%;
  padding: 0 24px;
}

.permissionsLabel {
  padding: 0 24px;
  font-size: 16px;
  color: $gray-600;
  margin-top: 25px !important;
}

.flagsDivider {
  margin: -1px 24px 24px;
}

.composeMessage textarea {
  background: white !important;
  border: 1px solid #cfd6da;
  resize: none;
  box-sizing: border-box;
}

.fontFam {
  font-family: $font-family-bold;
}

.permissionsContainer {
  padding: 5px;
  height: 350px;
  padding: 0 24px 24px;
}

.noFeatures {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 88px 0;
  overflow: hidden;
  margin: 0;
  background-color: $gray-100;
  font-size: 16px;
}

.textBlocksContainer {
  transform: translateY(-2.5%);
  color: $gray-900;
}

.textBlock,
.iconBlock {
  display: block;
  text-align: center;
  position: relative;
}

.iconBlock {
  margin-bottom: 10px;
}

.dropDownListContainer {
  width: 17%;
  min-width: 130px;
  vertical-align: top;
}

.searchContainer {
  vertical-align: middle;
  flex: 0 1 100%;
  background: white;
}

.dropDownSearchContainer {
  width: 100%;
  display: flex;
}

.table {
  table-layout: fixed;
}

.tableContainer {
  height: auto;
  max-height: 298px;
  width: 100%;
  overflow-y: auto;
  padding: 0;
  border: 1px solid $gray-200;
  margin-top: 12px;

  .displayUnset {
    display: initial !important;
  }

  // Overriding Material UI's default table cell styles
  td {
    height: 4rem !important;
    padding: 0 0 0 13px !important;
    line-height: 1 !important;
    border-bottom: none !important;
    border-top: 1px solid $gray-200;
    display: table-cell;
    vertical-align: middle;

    i {
      transform: translateY(1px) !important;
      display: inline-block !important;
    }
  }

  th {
    padding: 10px 13px !important;
    line-height: 1 !important;
    background: $gray-100;
    position: sticky !important;
    top: 0 !important;
    box-shadow:
      4px 1px 5px 0px rgba(0, 0, 0, 0.15),
      3px 2px 2px 0px rgba(0, 0, 0, 0.09),
      2px 3px 1px -2px rgba(0, 0, 0, 0.07) !important;
  }
}

.cellText {
  font-size: 1.6rem;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  height: auto;
  line-height: 4rem;
}

.closeButton {
  i {
    color: $gray-500 !important;
    font-size: 1.6rem;
  }

  &:hover>i {
    color: $darkred !important;
  }
}

.removeAllBtn {
  float: right;

  span {
    transform: translateY(-1px) !important;
    display: inline-block;
  }

  &:hover>i,
  &:hover>span {
    color: $darkred !important;
  }
}