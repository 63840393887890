@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_helpers.scss';
@import '../../shared/admin/AdminCard.module.scss';

.usersAddModal {
  @extend %adminModalWidth;
}

.asterisk {
  color: #e92851;
  font-family: $font-family-bold;
  font-size: 1.8rem;
  display: inline-block;
  transform: translateY(3px);
  margin-left: 0.3rem;
  line-height: 0.5;
}

.weighmaster {
  margin-top: -5px;
}