@import '../../../styles/MapComponent.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_helpers.scss';
@import '../../../components/admin/plants/PlantsAddModal.module.scss';

.zoneActionBtn {
  font-family: $font-family-bold !important;
  font-weight: bold;
  font-size: 14px !important;
  color: $txt-body !important;
  background-color: transparent;
  background: transparent !important;
  box-shadow: none !important;
  padding-top: 2px !important;
  cursor: pointer !important;
}

.clearGeozonesBtn {
  @extend .zoneActionBtn;
  width: 50%;

  &:hover,
  &:hover>i,
  &:hover>span {
    color: $red !important;
  }

  i {
    @extend .marginRight;
    color: $red !important;
    font-size: 1.6rem !important;
    display: inline-block;
    transform: translateY(2px);
  }
}

.dupPopper {
  z-index: 9999 !important;
  opacity: 1 !important;
  margin-left: -20px;
}

.dupTooltip {
  background: $primary !important;
  padding: 0.6rem 1rem !important;
  font-size: 1.3rem !important;
  line-height: 1.3 !important;
  text-align: center;
  border-radius: $border-radius !important;
  pointer-events: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 6px;
    pointer-events: none;
    border-right-color: $primary;
    top: 100%;
    right: 44%;
    transform: translateY(0%) rotate(-90deg);
  }
}

.dupAcrossBtn {
  @extend .zoneActionBtn;
  width: 50%;
  border-radius: 0 !important;

  i {
    @extend .marginRight;
    color: $darkblue !important;
    font-size: 1.6rem !important;
    display: inline-block;
    transform: translateY(2px);
  }

  &:hover {
    background-color: $xlightblue !important;
  }
}

.btnDisabled {
  color: $gray-400 !important;

  i {
    color: $gray-400 !important;
  }

  &:hover {
    cursor: default !important;
    color: $gray-400 !important;

    i {
      color: $gray-400 !important;
    }
  }
}

.border {
  border: 1px solid $gray-200;
}

.zoneActionCt {
  white-space: nowrap;
  text-overflow: ellipsis;
  height: 4rem;
}

.redBorder {
  border: 1px solid $darkred;
}

.redBorderMap {
  border: 1.5px solid red;
}

// Tabs styling
.tabs {
  min-height: 41px !important;
  max-height: 41px !important;
  border-bottom: 1px solid $gray-200;

  [class*="MuiTabs-flexContainer"] {
    width: 100%;
  }
}

.tab {
  flex-grow: 1;
  min-height: 41px !important;
  max-height: 41px !important;
  min-width: 60px !important;
  flex-direction: row !important;
}

.marginRight {
  margin-right: 5px;
}

.error {
  font-family: $font-family-regular !important;
  color: $darkred !important;
}

.errorMessageMap {
  font-family: $font-family-regular !important;
  color: $darkred !important;
  margin-bottom: 10px;
}

.noGeozoneContainer {
  text-align: center;
  height: 55px;
  background-color: $xlightorange;
  font-size: 1.3rem;
  padding: 7px !important;
}

.noGeozoneLabel {
  font-size: 1.3rem;
  font-family: $font-family-bold;
  line-height: 1;
  margin: 0 0 3px 0 !important;
  color: $gray-900;
}

.warningIcon {
  margin-right: 4px !important;
  color: $orange;
  font-size: 1.6rem;
  line-height: 1;
  display: inline-block;
  transform: translateY(2px) !important;
}

.editPlantLink {
  padding: 0 !important;
  min-height: 0 !important;
  display: inline-block;

  span {
    font-family: $font-family-bold !important;
    font-size: 1.3rem;
    line-height: 1;
  }

  i {
    font-weight: bolder;
    padding: 0 0.5rem 0 0;
  }

  &:hover span {
    text-decoration: underline !important;
    color: $darkblue !important;
  }

  &:hover i {
    color: $darkblue !important;
  }
}