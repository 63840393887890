@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.tabs {
  width: 100%;
  height: $tabs-height;
  display: flex;
  justify-content: space-between;
  overflow: hidden;

  .count {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: $red;
    top: 1.7rem;
    left: 1.5rem;
  }

  .tab,
  .newMessage {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;
    transition: $transition-ni;
    background: $gray-100;
    border-bottom: 1px solid $gray-200;
    z-index: 1;
  }

  .tab {
    width: 50%;
    border-right: 1px solid $gray-100;
    border-left: 1px solid white;
    border-top: 1px solid transparent;
    outline: 0;

    i {
      color: $gray-600;
      font-size: 1.8rem;
      transition: $transition-ni;
    }

    .title {
      font-size: 1.3rem;
      margin-right: 9px;
      padding: 0;
      line-height: 1;
      letter-spacing: 0.15rem;
      font-family: $font-family-bold;
      text-transform: uppercase;
      color: $gray-600;
      transition: $transition-ni;
    }

    &:hover> {

      .title,
      i {
        color: $txt-body;
      }
    }

    &:focus {
      outline: none;
    }

    &:active {
      border-style: solid;

      .title {
        color: $blue !important;
        transition: none;
      }

      i {
        color: $blue !important;
        transition: none;
      }
    }
  }

  .newMessage {
    width: 4.4rem !important;

    i {
      font-size: 1.8rem;
      color: $gray-600;
      transition: $transition;
    }

    &:hover>i {
      color: $txt-body;
    }
  }

  .selected {
    background: white !important;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.4) !important;
    border-right: 1px solid white;
    border-bottom: 1px solid white;
    z-index: 2;

    .title,
    i {
      color: $txt-body !important;
    }

    &:active {
      border-style: solid;

      .title {
        color: $txt-body !important;
      }

      i {
        color: $txt-body !important;
      }
    }
  }
}