@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

$padding: 16px;
$formLabelHeight: 28px;
$headerHeight: 50px;
$arrowRounding: 5px;
$arrowSize: 25px;

.wrapper {
  position: relative;
}

.paper {
  overflow: visible !important; // this allows the arrow to stick out above the Popover element
  margin-left: 20px;
}

.header {
  width: 100%;
  height: $headerHeight;
  background: $blue;
  border-radius: 0.3rem 0.3rem 0 0;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 2rem;
}

.headerText {
  font-size: 1.3rem;
  font-family: $font-family-bold;
  text-transform: uppercase;
  line-height: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: white;
  letter-spacing: 0.2rem;
}

.arrow {
  display: block;
  height: $arrowSize;
  width: $arrowSize;
  background-color: $white;
  border: inherit;
  position: absolute;
  top: calc(50% + #{$arrowSize});
  left: -$arrowSize * 0.5;
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  transform: rotate(45deg);
  border-radius: 0 0 0 $arrowRounding;
}

.appLogo,
.appLogo img {
  height: auto !important;
  transition: $transition;
  display: inline-block;
  transform: translateY(1px);
}

.newWindowIcon {
  color: $darkblue;
  font-size: 2.2rem;
  line-height: 1.1;
  text-align: left;
  margin-left: 45px;
}

.openAppButton {
  display: flex !important;
  align-items: center;
}