.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-center,
.flex-horizontal-center,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.detailHeader {
  background: white;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15), 0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 3px 1px -2px rgba(0, 0, 0, 0.07) !important;
  clip-path: inset(0px 0px -20px 0px) !important;
  padding-left: 20px;
  width: 100%;
  z-index: 4;
  height: 5.4rem !important;
}

.receiptBtn {
  margin: 7px 17px 7px 0;
  white-space: nowrap;
}

.receiptBtnDisabled {
  background-color: #f4f7f9 !important;
  color: #878e93 !important;
  box-shadow: none !important;
  cursor: not-allowed !important;
}

.receiptBtnIcon {
  font-size: 22px !important;
  vertical-align: text-top !important;
}

.receiptBtnIconDisabled {
  color: #878e93 !important;
}

.paper {
  max-height: calc(100% - 55px) !important;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  transition: none !important;
  transform: unset !important;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.tabsCt {
  height: 55px;
  width: 35%;
  max-width: 330px;
  min-width: 220px;
}

.printBtn,
.printBtnClicked {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: none;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  padding: 0.1rem 1.3rem 0.35rem 1.3rem;
  min-height: 4rem;
  min-width: 4rem;
  margin: 7px 7px;
  transition: 0.3s !important;
}

.printBtnClicked,
.printBtn:hover {
  background-color: #f4f7f9;
}

.printBtnClicked span,
.printBtn span {
  margin-left: 7px;
  margin-right: 5px;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
}

.printBtnClicked span {
  color: #0A63F3 !important;
}

.printIcon,
.printIcon img {
  height: 18px;
  width: 18px;
}

.printBtn i {
  font-weight: bold;
  color: #000;
}

.printBtnClicked i {
  font-weight: bold;
  color: #0A63F3 !important;
}

.printPopover {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.printPopover button {
  background: none;
  border: none;
  color: #151F28;
  font-size: 16px;
  padding: 14px;
  width: 165px;
  cursor: pointer;
  text-align: start;
}

.printPopover button:hover {
  background-color: #f4f7f9;
}

.printVisibleOnly,
.hidden {
  height: 0;
  width: 0;
  visibility: hidden;
  display: none;
}

.printHeader {
  font-size: 0;
  visibility: hidden;
  display: none;
}

.pageBreak {
  display: none;
  height: 0;
  margin-top: 0;
}

.enabledCloseSection {
  display: flex;
  justify-content: flex-start;
  padding: 0px 0px 0px 30px;
  height: 40px;
  background: white;
  align-items: center;
}
.enabledCloseSection > div {
  width: 121px;
  height: 36px;
  padding: 0px 10px 0px 10px;
  gap: 8px;
  border-radius: 3px;
  margin: 8px 5px 0px 0px;
}
.enabledCloseSection > div > button {
  width: 100%;
  height: 100%;
  background: transparent !important;
  border: 0px !important;
  padding: 0px 0px 0px 0px !important;
  font-size: 13px !important;
  color: #151F28 !important;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.enabledCloseSection > div > button > svg {
  font-size: 16px !important;
  color: #1C63f3 !important;
  margin-right: 5px;
}
.enabledCloseSection > div > button > span {
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif !important;
}
.enabledCloseSection > div:hover {
  background: #f4f7f9 !important;
}

@media print {
  @page {
    size: portrait;
  }
  * {
    box-shadow: none !important;
    font-size: 10px !important;
  }
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
  .paper {
    max-height: 100% !important;
    overflow: visible !important;
    width: 100%;
    height: 100%;
  }
  h2 {
    font-size: 14px !important;
  }
  .printVisibleOnly {
    height: auto;
    width: 100%;
    visibility: visible;
    display: block;
  }
  .detailHeader {
    height: 0;
    width: 0;
    visibility: hidden;
    display: none;
  }
  .printHeader {
    visibility: visible;
    display: block;
    margin: 0;
    padding: 26px 0 5px 28px;
    font-size: 22px !important;
    font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
    color: #151F28;
  }
  .pageBreak {
    display: block;
    margin-top: 180px;
    page-break-before: always !important;
    break-before: always !important;
  }
}

