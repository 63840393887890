@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.messageList {
  height: 100vh;
  overflow: auto;
  position: relative;
  z-index: 1;
  margin-top: 0.5rem;
  padding-bottom: 30px;
}

.selected {
  background: $xlightblue !important;
  cursor: auto !important;
}

.messageItem {
  width: 100%;
  padding: 1.2rem 2.2rem 1.4rem 4rem;
  border-bottom: 1px solid $gray-200;
  cursor: pointer;
  transition: $transition;
  position: relative;

  &:hover {
    background: $gray-100;
  }

  &:focus {
    outline: none;
  }

  &:last-child {
    border-bottom: 0px;
  }

  .time {
    font-size: 1.2rem;
    color: $gray-600;
    line-height: 1.7rem;
    white-space: nowrap;
  }

  .dot {
    width: 1rem;
    height: 1rem;
    padding: 0.5rem;
    border-radius: 50%;
    background: $red;
    position: absolute;
    top: 1.7rem;
    left: 1.5rem;
  }

  .acknowledgedDot {
    @extend .dot;
    background: $gray-100;
    border: solid 1px $gray-400;
    cursor: pointer;

    &:hover {
      border: solid 1px $red;
      background-color: white;
    }
  }

  .unreadText {
    font-family: $font-family-bold !important;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 0.6rem;

    .author {
      font-size: 1.6rem;
      font-weight: 400;
      color: $txt-body;
      line-height: 2rem;
      padding: 0;
      margin: 0;
      max-width: 18rem;
    }
  }

  .preview {
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.3;
    color: $gray-600;
    // the next lines allow a second line ellipsis
    // they are only supported in chrome as this app
    // please update them to a javascript solution or a single line ellipsis if browser support expands
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
  }
}