@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_helpers.scss';

.fullWidthContainer {
  width: 100%;

  table tr:first-child {
    border-top: none !important;
  }
}

.asterisk {
  color: #e92851;
  font-family: $font-family-bold;
  font-size: 1.8rem;
  display: inline-block;
  transform: translateY(3px);
  margin-left: 0.3rem;
  line-height: 0.5;
}

.labelBold {
  height: 20px;
  font-family: $font-family-bold !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  width: 50%;
}

.noBorder {
  border: none !important;
  padding-left: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 20px !important;
}

.noBorderSecondaryHeader {
  border: none !important;
  height: 15px;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
}

.noPadding {
  padding-left: 0px !important;
}

.primaryLabel {
  color: #686868;
  font-size: 12px;
  padding: 0.005px;
  font-family: $font-family-bold !important;
  padding-left: 0px !important;
}

.productLabel {
  font-size: 16px;
  font-weight: 400;
  margin: 4px;
  font-family: $font-family-regular;
  color: $gray-900;
  padding-left: 0px !important;
}