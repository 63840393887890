@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.wrapperDetails {
  grid-template-columns: repeat(5, 1fr);
}

.wrapperEdit {
  grid-template-columns: repeat(5, 1fr);
}

.checklistsName {
  grid-column-start: 1;
  grid-column-end: 4;

  p {
    font-size: 3rem !important;
    font-family: $font-family-bold !important;
    line-height: 1 !important;
    margin: -5px 0 -20px 0 !important;
    display: inline-block;
    transform: translateY(1px);
  }
}

.checklistsProductLines {
  grid-column-start: 1;
  grid-row: 2;
}

.checklistsOptions {
  grid-column-start: 2;
  grid-row: 2;
}

.checklistsWhenPresent {
  grid-column: 3 / 5;
  grid-row: 2;
}

.optionsCtr {
  white-space: nowrap;
}

.optionItemCtr {
  display: inline-block;
  margin-right: 8px;
}

.checklistDetails {
  grid-row-start: 2;
}

.name {
  grid-row: 1;
  grid-column: 1 / 3;
}

.regions {
  grid-row: 1;
  grid-column: 3 / 5;
}

.productLines {
  grid-row: 2;
  grid-column-start: 1;
}

.optionsFormats {
  grid-row: 2;
  grid-column-start: 2;
}

.whenToPresent {
  grid-row: 2;
  grid-column: 3 / 5;
}

.iconClose {
  color: $red !important;
  font-size: 14px;
  margin-right: 3px;
}

.iconCheck {
  color: $green !important;
  font-size: 14px;
  font-weight: bold;
  margin-right: 3px;
  margin-left: 3px;
}

@media (max-width: $breakpoint-large) {
  .name {
    grid-column: 1 / 4;
  }

  .regions {
    grid-row: 2;
    grid-column: 1 / 4;
    margin-bottom: 30px;
  }

  .productLines {
    grid-row: 3;
    grid-column-start: 1;
  }

  .optionsFormats {
    grid-row: 3;
    grid-column-start: 2;
  }

  .whenToPresent {
    grid-row: 3;
    grid-column: 3 / 6;
  }
}

@media (max-width: $breakpoint-medium) {
  .regions {
    margin: -10px 0 10px 0;
  }

  .productLines {
    grid-column: 1 / 2;
  }

  .optionsFormats {
    grid-row: 4;
    grid-column: 1 / 2;
  }

  .whenToPresent {
    grid-row: 5;
    grid-column: 1 / 4;
  }
}

@media (max-width: $breakpoint-small) {

  .name,
  .regions,
  .whenToPresent {
    grid-column: 1 / 5;
  }
}