@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';
@import '../../../styles/MapComponent.scss';

.mapPane {
  width: 100%;
  flex: 1 1 auto;
  background: $gray-700;
  cursor: none;
}

.containerElement {
  height: 100% !important;
  position: block !important;
}

.mapElement {
  height: 100%;

  div[title="Show street map"] {
    top: 7px !important;
  }

  div[title="Show satellite imagery"] {
    top: 7px !important;
  }
}

button[title="Zoom in"] {
  img {
    height: 10px !important;
    width: 10px !important;
  }
}

button[title="Zoom out"] {
  img {
    height: 10px !important;
    width: 10px !important;
  }
}