@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';
@import '../../shared/admin/AdminCard.module.scss';

.wrapper {
  display: flex;
  flex-wrap: wrap;

  >div {
    flex-grow: 1 !important;
    height: auto !important;
    @extend %ifValueEmpty;

    &:not(:last-of-type) {
      width: 25% !important;
    }

    &:first-of-type,
    &:last-of-type {
      width: 100% !important;
    }

    &:first-of-type {
      p {
        font-family: $font-family-bold !important;
      }
    }
  }

  .buttonClass {
    margin-bottom: -2rem !important;
    transform: translateY(-0.4rem) !important;
    text-align: left !important;
  }

  p:first-of-type {
    margin-bottom: -2rem !important;
    transform: translateY(-0.4rem) !important;
  }

  p:not(:first-of-type) {
    margin-top: 1.4rem !important;
  }

  &.editWrapper {
    >div {

      &:not(:last-of-type),
      &:first-of-type,
      &:last-of-type {
        width: 100% !important;
        max-width: 500px;
      }
    }

    .fullName {
      display: none !important;
    }
  }
}

.resetPasswordBody {
  white-space: pre-wrap;

  b {
    font-family: $font-family-bold !important;
  }
}

@media (max-width: $breakpoint-admindetails) {
  .wrapper {
    >div {

      &:not(:last-of-type),
      &:first-of-type,
      &:last-of-type {
        width: 100% !important;
      }
    }
  }
}