@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_helpers.scss';

.wrapper {
  display: flex;
  flex-wrap: wrap;

  >div {
    flex-grow: 1 !important;
    height: auto !important;
    @extend %ifValueEmpty;

    &:not(:last-of-type) {
      width: 43% !important;
    }

    &:first-of-type,
    &:last-of-type,
    &.map {
      width: 100% !important;
    }

    p:first-of-type {
      margin-bottom: -2rem !important;
      transform: translateY(-0.4rem) !important;
    }

    p:not(:first-of-type) {
      margin-top: 1.4rem !important;
    }
  }

  &.editWrapper {
    >div {

      &:not(:last-of-type),
      &:first-of-type,
      &:last-of-type {
        width: 100% !important;
        max-width: 500px;
      }
    }

    p:first-of-type {
      margin-bottom: -2rem !important;
      transform: translateY(-0.4rem) !important;
    }

    p:not(:first-of-type) {
      margin-top: 1.4rem !important;
    }

    .name {
      display: none !important;
    }

    .addressGroup {
      margin-top: -5rem;

      &>div {
        margin-top: 5rem !important;
      }
    }

    .region {
      margin-top: -2rem !important;
      margin-bottom: 2rem !important;
    }

    .location {
      margin-bottom: 0.5rem;

      .latitudeEditMode {
        margin: 1rem 0 3rem 0;
      }
    }
  }
}

.resetPasswordBody {
  white-space: pre-wrap;

  b {
    font-family: $font-family-bold !important;
  }
}

@media (max-width: $breakpoint-admindetails) {
  .wrapper {
    >div {

      &:not(:last-of-type),
      &:first-of-type,
      &:last-of-type {
        width: 100% !important;
      }
    }
  }
}

.productLines {
  margin-bottom: 1rem;
}

.editWeights {
  margin-left: 27px;
  margin-top: -1rem;
}

.editGtLiteParticipant {
  margin-left: 0rem;
  margin-top: -1rem;
}

.LoadedTimeLabel {
  margin-left: 1.5rem !important;
  margin-top: 0.8rem;
}

.loadedTimeSwitch {
  margin-left: 1rem;
  margin-bottom: 0.8rem;
}

.loadTimeSelect {
  background: #f4f7f9 !important;
  margin-bottom: 1rem;
  margin-left: 1.5rem;
  width: 55% !important;
  height: 40px
}

.asterisk {
  color: #e92851;
  font-family: $font-family-bold;
  font-size: 1.8rem;
  display: inline-block;
  transform: translateY(3px);
  margin-left: 0.3rem;
  line-height: 0.5;
}

.uom {
  margin-left: 3rem;
}

.productLines {
  font-size: 16px !important;
  font-weight: 400 !important;
  margin: 4px !important;
  font-family: $font-family-regular !important;
  color: #151f28;
}

.radioBtnSize {
  height: 10px;
  width: 10px;
  margin-left: 13px !important;
}

.radioBtn {
  color: $gray-900 !important;
  padding: 12px !important;
  margin-left: 10px !important;
  @extend .radioBtnSize;

  :global(.MuiSvgIcon-root) {
    font-size: 2.4rem !important;
  }
}

.radioBtnSelected {
  color: $blue !important;
  @extend .radioBtnSize;
}

.radioLabel {
  font-size: 16px !important;
  font-weight: 400 !important;
  margin: 4px !important;
  font-family: $font-family-regular !important;
  color: $gray-900;
}

.uomLabel {
  margin-top: 0.8rem;
}

.labelWithTooltip {
  display: flex;
  align-items: center;
  width: 240px;
}

.tooltipIcon {
  margin-left: 0.5rem;
  cursor: pointer;
  color: lightgray;
  font-size: 1.8rem !important;
}

.wrapper>div p[title="Not set"] {
  color: #a5abaf;
}