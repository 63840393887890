@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_helpers.scss';

.wrapper {
  display: flex;
  flex-wrap: wrap;

  >div {
    flex-grow: 1 !important;
    height: auto !important;

    &:not(:last-of-type) {
      width: 43% !important;
    }

    &:first-of-type,
    &:last-of-type,
    &.map {
      width: 100% !important;
    }

    &:last-of-type {
      margin-top: -1rem !important;
    }

    &.regionNameTitle {
      margin-bottom: 1rem;
    }

    &.description {
      width: 100% !important;
    }

    &.latitude,
    &.longitude {
      max-width: 200px !important;
    }

    &.container {
      display: flex;
      justify-content: space-between;
      margin: -1rem 0 -4rem 0;
    }

    &.divider {
      margin-top: 0.5rem;
    }

    &.dividerLabel {
      height: 1.6rem;
      font-size: 1.3rem;
      font-family: $font-family-bold;
      color: $gray-900;
    }

    &.productLines {
      >p {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        margin: 0px !important;
      }
    }
  }

  &.editWrapper {
    >div {

      &:not(:last-of-type),
      &:first-of-type,
      &:last-of-type {
        width: 100% !important;
        max-width: 500px;
      }
    }

    p:first-of-type {
      margin-bottom: -2rem !important;
      transform: translateY(-0.4rem) !important;
    }

    p:not(:first-of-type) {
      margin-top: 1.4rem !important;
    }

    .map {
      max-width: 100% !important;
    }

    .regionNameTitle {
      display: none;
    }
  }
}

@media (max-width: $breakpoint-admindetails) {
  .wrapper {
    >div {

      &:not(:last-of-type),
      &:first-of-type,
      &:last-of-type {
        width: 100% !important;
      }
    }
  }
}