@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.container {
  transform: translateY(10px) !important;
  margin-top: -10px !important;
}

.btnGroup {
  width: auto;
  height: $header-height;
  display: flex;
  padding: 20px 0;
  margin-top: -10px !important;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  .editBtn {
    display: inline-block;
    align-self: center;
    justify-self: center;
    width: auto;
  }

  .saveBtn {
    display: inline-block;
  }

  .cancelBtn {
    display: inline-block;
    width: auto;
    margin: 0 12px 0 0;
  }
}

.disabledEditText {
  color: $gray-600;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.2;
  letter-spacing: normal;
  max-width: 13rem;
  margin: 0 0 0.1rem 1.3rem;
}

.logOutBtnDisabled {
  background-color: #f0f3f5 !important;
  color: rgb(194, 189, 189) !important;
  box-shadow: none !important;
  cursor: not-allowed !important;
}

.logOutModalBody {
  white-space: pre-wrap;

  b {
    font-family: $font-family-bold !important;
  }
}