.copyToRegionContainer {
  max-height: 369px;
  width: 302px;
}
.copyToRegionContainer .selectAllContainer {
  border-bottom: 1px solid #e1e4e7;
}
.copyToRegionContainer .selectAllLabel {
  font-size: 1.6rem;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  vertical-align: middle;
}
.copyToRegionContainer .selectSingleContainer {
  border-bottom: 1px solid #e1e4e7;
  overflow: auto;
  max-height: 270px;
}
.copyToRegionContainer .selectSingleLabel {
  font-size: 1.6rem;
  vertical-align: middle;
}
.copyToRegionContainer .disabledLabel {
  color: #b3bdc3;
}
.copyToRegionContainer .copyToRegionBtnContainer {
  display: grid;
  justify-items: center;
  align-items: center;
  height: 56px;
}
.copyToRegionContainer .icon {
  color: #2A353E;
}

