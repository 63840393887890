@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.permissionsAdminSection {
  grid-column: 1 / span 4;
  grid-row: 4;
  align-self: center;
  overflow: hidden;
}

.permissionsDivider {
  margin: 0 0 30px 0;
}

.permissionsHeader {
  padding: 0;
  font-size: 16px;
  color: $gray-600;
}

.permissionsLabel {
  padding: 0 0 0 1px;
  margin-bottom: 10px;
  font-size: 13px;
  color: $gray-900;
}

.permissionsContainer {
  padding: 0;
  height: 325px;
}

.dropDownSearchContainer {
  width: 70%;
  display: flex;
}

.marginTop {
  margin-top: 25px;
  margin-bottom: 5px;
}

.rightAlign {
  text-align: right !important;
}

.removeButton {
  padding: 0.2rem 1.3rem 0.3rem 1.3rem !important;

  i {
    color: $gray-500 !important;
    font-size: 1.6rem;
  }

  &:hover>i {
    color: $darkred !important;
  }
}

.searchContainer {
  vertical-align: middle;
  flex: 0 1 100%;
  background-color: $gray-100;
  margin-bottom: 23px;

  :global(.MuiListItem-root) {
    transition: $transition !important;
    background: transparent !important;
    font-size: 1.6rem !important;
    height: 40px !important;
    display: flex !important;
    justify-content: flex-start !important;
    width: 100% !important;
    flex-grow: 1 !important;
    padding-left: 11px !important;

    &:hover {
      background: $gray-100 !important;
    }
  }
}

.searchContainer input {
  background-color: $gray-100 !important;
}

.tableContainer {
  height: auto;
  max-height: 263px;
  table-layout: fixed;
  width: 100%;
  overflow: auto;
  padding: 0;
  border: 1px solid $gray-200 !important;
  border-bottom: 1px solid transparent !important;
  box-shadow: inset 0 -1px 0 0 $gray-200 !important;
  border-collapse: collapse;

  &::-webkit-scrollbar {
    border-bottom: 1px solid $gray-200;
    border-left: 1px solid $gray-200;
    background-color: rgb(250, 250, 250) !important;
  }

  &::-webkit-scrollbar-track {
    border-radius: 1rem;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background-color: rgb(193, 193, 193) !important;
    width: 1rem !important;
    border: 0.3rem solid transparent;
    border-left: 0.4rem solid transparent;
    background-clip: content-box;
    transition: $transition !important;

    &:hover {
      background-color: rgb(125, 125, 125) !important;
    }
  }
}

.tableContainer,
.tableContainer th,
.tableContainer td {
  border: none;
  padding: 0;
}

.tableContainer th {
  padding: 10px 0 10px 11px !important;
  line-height: 1 !important;
  background: white;
  position: sticky !important;
  top: 0 !important;
  box-shadow:
    4px 1px 5px 0px rgba(0, 0, 0, 0.15),
    3px 2px 2px 0px rgba(0, 0, 0, 0.09),
    2px 3px 1px -2px rgba(0, 0, 0, 0.07) !important;
}

.tableContainer td {
  padding: 0 0 0 11px !important;
  height: 4rem;
  font-size: 1.6rem;
  font-family: $font-family-regular;
  line-height: 1 !important;
  color: $gray-900;
  border-top: 1px solid $gray-200;
}

.tableContainer th span {
  font-size: 1.3rem;
  font-family: $font-family-bold;
  line-height: 1 !important;
  color: $gray-900;
  margin: 0 0 0 1px !important;
  padding: 0 !important;
}

.emptyTable {
  background-color: $gray-100;
  text-align: center;
  padding: 53px 0;
}

.emptyTable i {
  font-size: 80px;
  color: $gray-300;
}

.emptyTable p {
  padding-top: 15px;
  white-space: pre-wrap;
  font-size: 16px;
  color: $gray-900;
}

.emptyTable .crossLine {
  background-color: $gray-300;
  outline: 4px solid $gray-100 !important;
  display: block;
  width: 110px;
  height: 8px;
  margin: -50px auto 45px auto;
  transform: rotate(135deg);
  outline: none;
}

.MuiMenuItem-root {
  width: 100% !important;
}

.memberRow {
  height: 40px;

  &:hover {
    background: none !important;
  }
}

.usernameLink {
  font-family: $font-family-bold;
  color: $darkblue;
}

.removeAllBtn {
  margin-right: 14px;

  span {
    transform: translateY(-1px) !important;
    display: inline-block;
  }

  &:hover>i,
  &:hover>span {
    color: $darkred !important;
  }
}