@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_helpers.scss';

.asterisk {
  color: #e92851;
  font-family: $font-family-bold;
  font-size: 1.8rem;
  display: inline-block;
  transform: translateY(3px);
  margin-left: 0.3rem;
  line-height: 0.5;
}

.productLines {
  font-size: 16px !important;
  font-weight: 400 !important;
  margin-bottom: 2rem;
  font-family: $font-family-bold !important;
  color: $gray-900;
  width: 75%;

  div.errorMessage {
    border: 1px solid #E92851;
    padding-left: 5px;
    background-color: #ffecf0;
  }

  p.errorMessage {
    font-family: $font-family-regular;
    font-size: 13px;
    color: #e92851;
  }

  label {
    margin: 0;
    height: 20px;
    align-content: center;
  }

  .tableHeadRow {
    height: 28px !important;
    width: 75%;

    th {
      font-size: 1.11rem !important;
      font-family: $font-family-bold !important;
      color: $gray-600;
      padding: 0px;
    }
  }

  .tableBodyRow {
    height: 36px !important;

    td {
      font-size: 1.5rem !important;
      padding: 0px;
      font-family: $font-family-regular !important;
      width: 30%;
      height: 36px !important;
    }

    .selector {
      width: 20%;
      height: 36px !important;
    }

    .rest {
      width: 30%;
      height: 36px !important;
    }
  }

  tbody tr:last-child td {
    border-bottom: none !important;
  }
}

.radioBtn {
  color: $gray-900 !important;
  padding: 12px !important;
  margin-left: 10px !important;
  @extend .radioBtnSize;

  :global(.MuiSvgIcon-root) {
    font-size: 2.4rem !important;
  }
}

.radioBtnSize {
  height: 10px;
  width: 10px;
  margin-left: 13px !important;
}

.radioBtnSelected {
  color: $blue !important;
  @extend .radioBtnSize;
}

.radioLabel {
  font-size: 16px !important;
  font-weight: 400 !important;
  margin: 4px !important;
  font-family: $font-family-regular !important;
  color: $gray-900;
}

.checkboxLabel {
  color: $gray-900 !important;
  font-size: 16px !important;
}

.checkboxLabel span,
.checkboxLabel span input {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-left: 13px !important;
  padding-right: 0px !important;
  font-size: 16px !important;

  &:hover {
    background: none !important;
  }

  svg {
    height: 24px;
    width: 24px;
  }
}

.checkboxUnchecked {
  color: $gray-900 !important;
  font-size: 16px !important;
}

.checkboxChecked {
  color: $blue !important;
  font-size: 16px !important;
}