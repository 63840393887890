.margin-0 {
  margin: 0 !important;
}

.margin-top-0 {
  margin-top: 0;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-right-20 {
  margin-right: 20px;
}

.margin-right-30 {
  margin-right: 30px;
}

.margin-right-40 {
  margin-right: 40px;
}

.margin-left-5 {
  margin-left: 5px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-left-30 {
  margin-left: 30px;
}

.margin-left-40 {
  margin-left: 40px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

.wrap-words {
  word-break: initial;
  line-height: 1.1;
}

.nowrap-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

.pre-wrap {
  white-space: pre-wrap;
}

.height-100 {
  height: 100%;
}

.icon-md {
  font-size: 1.8rem;
}

.icon-lg {
  font-size: 2rem;
}

.icon-xl {
  font-size: 3rem;
}

.icon-people-margin {
  position: relative;
  top: 2px;
  margin-right: 3px;
}

.underline {
  text-decoration: underline;
}

.clickable {
  cursor: pointer;
}

.fullWidthContainer {
  width: 100%;
}
.fullWidthContainer table tr:first-child {
  border-top: none !important;
}

.asterisk {
  color: #e92851;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  font-size: 1.8rem;
  display: inline-block;
  transform: translateY(3px);
  margin-left: 0.3rem;
  line-height: 0.5;
}

.labelBold {
  height: 20px;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif !important;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
  width: 50%;
}

.noBorder {
  border: none !important;
  padding-left: 0px !important;
  padding-top: 0px !important;
  padding-bottom: 20px !important;
}

.noBorderSecondaryHeader {
  border: none !important;
  height: 15px;
  padding-bottom: 0px !important;
  padding-left: 0px !important;
}

.noPadding {
  padding-left: 0px !important;
}

.primaryLabel {
  color: #686868;
  font-size: 12px;
  padding: 0.005px;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif !important;
  padding-left: 0px !important;
}

.productLabel {
  font-size: 16px;
  font-weight: 400;
  margin: 4px;
  font-family: "PublicSans Regular", Helvetica, Arial, sans-serif;
  color: #151F28;
  padding-left: 0px !important;
}

