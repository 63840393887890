@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';

.copyToRegionContainer {
  max-height: 369px;
  width: 302px;

  .selectAllContainer {
    border-bottom: 1px solid $gray-200;
  }

  .selectAllLabel {
    font-size: 1.6rem;
    font-family: $font-family-bold;
    vertical-align: middle;
  }

  .selectSingleContainer {
    border-bottom: 1px solid $gray-200;
    overflow: auto;
    max-height: 270px;
  }

  .selectSingleLabel {
    font-size: 1.6rem;
    vertical-align: middle;
  }

  .disabledLabel {
    color: #b3bdc3;
  }

  .copyToRegionBtnContainer {
    display: grid;
    justify-items: center;
    align-items: center;
    height: 56px;
  }

  .icon {
    color: $info;
  }
}