.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-center,
.flex-horizontal-center,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.active {
  background-color: white;
  color: gray;
  height: 37px;
  margin-top: -1px;
}

.modalRoot {
  z-index: 200;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.35) !important;
}

.messageCenterTab {
  font-size: 17px;
}

.titleHeader {
  letter-spacing: 2px;
  padding-right: 15px;
  padding-left: 10px;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  font-size: 1.3rem;
  color: white;
}

.header {
  font-size: 1.5rem;
  display: flex;
  width: 100%;
  background-color: #008EF3;
  border-radius: 0;
  justify-content: space-between;
  align-items: center;
  padding: 7px 7px 7px 12px;
  color: #151F28;
  height: 55px;
  flex-direction: row;
}
.header .headerTitle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 18px;
}
.header .headerActions {
  display: flex;
  align-items: center;
}

.datablock-info-close {
  padding: 0 1rem;
}
.datablock-info-close i {
  font-size: 1.6rem;
}

.datablock-info-close:hover {
  opacity: 0.5;
  transition-duration: 0.3s;
}

.expendedButton {
  border: none;
  cursor: pointer;
  transition: 0.3s !important;
  box-shadow: none !important;
  background: transparent !important;
  padding: 0;
  min-height: auto;
}
.expendedButton:hover, .expendedButton:hover > i, .expendedButton:hover > span {
  color: rgba(255, 255, 255, 0.7);
}

.newWindowIcon {
  color: white;
  font-size: 2.2rem;
  line-height: 1.1;
  text-align: left;
}

