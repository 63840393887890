.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-center,
.flex-horizontal-center, .questionsContainer .questionsBody .questionsListContainer .questionsListHeaderTxt, .questionsContainer .questionsBody .questionsListContainer .questionsListHeaderDeleteBtn,
.questionsContainer .questionsBody .questionsListContainer .questionsListCopyRegionBtn, .questionsContainer .questionsBody .questionsListContainer .questionsListHeader, .questionsContainer .questionsHeader .questionsActiveDispContainerEdit, .checklistContentEditBtn, .checklistContentHeader,
.flex-vertical-center, .questionsContainer .questionsBody .questionsListContainer .question, .questionsContainer .questionsHeader,
.flex-space-between, .featureFooter,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.featureFooter,
.flex-end {
  justify-content: flex-end;
}

.questionsContainer .questionsBody .questionsListContainer .question, .questionsContainer .questionsBody .questionsListContainer .questionsListHeader, .questionsContainer .questionsHeader, .checklistContentHeader,
.flex-space-between {
  justify-content: space-between;
}

.questionsContainer .questionsBody .questionsListContainer .questionsListHeaderTxt, .questionsContainer .questionsBody .questionsListContainer .questionsListHeaderDeleteBtn,
.questionsContainer .questionsBody .questionsListContainer .questionsListCopyRegionBtn, .questionsContainer .questionsBody .questionsListContainer .questionsListHeader, .questionsContainer .questionsHeader .questionsActiveDispContainerEdit, .checklistContentEditBtn, .checklistContentHeader,
.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.margin-0 {
  margin: 0 !important;
}

.margin-top-0 {
  margin-top: 0;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-right-20 {
  margin-right: 20px;
}

.margin-right-30 {
  margin-right: 30px;
}

.margin-right-40 {
  margin-right: 40px;
}

.margin-left-5 {
  margin-left: 5px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-left-30 {
  margin-left: 30px;
}

.margin-left-40 {
  margin-left: 40px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

.wrap-words {
  word-break: initial;
  line-height: 1.1;
}

.nowrap-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

.pre-wrap {
  white-space: pre-wrap;
}

.height-100 {
  height: 100%;
}

.icon-md {
  font-size: 1.8rem;
}

.icon-lg {
  font-size: 2rem;
}

.icon-xl {
  font-size: 3rem;
}

.icon-people-margin {
  position: relative;
  top: 2px;
  margin-right: 3px;
}

.underline {
  text-decoration: underline;
}

.clickable {
  cursor: pointer;
}

.cardWrapper {
  margin: 0 auto;
  padding: 0 31px 60px 31px;
  width: 100%;
  max-width: 1062px;
}

.cardContainer {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15), 0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 3px 1px -2px rgba(0, 0, 0, 0.07) !important;
}

.cardContent {
  padding: 0 !important;
  max-height: 775px;
}

.checklistContentHeader {
  background-color: #434c53;
  padding: 7px 46px;
}

.checklistContentTitle {
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  color: white;
  font-size: 15px;
  display: inline-block;
}

.checklistContentHeaderIcon {
  color: #17A9FF;
  font-size: 15px;
  line-height: 1.5;
  vertical-align: bottom;
}

.checklistContentBody {
  padding: 30px 45px 45px 45px;
}

.checklistContentEditBtn {
  background-color: transparent;
  border: 0;
  padding: 0;
  transition: 0.3s !important;
}
.checklistContentEditBtn:hover {
  cursor: pointer;
}
.checklistContentEditBtn:hover .checklistContentEditBtnText {
  color: #17A9FF;
}

.checklistContentEditBtnText {
  display: inline-block;
  transform: translateY(-1px);
  color: white;
  font-size: 1.3rem;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  transition: 0.3s !important;
}

.regionsTabContainer {
  grid-column: 1/span 4;
  overflow-x: auto;
  border-bottom: 1px solid #c8cdd0;
  margin-bottom: 30px;
}
.regionsTabContainer .btnContainer {
  opacity: 1 !important;
}
.regionsTabContainer .btnContainerDisabled {
  opacity: 0.4 !important;
}

.disabledClickIcon {
  pointer-events: auto !important;
  cursor: not-allowed !important;
}

.lockPopper {
  z-index: 9999 !important;
  opacity: 1 !important;
  margin-left: -20px;
}

.lockTooltip {
  background: #2A353E !important;
  padding: 0.6rem 1rem !important;
  font-size: 1.3rem !important;
  line-height: 1.3 !important;
  white-space: nowrap;
  border-radius: 3px !important;
  pointer-events: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}
.lockTooltip:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 6px;
  pointer-events: none;
  border-right-color: #2A353E;
  top: 100%;
  right: 44%;
  transform: translateY(0%) rotate(-90deg);
}

.disabledRegionName {
  font-weight: bolder;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
}

.checklistEmailsCtr,
.checklistEditingEmailsCtr {
  margin-bottom: 33px;
}
.checklistEmailsCtr > div > div > div > input,
.checklistEditingEmailsCtr > div > div > div > input {
  position: relative;
  border-bottom: 1px solid #e1e4e7;
  border-radius: 0px !important;
  padding: 10px 2px 4px;
}
.checklistEmailsCtr > div > div > div > input:focus,
.checklistEditingEmailsCtr > div > div > div > input:focus {
  border-bottom: 1px solid #008EF3;
}
.checklistEmailsCtr > div > div > div > fieldset,
.checklistEditingEmailsCtr > div > div > div > fieldset {
  display: none;
}

.checklistEmailLabel {
  height: 16px;
  font-size: 13px !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  color: #151F28;
}

.checklistEmailText {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: #151F28;
}

.checklistEmailNoneLabel,
.checklistEmailDelim {
  color: #a5abaf;
}

.checklistEmailDelim {
  padding: 0 4px;
}

.checklistEmailDiv {
  padding-bottom: 0px;
  margin-bottom: 6px;
}

.chip {
  color: #636b71 !important;
  background-color: #f4f7f9 !important;
  border: 1px solid #e1e4e7 !important;
  border-radius: 3px !important;
  height: 2.6rem !important;
  display: inline-flex !important;
  margin-bottom: 0.5rem;
  margin-right: 10px;
  margin-top: 4px;
  padding: 0 !important;
}

.chipLabel {
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  font-size: 1.3rem;
  line-height: 2.6rem;
  padding: 0 6px 0 7px !important;
  margin: 0 !important;
  display: block !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chipRemove {
  width: 2.5rem !important;
  height: 2.6rem !important;
  background-color: transparent;
  border-radius: 0 3px 3px 0;
  border: none;
  outline: none;
  margin: 0 !important;
  transition: 0.3s !important;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.chipRemove i {
  color: #636b71;
  font-size: 1.2rem;
  line-height: 1.2rem;
}
.chipRemove:hover {
  background-color: #e1e4e7 !important;
}
.chipRemove:hover i {
  color: #151F28 !important;
}

.textField {
  display: inline-block !important;
  padding-left: 5px !important;
}
.textField:before {
  border-bottom: 1px solid #e1e4e7 !important;
}
.textField:after {
  border-bottom: 2px solid #008EF3 !important;
}

.textFieldInput::placeholder {
  color: #c8cdd0 !important;
  opacity: 1 !important;
}

.questionsContainer {
  overflow-x: hidden;
  background-color: #f4f7f9;
}
.questionsContainer .questionsOverflowPadding {
  margin-right: 15px;
}
.questionsContainer .questionsHeader {
  background-color: #e1e4e7;
  display: flex;
  overflow-y: auto;
}
.questionsContainer .questionsHeader .questionsHeaderTxt {
  font-size: 15px;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  padding: 7px 15px 7px 16px;
}
.questionsContainer .questionsHeader .questionsActiveDispContainer {
  justify-self: end;
  padding: 9px 15px;
  transform: translateX(-30px);
  width: 91px;
}
.questionsContainer .questionsHeader .questionsActiveDispContainerEdit {
  justify-self: end;
  padding: 0 15px;
}
.questionsContainer .questionsHeader .questionsActiveDisp {
  font-size: 1.3rem;
  line-height: 1.8rem;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
}
.questionsContainer .questionsHeader .questionsActiveDispLabel {
  font-size: 1.3rem;
  line-height: 1.8rem;
  font-family: "PublicSans Regular", Helvetica, Arial, sans-serif;
}
.questionsContainer .questionsHeader .questionsActiveDispVal {
  font-size: 1.3rem;
  line-height: 1.8rem;
}
.questionsContainer .questionsHeader .questionsActiveDispIconYes {
  font-size: 16px;
  vertical-align: text-bottom;
  margin-left: 5px;
  margin-right: 5px;
  color: #0A63F3;
}
.questionsContainer .questionsHeader .questionsActiveDispIconNo {
  font-size: 16px;
  vertical-align: text-bottom;
  margin-left: 5px;
  margin-right: 5px;
  color: #636b71;
}
.questionsContainer .questionsBody .emptyQuestionsContainer {
  display: grid;
  justify-items: center;
  align-items: center;
  height: 212px;
}
.questionsContainer .questionsBody .questionsListContent {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 277px;
}
.questionsContainer .questionsBody .questionsListContainer {
  background-color: white;
  max-height: 315px;
  overflow: hidden;
  border: 1px solid #e1e4e7;
}
.questionsContainer .questionsBody .questionsListContainer .questionsList {
  overflow-y: auto;
  max-height: 277px;
}
.questionsContainer .questionsBody .questionsListContainer .questionsListHeader,
.questionsContainer .questionsBody .questionsListContainer .questionsListHeaderEdit {
  overflow-y: auto;
}
.questionsContainer .questionsBody .questionsListContainer .questionsListHeader {
  background-color: #f4f7f9;
  padding: 5px 9px 6px 14px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15), 0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 3px 1px -2px rgba(0, 0, 0, 0.07) !important;
  z-index: 10;
  position: relative;
}
.questionsContainer .questionsBody .questionsListContainer .questionsListHeader span {
  font-size: 1.3rem;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
}
.questionsContainer .questionsBody .questionsListContainer .questionsListHeaderDeleteBtn span,
.questionsContainer .questionsBody .questionsListContainer .questionsListHeaderDeleteBtn i,
.questionsContainer .questionsBody .questionsListContainer .questionsListCopyRegionBtn span,
.questionsContainer .questionsBody .questionsListContainer .questionsListCopyRegionBtn i {
  transition: 0.3s !important;
}
.questionsContainer .questionsBody .questionsListContainer .questionsListHeaderDeleteBtn span,
.questionsContainer .questionsBody .questionsListContainer .questionsListCopyRegionBtn span {
  font-size: 1.3rem;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
}
.questionsContainer .questionsBody .questionsListContainer .questionsListHeaderDeleteBtn i,
.questionsContainer .questionsBody .questionsListContainer .questionsListCopyRegionBtn i {
  margin-right: 3px;
  color: #0A63F3;
}
.questionsContainer .questionsBody .questionsListContainer .questionsListHeaderDeleteBtn {
  background-color: #f4f7f9;
  border: none;
  max-height: 35px;
}
.questionsContainer .questionsBody .questionsListContainer .questionsListHeaderDeleteBtn:hover {
  cursor: pointer;
}
.questionsContainer .questionsBody .questionsListContainer .questionsListHeaderDeleteBtn:hover span,
.questionsContainer .questionsBody .questionsListContainer .questionsListHeaderDeleteBtn:hover i {
  color: #E92851;
}
.questionsContainer .questionsBody .questionsListContainer .questionsListHeaderDeleteBtn:active span,
.questionsContainer .questionsBody .questionsListContainer .questionsListHeaderDeleteBtn:active i {
  color: #FF3B64;
}
.questionsContainer .questionsBody .questionsListContainer .questionsListHeaderDeleteIcon {
  color: #008EF3;
  font-size: 16px;
  vertical-align: text-bottom;
}
.questionsContainer .questionsBody .questionsListContainer .questionsTextEdit {
  font-size: 1.3rem;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  margin: 6px 0 5px 49px;
}
.questionsContainer .questionsBody .questionsListContainer .questionsListHeaderEdit {
  background-color: #f4f7f9;
  margin: 0;
  display: grid;
  padding: 5px 9px 6px 2px;
  grid-template-rows: 36px;
  grid-template-columns: 145px 95px 150px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15), 0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 3px 1px -2px rgba(0, 0, 0, 0.07) !important;
  z-index: 10;
  position: relative;
}
.questionsContainer .questionsBody .questionsListContainer .questionsListCopyRegionBtn {
  background-color: #f4f7f9;
  border: none;
  max-height: 35px;
  padding: 8px 0;
}
.questionsContainer .questionsBody .questionsListContainer .questionsListCopyRegionBtn:hover {
  cursor: pointer;
}
.questionsContainer .questionsBody .questionsListContainer .questionsListCopyRegionBtn:hover span,
.questionsContainer .questionsBody .questionsListContainer .questionsListCopyRegionBtn:hover i {
  color: #0A63F3;
}
.questionsContainer .questionsBody .questionsListContainer .questionsListCopyRegionBtn:active span,
.questionsContainer .questionsBody .questionsListContainer .questionsListCopyRegionBtn:active i {
  color: #17A9FF;
}
.questionsContainer .questionsBody .questionsListContainer .activeCopyRegions {
  color: #008EF3;
}
.questionsContainer .questionsBody .questionsListContainer .questionsCopyRegionsIcon {
  color: #008EF3;
  font-size: 16px;
  vertical-align: text-bottom;
}
.questionsContainer .questionsBody .questionsListContainer .questionsCopyRegionsArrowIcon {
  font-size: 18px;
  vertical-align: text-bottom;
  transform: translateX(-10px);
}
.questionsContainer .questionsBody .questionsListContainer .questionsListHeaderTxt {
  padding: 4px 10px 4px 1px;
  min-width: 97px;
}
.questionsContainer .questionsBody .questionsListContainer .questionsListHeaderTxt span {
  font-size: 13px;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
}
.questionsContainer .questionsBody .questionsListContainer .questionsListHeaderTxt .checkboxLabel {
  margin: 0 0 2px 2px;
  white-space: nowrap;
}
.questionsContainer .questionsBody .questionsListContainer .questionsListCheckbox {
  margin: -2px 0 !important;
  padding: 10px !important;
}
.questionsContainer .questionsBody .questionsListContainer .question {
  font-size: 16px;
  padding: 12px 8px 12px 15px;
  border-top: 1px solid #e1e4e7;
}
.questionsContainer .questionsBody .questionsListContainer .question:nth-child(1) {
  border-top: none;
}
.questionsContainer .questionsBody .questionsListContainer .question .questionText {
  width: calc(100% - 98px) !important;
  padding-right: 12px;
}
.questionsContainer .questionsBody .questionsListContainer .question .answerType {
  width: 98px;
}
.questionsContainer .questionsBody .questionsListContainer .questionEdit {
  display: grid;
  font-size: 16px;
  padding: 8px 0;
  border-top: 1px solid #e1e4e7;
  grid-template-columns: 49px 16fr 44px;
}
.questionsContainer .questionsBody .questionsListContainer .questionInput {
  height: 40px;
}
.questionsContainer .questionsBody .questionsListContainer .characterCount {
  position: relative;
  font-size: 1.3rem;
  right: 8px;
  bottom: 32px;
  float: right;
  padding: 2px 7px 3px 8px;
  background-color: #e1e4e7;
  border-radius: 3px;
}
.questionsContainer .questionsBody .emptyQuestions {
  width: 267px;
  text-align: center;
}
.questionsContainer .questionsBody .emptyQuestions .emptyQuestionsIcon {
  font-size: 64px;
  display: block;
  text-align: center;
  color: #c8cdd0;
  margin-bottom: 15px;
}
.questionsContainer .questionsBody .emptyQuestions .emptyQuestionsLabel {
  font-size: 16px;
}

.saveBtn {
  display: block;
  grid-row: 1;
  grid-column: -2;
  margin-top: 0px;
  align-self: right;
  justify-self: right;
}

.addQuestionsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #e1e4e7;
  padding: 8px;
}

.addQuestionBtn {
  max-width: 185px;
}

.removeQuestionBtn i {
  color: #0A63F3 !important;
}
.removeQuestionBtn i:hover {
  color: #E92851 !important;
}

.blue {
  color: #008EF3 !important;
}

.featureFooter {
  padding: 8px 46px;
  border-top: 1px solid #e1e4e7;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.35);
}

