@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.footer {
  background-color: $gray-900;
  color: $white;
  position: fixed;
  bottom: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
}

.footerText {
  flex: 1 1 auto;
  text-align: center;
}

.footerTextLeft {
  flex: 1 1 auto;
  text-align: left;
}

.footerTextRight {
  flex: 1 1 auto;
  text-align: right;
}