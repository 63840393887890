.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.rightSidebarWindow, .rightSidebar,
.flex-center,
.flex-horizontal-center,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.rightSidebar {
  width: 0;
  height: calc(100vh - 55px);
  pointer-events: none;
  overflow: hidden;
  background: white;
  flex-direction: column;
}
.rightSidebar > div,
.rightSidebar form {
  width: 325px;
  flex: none;
}
.rightSidebar.open {
  pointer-events: all;
  width: 325px;
}

.rightSidebarWindow {
  width: 0;
  pointer-events: none;
  overflow: hidden;
  background: white;
  flex-direction: column;
  height: calc(100vh - 55px);
}
.rightSidebarWindow > div,
.rightSidebarWindow form {
  width: 100%;
  flex: none;
}
.rightSidebarWindow.open {
  pointer-events: all;
  width: 100%;
}

.bubbleContainer {
  flex: 1 !important;
  overflow-y: auto;
}

.icon {
  height: 25px;
  width: 25px;
}

.dropDownList-chev {
  transform: rotate(-90deg);
  display: inline-block;
  font-size: 11px;
}
.dropDownList-chev:hover {
  cursor: pointer;
  opacity: 0.7;
}

.dropDownList-chev-up {
  transform: rotate(90deg);
  display: inline-block;
  font-size: 11px;
}
.dropDownList-chev-up:hover {
  cursor: pointer;
  opacity: 0.7;
}

.listContainer {
  width: 325px;
  position: absolute;
  display: inline-block;
  background-color: #ffffff;
  opacity: 0.95;
  transform-origin: top;
  transform: scaleY(1);
  z-index: 10;
  overflow: auto;
  max-height: 319px;
  box-shadow: 0 10px 10px -3px rgba(147, 147, 147, 0.35);
}

.listContent {
  font-size: 16px;
}

.listItemStyle {
  margin-top: 10px;
}

.listItemStyle:first-child {
  margin-top: -8px;
}

.listStyle {
  list-style-type: none;
  margin-left: -19px;
}

