@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

$row-divider-height: 4px;

.headerTitle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 18px;
}

.toolbar {
  height: 53px;
  background-color: $white;
}

.body {
  overflow-y: scroll;
  height: calc(100% - 110px);
}

.body>div,
.body>button {
  margin-left: 48px;
  margin-right: 48px;
}

.headerText {
  letter-spacing: 2px;
  padding-right: 15px;
  padding-left: 10px;
  font-family: $font-family-bold;
  font-size: 1.3rem;
  color: $white;
}

.paper {
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  pointer-events: all;
  max-width: 100% !important;
  max-height: 100% !important;
}

.expanded {
  min-height: 95% !important;
  height: 95% !important;
  min-width: 95% !important;
  width: 95% !important;
  left: 2.5% !important;
  top: 2.5% !important;
  border-radius: $border-radius !important;
}

.header {
  font-size: 1.5rem;
  display: flex;
  width: 100%;
  background-color: $blue;
  border-radius: 0;
  justify-content: space-between;
  align-items: center;
  padding: 7px 7px 7px 12px;
  color: $gray-900;
  height: 55px;
}

.toolbar {
  padding-left: 34px;
  padding-right: 16px;
}

.toolbar,
.toolbarLeft,
.toolbarRight {
  display: flex;
  flex-direction: row;
}

.toolbarLeft,
.toolbarRight {
  width: 50%;
}

.toolbarRight {
  direction: rtl;
}

.operatorSearch {
  position: relative;
  margin-top: 1px;
}

.operatorSearchBtn {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4px;
  padding-right: 90px;
  border: none;
  background-color: transparent;
  color: $gray-900;
  font-family: $font-family-bold;
  font-size: 16px;
  height: 40px;
}

.operatorSearchBtnRemove {
  position: absolute;
  top: 13px;
  right: 40px;
  padding: 0;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  border: 0;
  background-color: white;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.35);
}

.operatorSearchBtnRemove i {
  color: #c85000 !important;
  font-size: 9px;
  position: absolute;
  top: 7px;
  left: 6px;
}

.operatorSearchAutoComplete {
  margin-top: 5px;
  width: 350px;
}

.datePicker {
  margin-right: 10px;
}

.datePicker,
.datePicker>div,
.datePicker>div>div,
.datePicker>div>div>div,
.datePickerBtn {
  display: flex;
  position: relative;
  flex-direction: row;
  width: 115px;
  align-items: center;
  background-color: transparent !important;
  border: none;
  box-shadow: none;
  color: transparent;
}

.datePicker :hover {
  background-color: white !important;
}

.datePickerBtn span {
  color: $gray-900;
  font-family: $font-family-bold;
  font-size: 16px;
  padding-left: 13px !important;
}

.iconChevronDown {
  width: 23px;
  height: 23px;
  position: absolute;
  right: 11px;
}

.operatorSearchBtn .iconChevronDown {
  top: 9px;
}

.datePickerBtn .iconChevronDown {
  top: -2px;
}

.todayBtn,
.todayBtnDisabled,
.printBtn {
  border: none;
  background-color: transparent;
  padding: 8px 12px;
}

.todayBtn {
  color: $darkblue;
  cursor: pointer;
}

.printBtn {
  cursor: pointer;
}

.printBtn img {
  width: 18px;
  height: 18px;
}

.todayBtnDisabled {
  color: $gray-500;
  cursor: default;
}

.popoverContainer {
  position: absolute;
  left: 302px;
  right: 0;
  bottom: 0;
  height: 40%;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
  z-index: 999;
}

.fullTicketBlockWidth {
  left: $header-height;
}

.popoverContainerExpanded {
  position: absolute;
  z-index: 999;
  background: rgba(0, 0, 0, 0.8);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.expandedPopover {
  width: 95% !important;
  height: 95% !important;
  left: 2.5%;
  top: 2.5%;

  .header {
    border-radius: $border-radius $border-radius 0 0 !important;
  }

  :global(.MuiPaper-root) {
    border-radius: 0 0 $border-radius $border-radius !important;
  }
}

.popover {
  position: relative !important;
  background-color: #fdf7e0;
  width: 100%;
  height: 100%;
}

.date {
  color: $gray-600;
  font-size: 13px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.note {
  font-size: 16px;
  margin-bottom: 8px;
}

.createdBy {
  color: $gray-600;
  font-size: 14px;
  margin-bottom: 20px;
}

.textInputUnselected,
.textInputSelected {
  background-color: transparent;
  border: none;
  display: block;
  width: calc(100% - 96px);
  padding: 0;
}

.textInputUnselected {
  cursor: pointer;
  margin-bottom: 38px;
}

.textInputUnselected .note {
  color: $gray-400;
  text-align: start;
  height: 38px;
  border-radius: 6px;
  padding-top: 6px;
  padding-left: 10px;
  margin-left: -10px;
  margin-right: -10px;
  align-self: center;
}

.textInputUnselected .note:hover {
  background-color: rgba(255, 255, 255, 0.6);
}

.textInputSelected {
  margin-bottom: 50px;
}

.textInputSelected>div>button {
  margin-top: 35px;
  margin-right: 12px;
}

.textInputSelected textarea {
  background-color: rgba(255, 255, 255, 0.6);
  border: none;
  border-radius: 6px;
  padding: 10px;
  font-size: 16px;
  width: 100%;
  max-width: 100%;
  min-width: 800px;
  height: 65px;
  min-height: 45px;
  max-height: 135px;
  box-shadow: none;
  outline: none;
}

@media print {

  .textInputUnselected .note,
  .textInputSelected textarea,
  .textInputSelected div {
    visibility: hidden;
    display: none;
  }

  .body {
    overflow-y: scroll;
    height: auto;
    margin-right: -30px;
    box-shadow: none;
  }

  @page {
    size: auto;
    margin: 20mm 15mm 20mm 15mm;
  }
}