@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_helpers.scss';

.numericInputContainer {
  position: relative;
}

.numericInputContainer>div {
  padding-left: 35px;
  padding-right: 35px;
}

.numericInputContainer>div label {
  margin-left: -35px;
  margin-right: -35px;
}

.numericInputContainer>div>input {
  text-align: center;
}

.numericInputContainer>button {
  width: 35px;
  position: absolute;
  top: 21px;
  height: 40px;
  background-color: $gray-100;
  border: none;
  padding: 11px;
  cursor: pointer;
}

.numericInputContainer>button>i {
  color: $darkblue;
}

.numericInputContainer>.minusButton {
  left: 0;
}

.numericInputContainer>.plusButton {
  right: 0;
}