.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-center,
.flex-horizontal-center,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.container {
  height: calc(100% - 40px) !important;
}

.paper {
  height: 100%;
  border-radius: 3px 3px 0 0 !important;
  box-shadow: none !important;
  background-color: #f4f7f9 !important;
}
.paper > div {
  padding-bottom: 65px;
}

.header {
  background-color: white;
  padding: 23px 0 0px 35px;
  margin: 0;
}
.header h2 {
  margin: 0;
  padding: 0;
  font-size: 24px;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  color: #151F28;
}

