@import '../../../../styles/MapComponent.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';

.mapContainer {
  width: 100%;
}

.map {
  width: 100%;
  height: 100%;
}

.container {
  margin: 0;
  padding: 0;
}

.body {
  background-color: white;
  margin: 0;
  padding: 0;
  width: 100%;
}

.noRouteContainer {
  width: 100%;
  height: 100%;
  background-color: $gray-100;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noRouteMessage {
  text-align: center;
  margin-bottom: 2%;
}

.noRouteIcon {
  font-size: 60px;
  color: $gray-300;
  line-height: 1;
  margin: 0;
}

.noRouteText {
  color: $gray-600;
  font-family: $font-family-bold;
  line-height: 1;
  margin: 12px 0 0;
}