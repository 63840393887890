@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_helpers.scss';

.statusContainer,
.statusContainerNoEdit {
  position: absolute;
  height: $subheader-height;
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
  padding: 3px 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-33px);
  border-radius: $border-radius $border-radius 0 0;
  box-shadow: $boxshadow-medium;

  label {
    margin: 0 10px 2px 0;
  }
}

.statusContainer {
  background: $xlightgreen;
  margin-right: 0;
  @extend %flexStart;
}

.statusCtrDeactivated {
  background: $xlightred;
}

.statusCtrDisabled {
  background: $gray-100;
}

.statusContent {
  align-items: center;
  flex-direction: row;
  @extend %flexStart;
}

.statusContentContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.lockPopper {
  z-index: 9999 !important;
  opacity: 1 !important;
  margin-left: -20px;
}

.lockTooltip {
  width: 177px;
  height: 54px;
  background: $primary !important;
  padding: 0.9rem 0.6rem !important;
  font-size: 13px !important;
  font-family: $font-family-bold !important;
  font-stretch: normal !important;
  line-height: 1.23 !important;
  letter-spacing: normal !important;
  text-align: center;
  white-space: normal;
  border-radius: $border-radius !important;
  pointer-events: none;

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 6px;
    pointer-events: none;
    border-right-color: $primary;
    right: 41%;
    transform: translateY(-302%) rotate(-270deg);
  }
}