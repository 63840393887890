@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.header {
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 $message-center-sidebar-padding;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom: 1px solid $gray-200;
    width: 100%;
  }
}

.backButton {
  display: none;

  [data-expanded="true"] & {
    background: none;
    border: none;
    border-right: 1px solid $gray-200;
    margin-left: -2rem;
    margin-right: 10px;
    height: 4rem;
    width: 4rem;
    cursor: pointer;

    @media (max-width: 640px) {
      display: block;
    }

    &:hover {
      background-color: $gray-100;
    }
  }
}

.containerStyle[data-expanded="true"] {
  @media (min-width: 640px) {
    display: none;
  }
}

.title {
  font-size: 1.3rem;
  letter-spacing: 0.15rem;
  font-family: $font-family-bold;
  text-transform: uppercase;
  color: $txt-body;
  line-height: 1.6rem;
  white-space: nowrap;
  overflow: hidden;
  max-width: 18rem;
  text-overflow: ellipsis;
}

.time {
  margin-left: 0.8rem;
  white-space: nowrap;
  font-size: 1.3rem;
  color: $gray-600;
  line-height: 1.7rem;
}

.nameWrapper {
  @extend %flexVerticalCenter;
}