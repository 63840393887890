@import './TicketDetails.module.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';

.tabContainer {
  grid-template-columns: auto auto;
  display: grid;
  grid-gap: 24px 64px;
  padding: 32px 61px;
  width: 100%;
}

.tableContainer {
  width: 100%;
  height: auto;
  border-spacing: 0;
}

.leftSection {
  grid-column: 1;
  grid-row: 1;
}

.rightSection {
  grid-column: 2;
  grid-row: 1;
}

.headerStyle {
  height: 36px;
  margin: 0 0 35px;
  color: $gray-500;
  text-align: left;
  line-height: 1;
  padding-top: 0;
  font-weight: normal;
  font-size: 16px;
}

.bodyRowStyle {
  height: 36px;
  margin: 35px 0 0 1px;
  padding: 8px 12px 11px 12px;
  font-stretch: normal;
  text-transform: none;
  font-family: $font-family-bold;
  font-size: 16px;
}

.darkBodyRowStyle {
  @extend .bodyRowStyle;
  background-color: $gray-100;
}

.tdStyle {
  border-top: solid 1px $gray-200;
  height: 16px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
}

.label {
  @extend .tdStyle;
  width: 158px;
  margin: 1px 101.6px 0 0;
  font-family: $font-family-bold;
  font-size: 16px;
  font-weight: bold;
  color: $gray-900;
  padding-left: 12px;
}

.timestamp {
  @extend .tdStyle;
  width: 140px;
  padding-left: 24px;
  font-size: 16px;
  font-style: normal;
  color: $gray-600;
}

.value {
  width: 32px;
  height: 16px;
  border-top: solid 1px $gray-200;
  padding-right: 12px;
  margin: 0 0 1px 101.6px;
  font-family: 'TGH', Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: right;
  color: $gray-900;
}

.lastTrStyle {
  border-bottom: solid 1px $gray-200;
}

.ticketQualityFactorsHeader {
  visibility: hidden;
  display: none;
  font-size: 0;
}

@media print {
  .ticketQualityFactorsHeader {
    visibility: visible;
    display: block;
    border-bottom: 2px solid $gray-900;
    margin: 26px 30px 20px 30px;
  }
}