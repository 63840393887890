@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.title {
  padding: 2.2rem;
  background: $gray-200;
}

.titleInput {
  width: 60%;
  margin: 32px 0;
  padding: 0 35px;
}

.marginTop {
  margin-top: 25px;
  margin-bottom: 2px;
}

.marginLeft {
  margin-left: 5px !important;
}

.permissionsLabel {
  padding: 0 35px;
  font-size: 16px;
  color: $gray-600;
}

.permissionsContainer {
  height: 325px;
  padding: 5px;
  padding: 0 35px 24px 35px;
}

.adminContainer {
  width: 100%;
  max-width: 731px;
  height: calc(#{$window-height} - #{$header-height});
  overflow: auto;
  background: $gray-100;
  padding: 30px 30px 60px 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.addPermissionGroupModal {
  width: 75%;
  max-width: none !important;
}

.flagsDivider {
  margin: -2px 35px 32px 35px;
}

.tableContainer {
  width: 100%;
  height: auto;
  padding: 0 35px;
  margin: 0 auto 42px;
}

.textRed {
  color: $darkred;
  margin-bottom: 5px;
}

.featureFooter {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 12px 23px 12px 24px;
  border-top: 1px solid $gray-200;
}