@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.view {
  text-align: center;
  height: calc(100vh - 140px);
  @extend %flexCenter;

  p {
    white-space: pre-wrap;
    color: $gray-900;
    line-height: 1.4;
  }

  h3 {
    margin-bottom: 10px;
    margin-top: 10px;
    white-space: pre-wrap;
    line-height: 1.2;
  }
}