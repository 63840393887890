@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';

.trafficButtonContainer {
  margin-right: 6px !important;
  margin-bottom: 6px !important;
}

.trafficButtonPopover {
  padding: 0 !important;
}

// Popover Label Spacing Overrides
.trafficButtonPopover:before {
  margin-right: 0 !important;
  height: 30px;
  padding: 0 1rem !important;
  display: flex;
  align-items: center;
}

// Popover Arrow Spacing Overrides
.trafficButtonPopover:after {
  margin-right: 5px !important;
}

.trafficButton {
  width: 24px;
  height: 30px;
  background-color: rgb(255, 255, 255);
  // box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    color: #666666;
    font-size: 20px;
  }

  &:hover>i {
    color: $gray-800;
  }
}