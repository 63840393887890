.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-center,
.flex-horizontal-center,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.title {
  padding: 2.2rem;
  background: #e1e4e7;
}

.titleInput {
  width: 60%;
  margin: 32px 0;
  padding: 0 35px;
}

.marginTop {
  margin-top: 25px;
  margin-bottom: 2px;
}

.marginLeft {
  margin-left: 5px !important;
}

.permissionsLabel {
  padding: 0 35px;
  font-size: 16px;
  color: #636b71;
}

.permissionsContainer {
  height: 325px;
  padding: 5px;
  padding: 0 35px 24px 35px;
}

.adminContainer {
  width: 100%;
  max-width: 731px;
  height: calc(100vh - 6.2rem);
  overflow: auto;
  background: #f4f7f9;
  padding: 30px 30px 60px 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.addPermissionGroupModal {
  width: 75%;
  max-width: none !important;
}

.flagsDivider {
  margin: -2px 35px 32px 35px;
}

.tableContainer {
  width: 100%;
  height: auto;
  padding: 0 35px;
  margin: 0 auto 42px;
}

.textRed {
  color: #BD0029;
  margin-bottom: 5px;
}

.featureFooter {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 12px 23px 12px 24px;
  border-top: 1px solid #e1e4e7;
}

