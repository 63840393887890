@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';

.legend {
  font-family: $font-family-regular !important;
  background: $white;
  font-size: 12px;
  color: $gray-900;
  margin: 10px;
  border-radius: 5px;
  display: flex;
  gap: 10px;
  padding: 10px;
  cursor: auto !important;
}

.productColumn {
  .title {
    font-weight: bold;
    font-size: 10px;
    margin-bottom: 6px;
    cursor: none;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: 8px;
      text-transform: uppercase;
      cursor: pointer;

      i {
        margin-right: 6px;
      }

      .name {
        display: none;
      }
    }
  }
}

.tooltip {
  background: $info !important;
  padding: 0.6rem 1rem !important;
  font-size: 1.3rem !important;
  line-height: 1.3 !important;
  margin-top: 50px;
  white-space: nowrap;
  border-radius: $border-radius !important;
  pointer-events: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  // re-aligning the tooltip to be right over the colored dot
  transform: translateX(-10px) translateY(9px) !important;

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 6px;
    pointer-events: none;
    border-right-color: $info;
    top: 100%;
    right: 44%;
    transform: translateY(0%) rotate(-90deg);
  }
}