.background {
  background: url(../../assets/img/bgSignIn.jpg) center/cover no-repeat;
  height: 100%;
  flex-direction: column;
}

.container {
  text-align: center;
  width: 760px;
  height: 410px;
  padding: 25px 34px 31px;
  border-radius: 4px;
  box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.08), 0 5px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.containerWithoutPermissions {
  text-align: center;
  width: 760px;
  height: 410px;
  padding: 110px 34px;
  border-radius: 4px;
  box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.08), 0 5px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  background-color: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.appContainer {
  width: 200px;
  height: 180px;
  margin: 28px 8px 26px 8px;
  padding: 36px 3px 21px 4px;
  border-radius: 4px;
  display: inline-block;
}

.appContainer:hover {
  background: #E2F8FF;
}

.greeting {
  height: 50px;
  font-size: 24px;
  color: #151f28;
}

.userFullName {
  font-weight: bold;
}

.text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0a63f3;
}

.navButton {
  cursor: pointer;
  border: none;
  padding: 0;
  margin: 0 7px 0 7px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.14);
}

.navButton:hover {
  background: #E2F8FF;
}

