@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';
@import './global.scss';

.mapContainer {
  width: 100%;
}

.containerElement {
  height: 100%;
}

.mapPane {
  width: 100%;
  height: calc(#{$window-height} - #{$header-height});
  background: $gray-700;
}

.mapElement,
.map,
.mapPane,
.cardMap {
  /**
  * The way Google Maps styles each of the
  * controls in the map is by inline styles
  * which forces us to add "!important"
  * in order to override the inline style
  * specificity.
  */

  div[title="Show street map"] {
    height: 20px !important;
    font-size: 12px !important;
  }

  div[title="Show satellite imagery"] {
    height: 20px !important;
    font-size: 12px !important;
  }

  div[class^="RecenterMapBtn_recenterButtonContainer"],
  div[class^="RecenterMapBtn_recenterButtonContainer"] button {
    width: 24px !important;
    height: 24px !important;
    margin: 0;
    padding: 0;
  }

  div[class^="RecenterMapBtn_recenterButtonContainer"] i[class="icon-reset-map"] {
    font-size: 14px !important;
  }

  & div[class="gm-style"] {
    >div[class="gmnoprint"]:last-child {
      margin: 5px 10px !important;
    }
  }

  .mapInput {
    background: white !important;
    border: 1px solid white !important;
    box-shadow: inset 0 0 0 1px transparent, 0 1px 4px rgba(0, 0, 0, 0.2) !important;

    &::-webkit-input-placeholder {
      color: $gray-400 !important;
    }

    &:focus {
      border: 1px solid $blue !important;
      box-shadow: inset 0 0 0 1px $blue, 0 1px 4px rgba(0, 0, 0, 0.2) !important;
    }
  }
}