.categoryLink {
  transition: 0.3s !important;
}
.categoryLink:hover {
  text-decoration: none;
}
.categoryLink:focus {
  outline: none;
}
.categoryLink:focus p {
  outline: none;
}

