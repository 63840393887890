@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.header {
  display: flex;
  flex-direction: row;
}

.header h3 {
  margin: 3px 0 0 0;
}

.uomContainer {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.uomContainer .label {
  font-family: 'PublicSans Bold', Helvetica, sans-serif;
  font-size: 13px;
  margin-right: 7px;
}

.uomContainer>div>div {
  background: transparent !important;
  box-shadow: none !important;
  border: none !important;
  font-size: 12px !important;
  line-height: 23px !important;
  height: 22px !important;
  padding-top: 0 !important;
}

.uomContainer>div>i {
  top: 5px !important;
}

.scaleContainer {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.14);
  background-color: #fff;
  margin-top: 7px;
  min-height: 320px;
}

.scaleContainer .container {
  display: flex;
  align-items: center;
  padding: 16px 20px;
}

.scaleDivider {
  border-left: 1px solid $gray-300;
  min-height: 200px;
}

.scaleRectangleContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 50%;
  max-width: 185px;
}

.scaleElementRectangle {
  display: flex;
  align-items: center;
  margin: 0 0 10px 0;
  background-color: #fff;
  position: relative;
  width: 100%;
  height: 60px;
  max-width: 185px;
}

.scaleElementRectangle input {
  text-align: right;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 0;
  padding-top: 22px !important;
  padding-right: 36px !important;
  font-size: 19px !important;
  background-color: #fff !important;
}

.scaleElementRectangleTare {
  display: flex;
  align-items: center;
  margin: 0 0 10px 0;
  background-color: #fff;
  position: relative;
  width: 100%;
  height: 60px;
  max-width: 185px;
}

.scaleElementRectangleTare input {
  text-align: right;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 0;
  padding-top: 22px !important;
  padding-right: 36px !important;
  font-size: 19px !important;
  background-color: #ffecf0 !important;
}

.scaleElementRectangleTare label {
  position: absolute;
  top: 12px;
  left: 14px;
  font-size: 14px;
  z-index: 1;
}

.scaleElementRectangle label {
  position: absolute;
  top: 12px;
  left: 14px;
  font-size: 14px;
  z-index: 1;
}

.scaleDashesContainer {
  @extend .scaleRectangleContainer;
  padding: 0 20px;
}

.scaleDashesRectangle {
  width: 100%;
  height: 59px;
  margin: 0 0 10px;
  padding: 8.3px 14px 8px;
  border-radius: 4px;
  border: solid 1px $gray-200;
  background-color: #fff;

  color: $gray-900;
}

.scaleDashLabel {
  font-weight: bold;
  font-size: 16px;
}

.scaleDashUOM {
  color: $gray-900;
  font-size: 12px;
  text-align: right;
}

.Gross {
  width: 41px;
  height: 21px;
  margin: 0 52px 22px 0;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $gray-900;
}

.Tare {
  width: 29px;
  height: 21px;
  margin: 0 64px 21.7px 0;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $gray-900;
}

.Net {
  width: 23px;
  height: 21px;
  margin: 0 70px 22.7px 0;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: $gray-900;
}

.tareButton {
  width: 100%;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.scaleWeight {
  width: 37px;
  height: 28px;
  margin: 15px 0 0 52px;
  font-family: $font-family-regular;
  font-size: 19px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: $gray-900;
}

.VehicleScaleLabel {
  width: 74px;
  height: 20px;
  margin: 0 156px 7px 0;
  font-family: 'PublicSans Regular';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2px;
  color: var(--gray-600);
}

.uom {
  color: $gray-900;
  font-size: 12px;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 3em;
  margin-left: 0.3em;
  margin-top: 24px;
  z-index: 1;
  right: 14px;
  position: absolute;
}

.tareContainer {
  margin-left: 20px;
  display: flex;
  align-items: center;
  width: 50%;
}

.tareDetails {
  &>div {
    text-transform: uppercase !important;
    line-height: 1.25;
    font-size: 1.3rem !important;
  }
}

.truckLabelText,
.tareLabelText {
  font-family: $font-family-bold !important;
}

.truckLabelText {
  font-size: 1.6rem !important;
}

.tareLabelText {
  font-size: 1.3rem !important;
  margin: 1.2rem 0 0 0 !important;
}

.tareRequiredText {
  color: $darkred;
  font-size: 12px;
  line-height: 1.25;
}

.tareExpireText {
  color: $darkred;
  font-family: $font-family-bold !important;
  font-size: 1.3rem !important;
}

.tareManualText {
  text-transform: none;
  font-size: 12px;
  font-family: $font-family-bold;
  font-style: italic;
  color: $gray-900;
}

.weightAlert {
  min-height: 28px;
  background-color: #ffecf0;
  margin: 6px;
  margin-bottom: 0;
  border-radius: 4px;
  padding: 6px 10px;
  text-align: center;
  color: #bd0029;
  font-weight: bold;
  font-size: 11px;
  text-transform: uppercase;
}

.weightAlertCaps {
  @extend .weightAlert;
  text-transform: uppercase;
}

.weightWarning {
  @extend .weightAlert;
  background-color: $xlightorange;
  color: $darkorange;
}

.loadingCircle {
  vertical-align: top;
}