.externalId p:empty::after {
  content: "N/A";
  color: #a5abaf;
}
.externalId p[title="N/A"] {
  color: #a5abaf;
}
.externalId p[placeholder="N/A"] {
  color: #a5abaf;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.plantsAddModal,
.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-center,
.flex-horizontal-center,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.margin-0 {
  margin: 0 !important;
}

.margin-top-0 {
  margin-top: 0;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-right-20 {
  margin-right: 20px;
}

.margin-right-30 {
  margin-right: 30px;
}

.margin-right-40 {
  margin-right: 40px;
}

.margin-left-5 {
  margin-left: 5px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-left-30 {
  margin-left: 30px;
}

.margin-left-40 {
  margin-left: 40px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

.wrap-words {
  word-break: initial;
  line-height: 1.1;
}

.nowrap-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

.pre-wrap {
  white-space: pre-wrap;
}

.height-100 {
  height: 100%;
}

.icon-md {
  font-size: 1.8rem;
}

.icon-lg {
  font-size: 2rem;
}

.icon-xl {
  font-size: 3rem;
}

.icon-people-margin {
  position: relative;
  top: 2px;
  margin-right: 3px;
}

.underline {
  text-decoration: underline;
}

.clickable {
  cursor: pointer;
}

/* MODAL 1*/
.modal1 {
  display: grid;
  grid-gap: 28px;
  grid-template-columns: repeat(4, 1fr);
  margin: 30px auto;
}

.dropdownWidth {
  grid-column: 1/3;
}

.mediumWidth {
  grid-column: 1/3;
}

.fullWidth {
  grid-column: 1/4;
}

.headerText {
  color: #151F28;
  line-height: 1.1;
}

.city {
  grid-column: 1/3;
}

.state {
  grid-column: 3;
  min-width: 72px;
}

.zip {
  grid-column: 4;
  min-width: 72px;
}

.productLinesM1 {
  grid-column: 1;
  grid-row: 8;
}

.uomM1 {
  grid-column: 2;
  grid-row: 9;
}

.gtLiteParticipantM1 {
  grid-column: 1/3;
  grid-row: 9;
  color: #151F28;
  grid-row: 10;
}

.ownershipTypeM1 {
  grid-column: 1;
  grid-row: 11;
}

.editWeights {
  grid-column: 1/3;
  grid-row: 11;
  margin-left: 14px;
  color: #151F28;
  grid-row: 12;
}

.editWeightsSwitchContainer {
  margin-top: -7px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.gtLiteParticipantSwitchContainer {
  margin-top: -7px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.editWeightsSwitchLabel {
  font-size: 1.6rem !important;
}

.gtLiteParticipantSwitchLabel {
  font-size: 1.6rem !important;
}

.editWeightsNotAllowed {
  cursor: not-allowed;
}

.gtLiteParticipantNotAllowed {
  cursor: not-allowed;
}

/* MODAL 2*/
.modal2 {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: minmax(auto, 700px) 1fr;
  color: #151F28;
}

.title {
  grid-column: 1/3;
  grid-row: 1;
}
.title :global(h3) {
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  margin-top: 5px;
  margin-bottom: 18px;
}

.map {
  grid-column: 1/span 3;
  grid-row: 2/7;
}

/* MAP GEOZONE MODAL */
.geozoneModal {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 28px;
  padding-bottom: 35px;
  color: #151F28;
}

.geozoneTitle {
  grid-column: 1/span 3;
  grid-row: 1;
}
.geozoneTitle :global(h3) {
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  padding: 0 !important;
  line-height: 1 !important;
  margin-block-start: 0 !important;
}
.geozoneTitle p {
  margin-bottom: 8px;
}

.reviewTitleText {
  margin-bottom: 4px !important;
}

.geozoneMap {
  grid-column: 1/span 3;
  grid-row: 3/7;
}
.geozoneMap label:after {
  content: " *";
  color: #BD0029;
}

.plantSummaryModal {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
  padding-bottom: 10px;
  color: #151F28;
}

.mapSummary {
  grid-column: 2/span 2;
  grid-row: 2/7;
  height: 350px;
}

.externalId {
  grid-column: 1;
  grid-row: 2;
  margin-top: 20px;
}

.externalId,
.mapSummary {
  margin-top: 20px;
}

.name {
  grid-column: 1;
  grid-row: 3;
}

.address {
  grid-column: 1;
  grid-row: 4;
}
.address :global(p) {
  white-space: pre-wrap;
  word-break: break-word;
}

.region {
  grid-column: 1;
  grid-row: 5;
}

.latitude {
  grid-column: 1;
  grid-row: 6;
}

.longitude {
  grid-column: 1;
  grid-row: 7;
}

.productLinesM2 {
  grid-column: 1;
  grid-row: 8;
}

.uomM2 {
  grid-column: 1;
  grid-row: 9;
}

.LoadTimeIndex {
  grid-column: 2;
  grid-row: 7/span 2;
  margin-top: 1.8rem;
  width: 300px;
}

.gtLiteParticipantM2 {
  grid-column: 1;
  grid-row: 10;
}

.ownershipTypeM2 {
  grid-column: 1;
  grid-row: 11;
}

.scaleHostAddressM2 {
  grid-column: 1;
  grid-row: 12;
}

.editWeightsRead {
  grid-column: 1;
  grid-row: 13;
}

.geozoneBoundariesOptions {
  grid-column: 1/span 3;
  grid-row: 2;
  margin-top: -40px;
}

.inlineStatusIcon {
  line-height: 1;
  display: inline-block;
}

.statusIconArrow {
  margin-left: -1px;
  margin-right: -3px;
  font-size: 1.6rem;
  transform: translateY(2px);
}

.statusIconDot {
  margin-left: -1px;
  margin-right: -1px;
  font-size: 1.8rem;
  transform: translateY(3px);
}

.listedStatusIcon {
  font-size: 1.6rem;
  margin: 0 5px 0 0;
  display: inline-block;
  transform: translateY(2.5px);
}

.statusIconToJob {
  color: #F66A00;
}

.statusIconInQueue {
  color: #03d8e2;
}

.statusIconReturn {
  color: #636b71;
}

.geozonesIndex {
  grid-row: 7/span 2;
  grid-column: 3;
}

.text {
  font-size: 1.3rem;
  display: block;
}

.asterisk {
  color: #e92851;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  font-size: 1.8rem;
  display: inline-block;
  transform: translateY(3px);
  margin-left: 0.3rem;
  line-height: 0.5;
}

.uom {
  margin-left: 3rem;
}

.productLines {
  font-size: 16px !important;
  font-weight: 400 !important;
  margin: 4px !important;
  font-family: "PublicSans Regular", Helvetica, Arial, sans-serif !important;
  color: #151f28;
  grid-row: 8;
}

.radioBtnSize, .radioBtnSelected, .radioBtn {
  height: 10px;
  width: 10px;
  margin-left: 13px !important;
}

.radioBtn {
  color: #151F28 !important;
  padding: 12px !important;
  margin-left: 10px !important;
}
.radioBtn :global(.MuiSvgIcon-root) {
  font-size: 2.4rem !important;
}

.radioBtnSelected {
  color: #008EF3 !important;
}

.radioLabel {
  font-size: 16px !important;
  font-weight: 400 !important;
  margin: 4px !important;
  font-family: "PublicSans Regular", Helvetica, Arial, sans-serif !important;
  color: #151F28;
}

.uomLabel {
  margin-top: 0.8rem;
}

.LoadedTimeLabel {
  margin-left: 1.5rem !important;
  margin-top: 0.8rem;
  margin-bottom: 0.8rem;
  width: 300px;
}

.loadedTimeSwitch {
  margin-left: 1rem;
}

.loadTimeSelect {
  background: #f4f7f9 !important;
  box-sizing: border-box;
  margin-left: 1.5rem !important;
  margin-bottom: 0.8rem;
  width: 85%;
  height: 40px;
}

.labelWithTooltip {
  display: flex;
  align-items: center;
  width: 140px;
}

.tooltipIcon {
  margin-left: 0.5rem;
  cursor: pointer;
  color: lightgray;
  font-size: 1.8rem !important;
}

.LoadTimeIndex p[title="Not set"] {
  color: #a5abaf;
}

