.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-center,
.flex-horizontal-center,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.wrapper {
  position: relative;
}

.paper {
  overflow: visible !important;
  margin-left: 20px;
}

.header {
  width: 100%;
  height: 50px;
  background: #008EF3;
  border-radius: 0.3rem 0.3rem 0 0;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 2rem;
}

.headerText {
  font-size: 1.3rem;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  line-height: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: white;
  letter-spacing: 0.2rem;
}

.arrow {
  display: block;
  height: 25px;
  width: 25px;
  background-color: white;
  border: inherit;
  position: absolute;
  top: calc(50% + 25px);
  left: -12.5px;
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  transform: rotate(45deg);
  border-radius: 0 0 0 5px;
}

.appLogo,
.appLogo img {
  height: auto !important;
  transition: 0.3s !important;
  display: inline-block;
  transform: translateY(1px);
}

.newWindowIcon {
  color: #0A63F3;
  font-size: 2.2rem;
  line-height: 1.1;
  text-align: left;
  margin-left: 45px;
}

.openAppButton {
  display: flex !important;
  align-items: center;
}

