.docsPanelContent {
  margin-top: -13px;
}

.categoryLink:hover {
  text-decoration: none;
}

categoryLink:focus {
  outline: none;
}
categoryLink:focus p {
  outline: none;
}

.folder {
  background: #2A353E;
}

.folderContent {
  max-height: 130px !important;
}

