.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-center,
.flex-horizontal-center,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.featureFooter {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 12px 22px 12px 23px;
  border-top: 1px solid #e1e4e7;
}

.dropDownListDiv {
  margin-top: -1px;
}

.dropDownListDiv.hasError > div > div {
  border: 1px solid #E92851 !important;
  background-color: #FFECF0 !important;
}
.dropDownListDiv.hasError > div > fieldset {
  display: none;
}

.multiSelectErrorMessage {
  margin-top: 3px;
  font-size: 13px;
  color: red;
}

.menuContainer {
  margin-top: 4px !important;
}
.menuContainer div {
  max-height: 136px !important;
}

.forms {
  margin: 30px 0 0 0;
  padding: 0 34px;
}

:global(.MuiTypography-root.MuiDialogContentText-root.MuiTypography-body1.MuiTypography-colorTextSecondary) {
  margin-bottom: 0 !important;
}

.addModal :global(.quarterWidth) {
  width: 25%;
}
.addModal :global(.baseMargin) {
  margin: 25px 0 30px 0;
}
.addModal :global(.smallMargin) {
  margin: 10px 0 20px 0;
}
.addModal .baseWidthSpace, .addModal :global(.bottomWidthSpace), .addModal :global(.topWidthSpace), .addModal :global(.quarterWidthSpace), .addModal :global(.halfWidthSpace), .addModal :global(.fullWidthSpace), .addModal :global(.widthSpace) {
  margin: 32px 0 30px 0;
}
.addModal .baseWidthSpace:first-of-type, .addModal :first-of-type:global(.bottomWidthSpace), .addModal :first-of-type:global(.topWidthSpace), .addModal :first-of-type:global(.quarterWidthSpace), .addModal :first-of-type:global(.halfWidthSpace), .addModal :first-of-type:global(.fullWidthSpace), .addModal :first-of-type:global(.widthSpace) {
  margin: 0 0 30px 0;
}
.addModal .bottomSpace, .addModal :global(.bottomWidthSpace), .addModal :global(.gBottomSpace) {
  margin-bottom: 40px;
}
.addModal :global(.widthSpace) {
  width: 75%;
}
.addModal :global(.fullWidthSpace) {
  width: 100%;
}
.addModal :global(.halfWidthSpace) {
  width: 50%;
}
.addModal :global(.quarterWidthSpace) {
  width: 25%;
}
.addModal :global(.baseDropDown) {
  width: 62.5%;
  vertical-align: top;
}
.addModal :global(.bottomDropDown) {
  vertical-align: top;
}
.addModal :global(.topWidthSpace) {
  margin-top: 40px;
}
.addModal :global(.flexInput) {
  margin-bottom: 10px;
  width: 100%;
}
.addModal :global(.fontFam) {
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
}
.addModal :global(.flexHorizontalRadio) > div > div > div {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.addModal :global(.margin-top) {
  margin-top: 32px;
}
.addModal :global(.tt-label--help) {
  display: flex;
  justify-content: flex-end;
  margin-top: 4px;
  line-height: 16px;
  font-size: 13px;
  font-weight: 200;
}
.addModal :global(.tt-label--help) span {
  margin-bottom: -10px;
}
.addModal :global(.tt-label--character-count) {
  display: flex;
  justify-content: flex-end;
  margin-top: 3px;
}
.addModal :global(.tt-label--character-count) span {
  margin-bottom: -10px;
}

.errorFieldMargin {
  margin: 20px 0 10px !important;
}

.radioBtnSize, .radioBtnError, .radioBtnDisabled, .radioBtnSelected, .radioBtn {
  height: 10px;
  width: 10px;
  margin-left: 13px !important;
}

.radioBtn {
  color: #151F28 !important;
  padding: 12px !important;
  margin-left: 10px !important;
}
.radioBtn :global(.MuiSvgIcon-root) {
  font-size: 2.4rem !important;
}

.radioBtnSelected {
  color: #008EF3 !important;
}

.radioBtnDisabled {
  color: #c8cdd0 !important;
}

.radioBtnError {
  color: #BD0029 !important;
  background-color: #FFECF0 !important;
}

.radioLabel {
  font-size: 16px !important;
  font-weight: 400 !important;
  margin: 4px !important;
  font-family: "PublicSans Regular", Helvetica, Arial, sans-serif !important;
  color: #151F28;
}

.radioLabelDisabled {
  opacity: 0.4;
}

.textRed {
  color: #BD0029;
}

.checkboxLabel {
  color: #151F28 !important;
  font-size: 16px !important;
}

.checkboxLabel span,
.checkboxLabel span input {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-left: 13px !important;
  padding-right: 0px !important;
  font-size: 16px !important;
}
.checkboxLabel span:hover,
.checkboxLabel span input:hover {
  background: none !important;
}
.checkboxLabel span svg,
.checkboxLabel span input svg {
  height: 24px;
  width: 24px;
}

.checkboxUnchecked {
  color: #151F28 !important;
  font-size: 16px !important;
}

.checkboxRequired svg {
  color: #E92851;
}

.checkboxChecked {
  color: #008EF3 !important;
  font-size: 16px !important;
}

.selectContainer {
  width: 100% !important;
}
.selectContainer :global(.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input) {
  font-size: 1.6rem;
}

.select {
  height: 4rem !important;
  box-sizing: border-box !important;
  padding: 0.8rem 3.3rem 0.2rem 1.3rem !important;
  box-shadow: inset 0 0 0 1px #e1e4e7;
  background: #f4f7f9 !important;
  border-radius: 3px !important;
  transition: 0.3s !important;
  line-height: 3.25rem !important;
}
.select:hover {
  box-shadow: inset 0 0 8px 2px #e1e4e7;
}
.select:focus, .select:global(.open) {
  box-shadow: inset 0 0 0 2px #008EF3 !important;
}

.placeholderText {
  color: #878e93 !important;
}

.groupHeader {
  font-weight: bold;
}

.groupItem {
  margin-left: 10px;
}

.labelDisabled {
  opacity: 0.4;
}

.subLabel {
  margin-top: 5px;
  font-family: "PublicSans Regular";
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
}

.labelWithTooltip {
  display: flex;
}
.labelWithTooltip .tooltipSpacer {
  padding-left: 4px;
}
.labelWithTooltip .tooltipSpacer span {
  font-size: 12px;
  display: block;
  color: #a5abaf;
}

