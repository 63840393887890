@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_helpers.scss';
@import '../../../../styles/OrderStatuses.scss';

.goBack,
.keepAddress {
  padding: 0 !important;
  min-height: 0 !important;
  display: inline-block;

  span {
    font-family: $font-family-bold !important;
    font-size: 1.3rem;
    line-height: 1;
  }

  i {
    font-weight: bolder;
    padding: 0 0.5rem 0 0;
  }

  &:hover span {
    text-decoration: underline !important;
    color: $darkblue !important;
  }

  &:hover i {
    color: $darkblue !important;
  }
}

.goBack {
  margin: -3px 0 0 2px;
}

.keepAddress {
  margin: -3px 0 0 4px;
}

.wrapper {
  height: calc(#{$window-height} - #{$header-height});
  overflow-y: auto;
}

.layout {
  margin: 31px 31px 0 31px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-areas: 'details map' 'table table';
  grid-gap: 24px;
  min-height: 500px;
}

.details {
  padding: 34px 38px 50px 38px;
  position: relative;
  min-height: 565px;

  h3 {
    margin: 0;
    font-size: 3rem !important;
    font-family: $font-family-bold !important;
    line-height: 1 !important;
    word-wrap: break-word !important;
    white-space: normal !important;
  }

  label {
    word-wrap: break-word !important;
  }
}

.details,
.mapContainer {
  position: relative;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15),
    0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 3px 1px -2px rgba(0, 0, 0, 0.07) !important;
  overflow: hidden;
}

.plant {
  margin-top: 34px;
}

.map {
  height: 100%;
  z-index: 1;
}

.fullMap {
  height: 100%;
}

.mapEditingWithNotification {
  height: calc(100% - 55px);
}

.milesInput {
  max-width: 50px;
  margin: -8px 10px 0 10px;
}

.mapEditControls {
  line-height: 16px;
  font-size: 16px;
  height: 59px;
  box-shadow: 0 1px 4px 0 rgba(38, 41, 48, 0.35);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 16px 10px 16px 24px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;

  .geofenceRadiusLabel {
    font-size: 1.3rem;
    font-family: $font-family-bold;
  }

  .miles {
    margin-bottom: 1px;
  }
}

.mapEditControlsButtons {
  display: inline-block;
  margin-left: auto;

  button+button {
    margin-left: 10px;
  }
}

.orderItem {
  padding-bottom: 26px;

  label {
    margin-bottom: 3px;
  }

  p {
    margin-bottom: -1px;
    line-height: 1.3;
    white-space: normal;
    word-wrap: break-word;
  }
}

.noPadding {
  padding-bottom: 0;
}

.pageContainer {
  height: calc(#{$window-height} - #{$header-height});
  overflow-y: auto;
}

.headerAndContainerStyle {
  height: 100%;
}

.mapClickLabelContainer {
  text-align: center;
}

.mapClickLabel {
  height: 1.3rem;
  font-family: $font-family-regular;
  font-size: 1.3rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.5em;
  letter-spacing: normal;
  text-align: center;
  color: $gray-900;
}

.sourceLabel {
  color: $gray-600;
  margin-top: 4rem !important;
  position: absolute;
  bottom: 29px;
  font-size: 1.3rem;

  label {
    padding-right: 5px;
    display: inline-block;
    color: $gray-600;
  }
}

.table {
  grid-area: table;
}

.closerPlantNotificationContainer {
  background-color: $xlightorange;
  font-size: 1.3rem;
  padding: 1.8rem;
  border-left: 4px solid $orange;
  margin: 3px 0 28px 0;
  border-radius: 3px;
}

.closerPlantLabelContainer {
  padding-bottom: 11px;

  span {
    font-size: 1.6rem;
    line-height: 1.3;
    white-space: normal;
    word-wrap: break-word;
  }
}

.clearViewMsg {
  font-family: $font-family-italic;
  font-size: 1.3rem;
  line-height: 1.2 !important;
  margin-left: 1px;
  margin-right: 2px;
  display: inline-block;
}

.keepAsBtn {
  margin: 1.3rem 0 0.1rem 0;
  padding: 0;
  display: inline-block;
  line-height: 1.2;
  background: none;
  border: none;
  text-align: left;

  span {
    font-family: $font-family-bold !important;
    font-size: 1.3rem;
    line-height: 1 !important;
    text-align: left !important;
    font-size: 1.3rem;
    color: $darkblue;
  }

  &:hover span {
    text-decoration: underline;
    cursor: pointer;
  }
}

.inaccurateNotificationContainer {
  text-align: center;
  height: 55px;
  background-color: $xlightorange;
  font-size: 1.3rem;
  padding: 7px !important;
}

.inaccurateAddressLabel {
  font-size: 1.3rem;
  font-family: $font-family-bold;
  line-height: 1;
  margin: 0 0 3px 0 !important;
  color: $gray-900;
}

.warningIcon {
  margin-right: 4px !important;
  color: $orange;
  font-size: 1.6rem;
  line-height: 1;
  display: inline-block;
  transform: translateY(2px) !important;
}

.editMapButton {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;

  // Added elevation shadow without overriding existing tt-btn styles
  &:after {
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-shadow: 0px 1px 4px 0 rgba(0, 0, 0, 0.3);
  }
}

.orderBadge {
  @extend .orderStatus;
  margin-left: 0;
  margin-top: 10px;
  margin-bottom: 26px;
  background-color: $gray-100;
  border: solid 1px $gray-200;
  border-radius: $border-radius;
  height: 23px;
  display: inline-block;
  padding: 2px 5px 2px 4px;

  &:before {
    display: none;
  }
}

.statusLabel {
  position: relative;
  display: inline-block;
  font-size: 1.3rem;
  line-height: 1;
  font-family: $font-family-bold;

  &:before {
    @include beforeDot();
  }
}

// changes order details layout when container is smaller than 850px
.detailsSmall {
  &.layout {
    display: flex;
    flex-direction: column;
    margin: 0;

    .details {
      margin: 31px 31px 0 31px;
      min-height: 0;
    }

    .mapContainer {
      margin: 0 31px 25px 31px;
      height: 404px;
    }

    .closerPlantNotificationContainer {
      width: 100%;
    }

    .firstDetailGroup,
    .secondDetailGroup {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }

    .orderItem {
      width: 100% !important;

      &:last-of-type {
        padding-bottom: 0 !important;
        margin-bottom: 2rem;
      }
    }
  }
}