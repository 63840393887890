.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.background,
.flex-center,
.flex-horizontal-center,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.background,
.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

/*----------  BUTTONS  ----------*/
.tt-btn {
  padding: 0.1rem 1.3rem 0.35rem 1.3rem;
  min-height: 4rem;
  min-width: 4rem;
  font-family: "PublicSans Regular", Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  transition: 0.3s !important;
  color: white;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.28), 0px 2px 2px 0px rgba(0, 0, 0, 0.22), 0px 3px 1px -2px rgba(0, 0, 0, 0.2);
  background: #008EF3;
}
.tt-btn span {
  font-family: "PublicSans Regular", Helvetica, Arial, sans-serif;
  color: white;
}
.tt-btn i {
  color: white;
  font-size: 1.4rem;
  line-height: 1;
  padding-top: 0;
  padding-bottom: 0.1rem;
}
.tt-btn:hover {
  background: #17A9FF;
}
.tt-btn:focus {
  outline: none;
}
.tt-btn--accent {
  background: #F66A00;
  color: white;
}
.tt-btn--accent span,
.tt-btn--accent i {
  color: white;
}
.tt-btn--accent:hover {
  background: #FF8C12;
}
.tt-btn--submit {
  background: #00AC4A;
  color: white;
}
.tt-btn--submit span,
.tt-btn--submit i {
  color: white;
}
.tt-btn--submit:hover {
  background: #00C46C;
}
.tt-btn--error {
  background: #E92851;
  color: white;
}
.tt-btn--error span,
.tt-btn--error i {
  color: white;
}
.tt-btn--error:hover {
  background: #FF3B64;
}
.tt-btn:disabled, .tt-btn--accent:disabled, .tt-btn--submit:disabled {
  background-color: #a5abaf;
  color: white;
  cursor: not-allowed;
  box-shadow: none !important;
}
.tt-btn:disabled i, .tt-btn--accent:disabled i, .tt-btn--submit:disabled i {
  color: white;
}
.tt-btn-secondary {
  background: #f4f7f9;
  color: #0A63F3;
  transition: 0.3s !important;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15), 0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 3px 1px -2px rgba(0, 0, 0, 0.07) !important;
}
.tt-btn-secondary i {
  color: #0A63F3;
}
.tt-btn-secondary:hover {
  background: #E2F8FF;
}
.tt-btn-secondary:focus {
  outline: none;
}
.tt-btn-secondary--accent {
  background: #f4f7f9;
  color: #C85000;
  transition: 0.3s !important;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15), 0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 3px 1px -2px rgba(0, 0, 0, 0.07) !important;
}
.tt-btn-secondary--accent i {
  color: #C85000;
}
.tt-btn-secondary--accent:hover {
  background: #FDF3E2;
}
.tt-btn-secondary--accent:focus {
  outline: none;
}
.tt-btn-secondary--submit {
  background: #f4f7f9;
  color: #00884F !important;
  transition: 0.3s !important;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15), 0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 3px 1px -2px rgba(0, 0, 0, 0.07) !important;
}
.tt-btn-secondary--submit i {
  color: #00884F;
}
.tt-btn-secondary--submit:hover {
  background: #E5FCF5;
}
.tt-btn-secondary--submit:focus {
  outline: none;
}
.tt-btn-secondary--error {
  background: #f4f7f9;
  color: #BD0029;
  transition: 0.3s !important;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15), 0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 3px 1px -2px rgba(0, 0, 0, 0.07) !important;
}
.tt-btn-secondary--error i {
  color: #BD0029;
}
.tt-btn-secondary--error:hover {
  background: #FFECF0;
}
.tt-btn-secondary--error:focus {
  outline: none;
}
.tt-btn-secondary:disabled, .tt-btn-secondary--accent:disabled, .tt-btn-secondary--error:disabled, .tt-btn-secondary--submit:disabled {
  background-color: #f4f7f9;
  color: #878e93;
  cursor: not-allowed;
  box-shadow: none !important;
}
.tt-btn-secondary:disabled i, .tt-btn-secondary--accent:disabled i, .tt-btn-secondary--error:disabled i, .tt-btn-secondary--submit:disabled i {
  color: #878e93;
}
.tt-btn.tt-btn-basic {
  background: transparent !important;
  box-shadow: none !important;
  color: #0A63F3;
  padding: 0;
  min-height: auto;
}
.tt-btn.tt-btn-basic i,
.tt-btn.tt-btn-basic span {
  color: #0A63F3;
  transition: 0.3s !important;
}
.tt-btn.tt-btn-basic:hover, .tt-btn.tt-btn-basic:hover > i, .tt-btn.tt-btn-basic:hover > span {
  color: #008EF3;
}
.tt-btn.tt-btn-basic:focus {
  outline: none;
}
.tt-btn.tt-btn-basic--accent {
  background: transparent !important;
  box-shadow: none !important;
  color: #C85000;
}
.tt-btn.tt-btn-basic--accent i,
.tt-btn.tt-btn-basic--accent span {
  color: #C85000;
}
.tt-btn.tt-btn-basic--accent:hover, .tt-btn.tt-btn-basic--accent:hover > i, .tt-btn.tt-btn-basic--accent:hover > span {
  color: #F66A00;
}
.tt-btn.tt-btn-basic--submit {
  background: transparent !important;
  box-shadow: none !important;
  color: #00884F;
}
.tt-btn.tt-btn-basic--submit i,
.tt-btn.tt-btn-basic--submit span {
  color: #00884F;
}
.tt-btn.tt-btn-basic--submit:hover, .tt-btn.tt-btn-basic--submit:hover > i, .tt-btn.tt-btn-basic--submit:hover > span {
  color: #00AC4A;
}
.tt-btn.tt-btn-basic--error {
  background: transparent !important;
  box-shadow: none !important;
  color: #BD0029;
}
.tt-btn.tt-btn-basic--error i,
.tt-btn.tt-btn-basic--error span {
  color: #BD0029;
}
.tt-btn.tt-btn-basic--error:hover, .tt-btn.tt-btn-basic--error:hover > i, .tt-btn.tt-btn-basic--error:hover > span {
  color: #E92851;
}
.tt-btn.tt-btn-basic--white {
  background: transparent !important;
  box-shadow: none !important;
  color: white;
  transition: 0.3s !important;
}
.tt-btn.tt-btn-basic--white i,
.tt-btn.tt-btn-basic--white span {
  color: white;
  transition: 0.3s !important;
}
.tt-btn.tt-btn-basic--white:hover, .tt-btn.tt-btn-basic--white:hover > i, .tt-btn.tt-btn-basic--white:hover > span {
  color: rgba(255, 255, 255, 0.7);
}
.tt-btn.tt-btn-basic:disabled, .tt-btn.tt-btn-basic--accent:disabled, .tt-btn.tt-btn-basic--error:disabled, .tt-btn.tt-btn-basic--submit:disabled {
  color: #a5abaf;
  box-shadow: none;
}
.tt-btn.tt-btn-basic:disabled i,
.tt-btn.tt-btn-basic:disabled span, .tt-btn.tt-btn-basic--accent:disabled i,
.tt-btn.tt-btn-basic--accent:disabled span, .tt-btn.tt-btn-basic--error:disabled i,
.tt-btn.tt-btn-basic--error:disabled span, .tt-btn.tt-btn-basic--submit:disabled i,
.tt-btn.tt-btn-basic--submit:disabled span {
  color: #a5abaf !important;
}
.tt-btn.tt-btn-text {
  background: transparent !important;
  box-shadow: none !important;
  color: #636b71;
}
.tt-btn.tt-btn-text i,
.tt-btn.tt-btn-text span {
  color: #0A63F3;
  transition: 0.3s !important;
}
.tt-btn.tt-btn-text:hover, .tt-btn.tt-btn-text:hover > i, .tt-btn.tt-btn-text:hover > span {
  color: #008EF3 !important;
}
.tt-btn.tt-btn-text--small {
  background: transparent;
  box-shadow: none;
  color: #636b71;
  font-size: 1.3rem;
  line-height: 1;
  padding: 0;
  min-height: 0;
  min-width: 0;
}
.tt-btn.tt-btn-text--small i,
.tt-btn.tt-btn-text--small span {
  color: #0A63F3;
  transition: 0.3s !important;
}
.tt-btn.tt-btn-text--small span {
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif !important;
}
.tt-btn.tt-btn-text--small:hover, .tt-btn.tt-btn-text--small:hover > i, .tt-btn.tt-btn-text--small:hover > span {
  color: #008EF3;
}
.tt-btn.tt-btn-text-dark {
  background: transparent !important;
  box-shadow: none !important;
  color: #151F28;
}
.tt-btn.tt-btn-text-dark i,
.tt-btn.tt-btn-text-dark span {
  color: #008EF3;
  transition: 0.3s !important;
}
.tt-btn.tt-btn-text-dark:hover, .tt-btn.tt-btn-text-dark:hover > i, .tt-btn.tt-btn-text-dark:hover > span {
  background-color: #f4f7f9 !important;
}
.tt-btn-selection {
  background: #f4f7f9;
  box-shadow: 0 2px 5px 0 rgba(76, 88, 98, 0.3);
  padding: 2.4rem;
}
.tt-btn-selection i,
.tt-btn-selection span {
  display: block;
}
.tt-btn-selection i {
  font-size: 8rem;
  line-height: 1;
  margin: -1.8rem 0 0 0;
  padding: 0;
  color: #008EF3;
  opacity: 0.4;
}
.tt-btn-selection .tt-btn-selection--label {
  color: #151F28;
  font-size: 2.8rem;
  line-height: 2.8rem;
  margin: 0;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
}
.tt-btn-selection:hover {
  box-shadow: 0 3px 8px 0 rgba(76, 88, 98, 0.4);
}
.tt-btn-selection:disabled {
  background: #f4f7f9;
  box-shadow: none;
  cursor: not-allowed;
}
.tt-btn-selection:disabled i {
  color: #c8cdd0;
  opacity: 0.8;
}
.tt-btn-selection:disabled .tt-btn-selection--label {
  color: #a5abaf;
}
.tt-btn-selection.btn-selected {
  background: #dae0e5;
  box-shadow: inset 0 2px 8px 0 #b4b9c9;
  cursor: default;
}
.tt-btn-selection.btn-selected i {
  opacity: 0.9;
  color: #0A63F3;
}
.tt-btn.tt-btn-bottom {
  border-radius: 0 0 3px 3px !important;
}

.card {
  width: 360px;
  overflow: visible !important;
}

.form {
  margin: 30px;
}

.error {
  font-weight: 400;
  color: #BD0029 !important;
  display: block;
  margin-top: 6px;
}

.background {
  background: url(../../assets/img/bgSignIn.jpg) center/cover no-repeat;
  height: 100%;
  flex-direction: column;
}

.samlButtonWrapper {
  margin: 30px;
}

.orWrapper {
  padding-left: 8%;
  padding-right: 8%;
}

.orSpan {
  font-weight: bolder;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #d6d4d4;
  line-height: 0.1em;
  margin: 10px 0 20px;
}

.orSpan span {
  background: #fff;
  padding: 0 10px;
}

.logo {
  max-height: 80px;
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.samlButton {
  width: 100%;
  background: #E2F8FF !important;
  color: #0A63F3 !important;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15), 0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 3px 1px -2px rgba(0, 0, 0, 0.07) !important;
}

.btnSubmit:disabled {
  background-color: #e1e4e7 !important;
  color: #a5abaf !important;
  box-shadow: none !important;
  cursor: not-allowed !important;
}

.btnSubmit:disabled span {
  color: #a5abaf !important;
}

.btnResetTenant {
  background: transparent !important;
  text-transform: none !important;
  font-size: 14px !important;
}
.btnResetTenant:hover {
  box-shadow: inset 0 0 10px 30px rgba(244, 247, 249, 0.14) !important;
}

.resetTenantContainer {
  position: absolute;
  bottom: 21px;
}

.resetPassword h4 {
  text-align: center;
  color: #151F28;
  font-family: PublicSans Bold, Helvetica, Arial, sans-serif;
}

.resetPassword h5 {
  font-family: PublicSans Bold, Helvetica, Arial, sans-serif;
  color: #151F28;
  margin: 0 0 10px 0;
}

.resetPassword ul {
  list-style-type: none;
  color: #151F28;
  padding: 0;
}

.resetPassword li {
  font-size: 1.6rem;
  line-height: 2.8rem;
}

.resetPassword li span {
  vertical-align: top;
}

.resetPassword li i {
  display: inline-block;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  vertical-align: middle;
  margin-top: -3px;
}

.resetPassword li .circle {
  color: #c8cdd0;
  background-color: #c8cdd0;
}

.resetPasswordSuccess {
  color: #00AC4A !important;
}

.resetPasswordSuccess span {
  color: #151F28 !important;
}

.resetPasswordError {
  color: #E92851 !important;
}

.arrow {
  position: absolute;
  right: -10px;
  height: 0;
  width: 0;
  background-color: transparent;
  top: 9px;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-left: 14px solid #E92851;
}

.passwordTooltip {
  margin: 0 7px 0 0;
}

.circle {
  background-color: #151F28 !important;
  height: 7px !important;
  width: 7px !important;
  margin-left: 4px !important;
  margin-right: 12px !important;
}

