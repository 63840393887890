@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.ticketContentContainer {
  height: calc(100% - #{$tickets-header-height}) !important;
}

.routeInfoContainer {
  display: flex;
  width: 100%;
  height: 100% !important;
}

.routeInfoSidebar {
  flex-basis: 395px;
  flex-grow: 0;
  flex-shrink: 0;
  height: 100% !important;
  border-radius: 0 !important;
  z-index: 1;
  padding: 34px;
  overflow-y: auto;
}

.titleValue {
  padding-left: calc(1.6rem + 10px);
}

.markerIcon {
  color: $gray-700 !important;
}

.ticketRouteHeader {
  visibility: hidden;
  display: none;
  font-size: 0;
}

.mapContainer {
  height: 100%;
  width: 100%;
}

@media print {
  .ticketRouteHeader {
    visibility: visible;
    display: block;
    border-bottom: 2px solid $gray-900;
    margin: 26px 30px 20px 30px;
  }

  .routeInfoContainer {
    padding-left: 30px !important;
    padding-right: 30px !important;
    overflow: auto !important;
    height: auto !important;
  }

  .routeInfoSidebar {
    padding: 0 20px 0 0;
    flex-basis: 270px;
  }

  .routeInfoSidebar button {
    padding: 0 10px 0 10px;
    height: 27px;
  }

  .mapContainer {
    height: 400px !important;
  }
}