.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-center,
.flex-horizontal-center,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.autoCompleteWithChipContainer {
  padding-bottom: 10px;
}

.chipInput {
  margin-right: 50px;
}

.chip {
  color: #636b71 !important;
  background-color: #f4f7f9 !important;
  border: 1px solid #e1e4e7 !important;
  border-radius: 3px !important;
  height: 2.6rem !important;
  display: inline-flex !important;
  margin-bottom: 0.5rem;
  margin-right: 10px;
  margin-top: 4px;
  padding: 0 !important;
}

.chip:first-of-type {
  margin-left: 33px;
}

.chipRemove {
  width: 2.5rem !important;
  height: 2.6rem !important;
  background-color: transparent;
  border-radius: 0 3px 3px 0;
  border: none;
  outline: none;
  margin: 0 !important;
  transition: 0.3s !important;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.chipRemove i {
  color: #636b71;
  font-size: 1.2rem;
  line-height: 1.2rem;
}
.chipRemove:hover {
  background-color: #e1e4e7 !important;
}
.chipRemove:hover i {
  color: #151F28 !important;
}

.menuItemBodyContainer {
  display: flex;
  flex-direction: row;
}

.suggestedMenuItemIcon {
  color: #636b71;
  width: 20px !important;
  height: 13px !important;
  font-size: 1.7rem;
  margin-right: 5px;
}

.inputRoot {
  font-size: 16px !important;
}
.inputRoot:before {
  border-bottom: 1px solid #e1e4e7 !important;
}
.inputRoot:after {
  border-bottom: 2px solid #008EF3 !important;
}

.inputIndent input {
  padding-left: 33px !important;
}

.chipLabel {
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  font-size: 1.3rem;
  line-height: 2.6rem;
  padding: 0 6px 0 7px !important;
  margin: 0 !important;
  display: block !important;
  max-width: 195px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.popper {
  z-index: 1300;
  overflow: hidden !important;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15), 0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 3px 1px -2px rgba(0, 0, 0, 0.07) !important;
  margin-top: 5px !important;
  border-radius: 3px;
}

.popperPaper {
  max-height: 365px;
  overflow-y: auto;
  overflow-x: hidden;
}

.menuItem, .productLineMenuItem {
  transition: 0.3s !important !important;
  font-size: 16px !important;
  padding: 10px 14px !important;
  font-family: "PublicSans Regular", Helvetica, Arial, sans-serif !important;
  display: block !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
}
.menuItem:hover, .productLineMenuItem:hover {
  background: #f4f7f9 !important;
}
.menuItem:focus, .productLineMenuItem:focus {
  outline: none !important;
}

.productLineMenuItem {
  border-bottom: solid #e1e4e7 1px !important;
}

