.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-center,
.flex-horizontal-center,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.ticketBtn {
  border-radius: 3px 0 0 3px !important;
  height: 4.2rem;
  padding: 0.3rem 1.3rem 0 1.3rem !important;
}

.ticketBtnDisabled {
  background-color: #a5abaf !important;
  color: white !important;
  box-shadow: inset 0 0 0 1px #878e93 !important;
  cursor: not-allowed !important;
}

.ticketBtnIcon {
  font-size: 19px !important;
}

.ticketBtnIconDisabled {
  color: white !important;
}

.ticketListContainer {
  height: calc(100% - 55px) !important;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.ticketListContainer :global(.ReactTable) {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 30px);
  background-color: #f4f7f9 !important;
}
.ticketListContainer :global(.ReactTable *) {
  box-sizing: border-box;
}
.ticketListContainer :global(.ReactTable .rt-table) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
  flex: auto 0;
}
.ticketListContainer :global(.ReactTable .rt-thead) {
  flex: 0 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  user-select: none;
}
.ticketListContainer :global(.ReactTable .rt-thead.-headerGroups) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.ticketListContainer :global(.ReactTable .rt-thead.-header) {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15), 0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 3px 1px -2px rgba(0, 0, 0, 0.07);
}
.ticketListContainer :global(.ReactTable .rt-thead .rt-tr) {
  text-align: center;
}
.ticketListContainer :global(.ReactTable .rt-thead .rt-th) {
  border-right: 1px solid #e1e4e7;
  background-color: #f4f7f9 !important;
}
.ticketListContainer :global(.ReactTable .rt-thead .rt-th),
.ticketListContainer :global(.ReactTable .rt-thead .rt-td) {
  padding: 5px 5px;
  line-height: normal;
  transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
}
.ticketListContainer :global(.ReactTable .rt-thead .rt-th.-cursor-pointer),
.ticketListContainer :global(.ReactTable .rt-thead .rt-td.-cursor-pointer) {
  cursor: pointer;
}
.ticketListContainer :global(.ReactTable .rt-thead .rt-th:last-child),
.ticketListContainer :global(.ReactTable .rt-thead .rt-td:last-child) {
  border-right: 0;
}
.ticketListContainer :global(.ReactTable .rt-thead .rt-resizable-header) {
  padding: 10px 0 10px 15px;
  outline: 0;
  text-align: left;
}
.ticketListContainer :global(.ReactTable .rt-thead .rt-resizable-header:last-child) {
  overflow: hidden;
}
.ticketListContainer :global(.ReactTable .rt-thead .rt-resizable-header-content) {
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
}
.ticketListContainer :global(.ReactTable .rt-thead .rt-header-pivot) {
  border-right-color: #f4f7f9;
}
.ticketListContainer :global(.ReactTable .rt-thead .rt-header-pivot:after),
.ticketListContainer :global(.ReactTable .rt-thead .rt-header-pivot:before) {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ticketListContainer :global(.ReactTable .rt-thead .rt-header-pivot:after) {
  border-color: rgba(255, 255, 255, 0);
  border-left-color: #f4f7f9;
  border-width: 8px;
  margin-top: -8px;
}
.ticketListContainer :global(.ReactTable .rt-thead .rt-header-pivot:before) {
  border-color: rgba(102, 102, 102, 0);
  border-left-color: #f4f7f9;
  border-width: 10px;
  margin-top: -10px;
}
.ticketListContainer :global(.ReactTable .rt-tbody) {
  flex: 99999 1 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  overflow: visible;
}
.ticketListContainer :global(.ReactTable .rt-tbody .rt-tr-group) {
  background: white;
  cursor: pointer;
}
.ticketListContainer :global(.ReactTable .rt-tbody .rt-tr:hover .rt-td) {
  background-color: #e1e4e7;
}
.ticketListContainer :global(.ReactTable .rt-tbody .rt-td) {
  transition-duration: 0.3s;
  padding: 3px 0 5px 15px;
  background: white;
  cursor: pointer;
  line-height: 2.9em !important;
}
.ticketListContainer :global(.ReactTable .rt-tbody .rt-tr-group.selected) :global(.rt-td) {
  background-color: #E2F8FF;
}
.ticketListContainer :global(.ReactTable .rt-tbody .rt-td) {
  padding: 3px 0 5px 15px;
  cursor: pointer;
}
.ticketListContainer :global(.ReactTable .rt-tbody .rt-expandable) {
  cursor: pointer;
  text-overflow: clip;
}
.ticketListContainer :global(.ReactTable .rt-tr-group) {
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.ticketListContainer :global(.ReactTable .rt-tr) {
  flex: 1 0 auto;
  display: inline-flex;
}
.ticketListContainer :global(.ReactTable .rt-th),
.ticketListContainer :global(.ReactTable .rt-td) {
  flex: 1 0 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 7px 5px;
  transition: 0.3s ease;
  transition-property: width, min-width, padding, opacity, background-color;
  min-width: 11px !important;
}
.ticketListContainer :global(.ReactTable .rt-th.-hidden),
.ticketListContainer :global(.ReactTable .rt-td.-hidden) {
  width: 0 !important;
  min-width: 0 !important;
  padding: 0 !important;
  border: 0 !important;
  opacity: 0 !important;
}
.ticketListContainer :global(.ReactTable .rt-expander) {
  display: inline-block;
  position: relative;
  color: transparent;
  margin: 0 10px;
}
.ticketListContainer :global(.ReactTable .rt-expander:after) {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  border-left: 5.04px solid transparent;
  border-right: 5.04px solid transparent;
  border-top: 7px solid rgba(0, 0, 0, 0.8);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer;
}
.ticketListContainer :global(.ReactTable .rt-expander.-open:after) {
  transform: translate(-50%, -50%) rotate(0);
}
.ticketListContainer :global(.ReactTable .rt-resizer) {
  display: inline-block;
  position: absolute;
  width: 36px;
  top: 0;
  bottom: 0;
  right: -18px;
  cursor: col-resize;
  z-index: 10;
  overflow: hidden;
}
.ticketListContainer :global(.ReactTable .rt-tfoot) {
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
}
.ticketListContainer :global(.ReactTable .rt-tfoot .rt-td:last-child) {
  border-right: 0;
}
.ticketListContainer :global(.ReactTable.-striped .rt-tr.-odd) {
  background: #f4f7f9;
}
.ticketListContainer :global(.ReactTable .rt-noData) {
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: 1;
  pointer-events: none;
  padding: 20px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
  font-weight: bold;
}
.ticketListContainer :global(.ReactTable .-loading) {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}
.ticketListContainer :global(.ReactTable .-loading > div) {
  position: absolute;
  display: block;
  text-align: center;
  width: 100%;
  top: 50%;
  left: 0;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
  transform: translateY(-52%);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.ticketListContainer :global(.ReactTable .-loading.-active) {
  opacity: 1;
  z-index: 2;
  pointer-events: all;
}
.ticketListContainer :global(.ReactTable .-loading.-active > div) {
  transform: translateY(50%);
}
.ticketListContainer :global(.ReactTable .rt-resizing .rt-th),
.ticketListContainer :global(.ReactTable .rt-resizing .rt-td) {
  transition: none !important;
  cursor: col-resize;
  user-select: none;
}
.ticketListContainer :global(.ReactTable .rt-thead .rt-th.-sort-asc),
.ticketListContainer :global(.ReactTable .rt-thead .rt-td.-sort-asc),
.ticketListContainer :global(.ReactTable .rt-thead .rt-th.-sort-desc),
.ticketListContainer :global(.ReactTable .rt-thead .rt-td.-sort-desc) {
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.6);
}

.leftSearchContainer {
  display: flex;
  flex-direction: row;
}

.searchDiv {
  position: relative;
  height: 5.4rem !important;
  z-index: 5;
}
.searchDiv > div > div:first-child {
  height: 5.4rem !important;
  border-radius: 0px !important;
  padding-right: 37px;
}

.searchDivSpace {
  height: 105px !important;
}
.searchDivSpace > div > div:first-child {
  height: 105px !important;
  padding-left: 35px;
}

.dateRangePicker {
  margin-left: 30px;
  margin-right: 12px;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  font-size: 1.3rem;
  line-height: 1.3;
  color: #151F28;
  text-align: right;
}

.dateRangePickerBlock {
  margin-top: 8px;
}

.refreshBlock {
  margin-top: 26px;
}

.paper {
  height: calc(100% - 55px);
  border-radius: 3px 3px 0 0 !important;
  box-shadow: none !important;
  background-color: #f4f7f9 !important;
}

.backendTicketsTable {
  height: 100%;
}
.backendTicketsTable > div {
  height: 100%;
  min-height: 250px;
}
.backendTicketsTable > div > div {
  /*geotraxTable*/
  max-width: 100% !important;
  width: 100% !important;
  height: 100% !important;
  padding: 0 !important;
}
.backendTicketsTable > div > div > div > div:nth-child(2) > div {
  /*pagination*/
  margin-top: 0;
  margin-bottom: 0;
}

.orderLink {
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif !important;
}

.refreshCurrentTicketList {
  display: flex !important;
  flex-direction: row-reverse;
  justify-content: flex-start;
}

.refreshCurrentTicketList button {
  margin: 0 -30px 0 7px;
  cursor: pointer;
  background: transparent;
  border: transparent;
  font-size: 1.3rem;
  transition: 0.3s !important;
  border-radius: 3px;
  padding: 1.1rem 1.3rem;
  height: 4rem;
  width: 12.3rem;
}
.refreshCurrentTicketList button .loadingCircleContainer {
  margin: 0 -1px 0 0;
}
.refreshCurrentTicketList button i {
  font-size: 1.6rem;
}
.refreshCurrentTicketList button:hover {
  background: #f4f7f9;
}
.refreshCurrentTicketList button:active {
  background: #e1e4e7;
}
.refreshCurrentTicketList button:focus {
  outline: none;
}
.refreshCurrentTicketList button.fetchingList {
  background: transparent;
  cursor: default;
}

.refreshCurrentTicketList button i {
  display: inline-block;
  vertical-align: middle;
  color: #0A63F3;
  transform: translateY(-3px);
}

.refreshCurrentTicketList button span {
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
  transition: 0.3s !important;
  display: inline-block;
  transform: translateY(-3px);
}

.loadingText {
  color: #a5abaf;
  font-family: "PublicSans Bold" !important;
}

.boldText {
  font-family: "PublicSans Bold" !important;
}

.dateRangePicker {
  text-align: left;
}

.voidedCellStyle {
  position: relative;
  color: #636b71 !important;
}
.voidedCellStyle > img {
  position: absolute;
  margin-left: 5px;
  height: 20px;
  top: 10px;
  margin-left: 6px;
}

