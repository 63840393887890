.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-center,
.flex-horizontal-center,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.permissionsAdminSection {
  grid-column: 1/span 4;
  grid-row: 4;
  align-self: center;
  overflow: hidden;
}

.permissionsDivider {
  margin: 0 0 30px 0;
}

.permissionsHeader {
  padding: 0;
  font-size: 16px;
  color: #636b71;
}

.permissionsLabel {
  padding: 0 0 0 1px;
  margin-bottom: 10px;
  font-size: 13px;
  color: #151F28;
}

.permissionsContainer {
  padding: 0;
  height: 325px;
}

.dropDownSearchContainer {
  width: 70%;
  display: flex;
}

.marginTop {
  margin-top: 25px;
  margin-bottom: 5px;
}

.rightAlign {
  text-align: right !important;
}

.removeButton {
  padding: 0.2rem 1.3rem 0.3rem 1.3rem !important;
}
.removeButton i {
  color: #878e93 !important;
  font-size: 1.6rem;
}
.removeButton:hover > i {
  color: #BD0029 !important;
}

.searchContainer {
  vertical-align: middle;
  flex: 0 1 100%;
  background-color: #f4f7f9;
  margin-bottom: 23px;
}
.searchContainer :global(.MuiListItem-root) {
  transition: 0.3s !important !important;
  background: transparent !important;
  font-size: 1.6rem !important;
  height: 40px !important;
  display: flex !important;
  justify-content: flex-start !important;
  width: 100% !important;
  flex-grow: 1 !important;
  padding-left: 11px !important;
}
.searchContainer :global(.MuiListItem-root):hover {
  background: #f4f7f9 !important;
}

.searchContainer input {
  background-color: #f4f7f9 !important;
}

.tableContainer {
  height: auto;
  max-height: 263px;
  table-layout: fixed;
  width: 100%;
  overflow: auto;
  padding: 0;
  border: 1px solid #e1e4e7 !important;
  border-bottom: 1px solid transparent !important;
  box-shadow: inset 0 -1px 0 0 #e1e4e7 !important;
  border-collapse: collapse;
}
.tableContainer::-webkit-scrollbar {
  border-bottom: 1px solid #e1e4e7;
  border-left: 1px solid #e1e4e7;
  background-color: rgb(250, 250, 250) !important;
}
.tableContainer::-webkit-scrollbar-track {
  border-radius: 1rem;
}
.tableContainer::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background-color: rgb(193, 193, 193) !important;
  width: 1rem !important;
  border: 0.3rem solid transparent;
  border-left: 0.4rem solid transparent;
  background-clip: content-box;
  transition: 0.3s !important !important;
}
.tableContainer::-webkit-scrollbar-thumb:hover {
  background-color: rgb(125, 125, 125) !important;
}

.tableContainer,
.tableContainer th,
.tableContainer td {
  border: none;
  padding: 0;
}

.tableContainer th {
  padding: 10px 0 10px 11px !important;
  line-height: 1 !important;
  background: white;
  position: sticky !important;
  top: 0 !important;
  box-shadow: 4px 1px 5px 0px rgba(0, 0, 0, 0.15), 3px 2px 2px 0px rgba(0, 0, 0, 0.09), 2px 3px 1px -2px rgba(0, 0, 0, 0.07) !important;
}

.tableContainer td {
  padding: 0 0 0 11px !important;
  height: 4rem;
  font-size: 1.6rem;
  font-family: "PublicSans Regular", Helvetica, Arial, sans-serif;
  line-height: 1 !important;
  color: #151F28;
  border-top: 1px solid #e1e4e7;
}

.tableContainer th span {
  font-size: 1.3rem;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  line-height: 1 !important;
  color: #151F28;
  margin: 0 0 0 1px !important;
  padding: 0 !important;
}

.emptyTable {
  background-color: #f4f7f9;
  text-align: center;
  padding: 53px 0;
}

.emptyTable i {
  font-size: 80px;
  color: #c8cdd0;
}

.emptyTable p {
  padding-top: 15px;
  white-space: pre-wrap;
  font-size: 16px;
  color: #151F28;
}

.emptyTable .crossLine {
  background-color: #c8cdd0;
  outline: 4px solid #f4f7f9 !important;
  display: block;
  width: 110px;
  height: 8px;
  margin: -50px auto 45px auto;
  transform: rotate(135deg);
  outline: none;
}

.MuiMenuItem-root {
  width: 100% !important;
}

.memberRow {
  height: 40px;
}
.memberRow:hover {
  background: none !important;
}

.usernameLink {
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  color: #0A63F3;
}

.removeAllBtn {
  margin-right: 14px;
}
.removeAllBtn span {
  transform: translateY(-1px) !important;
  display: inline-block;
}
.removeAllBtn:hover > i, .removeAllBtn:hover > span {
  color: #BD0029 !important;
}

