.trafficButtonContainer {
  margin-right: 6px !important;
  margin-bottom: 6px !important;
}

.trafficButtonPopover {
  padding: 0 !important;
}

.trafficButtonPopover:before {
  margin-right: 0 !important;
  height: 30px;
  padding: 0 1rem !important;
  display: flex;
  align-items: center;
}

.trafficButtonPopover:after {
  margin-right: 5px !important;
}

.trafficButton {
  width: 24px;
  height: 30px;
  background-color: rgb(255, 255, 255);
  border: none;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.trafficButton i {
  color: #666666;
  font-size: 20px;
}
.trafficButton:hover > i {
  color: #2A353E;
}

