@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.notesSection {
  position: relative;
  width: 100%;
  padding: 20px 10px 0 10px;

  & label {
    margin: 0 0 0.8rem 0;
    line-height: 1;
    font-size: 1.3rem;
    font-family: $font-family-bold;
    color: $gray-900;
    white-space: normal !important;
    display: block;
  }

  & textarea {
    margin-bottom: 20px !important;
    width: calc(50% - 10px);
    min-width: 377px;
    max-width: 100%;
    height: 150px;
    min-height: 150px;
    max-height: 500px;
    color: $gray-900;
    background-color: $gray-100;
    border: 1px solid $gray-200;
    border-radius: 4px !important;
    padding: 10px;
  }
}