.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-center,
.flex-horizontal-center,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.margin-0 {
  margin: 0 !important;
}

.margin-top-0 {
  margin-top: 0;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-right-20 {
  margin-right: 20px;
}

.margin-right-30 {
  margin-right: 30px;
}

.margin-right-40 {
  margin-right: 40px;
}

.margin-left-5 {
  margin-left: 5px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-left-30 {
  margin-left: 30px;
}

.margin-left-40 {
  margin-left: 40px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

.wrap-words {
  word-break: initial;
  line-height: 1.1;
}

.nowrap-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

.pre-wrap {
  white-space: pre-wrap;
}

.height-100 {
  height: 100%;
}

.icon-md {
  font-size: 1.8rem;
}

.icon-lg {
  font-size: 2rem;
}

.icon-xl {
  font-size: 3rem;
}

.icon-people-margin {
  position: relative;
  top: 2px;
  margin-right: 3px;
}

.underline {
  text-decoration: underline;
}

.clickable {
  cursor: pointer;
}

.orderStatus, .orderBadge {
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  font-size: 13px;
  line-height: 16px;
  color: #151F28;
  display: inline-block;
  transform: translate(-1px, -4px);
  margin: 0;
  padding: 0;
  white-space: nowrap;
}
.orderStatus:before, .orderBadge:before {
  content: "\a";
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #a5abaf;
  display: inline-block;
  margin-left: 2px;
  margin-right: 4px;
  position: relative;
  top: 1px;
}

.willCall:before {
  background-color: #008EF3 !important;
}

.ship:before {
  background-color: #00AC4A !important;
}

.hold:before {
  background-color: #F1BE00 !important;
}

.fob:before {
  background-color: #984BD2 !important;
}

.canceled:before {
  background-color: #E92851 !important;
}

.goBack,
.keepAddress {
  padding: 0 !important;
  min-height: 0 !important;
  display: inline-block;
}
.goBack span,
.keepAddress span {
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif !important;
  font-size: 1.3rem;
  line-height: 1;
}
.goBack i,
.keepAddress i {
  font-weight: bolder;
  padding: 0 0.5rem 0 0;
}
.goBack:hover span,
.keepAddress:hover span {
  text-decoration: underline !important;
  color: #0A63F3 !important;
}
.goBack:hover i,
.keepAddress:hover i {
  color: #0A63F3 !important;
}

.goBack {
  margin: -3px 0 0 2px;
}

.keepAddress {
  margin: -3px 0 0 4px;
}

.wrapper {
  height: calc(100vh - 6.2rem);
  overflow-y: auto;
}

.layout {
  margin: 31px 31px 0 31px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-areas: "details map" "table table";
  grid-gap: 24px;
  min-height: 500px;
}

.details {
  padding: 34px 38px 50px 38px;
  position: relative;
  min-height: 565px;
}
.details h3 {
  margin: 0;
  font-size: 3rem !important;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif !important;
  line-height: 1 !important;
  word-wrap: break-word !important;
  white-space: normal !important;
}
.details label {
  word-wrap: break-word !important;
}

.details,
.mapContainer {
  position: relative;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15), 0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 3px 1px -2px rgba(0, 0, 0, 0.07) !important;
  overflow: hidden;
}

.plant {
  margin-top: 34px;
}

.map {
  height: 100%;
  z-index: 1;
}

.fullMap {
  height: 100%;
}

.mapEditingWithNotification {
  height: calc(100% - 55px);
}

.milesInput {
  max-width: 50px;
  margin: -8px 10px 0 10px;
}

.mapEditControls {
  line-height: 16px;
  font-size: 16px;
  height: 59px;
  box-shadow: 0 1px 4px 0 rgba(38, 41, 48, 0.35);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  padding: 16px 10px 16px 24px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
}
.mapEditControls .geofenceRadiusLabel {
  font-size: 1.3rem;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
}
.mapEditControls .miles {
  margin-bottom: 1px;
}

.mapEditControlsButtons {
  display: inline-block;
  margin-left: auto;
}
.mapEditControlsButtons button + button {
  margin-left: 10px;
}

.orderItem {
  padding-bottom: 26px;
}
.orderItem label {
  margin-bottom: 3px;
}
.orderItem p {
  margin-bottom: -1px;
  line-height: 1.3;
  white-space: normal;
  word-wrap: break-word;
}

.noPadding {
  padding-bottom: 0;
}

.pageContainer {
  height: calc(100vh - 6.2rem);
  overflow-y: auto;
}

.headerAndContainerStyle {
  height: 100%;
}

.mapClickLabelContainer {
  text-align: center;
}

.mapClickLabel {
  height: 1.3rem;
  font-family: "PublicSans Regular", Helvetica, Arial, sans-serif;
  font-size: 1.3rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.5em;
  letter-spacing: normal;
  text-align: center;
  color: #151F28;
}

.sourceLabel {
  color: #636b71;
  margin-top: 4rem !important;
  position: absolute;
  bottom: 29px;
  font-size: 1.3rem;
}
.sourceLabel label {
  padding-right: 5px;
  display: inline-block;
  color: #636b71;
}

.table {
  grid-area: table;
}

.closerPlantNotificationContainer {
  background-color: #FDF3E2;
  font-size: 1.3rem;
  padding: 1.8rem;
  border-left: 4px solid #F66A00;
  margin: 3px 0 28px 0;
  border-radius: 3px;
}

.closerPlantLabelContainer {
  padding-bottom: 11px;
}
.closerPlantLabelContainer span {
  font-size: 1.6rem;
  line-height: 1.3;
  white-space: normal;
  word-wrap: break-word;
}

.clearViewMsg {
  font-family: "PublicSans Italic", Helvetica, Arial, sans-serif;
  font-size: 1.3rem;
  line-height: 1.2 !important;
  margin-left: 1px;
  margin-right: 2px;
  display: inline-block;
}

.keepAsBtn {
  margin: 1.3rem 0 0.1rem 0;
  padding: 0;
  display: inline-block;
  line-height: 1.2;
  background: none;
  border: none;
  text-align: left;
}
.keepAsBtn span {
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif !important;
  font-size: 1.3rem;
  line-height: 1 !important;
  text-align: left !important;
  font-size: 1.3rem;
  color: #0A63F3;
}
.keepAsBtn:hover span {
  text-decoration: underline;
  cursor: pointer;
}

.inaccurateNotificationContainer {
  text-align: center;
  height: 55px;
  background-color: #FDF3E2;
  font-size: 1.3rem;
  padding: 7px !important;
}

.inaccurateAddressLabel {
  font-size: 1.3rem;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  line-height: 1;
  margin: 0 0 3px 0 !important;
  color: #151F28;
}

.warningIcon {
  margin-right: 4px !important;
  color: #F66A00;
  font-size: 1.6rem;
  line-height: 1;
  display: inline-block;
  transform: translateY(2px) !important;
}

.editMapButton {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
}
.editMapButton:after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0px 1px 4px 0 rgba(0, 0, 0, 0.3);
}

.orderBadge {
  margin-left: 0;
  margin-top: 10px;
  margin-bottom: 26px;
  background-color: #f4f7f9;
  border: solid 1px #e1e4e7;
  border-radius: 3px;
  height: 23px;
  display: inline-block;
  padding: 2px 5px 2px 4px;
}
.orderBadge:before {
  display: none;
}

.statusLabel {
  position: relative;
  display: inline-block;
  font-size: 1.3rem;
  line-height: 1;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
}
.statusLabel:before {
  content: "\a";
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #a5abaf;
  display: inline-block;
  margin-left: 2px;
  margin-right: 4px;
  position: relative;
  top: 1px;
}

.detailsSmall.layout {
  display: flex;
  flex-direction: column;
  margin: 0;
}
.detailsSmall.layout .details {
  margin: 31px 31px 0 31px;
  min-height: 0;
}
.detailsSmall.layout .mapContainer {
  margin: 0 31px 25px 31px;
  height: 404px;
}
.detailsSmall.layout .closerPlantNotificationContainer {
  width: 100%;
}
.detailsSmall.layout .firstDetailGroup,
.detailsSmall.layout .secondDetailGroup {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.detailsSmall.layout .orderItem {
  width: 100% !important;
}
.detailsSmall.layout .orderItem:last-of-type {
  padding-bottom: 0 !important;
  margin-bottom: 2rem;
}

