@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';

.pane {
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.verticalSplitPaneContainer {
  height: 100%;
}

.slidingPaneBoundary {
  background: none;
  width: 100%;
  height: 7px;
  max-height: 7px;
  background-color: $gray-300;
  cursor: ns-resize;
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover,
  &.isResizing {
    background-color: $gray-600;
  }
}