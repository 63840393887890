@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.headerAndContainer {
  height: 100%;
}

.tableContainer {
  height: 100%;
  position: relative;
}

.exportButtonContainer {
  position: absolute;
  right: 0;
  top: -52px;
}

.ordersHeaderAndContainer {
  flex-direction: column !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
}

.geotraxTableNoData {
  width: 100%;
  height: calc(100% - 10.5rem);
  background: $gray-100;
  position: relative;
  padding: 20px 30px;

  :global(.ReactTable) {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    border: none;
  }
}

.geotraxTable960 {
  max-width: 960px;
  width: 100%;
  height: calc(100% - 10.5rem);
  background: $gray-100;
  position: relative;
  padding: 20px 0px 10px 0px;
  margin-left: auto;
  margin-right: auto;

  // React table custom styles
  :global(.ReactTable) {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  :global(.ReactTable *) {
    box-sizing: border-box;
  }

  :global(.ReactTable .rt-table) {
    flex: auto 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    border-collapse: collapse;
    overflow: auto;
    position: relative;
  }

  :global(.ReactTable .rt-thead) {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    user-select: none;
  }

  :global(.ReactTable .rt-tbody) {
    overflow: visible !important;
  }

  :global(.ReactTable .rt-table .rt-tbody) {
    height: fit-content;
    max-height: fit-content;
    min-height: fit-content;
  }

  :global(.ReactTable .rt-thead.-headerGroups) {
    background: rgba(0, 0, 0, 0.03);
  }

  :global(.ReactTable .rt-thead.-filters) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }

  :global(.ReactTable .rt-thead.-filters input),
  :global(.ReactTable .rt-thead.-filters select) {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    padding: 5px 7px;
    font-size: inherit;
    border-radius: 3px;
    font-weight: normal;
    outline: none;
  }

  :global(.ReactTable .rt-thead.-filters .rt-th) {
    border-right: 1px solid rgba(0, 0, 0, 0.02);
  }

  :global(.ReactTable .rt-thead.-header) {
    position: sticky;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15),
      0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 3px 1px -2px rgba(0, 0, 0, 0.07);
  }

  :global(.ReactTable .rt-thead .rt-tr) {
    text-align: center;
  }

  :global(.ReactTable .rt-thead .rt-th),
  :global(.ReactTable .rt-thead .rt-td) {
    background: $gray-100;
    padding: 5px 5px;
    line-height: normal;
    transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  :global(.ReactTable .rt-thead .rt-th.-cursor-pointer),
  :global(.ReactTable .rt-thead .rt-td.-cursor-pointer) {
    cursor: pointer;
  }

  :global(.ReactTable .rt-thead .rt-resizable-header) {
    padding: 10px 0 10px 15px;
    overflow: visible;
    outline: 0;
    text-align: left;
    box-shadow: none;
  }

  :global(.-sort-desc.-cursor-pointer .rt-resizable-header-content:after) {
    width: 0;
    height: 0;
    font-family: 'icomoon';
    content: '\e939'; // icomoon - icon-expand-more
    color: $orange;
    position: absolute;
    top: 15px;
    margin-left: 10px;
    font-size: 8px;
  }

  :global(.-sort-asc.-cursor-pointer .rt-resizable-header-content:after) {
    width: 0;
    height: 0;
    font-family: 'icomoon';
    content: '\e938'; // icomoon - icon-expand-less
    color: $orange;
    position: absolute;
    top: 15px;
    margin-left: 10px;
    font-size: 8px;
  }

  :global(.-cursor-pointer .rt-resizable-header-content:after) {
    width: 0;
    height: 0;
    font-family: 'icomoon';
    content: '\e939'; // icomoon - icon-expand-more
    color: $gray-400;
    position: absolute;
    top: 15px;
    margin-left: 10px;
    font-size: 8px;
  }

  :global(.rt-resizable-header-content) {
    font-family: $font-family-bold;
  }

  :global(.header) {
    font-family: $font-family-bold;
  }

  :global(.ReactTable .rt-thead .rt-resizable-header:last-child) {
    overflow: hidden;
  }

  :global(.ReactTable .rt-thead .rt-resizable-header-content) {
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 900;
    font-size: 12px;
    display: inline;
  }

  :global(.ReactTable .rt-thead .rt-header-pivot) {
    border-right-color: $gray-100;
  }

  :global(.ReactTable .rt-thead .rt-header-pivot:after),
  :global(.ReactTable .rt-thead .rt-header-pivot:before) {
    left: 100%;
    top: 50%;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  :global(.ReactTable .rt-thead .rt-header-pivot:after) {
    margin-top: -8px;
  }

  :global(.ReactTable .rt-thead .rt-header-pivot:before) {
    margin-top: -10px;
  }

  :global(.ReactTable .rt-tbody) {
    flex: 99999 1 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: auto;
    max-height: 800px;
    min-height: 50px;
  }

  :global(.ReactTable .rt-tbody .rt-tr-group) {
    border-bottom: solid 1px rgba(0, 0, 0, 0.05);
    flex: 0 0 auto;
  }

  :global(.ReactTable .rt-tbody .rt-tr-group:hover) {
    cursor: pointer;
  }

  :global(.ReactTable .rt-tbody .rt-tr-group:hover .rt-td) {
    background: $gray-100;
  }

  :global(.ReactTable .rt-tbody .rt-td) {
    text-align: left;
    padding-left: 15px;
    background: white;
    font-size: 15px;
    line-height: 2;
    transition: 0.3s;
  }

  :global(.ReactTable .rt-tbody .rt-tr-group:has(input[type="checkbox"]:checked) .rt-td) {
    background: $gray-100;
  }

  :global(.new-highlight) {
    background: $xlightgreen !important;
  }

  :global(.ReactTable .rt-tbody .rt-td .pin) {
    font-family: $font-family-bold;
  }

  :global(.ReactTable .rt-tbody .rt-expandable) {
    cursor: pointer;
    text-overflow: clip;
  }

  :global(.ReactTable .rt-tr-group) {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  :global(.ReactTable .rt-tr) {
    flex: 1 0 auto;
    display: inline-flex;
  }

  :global(.ReactTable .rt-th),
  :global(.ReactTable .rt-td) {
    flex: 1 0 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 7px 5px;
    overflow: hidden;
    transition: 0.3s ease;
    transition-property: width, min-width, padding, opacity;
  }

  :global(.ReactTable .rt-th.-hidden),
  :global(.ReactTable .rt-td.-hidden) {
    width: 0 !important;
    min-width: 0 !important;
    padding: 0 !important;
    border: 0 !important;
    opacity: 0 !important;
  }

  :global(.ReactTable .rt-expander) {
    display: inline-block;
    position: relative;
    margin: 0;
    color: transparent;
    margin: 0 10px;
  }

  :global(.ReactTable .rt-expander:after) {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-90deg);
    border-left: 5.04px solid transparent;
    border-right: 5.04px solid transparent;
    border-top: 7px solid rgba(0, 0, 0, 0.8);
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    cursor: pointer;
  }

  :global(.ReactTable .rt-expander.-open:after) {
    transform: translate(-50%, -50%) rotate(0);
  }

  :global(.ReactTable .rt-resizer) {
    display: inline-block;
    position: absolute;
    width: 36px;
    top: 0;
    bottom: 0;
    right: -5px;
    cursor: col-resize;
    z-index: 10;
  }

  :global(.ReactTable .rt-tfoot) {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
  }

  :global(.ReactTable.-striped .rt-tr.-odd) {
    background: rgba(0, 0, 0, 0.03);
  }

  :global(.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover) {
    background: rgba(0, 0, 0, 0.05);
  }

  :global(.ReactTable .-pagination input),
  :global(.ReactTable .-pagination select) {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    padding: 5px 7px;
    font-size: inherit;
    border-radius: 3px;
    font-weight: normal;
    outline: none;
  }

  :global(.ReactTable .-pagination .-btn) {
    appearance: none;
    display: block;
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 3px;
    padding: 6px;
    font-size: 1em;
    color: rgba(0, 0, 0, 0.6);
    background: rgba(0, 0, 0, 0.1);
    transition: all 0.1s ease;
    cursor: pointer;
    outline: none;
  }

  :global(.ReactTable .-pagination .-btn[disabled]) {
    opacity: 0.5;
    cursor: default;
  }

  :global(.ReactTable .-pagination .-btn:not([disabled]):hover) {
    background: rgba(0, 0, 0, 0.3);
    color: #fff;
  }

  :global(.ReactTable .-pagination .-previous),
  :global(.ReactTable .-pagination .-next) {
    flex: 1;
    text-align: center;
  }

  :global(.ReactTable .-pagination .-center) {
    flex: 1.5;
    text-align: center;
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
  }

  :global(.ReactTable .-pagination .-pageInfo) {
    display: inline-block;
    margin: 3px 10px;
    white-space: nowrap;
  }

  :global(.ReactTable .-pagination .-pageJump) {
    display: inline-block;
  }

  :global(.ReactTable .-pagination .-pageJump input) {
    width: 70px;
    text-align: center;
  }

  :global(.ReactTable .-pagination .-pageSizeOptions) {
    margin: 3px 10px;
  }

  :global(.ReactTable .rt-noData) {
    display: block;
    position: absolute;
    left: 50%;
    top: 36%;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.8);
    transition: all 0.3s ease;
    z-index: 1;
    pointer-events: none;
    padding: 16px;
    color: rgba(0, 0, 0, 0.5);
  }

  :global(.ReactTable .-loading) {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 85px;
    background: rgba(255, 255, 255, 0.8);
    transition: all 0.3s ease;
    z-index: -1;
    opacity: 0;
    pointer-events: none;
  }

  :global(.ReactTable .-loading > div) {
    position: absolute;
    display: block;
    text-align: center;
    width: 100%;
    top: 50%;
    left: 0;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.6);
    transform: translateY(-52%);
    transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  :global(.ReactTable .-loading.-active) {
    opacity: 1;
    z-index: 0;
    pointer-events: all;
  }

  :global(.ReactTable .-loading.-active > div) {
    transform: translateY(50%);
  }

  :global(.ReactTable .rt-resizing .rt-th),
  :global(.ReactTable .rt-resizing .rt-td) {
    transition: none !important;
    cursor: col-resize;
    user-select: none;
  }
}

.geotraxTable {
  width: 100%;
  height: calc(100% - 10.5rem);
  background: $gray-100;
  position: relative;
  padding: 20px 30px 10px 30px;

  // React table custom styles
  :global(.ReactTable) {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  :global(.ReactTable *) {
    box-sizing: border-box;
  }

  :global(.ReactTable .rt-table) {
    flex: auto 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    border-collapse: collapse;
    overflow: auto;
    position: relative;
  }

  :global(.ReactTable .rt-thead) {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    user-select: none;
  }

  :global(.ReactTable .rt-tbody) {
    overflow: visible !important;
  }

  :global(.ReactTable .rt-table .rt-tbody) {
    height: fit-content;
    max-height: fit-content;
    min-height: fit-content;
  }

  :global(.ReactTable .rt-thead.-headerGroups) {
    background: rgba(0, 0, 0, 0.03);
  }

  :global(.ReactTable .rt-thead.-filters) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }

  :global(.ReactTable .rt-thead.-filters input),
  :global(.ReactTable .rt-thead.-filters select) {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    padding: 5px 7px;
    font-size: inherit;
    border-radius: 3px;
    font-weight: normal;
    outline: none;
  }

  :global(.ReactTable .rt-thead.-filters .rt-th) {
    border-right: 1px solid rgba(0, 0, 0, 0.02);
  }

  :global(.ReactTable .rt-thead.-header) {
    position: sticky;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15),
      0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 3px 1px -2px rgba(0, 0, 0, 0.07);
  }

  :global(.ReactTable .rt-thead .rt-tr) {
    text-align: center;
  }

  :global(.ReactTable .rt-thead .rt-th),
  :global(.ReactTable .rt-thead .rt-td) {
    background: $gray-100;
    padding: 5px 5px;
    line-height: normal;
    transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  :global(.ReactTable .rt-thead .rt-th.-cursor-pointer),
  :global(.ReactTable .rt-thead .rt-td.-cursor-pointer) {
    cursor: pointer;
  }

  :global(.ReactTable .rt-thead .rt-resizable-header) {
    padding: 10px 0 10px 15px;
    overflow: visible;
    outline: 0;
    text-align: left;
    box-shadow: none;
  }

  :global(.-sort-desc.-cursor-pointer .rt-resizable-header-content:after) {
    width: 0;
    height: 0;
    font-family: 'icomoon';
    content: '\e939'; // icomoon - icon-expand-more
    color: $orange;
    position: absolute;
    top: 15px;
    margin-left: 10px;
    font-size: 8px;
  }

  :global(.-sort-asc.-cursor-pointer .rt-resizable-header-content:after) {
    width: 0;
    height: 0;
    font-family: 'icomoon';
    content: '\e938'; // icomoon - icon-expand-less
    color: $orange;
    position: absolute;
    top: 15px;
    margin-left: 10px;
    font-size: 8px;
  }

  :global(.-cursor-pointer .rt-resizable-header-content:after) {
    width: 0;
    height: 0;
    font-family: 'icomoon';
    content: '\e939'; // icomoon - icon-expand-more
    color: $gray-400;
    position: absolute;
    top: 15px;
    margin-left: 10px;
    font-size: 8px;
  }

  :global(.rt-resizable-header-content) {
    font-family: $font-family-bold;
  }

  :global(.header) {
    font-family: $font-family-bold;
  }

  :global(.ReactTable .rt-thead .rt-resizable-header:last-child) {
    overflow: hidden;
  }

  :global(.ReactTable .rt-thead .rt-resizable-header-content) {
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 900;
    font-size: 12px;
    display: inline;
  }

  :global(.ReactTable .rt-thead .rt-header-pivot) {
    border-right-color: $gray-100;
  }

  :global(.ReactTable .rt-thead .rt-header-pivot:after),
  :global(.ReactTable .rt-thead .rt-header-pivot:before) {
    left: 100%;
    top: 50%;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  :global(.ReactTable .rt-thead .rt-header-pivot:after) {
    margin-top: -8px;
  }

  :global(.ReactTable .rt-thead .rt-header-pivot:before) {
    margin-top: -10px;
  }

  :global(.ReactTable .rt-tbody) {
    flex: 99999 1 auto;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: auto;
    max-height: 800px;
    min-height: 50px;
  }

  :global(.ReactTable .rt-tbody .rt-tr-group) {
    border-bottom: solid 1px rgba(0, 0, 0, 0.05);
    flex: 0 0 auto;
  }

  :global(.ReactTable .rt-tbody .rt-tr-group:hover) {
    cursor: pointer;
  }

  :global(.ReactTable .rt-tbody .rt-tr-group:hover .rt-td) {
    background: $gray-100;
  }

  :global(.ReactTable .rt-tbody .rt-td) {
    text-align: left;
    padding-left: 15px;
    background: white;
    font-size: 15px;
    line-height: 2;
    transition: 0.3s;
  }

  :global(.ReactTable .rt-tbody .rt-tr-group:has(input[type="checkbox"]:checked) .rt-td) {
    background: $gray-100;
  }

  :global(.new-highlight) {
    background: $xlightgreen !important;
  }

  :global(.ReactTable .rt-tbody .rt-td .pin) {
    font-family: $font-family-bold;
  }

  :global(.ReactTable .rt-tbody .rt-expandable) {
    cursor: pointer;
    text-overflow: clip;
  }

  :global(.ReactTable .rt-tr-group) {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  :global(.ReactTable .rt-tr) {
    flex: 1 0 auto;
    display: inline-flex;
  }

  :global(.ReactTable .rt-th),
  :global(.ReactTable .rt-td) {
    flex: 1 0 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 7px 5px;
    overflow: hidden;
    transition: 0.3s ease;
    transition-property: width, min-width, padding, opacity;
  }

  :global(.ReactTable .rt-th.-hidden),
  :global(.ReactTable .rt-td.-hidden) {
    width: 0 !important;
    min-width: 0 !important;
    padding: 0 !important;
    border: 0 !important;
    opacity: 0 !important;
  }

  :global(.ReactTable .rt-expander) {
    display: inline-block;
    position: relative;
    margin: 0;
    color: transparent;
    margin: 0 10px;
  }

  :global(.ReactTable .rt-expander:after) {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-90deg);
    border-left: 5.04px solid transparent;
    border-right: 5.04px solid transparent;
    border-top: 7px solid rgba(0, 0, 0, 0.8);
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    cursor: pointer;
  }

  :global(.ReactTable .rt-expander.-open:after) {
    transform: translate(-50%, -50%) rotate(0);
  }

  :global(.ReactTable .rt-resizer) {
    display: inline-block;
    position: absolute;
    width: 36px;
    top: 0;
    bottom: 0;
    right: -5px;
    cursor: col-resize;
    z-index: 10;
  }

  :global(.ReactTable .rt-tfoot) {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
  }

  :global(.ReactTable.-striped .rt-tr.-odd) {
    background: rgba(0, 0, 0, 0.03);
  }

  :global(.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover) {
    background: rgba(0, 0, 0, 0.05);
  }

  :global(.ReactTable .-pagination input),
  :global(.ReactTable .-pagination select) {
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #fff;
    padding: 5px 7px;
    font-size: inherit;
    border-radius: 3px;
    font-weight: normal;
    outline: none;
  }

  :global(.ReactTable .-pagination .-btn) {
    appearance: none;
    display: block;
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 3px;
    padding: 6px;
    font-size: 1em;
    color: rgba(0, 0, 0, 0.6);
    background: rgba(0, 0, 0, 0.1);
    transition: all 0.1s ease;
    cursor: pointer;
    outline: none;
  }

  :global(.ReactTable .-pagination .-btn[disabled]) {
    opacity: 0.5;
    cursor: default;
  }

  :global(.ReactTable .-pagination .-btn:not([disabled]):hover) {
    background: rgba(0, 0, 0, 0.3);
    color: #fff;
  }

  :global(.ReactTable .-pagination .-previous),
  :global(.ReactTable .-pagination .-next) {
    flex: 1;
    text-align: center;
  }

  :global(.ReactTable .-pagination .-center) {
    flex: 1.5;
    text-align: center;
    margin-bottom: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
  }

  :global(.ReactTable .-pagination .-pageInfo) {
    display: inline-block;
    margin: 3px 10px;
    white-space: nowrap;
  }

  :global(.ReactTable .-pagination .-pageJump) {
    display: inline-block;
  }

  :global(.ReactTable .-pagination .-pageJump input) {
    width: 70px;
    text-align: center;
  }

  :global(.ReactTable .-pagination .-pageSizeOptions) {
    margin: 3px 10px;
  }

  :global(.ReactTable .rt-noData) {
    display: block;
    position: absolute;
    left: 50%;
    top: 36%;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.8);
    transition: all 0.3s ease;
    z-index: 1;
    pointer-events: none;
    padding: 16px;
    color: rgba(0, 0, 0, 0.5);
  }

  :global(.ReactTable .-loading) {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 85px;
    background: rgba(255, 255, 255, 0.8);
    transition: all 0.3s ease;
    z-index: -1;
    opacity: 0;
    pointer-events: none;
  }

  :global(.ReactTable .-loading > div) {
    position: absolute;
    display: block;
    text-align: center;
    width: 100%;
    top: 50%;
    left: 0;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.6);
    transform: translateY(-52%);
    transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  :global(.ReactTable .-loading.-active) {
    opacity: 1;
    z-index: 0;
    pointer-events: all;
  }

  :global(.ReactTable .-loading.-active > div) {
    transform: translateY(50%);
  }

  :global(.ReactTable .rt-resizing .rt-th),
  :global(.ReactTable .rt-resizing .rt-td) {
    transition: none !important;
    cursor: col-resize;
    user-select: none;
  }
}

.geotraxTableCustom {
  height: calc(100% - 21rem);
  @extend .geotraxTable;
}

.headerText {
  color: $gray-900;
  line-height: 1.1;
}

.noItemContentCt {
  margin: 0 auto;
  text-align: center;
  position: relative;
  top: 50%;
}

.icon {
  color: $orange;
  margin-left: 5px;
  margin-right: 5px;
}