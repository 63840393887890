.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-center,
.flex-horizontal-center,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.tabs {
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}
.tabs .count {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background: #E92851;
  top: 1.7rem;
  left: 1.5rem;
}
.tabs .tab,
.tabs .newMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
  transition: 0.3s;
  background: #f4f7f9;
  border-bottom: 1px solid #e1e4e7;
  z-index: 1;
}
.tabs .tab {
  width: 50%;
  border-right: 1px solid #f4f7f9;
  border-left: 1px solid white;
  border-top: 1px solid transparent;
  outline: 0;
}
.tabs .tab i {
  color: #636b71;
  font-size: 1.8rem;
  transition: 0.3s;
}
.tabs .tab .title {
  font-size: 1.3rem;
  margin-right: 9px;
  padding: 0;
  line-height: 1;
  letter-spacing: 0.15rem;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  color: #636b71;
  transition: 0.3s;
}
.tabs .tab:hover > .title,
.tabs .tab:hover > i {
  color: #151F28;
}
.tabs .tab:focus {
  outline: none;
}
.tabs .tab:active {
  border-style: solid;
}
.tabs .tab:active .title {
  color: #008EF3 !important;
  transition: none;
}
.tabs .tab:active i {
  color: #008EF3 !important;
  transition: none;
}
.tabs .newMessage {
  width: 4.4rem !important;
}
.tabs .newMessage i {
  font-size: 1.8rem;
  color: #636b71;
  transition: 0.3s !important;
}
.tabs .newMessage:hover > i {
  color: #151F28;
}
.tabs .selected {
  background: white !important;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.4) !important;
  border-right: 1px solid white;
  border-bottom: 1px solid white;
  z-index: 2;
}
.tabs .selected .title,
.tabs .selected i {
  color: #151F28 !important;
}
.tabs .selected:active {
  border-style: solid;
}
.tabs .selected:active .title {
  color: #151F28 !important;
}
.tabs .selected:active i {
  color: #151F28 !important;
}

