.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-center,
.flex-horizontal-center,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.headerTitle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 18px;
}

.isSelected {
  cursor: pointer;
  padding-right: 20px;
  padding-left: 10px;
  letter-spacing: 2px;
  transition: 0.3s !important;
  color: white;
}
.isSelected:hover {
  opacity: 0.6;
}

.isNotSelected {
  letter-spacing: 2px;
  padding-right: 15px;
  padding-left: 10px;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  font-size: 1.3rem;
  color: white;
}

.ticketNumber {
  letter-spacing: 2px;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  font-size: 1.4rem;
  color: white;
}

.ticket {
  font-size: 16px;
}

.paper {
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  pointer-events: all;
  max-width: 100% !important;
  max-height: 100% !important;
}

.expanded {
  min-height: 95% !important;
  height: 95% !important;
  min-width: 95% !important;
  width: 95% !important;
  left: 2.5% !important;
  top: 2.5% !important;
  border-radius: 3px !important;
}

.header {
  font-size: 1.5rem;
  display: flex;
  width: 100%;
  background-color: #008EF3;
  border-radius: 0;
  justify-content: space-between;
  align-items: center;
  padding: 7px 7px 7px 12px;
  color: #151F28;
  height: 55px;
}

.ticketsPopoverContainer {
  position: absolute;
  left: 306px;
  right: 0;
  bottom: 0;
  height: 40%;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
  z-index: 999;
}

.fullTicketBlockWidth {
  left: 6.2rem;
}

.ticketsPopoverContainerExpanded {
  position: absolute;
  z-index: 999;
  background: rgba(0, 0, 0, 0.8);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.ticketsPopoverContainerRouteHistory {
  position: absolute;
  left: 62px;
  right: 0;
  bottom: 0;
  height: 40%;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
  z-index: 999;
}

.expandedPopover {
  width: 95% !important;
  height: 95% !important;
  left: 2.5%;
  top: 2.5%;
}
.expandedPopover .header {
  border-radius: 3px 3px 0 0 !important;
}
.expandedPopover :global(.MuiPaper-root) {
  border-radius: 0 0 3px 3px !important;
}

.ticketsPopover {
  position: relative !important;
  width: 100%;
  height: 100%;
}
.ticketsPopover .datablock-info-close {
  padding: 0 1rem;
}
.ticketsPopover .datablock-info-close i {
  font-size: 1.6rem;
}
.ticketsPopover .datablock-info-close:hover {
  opacity: 0.5;
  transition-duration: 0.3s;
}
.ticketsPopover .ticketHeader {
  background-color: white;
  font-weight: 900;
}
.ticketsPopover :global(.-sort-desc.-cursor-pointer .rt-resizable-header-content:after) {
  width: 0;
  height: 0;
  font-family: "icomoon";
  content: "\e939";
  color: #F66A00;
  position: relative;
  top: 0;
  margin-left: 10px;
  font-size: 8px;
  overflow: hidden;
}
.ticketsPopover :global(.-sort-asc.-cursor-pointer .rt-resizable-header-content:after) {
  width: 0;
  height: 0;
  font-family: "icomoon";
  content: "\e938";
  color: #F66A00;
  position: relative;
  top: 0;
  margin-left: 10px;
  font-size: 8px;
  overflow: hidden;
}
.ticketsPopover :global(.-cursor-pointer .rt-resizable-header-content:after) {
  width: 0;
  height: 0;
  font-family: "icomoon";
  content: "\e939";
  color: #a5abaf;
  position: relative;
  top: 0;
  margin-left: 10px;
  font-size: 8px;
  overflow: hidden;
}
.ticketsPopover :global(.driver-cell),
.ticketsPopover :global(.vehicle-cell) {
  border-bottom: solid 4px white;
}
.ticketsPopover :global(.vehicle-header) {
  border-right: 1px solid #e1e4e7 !important;
}
.ticketsPopover :global(.driver-cell:after) {
  height: calc(100% + 4px);
  top: 0;
  right: -15px;
  position: absolute;
  width: 15px;
  content: " ";
}
.ticketsPopover :global(.driver-header:after) {
  height: calc(100% + 1px);
  bottom: -1px;
  right: -15px;
  box-shadow: 6px 0 6px -6px inset rgba(0, 0, 0, 0.4);
  position: absolute;
  width: 15px;
  content: " ";
}
.ticketsPopover :global(.rt-tr--add) {
  animation-name: rowAnim;
  animation-duration: 0.4s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-direction: reverse;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-play-state: running;
}
.ticketsPopover :global(.rt-tr--remove) {
  animation-name: rowAnim;
  animation-duration: 0.4s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-play-state: running;
}

@keyframes rowAnim {
  0% {
    height: 31px;
  }
  100% {
    height: 0px;
  }
}

