@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';

.view {
  margin-top: 300px;
  text-align: center;

  p {
    margin-top: 20px;
    white-space: pre-wrap;
    color: $gray-900;
    line-height: 1.2;
  }
}

.icon {
  font-size: 75px;
  color: $gray-300;
}