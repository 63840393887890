@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.container {
  :global(.ReactTable) {
    padding-bottom: 39px;
  }

  .tabsContainer {
    border-bottom: 1px solid $gray-300;
    margin-top: -10px;
  }
}