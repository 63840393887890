@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '../../../styles/geotrax_vars.scss';

.docsPanelContent {
  margin-top: $panelContentMarginOffset;
}

.categoryLink:hover {
  text-decoration: none;
}

categoryLink:focus {
  outline: none;

  p {
    outline: none;
  }
}

.folder {
  background: $gray-800;
}

.folderContent {
  max-height: 130px !important;
}