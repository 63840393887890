@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_helpers.scss';

.contentContainer {
  padding: 20px 24px 27px 24px;
  background-color: $white;
  overflow: auto;
  display: table-caption;
  margin: -113px -1px 20px 0;
  width: 43.4rem;

  input[type='text'].tt-input {
    min-height: 2rem;
  }
}

.resultCount {
  margin: 12px;
  display: flex;
  justify-content: space-between;
}

.floatClear {
  clear: both;
}

.smallFont {
  font-size: 14px;
  line-height: 20px;
  color: $gray-900;
}

.textButton {
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
  border: none;
  background-color: $white;
  color: $darkblue;
  cursor: pointer;
  padding-left: 10px;
}

.clearSearch {
  font-size: 14px;
  font-weight: bold;
  border: none;
  background-color: $white;
  color: $darkblue;
  cursor: pointer;
  padding-left: 10px;
}

.notApplicable {
  color: $gray-400;
}

.checkboxUnchecked {
  color: $gray-600 !important;
}

.checkboxChecked {
  color: $blue !important;
}

.sort {
  height: 50%;
  display: flex;
  align-items: center;
  margin-left: 3px;
}

.cellTruck {
  overflow: hidden;
}

.truckText {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.deactivatedText {
  color: $gray-600;
  font-size: 11px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}