@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';

.composeMessage {
  padding: 2.2rem;
  background: $gray-200;

  textarea {
    background: white !important;
    border: 1px solid $gray-300;
    resize: none;
    height: 180px;
    box-sizing: border-box;
  }
}

.footer {
  margin-top: 2.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.characters {
  font-size: 1.3rem;
  line-height: 1;
}

.maxCharcount {
  font-weight: 700;
  color: $red;
}

.textareaContainer {
  position: relative;
  width: 100%;
  max-width: 400px;
}

.textAreaWithIcon {
  width: 100%;
  padding-right: 30px !important;
}

.iconKeyboardArrowDown {
  font-size: 24px;
  /* Adjust the size as needed */
  width: 24px;
  /* Optional: Adjust width */
  height: 24px;
  /* Optional: Adjust height */
  color: #636B71;
}

.textareaIcon {
  position: absolute;
  right: 10px;
  /* Adjust positioning as needed */
  top: 8%;
  transform: translateY(-50%);
  cursor: pointer;
  /* Change cursor to pointer */
}

.popper {
  z-index: 1300;
  overflow: hidden !important;
  box-shadow: $boxshadow-medium;
  margin-bottom: 1px !important;
  border-radius: $border-radius;
}