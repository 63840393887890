@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.wrapper {
  display: block;
}

.textDisplay {
  margin-bottom: 35px;
}

.name {
  margin-bottom: 32px;
  width: 320px;
}

.maxWeight {
  width: 160px !important;
  display: inline-block;
  margin-right: 30px;
  margin-bottom: 30px;
  overflow: hidden;
  height: 66px;
  vertical-align: top;
}

.uomDropDown {
  width: 130px !important;
  display: inline-block !important;
  margin-top: 6px;
  margin-bottom: 30px;
  vertical-align: top;
}

.required {
  color: $red;
  font-size: 18px;
  margin-left: 3px;
}

.vehicleTypesAnchor {
  color: $darkblue;
  font-size: 13px;
  font-family: $font-family-bold;
  background-color: $white;
  padding: 4px 3px 2px 4px;
  position: absolute;
  top: 24px;
  left: 26px;
  z-index: 999;
}