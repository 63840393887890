@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.paper {
  height: 100%;
  border-radius: 3px 3px 0 0 !important;
}

$row-divider-height: 4px;

.h4 {
  margin: 10px 10px 10px 15px;
  letter-spacing: 2px;
  font-family: $font-family-bold;
  font-size: 1.4rem;
}

.ticketNumberLink {
  background-color: transparent;
  border: transparent;
  color: $darkblue;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  font-family: $font-family-bold;
  transition: $transition;
}

.receiptsButton {
  background: transparent;
  border: none;
  position: absolute;
  right: 5px;
  cursor: pointer;
  transition: $transition;
  height: 30px;
  width: 30px;
  border-radius: $border-radius;

  i {
    display: inline-block;
    transform: translateY(1px);
    font-size: 1.6rem;
    transition: $transition;
  }

  &:hover {
    background: $gray-400;
  }

  &:active {
    background: $gray-200;
  }
}

.wrapper {
  margin: 0 31px 40px 31px;
}

.ticketListHeader {
  background-color: $gray-300;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  position: relative;
  border-radius: $border-radius $border-radius 0 0;
  width: 100%;
  overflow-x: auto;
}

.ticketListContainer {
  box-shadow: none !important;
  box-shadow: $boxshadow-medium;
  overflow: hidden;

  :global(.ReactTable) {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border: none !important;
  }

  :global(.ReactTable *) {
    box-sizing: border-box;
  }

  :global(.ReactTable .rt-table) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    border-collapse: collapse;
    overflow: auto;
  }

  :global(.ReactTable .rt-thead) {
    flex: 1 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    user-select: none;
  }

  :global(.ReactTable .rt-thead.-header) {
    box-shadow: $boxshadow-medium;
  }

  :global(.ReactTable .rt-thead.-headerGroups) {
    background: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }

  :global(.ReactTable .rt-thead .rt-tr) {
    text-align: center;
  }

  :global(.ReactTable .rt-thead .rt-th),
  :global(.ReactTable .rt-thead .rt-td) {
    padding: 5px 5px;
    line-height: normal;
    position: relative;
    transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    box-shadow: inset 0 0 0 0 transparent;
  }

  :global(.ReactTable .rt-thead .rt-th:last-child),
  :global(.ReactTable .rt-thead .rt-td:last-child) {
    border-right: 0;
  }

  :global(.ReactTable .rt-thead .rt-resizable-header) {
    padding: 10px 0 10px 15px;
    outline: 0;
    text-align: left;
  }

  :global(.ReactTable .rt-thead .rt-resizable-header:last-child) {
    overflow: hidden;
  }

  :global(.ReactTable .rt-thead .rt-resizable-header-content) {
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: $font-family-bold;
  }

  :global(.ReactTable .rt-thead .rt-header-pivot) {
    border-right-color: $gray-100;
  }

  :global(.ReactTable .rt-thead .rt-header-pivot:after),
  :global(.ReactTable .rt-thead .rt-header-pivot:before) {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  :global(.ReactTable .rt-thead .rt-header-pivot:after) {
    border-color: rgba(255, 255, 255, 0);
    border-left-color: $gray-100;
    border-width: 8px;
    margin-top: -8px;
  }

  :global(.ReactTable .rt-thead .rt-header-pivot:before) {
    border-color: rgba(102, 102, 102, 0);
    border-left-color: $gray-100;
    border-width: 10px;
    margin-top: -10px;
  }

  :global(.ReactTable .rt-tbody) {
    flex: 99999 1 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  :global(.ReactTable .rt-tbody .rt-tr) {
    border-bottom: 1px solid $gray-200;
  }

  :global(.ReactTable .rt-tbody .rt-tr:hover .rt-td) {
    background-color: $gray-100;
    cursor: pointer;

    &:nth-child(2) {
      text-decoration: underline;
    }
  }

  :global(.ReactTable .rt-tbody .rt-tr:active .rt-td) {
    background-color: $gray-200;
  }

  :global(.ReactTable .rt-tbody .rt-td) {
    transition-duration: 0.3s;
    padding: 3px 0 5px 15px;
    border-bottom: none !important;
    border-radius: 0 !important;
  }

  :global(.ReactTable .rt-tbody .rt-tr-group.selected) {
    :global(.rt-td) {
      background-color: $xlightblue;
    }
  }

  :global(.ReactTable .rt-tbody .rt-td) {
    padding: 3px 0 0 15px;
    border-bottom: none !important;
  }

  :global(.ReactTable .rt-tbody .rt-expandable) {
    text-overflow: clip;
  }

  :global(.ReactTable .rt-tr-group) {
    flex: 1 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    border-bottom: none !important;
  }

  :global(.ReactTable .rt-tr-group:last-of-type .rt-tr) {
    border-bottom: 1px solid transparent !important;
  }

  :global(.ReactTable .rt-tr) {
    flex: 1 0 auto;
    display: inline-flex;
  }

  :global(.ReactTable .rt-tr .rt-td) {
    &:nth-child(2):hover {
      text-decoration: underline !important;
    }
  }

  :global(.ReactTable .rt-th),
  :global(.ReactTable .rt-td) {
    flex: 1 0 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 7px 5px;
    transition: 0.3s ease;
    transition-property: width, min-width, padding, opacity, background-color;
    min-width: 11px !important;
  }

  :global(.ReactTable .rt-th.-hidden),
  :global(.ReactTable .rt-td.-hidden) {
    width: 0 !important;
    min-width: 0 !important;
    padding: 0 !important;
    border: 0 !important;
    opacity: 0 !important;
  }

  :global(.ReactTable .rt-expander) {
    display: inline-block;
    position: relative;
    color: transparent;
    margin: 0 10px;
  }

  :global(.ReactTable .rt-expander:after) {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-90deg);
    border-left: 5.04px solid transparent;
    border-right: 5.04px solid transparent;
    border-top: 7px solid rgba(0, 0, 0, 0.8);
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }

  :global(.ReactTable .rt-expander.-open:after) {
    transform: translate(-50%, -50%) rotate(0);
  }

  :global(.ReactTable .rt-resizer) {
    display: none;
  }

  :global(.ReactTable .rt-tfoot) {
    flex: 1 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
  }

  :global(.ReactTable .rt-tfoot .rt-td:last-child) {
    border-right: 0;
  }

  :global(.ReactTable.-striped .rt-tr.-odd) {
    background: $gray-100;
  }

  :global(.ReactTable .rt-noData) {
    display: block;
    background: rgba(255, 255, 255, 0.8);
    transition: all 0.3s ease;
    z-index: 1;
    pointer-events: none;
    padding: 20px;
    color: rgba(0, 0, 0, 0.5);
    font-size: 16px;
    font-weight: bold;
  }

  :global(.ReactTable .-loading) {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    transition: all 0.3s ease;
    z-index: -1;
    opacity: 0;
    pointer-events: none;
  }

  :global(.ReactTable .-loading > div) {
    position: absolute;
    display: block;
    text-align: center;
    width: 100%;
    top: 50%;
    left: 0;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.6);
    transform: translateY(-52%);
    transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  :global(.ReactTable .-loading.-active) {
    opacity: 1;
    z-index: 2;
    pointer-events: all;
  }

  :global(.ReactTable .-loading.-active > div) {
    transform: translateY(50%);
  }

  :global(.ReactTable .rt-resizing .rt-th),
  :global(.ReactTable .rt-resizing .rt-td) {
    transition: none !important;
    cursor: col-resize;
    user-select: none;
  }

  :global(.ReactTable .rt-thead .rt-th.-sort-asc),
  :global(.ReactTable .rt-thead .rt-td.-sort-asc) {
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.6);
  }

  :global(.-sort-desc.-cursor-pointer .rt-resizable-header-content:after) {
    width: 0;
    height: 0;
    font-family: 'icomoon';
    content: '\e939'; // icomoon - icon-expand-more
    color: $orange;
    position: relative;
    top: 0;
    margin-left: 10px;
    font-size: 8px;
    overflow: hidden;
  }

  :global(.-sort-asc.-cursor-pointer .rt-resizable-header-content:after) {
    width: 0;
    height: 0;
    font-family: 'icomoon';
    content: '\e938'; // icomoon - icon-expand-less
    color: $orange;
    position: relative;
    top: 0;
    margin-left: 10px;
    font-size: 8px;
    overflow: hidden;
  }

  :global(.-cursor-pointer .rt-resizable-header-content:after) {
    width: 0;
    height: 0;
    font-family: 'icomoon';
    content: '\e939'; // icomoon - icon-expand-more
    color: $gray-400;
    position: relative;
    top: 0;
    margin-left: 10px;
    font-size: 8px;
    overflow: hidden;
  }

  :global(.driver-cell),
  :global(.vehicle-cell) {
    border-bottom: solid $row-divider-height white;
  }

  :global(.css-9za7aq:after) {
    box-shadow: 6px 0 6px -6px inset rgba(0, 0, 0, 0.4);
    position: absolute;
    width: 15px;
    content: ' ';
  }

  :global(.css-9za7aq) {
    border-right: none !important;
  }

  :global(.driver-cell.css-9za7aq:after) {
    height: calc(100% + 4px);
    top: 0;
    right: -15px;
  }

  :global(.driver-header.css-9za7aq:after) {
    height: calc(100% + 1px);
    bottom: -1px;
    right: -15px;
  }

  :global(.rt-tr--add) {
    animation-name: rowAnim;
    animation-duration: 0.4s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-direction: reverse;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-play-state: running;
  }

  :global(.rt-tr--remove) {
    animation-name: rowAnim;
    animation-duration: 0.4s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-direction: normal;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-play-state: running;
  }
}

.noTicketsContainer {
  height: 8rem;
  background: $gray-100;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.noTicketsText {
  color: $gray-500;
  font-size: 1.3rem;
  font-family: $font-family-regular;
  display: inline-block;
  transform: translateY(-2px);
}

.noTicketsShadow {
  position: absolute;
  top: -2rem;
  left: 0;
  right: 0;
  height: 2rem;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}