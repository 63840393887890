.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-center,
.flex-horizontal-center,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.topImg {
  width: 100%;
}

.topImg img {
  width: 100%;
  height: auto;
}

.headerAndContainer {
  height: 100%;
}

.header {
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif !important;
  margin: 0;
  line-height: 1;
  padding: 0;
  white-space: normal;
  word-wrap: break-word;
}

.divider {
  border: 0;
  background-color: #F66A00;
  width: 100px;
  height: 4px;
}

.cardStyle {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15), 0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 3px 1px -2px rgba(0, 0, 0, 0.07) !important;
}

.tableContainer {
  height: auto;
  width: 100%;
  max-width: 1000px;
  overflow-x: auto;
  overflow-y: auto;
  padding: 0;
  border-collapse: collapse;
}
.tableContainer table {
  border: 1px solid #e1e4e7;
}

.tableContainer,
.tableContainer th,
.tableContainer td {
  border: none;
  padding: 0;
}

.tableContainer td,
.tableContainer th {
  padding: 0 0 0 11px;
}

.tableContainer td a {
  font-weight: bold;
  font-size: 16px;
}

.tableContainer tbody tr {
  padding: 0.5rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.tableContainer tbody tr:not(:last-of-type) {
  border-bottom: 1px solid #e1e4e7;
}

.tableContainer tbody tr:hover {
  background: white !important;
  cursor: default !important;
}

.instructionDocuments {
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  line-height: 1;
}

.openPdfButton {
  width: 66%;
}

.openPdfButton button {
  background-color: transparent;
  border: transparent;
  cursor: pointer;
  transition: 0.3s !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 18px 0 12px;
  border-radius: 3px;
}
.openPdfButton button .pdfLogo,
.openPdfButton button .pdfLogo img {
  width: 28px !important;
  height: auto !important;
  margin-right: 16px !important;
  transition: 0.3s !important;
  display: inline-block;
  transform: translateY(1px);
}
.openPdfButton button .itemNameCtr {
  color: #0A63F3;
  font-size: 1.6rem;
  line-height: 1.1;
  text-align: left;
}
.openPdfButton button .itemName {
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
}
.openPdfButton button:hover .itemName {
  text-decoration: underline;
}
.openPdfButton button:focus {
  outline: none;
}

.downloadButton {
  display: flex !important;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
  margin-top: 3px;
  margin-right: 10px;
}

.downloadButton button {
  margin: 1px 3px 0 1px;
  cursor: pointer;
  background: transparent;
  border: transparent;
  font-size: 14px;
  font-weight: bold;
  transition: 0.3s !important;
  border-radius: 3px;
  padding: 2px 14px 0 14px;
  height: 41px;
  white-space: nowrap;
}
.downloadButton button:hover {
  background: #f4f7f9;
}
.downloadButton button:active {
  background: #e1e4e7;
}
.downloadButton button:focus {
  outline: none;
}
.downloadButton button.buttonDownloading {
  background: transparent;
  cursor: default;
}

.downloadButton button i {
  display: inline-block;
  vertical-align: middle;
  color: #0A63F3;
  display: inline-block;
  transform: translateY(-3px);
}

.downloadButton button span {
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  transition: 0.3s !important;
  display: inline-block;
  transform: translateY(-3px);
}

.downloadButtonLoading {
  display: inline-block !important;
  transform: translateY(-4px) !important;
  margin-left: -1px !important;
}

.downloadAllZip {
  display: flex !important;
  margin-top: 20px;
  margin-right: -25px;
  padding: 0 24px;
  float: right;
}

.videosList {
  display: block;
}

