@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';
@import '@trucktrax/trucktrax-common/src/styles/components/tt_buttons.scss';

.card {
  width: 360px;
  overflow: visible !important;
}

.form {
  margin: 30px;
}

.error {
  font-weight: 400;
  color: $darkred !important;
  display: block;
  margin-top: 6px;
}

.background {
  background: url(../../assets/img/bgSignIn.jpg) center/cover no-repeat;
  height: 100%;
  @extend %flexCenter;
  flex-direction: column;
}

.samlButtonWrapper {
  margin: 30px;
}

.orWrapper {
  padding-left: 8%;
  padding-right: 8%;
}

.orSpan {
  font-weight: bolder;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #d6d4d4;
  line-height: 0.1em;
  margin: 10px 0 20px;
}

.orSpan span {
  background: #fff;
  padding: 0 10px;
}

.logo {
  max-height: 80px;
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.samlButton {
  width: 100%;
  background: #E2F8FF !important;
  color: #0A63F3 !important;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15), 0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 3px 1px -2px rgba(0, 0, 0, 0.07) !important;
}

.btnSubmit:disabled {
  background-color: $gray-200 !important;
  color: $gray-400 !important;
  box-shadow: none !important;
  cursor: not-allowed !important;
}

.btnSubmit:disabled span {
  color: $gray-400 !important;
}

.btnResetTenant {
  background: transparent !important;
  text-transform: none !important;
  font-size: 14px !important;

  &:hover {
    box-shadow: inset 0 0 10px 30px rgba(244, 247, 249, 0.14) !important;
  }
}

.resetTenantContainer {
  position: absolute;
  bottom: 21px;
}

.resetPassword h4 {
  text-align: center;
  color: $gray-900;
  font-family: PublicSans Bold, Helvetica, Arial, sans-serif;
}

.resetPassword h5 {
  font-family: PublicSans Bold, Helvetica, Arial, sans-serif;
  color: $gray-900;
  margin: 0 0 10px 0;
}

.resetPassword ul {
  list-style-type: none;
  color: $gray-900;
  padding: 0;
}

.resetPassword li {
  font-size: 1.6rem;
  line-height: 2.8rem;
}

.resetPassword li span {
  vertical-align: top;
}

.resetPassword li i {
  display: inline-block;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  vertical-align: middle;
  margin-top: -3px;
}

.resetPassword li .circle {
  color: $gray-300;
  background-color: $gray-300;
}

.resetPasswordSuccess {
  color: $success !important;
}

.resetPasswordSuccess span {
  color: $gray-900 !important;
}

.resetPasswordError {
  color: $error !important;
}

.arrow {
  position: absolute;
  right: -10px;
  height: 0;
  width: 0;
  background-color: transparent;
  top: 9px;
  border-top: 9px solid transparent;
  border-bottom: 9px solid transparent;
  border-left: 14px solid $red;
}

.passwordTooltip {
  margin: 0 7px 0 0;
}

.circle {
  background-color: $gray-900 !important;
  height: 7px !important;
  width: 7px !important;
  margin-left: 4px !important;
  margin-right: 12px !important;
}