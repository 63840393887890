.margin-0 {
  margin: 0 !important;
}

.margin-top-0 {
  margin-top: 0;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-right-20 {
  margin-right: 20px;
}

.margin-right-30 {
  margin-right: 30px;
}

.margin-right-40 {
  margin-right: 40px;
}

.margin-left-5 {
  margin-left: 5px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-left-30 {
  margin-left: 30px;
}

.margin-left-40 {
  margin-left: 40px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

.wrap-words {
  word-break: initial;
  line-height: 1.1;
}

.nowrap-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

.pre-wrap {
  white-space: pre-wrap;
}

.height-100 {
  height: 100%;
}

.icon-md {
  font-size: 1.8rem;
}

.icon-lg {
  font-size: 2rem;
}

.icon-xl {
  font-size: 3rem;
}

.icon-people-margin {
  position: relative;
  top: 2px;
  margin-right: 3px;
}

.underline {
  text-decoration: underline;
}

.clickable {
  cursor: pointer;
}

.asterisk {
  color: #e92851;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  font-size: 1.8rem;
  display: inline-block;
  transform: translateY(3px);
  margin-left: 0.3rem;
  line-height: 0.5;
}

.productLines {
  font-size: 16px !important;
  font-weight: 400 !important;
  margin-bottom: 2rem;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif !important;
  color: #151F28;
  width: 75%;
}
.productLines div.errorMessage {
  border: 1px solid #E92851;
  padding-left: 5px;
  background-color: #ffecf0;
}
.productLines p.errorMessage {
  font-family: "PublicSans Regular", Helvetica, Arial, sans-serif;
  font-size: 13px;
  color: #e92851;
}
.productLines label {
  margin: 0;
  height: 20px;
  align-content: center;
}
.productLines .tableHeadRow {
  height: 28px !important;
  width: 75%;
}
.productLines .tableHeadRow th {
  font-size: 1.11rem !important;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif !important;
  color: #636b71;
  padding: 0px;
}
.productLines .tableBodyRow {
  height: 36px !important;
}
.productLines .tableBodyRow td {
  font-size: 1.5rem !important;
  padding: 0px;
  font-family: "PublicSans Regular", Helvetica, Arial, sans-serif !important;
  width: 30%;
  height: 36px !important;
}
.productLines .tableBodyRow .selector {
  width: 20%;
  height: 36px !important;
}
.productLines .tableBodyRow .rest {
  width: 30%;
  height: 36px !important;
}
.productLines tbody tr:last-child td {
  border-bottom: none !important;
}

.radioBtn {
  color: #151F28 !important;
  padding: 12px !important;
  margin-left: 10px !important;
}
.radioBtn :global(.MuiSvgIcon-root) {
  font-size: 2.4rem !important;
}

.radioBtnSize, .radioBtnSelected, .radioBtn {
  height: 10px;
  width: 10px;
  margin-left: 13px !important;
}

.radioBtnSelected {
  color: #008EF3 !important;
}

.radioLabel {
  font-size: 16px !important;
  font-weight: 400 !important;
  margin: 4px !important;
  font-family: "PublicSans Regular", Helvetica, Arial, sans-serif !important;
  color: #151F28;
}

.checkboxLabel {
  color: #151F28 !important;
  font-size: 16px !important;
}

.checkboxLabel span,
.checkboxLabel span input {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-left: 13px !important;
  padding-right: 0px !important;
  font-size: 16px !important;
}
.checkboxLabel span:hover,
.checkboxLabel span input:hover {
  background: none !important;
}
.checkboxLabel span svg,
.checkboxLabel span input svg {
  height: 24px;
  width: 24px;
}

.checkboxUnchecked {
  color: #151F28 !important;
  font-size: 16px !important;
}

.checkboxChecked {
  color: #008EF3 !important;
  font-size: 16px !important;
}

