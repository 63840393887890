@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.buttonClass {
  color: $darkblue !important;
  display: inline-block;
  line-height: 1.9;

  &:hover {
    text-decoration: underline;
  }
}

.buttonDisabled {
  color: $gray-600 !important;
  display: inline-block;
  line-height: 1.9;
  pointer-events: none;
}