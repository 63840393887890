@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_helpers.scss';

.cardWrapper {
  margin: 0 auto;
  padding: 0 31px 60px 31px;
  width: 100%;
  max-width: 1062px;
}

.cardContainer {
  box-shadow: $boxshadow-medium;
}

.cardContent {
  padding: 0 !important;
  max-height: 775px;
}

.checklistContentHeader {
  @extend %flexVerticalCenter;
  @extend %flexSpaceBetween;
  background-color: $gray-700;
  padding: 7px 46px;
}

.checklistContentTitle {
  font-family: $font-family-bold;
  color: $white;
  font-size: 15px;
  display: inline-block;
}

.checklistContentHeaderIcon {
  color: $blue-hover;
  font-size: 15px;
  line-height: 1.5;
  vertical-align: bottom;
}

.checklistContentBody {
  padding: 30px 45px 45px 45px;
}

.checklistContentEditBtn {
  @extend %flexVerticalCenter;
  background-color: transparent;
  border: 0;
  padding: 0;
  transition: $transition;

  &:hover {
    cursor: pointer;

    .checklistContentEditBtnText {
      color: $blue-hover;
    }
  }
}

.checklistContentEditBtnText {
  display: inline-block;
  transform: translateY(-1px);
  color: $white;
  font-size: 1.3rem;
  font-family: $font-family-bold;
  transition: $transition;
}

.regionsTabContainer {
  grid-column: 1 / span 4;
  overflow-x: auto;
  border-bottom: 1px solid $gray-300;
  margin-bottom: 30px;

  .btnContainer {
    opacity: 1 !important;
  }

  .btnContainerDisabled {
    opacity: 0.4 !important;
  }
}

.disabledClickIcon {
  pointer-events: auto !important;
  cursor: not-allowed !important;
}

.lockPopper {
  z-index: 9999 !important;
  opacity: 1 !important;
  margin-left: -20px;
}

.lockTooltip {
  background: $info !important;
  padding: 0.6rem 1rem !important;
  font-size: 1.3rem !important;
  line-height: 1.3 !important;
  white-space: nowrap;
  border-radius: $border-radius !important;
  pointer-events: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 6px;
    pointer-events: none;
    border-right-color: $info;
    top: 100%;
    right: 44%;
    transform: translateY(0%) rotate(-90deg);
  }
}

.disabledRegionName {
  font-weight: bolder;
  font-family: $font-family-bold;
}

.checklistEmailsCtr,
.checklistEditingEmailsCtr {
  margin-bottom: 33px;

  >div>div>div {
    >input {
      position: relative;
      border-bottom: 1px solid $gray-200;
      border-radius: 0px !important;
      padding: 10px 2px 4px;

      &:focus {
        border-bottom: 1px solid $blue;
      }
    }

    >fieldset {
      display: none;
    }
  }
}

.checklistEmailLabel {
  height: 16px;
  font-size: 13px !important;
  font-weight: bold !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.23;
  letter-spacing: normal;
  color: $gray-900;
}

.checklistEmailText {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
  color: $gray-900;
}

.checklistEmailNoneLabel,
.checklistEmailDelim {
  color: $gray-400;
}

.checklistEmailDelim {
  padding: 0 4px;
}

.checklistEmailDiv {
  padding-bottom: 0px;
  margin-bottom: 6px;
}

.chip {
  color: $gray-600 !important;
  background-color: $gray-100 !important;
  border: 1px solid $gray-200 !important;
  border-radius: $border-radius !important;
  height: 2.6rem !important;
  display: inline-flex !important;
  margin-bottom: 0.5rem;
  margin-right: 10px;
  margin-top: 4px;
  padding: 0 !important;
}

.chipLabel {
  font-family: $font-family-bold;
  font-size: 1.3rem;
  line-height: 2.6rem;
  padding: 0 6px 0 7px !important;
  margin: 0 !important;
  display: block !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.chipRemove {
  width: 2.5rem !important;
  height: 2.6rem !important;
  background-color: transparent;
  border-radius: 0 $border-radius $border-radius 0;
  border: none;
  outline: none;
  margin: 0 !important;
  transition: $transition;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    color: $gray-600;
    font-size: 1.2rem;
    line-height: 1.2rem;
  }

  &:hover {
    background-color: $gray-200 !important;

    i {
      color: $gray-900 !important;
    }
  }
}

.textField {
  display: inline-block !important;
  padding-left: 5px !important;

  &:before {
    border-bottom: 1px solid $gray-200 !important;
  }

  &:after {
    border-bottom: 2px solid $blue !important;
  }
}

.textFieldInput {
  &::placeholder {
    color: $gray-300 !important;
    opacity: 1 !important;
  }
}

.questionsContainer {
  overflow-x: hidden;
  background-color: $gray-100;

  .questionsOverflowPadding {
    margin-right: 15px;
  }

  .questionsHeader {
    background-color: $gray-200;
    display: flex;
    @extend %flexSpaceBetween;
    overflow-y: auto;

    .questionsHeaderTxt {
      font-size: 15px;
      font-family: $font-family-bold;
      padding: 7px 15px 7px 16px;
    }

    .questionsActiveDispContainer {
      justify-self: end;
      padding: 9px 15px;
      transform: $transform-left-30;
      width: 91px;
    }

    .questionsActiveDispContainerEdit {
      @extend %flexVerticalCenter;
      justify-self: end;
      padding: 0 15px;
    }

    .questionsActiveDisp {
      font-size: 1.3rem;
      line-height: 1.8rem;
      font-family: $font-family-bold;
    }

    .questionsActiveDispLabel {
      font-size: 1.3rem;
      line-height: 1.8rem;
      font-family: $font-family-regular;
    }

    .questionsActiveDispVal {
      font-size: 1.3rem;
      line-height: 1.8rem;
    }

    .questionsActiveDispIconYes {
      font-size: 16px;
      vertical-align: text-bottom;
      margin-left: 5px;
      margin-right: 5px;
      color: $darkblue;
    }

    .questionsActiveDispIconNo {
      font-size: 16px;
      vertical-align: text-bottom;
      margin-left: 5px;
      margin-right: 5px;
      color: $gray-600;
    }
  }

  .questionsBody {
    .emptyQuestionsContainer {
      display: grid;
      justify-items: center;
      align-items: center;
      height: 212px;
    }

    .questionsListContent {
      overflow-y: auto;
      overflow-x: hidden;
      max-height: 277px;
    }

    .questionsListContainer {
      background-color: $white;
      max-height: 315px;
      overflow: hidden;
      border: 1px solid $gray-200;

      .questionsList {
        overflow-y: auto;
        max-height: 277px;
      }

      .questionsListHeader,
      .questionsListHeaderEdit {
        overflow-y: auto;
      }

      .questionsListHeader {
        background-color: $gray-100;
        padding: 5px 9px 6px 14px;
        box-shadow: $boxshadow-medium;
        @extend %flexSpaceBetween;
        @extend %flexVerticalCenter;
        z-index: 10;
        position: relative;

        span {
          font-size: 1.3rem;
          font-family: $font-family-bold;
        }
      }

      .questionsListHeaderDeleteBtn,
      .questionsListCopyRegionBtn {
        @extend %flexVerticalCenter;

        span,
        i {
          transition: $transition;
        }

        span {
          font-size: 1.3rem;
          font-family: $font-family-bold;
        }

        i {
          margin-right: 3px;
          color: $darkblue;
        }
      }

      .questionsListHeaderDeleteBtn {
        background-color: $gray-100;
        border: none;
        max-height: 35px;

        &:hover {
          cursor: pointer;

          span,
          i {
            color: $red;
          }
        }

        &:active {

          span,
          i {
            color: $red-hover;
          }
        }
      }

      .questionsListHeaderDeleteIcon {
        color: $blue;
        font-size: 16px;
        vertical-align: text-bottom;
      }

      .questionsTextEdit {
        font-size: 1.3rem;
        font-family: $font-family-bold;
        margin: 6px 0 5px 49px;
      }

      .questionsListHeaderEdit {
        background-color: $gray-100;
        margin: 0;
        display: grid;
        padding: 5px 9px 6px 2px;
        grid-template-rows: 36px;
        grid-template-columns: 145px 95px 150px;
        box-shadow: $boxshadow-medium;
        z-index: 10;
        position: relative;
      }

      .questionsListCopyRegionBtn {
        background-color: $gray-100;
        border: none;
        max-height: 35px;
        padding: 8px 0;

        &:hover {
          cursor: pointer;

          span,
          i {
            color: $darkblue;
          }
        }

        &:active {

          span,
          i {
            color: $blue-hover;
          }
        }
      }

      .activeCopyRegions {
        color: $blue;
      }

      .questionsCopyRegionsIcon {
        color: $blue;
        font-size: 16px;
        vertical-align: text-bottom;
      }

      .questionsCopyRegionsArrowIcon {
        font-size: 18px;
        vertical-align: text-bottom;
        transform: $transform-left-10;
      }

      .questionsListHeaderTxt {
        @extend %flexVerticalCenter;
        padding: 4px 10px 4px 1px;
        min-width: 97px;

        span {
          font-size: 13px;
          font-family: $font-family-bold;
        }

        .checkboxLabel {
          margin: 0 0 2px 2px;
          white-space: nowrap;
        }
      }

      .questionsListCheckbox {
        margin: -2px 0 !important;
        padding: 10px !important;
      }

      .question {
        font-size: 16px;
        padding: 12px 8px 12px 15px;
        border-top: 1px solid $gray-200;
        @extend %flexSpaceBetween;

        &:nth-child(1) {
          border-top: none;
        }

        .questionText {
          width: calc(100% - 98px) !important;
          padding-right: 12px;
        }

        .answerType {
          width: 98px;
        }
      }

      .questionEdit {
        display: grid;
        font-size: 16px;
        padding: 8px 0;
        border-top: 1px solid $gray-200;
        grid-template-columns: 49px 16fr 44px;
      }

      .questionInput {
        height: 40px;
      }

      .characterCount {
        position: relative;
        font-size: 1.3rem;
        right: 8px;
        bottom: 32px;
        float: right;
        padding: 2px 7px 3px 8px;
        background-color: $gray-200;
        border-radius: $border-radius;
      }
    }

    .emptyQuestions {
      width: 267px;
      text-align: center;

      .emptyQuestionsIcon {
        font-size: 64px;
        display: block;
        text-align: center;
        color: $gray-300;
        margin-bottom: 15px;
      }

      .emptyQuestionsLabel {
        font-size: 16px;
      }
    }
  }
}

.saveBtn {
  display: block;
  grid-row: 1;
  grid-column: -2;
  margin-top: 0px;
  align-self: right;
  justify-self: right;
}

.addQuestionsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid $gray-200;
  padding: 8px;
}

.addQuestionBtn {
  max-width: 185px;
}

.removeQuestionBtn {
  i {
    color: $darkblue !important;

    &:hover {
      color: $red !important;
    }
  }
}

.blue {
  color: $blue !important;
}

.featureFooter {
  @extend %flexEnd;
  padding: 8px 46px;
  border-top: 1px solid $gray-200;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.35);
}