.recenterButtonContainer {
  margin-right: 6px !important;
}

.recenterButtonPopover {
  padding: 0 !important;
}

.recenterButtonPopover:before {
  margin-right: 0 !important;
  height: 30px;
  padding: 0 1rem !important;
  display: flex;
  align-items: center;
}

.recenterButtonPopover:after {
  margin-right: 5px !important;
}

.recenterButton {
  width: 30px;
  height: 30px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border: none;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.recenterButton i {
  color: #666666;
  font-size: 20px;
}
.recenterButton:hover > i {
  color: #2A353E;
}

