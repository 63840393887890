@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.body {
  background-color: white;
  margin: 5px;
  padding: 5px;
  width: 100%;
}

.details {
  display: flex;
  flex-direction: row;
}

.eta {
  align-items: center;
  background-color: $gray-100;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 90px;
  white-space: nowrap;
  margin-right: 10px;
}

.metrics {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 0 10px 0;
  width: 100%;
}

.flexItem {
  display: flex;
}

.baseInfoPaneBtns {
  display: flex;
  flex-direction: row;

  :global(button.tt-btn) {
    text-align: center;
    width: 49%;
  }
}

.infoPaneBtns {
  @extend .baseInfoPaneBtns;
  margin-top: 20px;
  justify-content: flex-start;
}

.plantPaneBtns {
  @extend .baseInfoPaneBtns;
  justify-content: center;

  :global(button.tt-btn) {
    text-align: center;
    min-width: 233px;
  }
}

.buttonHover {
  color: $gray-900 !important;
  font-family: $font-family-bold !important;
  padding: 7px 12px 8px 13px !important;
  margin: 0 0 -5px 0 !important;
  min-height: 2rem !important;

  &:hover {
    background: $gray-100 !important;
  }

  &:active {
    background: $gray-200 !important;
  }

  &:focus {
    outline: none !important;
  }
}

.infoPaneBtns> :first-child {
  margin-right: 10px;
}

.orderFooter>.orderDetailsBtn {
  color: $gray-900 !important;
  font-family: $font-family-bold !important;
  padding: 7px 12px 8px 13px !important;
  margin: 0 0 -5px 0 !important;
  min-height: 2rem !important;

  &:hover {
    background: $gray-100 !important;
  }

  &:active {
    background: $gray-200 !important;
  }

  &:focus {
    outline: none !important;
  }
}

.truckFooter>.truckDetailsBtn {
  color: $gray-900 !important;
  font-family: $font-family-bold !important;
  padding: 7px 12px 8px 13px !important;
  margin: 0 0 -5px 0 !important;
  min-height: 2rem !important;

  &:hover {
    background: $gray-100 !important;
  }

  &:active {
    background: $gray-200 !important;
  }

  &:focus {
    outline: none !important;
  }
}

.orderFooter>.orderDetailsBtn>i {
  color: $darkblue !important;
}

.plantSendMessageBtn {
  font-family: $font-family-bold !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.54 !important;
  letter-spacing: normal !important;
  color: #151f28 !important;
  border-radius: 3px !important;
}

.flagIcon {
  font-size: 1.8rem;
  display: inline-block;
}

.dotIcon {
  font-size: 1.6rem;
  display: inline-block;
}

.titleText {
  display: inline-block;
  transform: translateY(4px);
  font-family: $font-family-bold;
}

.orderContainer {
  width: max-content !important;
}

.orderContainer .orderDetailsBtn:last-of-type {
  margin-left: 10px !important;
}

.leftColumn {
  width: 80px;
  font-weight: bold;
  margin-right: 16px;
}

.popupItem {
  display: flex;
  margin-bottom: 8px;
}

.popupItem:last-child {
  margin-bottom: 0px;
}

.details .metrics {
  padding: 0;
}

table.routePopup {
  margin: 0 -10px;
  border-spacing: 20px 1rem;
}