@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.topImg {
  width: 100%;
}

.topImg img {
  width: 100%;
  height: auto;
}

.headerAndContainer {
  height: 100%;
}

.header {
  font-family: $font-family-bold !important;
  margin: 0;
  line-height: 1;
  padding: 0;
  white-space: normal;
  word-wrap: break-word;
}

.divider {
  border: 0;
  background-color: $orange;
  width: 100px;
  height: 4px;
}

.cardStyle {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  box-shadow: $boxshadow-medium;
}

.tableContainer {
  height: auto;
  width: 100%;
  max-width: 1000px;
  overflow-x: auto;
  overflow-y: auto;
  padding: 0;
  border-collapse: collapse;

  table {
    border: 1px solid $gray-200;
  }
}

.tableContainer,
.tableContainer th,
.tableContainer td {
  border: none;
  padding: 0;
}

.tableContainer td,
.tableContainer th {
  padding: 0 0 0 11px;
}

.tableContainer td a {
  font-weight: bold;
  font-size: 16px;
}

.tableContainer tbody tr {
  padding: 0.5rem 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  &:not(:last-of-type) {
    border-bottom: 1px solid $gray-200;
  }
}

.tableContainer tbody tr:hover {
  background: white !important;
  cursor: default !important;
}

.instructionDocuments {
  font-family: $font-family-bold;
  line-height: 1;
}

.openPdfButton {
  width: 66%;
}

.openPdfButton button {
  background-color: transparent;
  border: transparent;
  cursor: pointer;
  transition: $transition;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 18px 0 12px;
  border-radius: $border-radius;

  .pdfLogo,
  .pdfLogo img {
    width: 28px !important;
    height: auto !important;
    margin-right: 16px !important;
    transition: $transition;
    display: inline-block;
    transform: translateY(1px);
  }

  .itemNameCtr {
    color: $darkblue;
    font-size: 1.6rem;
    line-height: 1.1;
    text-align: left;
  }

  .itemName {
    font-family: $font-family-bold;
  }

  &:hover .itemName {
    text-decoration: underline;
  }

  &:focus {
    outline: none;
  }
}

.downloadButton {
  display: flex !important;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
  margin-top: 3px;
  margin-right: 10px;
}

.downloadButton button {
  margin: 1px 3px 0 1px;
  cursor: pointer;
  background: transparent;
  border: transparent;
  font-size: 14px;
  font-weight: bold;
  transition: $transition;
  border-radius: $border-radius;
  padding: 2px 14px 0 14px;
  height: 41px;
  white-space: nowrap;

  &:hover {
    background: $gray-100;
  }

  &:active {
    background: $gray-200;
  }

  &:focus {
    outline: none;
  }

  &.buttonDownloading {
    background: transparent;
    cursor: default;
  }
}

.downloadButton button i {
  display: inline-block;
  vertical-align: middle;
  color: $darkblue;
  display: inline-block;
  transform: translateY(-3px);
}

.downloadButton button span {
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
  font-family: $font-family-bold;
  transition: $transition;
  display: inline-block;
  transform: translateY(-3px);
}

.downloadButtonLoading {
  display: inline-block !important;
  transform: translateY(-4px) !important;
  margin-left: -1px !important;
}

.downloadAllZip {
  display: flex !important;
  margin-top: 20px;
  margin-right: -25px;
  padding: 0 24px;
  float: right;
}

.videosList {
  display: block;
}