@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '../../../styles/geotrax_vars.scss';

.adminPanelContent {
  margin-top: $panelContentMarginOffset;
  display: flex;
  flex-flow: column;
  height: calc(100vh - 150px);
}

.reportsBtnContainer {
  margin: 16px 0;
  padding: 0 3.2rem;
}

.reportsBtn {
  width: 100%;
  font-size: 1.6rem;
  background: transparent;
  color: $white;
  border: none;
  background: $gray-800;
  box-shadow: $button-shadow-dark !important;
  border-radius: 3px;
  padding: 0.1rem 1.3rem 0.35rem 1.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  height: 4rem;
  transition: $transition;

  &:hover {
    cursor: pointer;
    background: $gray-700;
  }

  &:active {
    background: $white;
    color: $gray-900;
  }

  &:focus {
    outline: none;
  }
}

.reportsBtnIcon {
  padding-right: 5px;
}

.reportsModal {
  width: 26%;
  min-width: 500px !important;
}

.logoutRegionBody {
  white-space: pre-wrap;
  margin: 0;
  padding: 0;

  b {
    font-family: 'PublicSans Bold';
  }
}

.companyToolHeader {
  width: 100%;
  font-size: 1.3rem;
  color: $gray-400;
  font-family: $font-family-bold;
  font-weight: bold;
  padding: 1.3rem 0 0.5rem 0;
  line-height: 1;
  border-bottom: 1px solid $gray-600;
  display: flex;
  justify-content: left;
  margin-bottom: 14px;
}

.adminPanelLowerContent {
  flex: 1;
  overflow-y: auto;
  @include niceScrollbar;
}