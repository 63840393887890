@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.geozonesAddModal {
  @extend %adminModalWidth;
}

.map {
  margin-bottom: 34px;
}

.map label:after {
  content: '*';
  font-size: 1.8rem;
  display: inline-block;
  transform: translateY(3px);
  color: $red;
  margin-left: 0.3rem;
}