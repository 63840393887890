.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-center,
.flex-horizontal-center,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.header {
  display: flex;
  flex-direction: row;
}

.header h3 {
  margin: 3px 0 0 0;
}

.uomContainer {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.uomContainer .label {
  font-family: "PublicSans Bold", Helvetica, sans-serif;
  font-size: 13px;
  margin-right: 7px;
}

.uomContainer > div > div {
  background: transparent !important;
  box-shadow: none !important;
  border: none !important;
  font-size: 12px !important;
  line-height: 23px !important;
  height: 22px !important;
  padding-top: 0 !important;
}

.uomContainer > div > i {
  top: 5px !important;
}

.scaleContainer {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.14);
  background-color: #fff;
  margin-top: 7px;
  min-height: 320px;
}

.scaleContainer .container {
  display: flex;
  align-items: center;
  padding: 16px 20px;
}

.scaleDivider {
  border-left: 1px solid #c8cdd0;
  min-height: 200px;
}

.scaleRectangleContainer, .scaleDashesContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 50%;
  max-width: 185px;
}

.scaleElementRectangle {
  display: flex;
  align-items: center;
  margin: 0 0 10px 0;
  background-color: #fff;
  position: relative;
  width: 100%;
  height: 60px;
  max-width: 185px;
}

.scaleElementRectangle input {
  text-align: right;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 0;
  padding-top: 22px !important;
  padding-right: 36px !important;
  font-size: 19px !important;
  background-color: #fff !important;
}

.scaleElementRectangleTare {
  display: flex;
  align-items: center;
  margin: 0 0 10px 0;
  background-color: #fff;
  position: relative;
  width: 100%;
  height: 60px;
  max-width: 185px;
}

.scaleElementRectangleTare input {
  text-align: right;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 0;
  padding-top: 22px !important;
  padding-right: 36px !important;
  font-size: 19px !important;
  background-color: #ffecf0 !important;
}

.scaleElementRectangleTare label {
  position: absolute;
  top: 12px;
  left: 14px;
  font-size: 14px;
  z-index: 1;
}

.scaleElementRectangle label {
  position: absolute;
  top: 12px;
  left: 14px;
  font-size: 14px;
  z-index: 1;
}

.scaleDashesContainer {
  padding: 0 20px;
}

.scaleDashesRectangle {
  width: 100%;
  height: 59px;
  margin: 0 0 10px;
  padding: 8.3px 14px 8px;
  border-radius: 4px;
  border: solid 1px #e1e4e7;
  background-color: #fff;
  color: #151F28;
}

.scaleDashLabel {
  font-weight: bold;
  font-size: 16px;
}

.scaleDashUOM {
  color: #151F28;
  font-size: 12px;
  text-align: right;
}

.Gross {
  width: 41px;
  height: 21px;
  margin: 0 52px 22px 0;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #151F28;
}

.Tare {
  width: 29px;
  height: 21px;
  margin: 0 64px 21.7px 0;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #151F28;
}

.Net {
  width: 23px;
  height: 21px;
  margin: 0 70px 22.7px 0;
  font-family: Helvetica;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #151F28;
}

.tareButton {
  width: 100%;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.scaleWeight {
  width: 37px;
  height: 28px;
  margin: 15px 0 0 52px;
  font-family: "PublicSans Regular", Helvetica, Arial, sans-serif;
  font-size: 19px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #151F28;
}

.VehicleScaleLabel {
  width: 74px;
  height: 20px;
  margin: 0 156px 7px 0;
  font-family: "PublicSans Regular";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2px;
  color: var(--gray-600);
}

.uom {
  color: #151F28;
  font-size: 12px;
  flex-shrink: 0;
  flex-grow: 0;
  flex-basis: 3em;
  margin-left: 0.3em;
  margin-top: 24px;
  z-index: 1;
  right: 14px;
  position: absolute;
}

.tareContainer {
  margin-left: 20px;
  display: flex;
  align-items: center;
  width: 50%;
}

.tareDetails > div {
  text-transform: uppercase !important;
  line-height: 1.25;
  font-size: 1.3rem !important;
}

.truckLabelText,
.tareLabelText {
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif !important;
}

.truckLabelText {
  font-size: 1.6rem !important;
}

.tareLabelText {
  font-size: 1.3rem !important;
  margin: 1.2rem 0 0 0 !important;
}

.tareRequiredText {
  color: #BD0029;
  font-size: 12px;
  line-height: 1.25;
}

.tareExpireText {
  color: #BD0029;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif !important;
  font-size: 1.3rem !important;
}

.tareManualText {
  text-transform: none;
  font-size: 12px;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  font-style: italic;
  color: #151F28;
}

.weightAlert, .weightWarning, .weightAlertCaps {
  min-height: 28px;
  background-color: #ffecf0;
  margin: 6px;
  margin-bottom: 0;
  border-radius: 4px;
  padding: 6px 10px;
  text-align: center;
  color: #bd0029;
  font-weight: bold;
  font-size: 11px;
  text-transform: uppercase;
}

.weightAlertCaps {
  text-transform: uppercase;
}

.weightWarning {
  background-color: #FDF3E2;
  color: #C85000;
}

.loadingCircle {
  vertical-align: top;
}

