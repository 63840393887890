@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.wrapper {
  display: flex;
  flex-wrap: wrap;

  >div {
    flex-grow: 1 !important;
    height: auto !important;
    width: 33% !important;
    @extend %ifValueEmpty;

    &:first-of-type,
    &:last-of-type {
      width: 100% !important;
    }

    &:first-of-type {
      p {
        font-family: $font-family-bold !important;
      }
    }
  }

  .buttonClass {
    margin-bottom: -2rem !important;
    transform: translateY(-0.4rem) !important;
    text-align: left !important;
  }

  &.editWrapper {
    >div {

      &:not(:last-of-type),
      &:first-of-type,
      &:last-of-type {
        width: 100% !important;
        max-width: 500px;
      }
    }

    .externalId {
      display: none !important;
    }

    .tareExpirationDays>div {
      width: 170px !important;
      max-width: 170px !important;
    }
  }
}

.unassignButton {
  &:hover {
    color: $darkblue !important;
    text-decoration: underline !important;
  }
}

.label {
  display: block;
  margin: 0 0 0.8rem 0;
  line-height: 1;
  font-size: 1.3rem;
  font-family: $font-family-bold;
  color: $gray-900;
  white-space: nowrap;
}

.asterisk {
  color: $red;
  font-family: $font-family-bold;
  font-size: 1.8rem;
  display: inline-block;
  transform: translateY(3px);
  margin-left: 0.3rem;
  line-height: 0.5;
}

@media (max-width: $breakpoint-admindetails) {
  .wrapper {
    >div {

      &:not(:last-of-type),
      &:first-of-type,
      &:last-of-type {
        width: 100% !important;
      }
    }
  }
}

.ownership {
  [role='radiogroup'] {
    flex-direction: row;
  }
}