@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';

.loading {
  display: inline-block;
  animation: loader-spin .75s infinite linear;
  border-radius: 50%;
  transform: translateX(8px);
  vertical-align: middle;
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}