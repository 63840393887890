.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-center,
.flex-horizontal-center, .featureFooter,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.featureFooter,
.flex-space-between {
  justify-content: space-between;
}

.featureFooter,
.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.plusButton {
  display: flex !important;
  margin-right: 20px;
  box-shadow: none !important;
  justify-content: flex-end;
  background: transparent;
  float: right;
  border: none;
}

.textRight {
  float: right;
  text-align: right;
}

.textRed {
  color: #BD0029;
}

.textTitle {
  font-weight: bold;
}

.marginRight {
  margin-right: 20px !important;
}

.marginLeft {
  margin-left: 5px !important;
}

.marginTop {
  margin-top: 25px;
  margin-bottom: 5px;
}

.descInput {
  margin-top: 25px !important;
  width: 90%;
}

.featureFooter {
  margin-top: 24px;
  position: relative !important;
  padding: 12px 24px;
  font-size: 16px;
  border-top: 1px solid #e1e4e7;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  padding: 2.2rem;
  background: #e9edf0;
}

.titleInput {
  width: 50%;
  padding: 0 24px;
  margin-top: 25px !important;
}

.descInput {
  width: 100%;
  padding: 0 24px;
}

.permissionsLabel {
  padding: 0 24px;
  font-size: 16px;
  color: #636b71;
  margin-top: 25px !important;
}

.flagsDivider {
  margin: -1px 24px 24px;
}

.composeMessage textarea {
  background: white !important;
  border: 1px solid #cfd6da;
  resize: none;
  box-sizing: border-box;
}

.fontFam {
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
}

.permissionsContainer {
  padding: 5px;
  height: 350px;
  padding: 0 24px 24px;
}

.noFeatures {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 88px 0;
  overflow: hidden;
  margin: 0;
  background-color: #f4f7f9;
  font-size: 16px;
}

.textBlocksContainer {
  transform: translateY(-2.5%);
  color: #151F28;
}

.textBlock,
.iconBlock {
  display: block;
  text-align: center;
  position: relative;
}

.iconBlock {
  margin-bottom: 10px;
}

.dropDownListContainer {
  width: 17%;
  min-width: 130px;
  vertical-align: top;
}

.searchContainer {
  vertical-align: middle;
  flex: 0 1 100%;
  background: white;
}

.dropDownSearchContainer {
  width: 100%;
  display: flex;
}

.table {
  table-layout: fixed;
}

.tableContainer {
  height: auto;
  max-height: 298px;
  width: 100%;
  overflow-y: auto;
  padding: 0;
  border: 1px solid #e1e4e7;
  margin-top: 12px;
}
.tableContainer .displayUnset {
  display: initial !important;
}
.tableContainer td {
  height: 4rem !important;
  padding: 0 0 0 13px !important;
  line-height: 1 !important;
  border-bottom: none !important;
  border-top: 1px solid #e1e4e7;
  display: table-cell;
  vertical-align: middle;
}
.tableContainer td i {
  transform: translateY(1px) !important;
  display: inline-block !important;
}
.tableContainer th {
  padding: 10px 13px !important;
  line-height: 1 !important;
  background: #f4f7f9;
  position: sticky !important;
  top: 0 !important;
  box-shadow: 4px 1px 5px 0px rgba(0, 0, 0, 0.15), 3px 2px 2px 0px rgba(0, 0, 0, 0.09), 2px 3px 1px -2px rgba(0, 0, 0, 0.07) !important;
}

.cellText {
  font-size: 1.6rem;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  height: auto;
  line-height: 4rem;
}

.closeButton i {
  color: #878e93 !important;
  font-size: 1.6rem;
}
.closeButton:hover > i {
  color: #BD0029 !important;
}

.removeAllBtn {
  float: right;
}
.removeAllBtn span {
  transform: translateY(-1px) !important;
  display: inline-block;
}
.removeAllBtn:hover > i, .removeAllBtn:hover > span {
  color: #BD0029 !important;
}

