@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.haulersAddModal {
  @extend %adminModalWidth;
}

.formContainer {
  display: grid;
  grid-gap: 28px;
  grid-template-columns: repeat(4, 1fr);
  margin: 30px auto;
}

.fullWidth {
  grid-column: 1 / 4;
}

.city {
  grid-column: 1 / 3;
}

.state {
  grid-column: 3;
  min-width: 72px;
}

.zip {
  grid-column: 4;
  min-width: 72px;
}