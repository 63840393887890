.autocompleteContainer {
  position: relative;
}
.autocompleteContainer input::placeholder {
  color: #878e93 !important;
  opacity: 1 !important;
}

.openSelection {
  position: relative;
  background-color: #f4f7f9;
  height: 40px;
  display: flex;
  border: 2px solid #008EF3 !important;
  border-radius: 3px;
  font-size: 16px;
  align-items: center;
  padding-left: 9px;
  padding-bottom: 1px;
  color: #a5abaf;
}

.openSelection span:nth-child(1) {
  width: calc(100% - 50px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #878e93 !important;
}

.openSelectionIcon {
  position: absolute;
  right: 6px;
  top: 12px;
}

.searchOpen {
  width: 100% !important;
  margin-top: 3px;
  display: flex;
  box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.08), 0 5px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.15);
}
.searchOpen > div > div fieldset {
  border: none;
}
.searchOpen > div > div input {
  font-size: 16px;
  padding-top: 0 !important;
  margin-top: -2px;
}

.searchOpen .iconRight {
  display: none;
}

.searchOpen i,
.searchTextOpen {
  background-color: white !important;
  align-self: center;
  z-index: 1;
  height: 40px !important;
}

.searchOpen i {
  font-size: 19px;
  line-height: 40px;
  border-radius: 3px 0 0 0;
  background-color: white;
  width: 40px;
  text-align: center;
  color: #636b71;
  border-top: 2px solid #008EF3;
  border-left: 2px solid #008EF3;
  border-bottom: 2px solid #008EF3;
}
.searchOpen i > div > div > div > button:last-of-type {
  display: none;
}

.searchClosed,
.searchClosedWithValue {
  background-color: #f4f7f9;
  cursor: pointer;
}
.searchClosed > div > div:hover > fieldset,
.searchClosedWithValue > div > div:hover > fieldset {
  border-color: #e1e4e7 !important;
}
.searchClosed > div > div > fieldset,
.searchClosedWithValue > div > div > fieldset {
  border-color: #e1e4e7 !important;
}
.searchClosed > div > div > div > button:last-of-type,
.searchClosedWithValue > div > div > div > button:last-of-type {
  pointer-events: none;
}

.searchClosed :hover {
  cursor: pointer;
}

.searchClosed button,
.searchClosedWithValue button {
  margin-right: 7px;
}

.searchClosedWithValue button:nth-child(1) {
  visibility: visible;
  border-radius: 50%;
  background-color: white;
  width: 20px;
  height: 20px;
  padding: 6px;
  right: 0px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.35);
}

.searchClosedWithValue button:nth-child(1):hover {
  background-color: #f4f7f9;
}

.searchClosedWithValue button:nth-child(1) svg {
  height: 14px;
  width: 14px;
  color: #C85000;
  margin-right: 1px;
}

.searchTextOpen {
  background-color: white !important;
  border-top: 2px solid #008EF3 !important;
  border-right: 2px solid #008EF3 !important;
  border-bottom: 2px solid #008EF3 !important;
  border-radius: 0 3px 0 0;
  padding: 0 !important;
}

.searchTextOpen > div {
  height: 100%;
  margin: 0 !important;
}

.searchTextOpen svg {
  visibility: hidden;
  display: none;
}

.searchTextClosed,
.searchTextClosedWithValue {
  border-radius: 3px;
  height: 40px !important;
}
.searchTextClosed input,
.searchTextClosedWithValue input {
  font-size: 16px;
}

.searchTextClosed input,
.searchTextClosedWithValue input {
  padding-right: 23px !important;
}

.searchTextClosed > div,
.searchTextClosedWithValue > div {
  height: 40px !important;
  padding-top: 3px !important;
}

.autocomplete {
  width: 100% !important;
  display: block;
  position: relative;
}
.autocomplete input {
  padding: 5.5px 5px 0 5px !important;
}
.autocomplete.emptySelection div[class*=MuiAutocomplete-endAdornment] {
  pointer-events: none;
}

.autocomplete > div[role=presentation] {
  background-color: white !important;
  border: none;
  margin: 0 0 0 1px !important;
  width: calc(100% - 2px) !important;
  box-shadow: 0 1px 15px 0 rgba(0, 0, 0, 0.08), 0 5px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  translate: -18px 4px;
}
.autocomplete > div[role=presentation][data-popper-placement=top] {
  translate: -18px -4px;
}

.reactNode {
  margin: -6px -16px -6px -16px;
  width: calc(100% + 32px);
  height: 100%;
  padding: 6px 16px 6px 16px;
  background-color: white;
  cursor: default !important;
}

.reactNode :hover {
  background-color: white !important;
  cursor: default !important;
}

.validationError {
  background-color: #FFECF0;
  border-color: #E92851 !important;
}

.iconRight {
  pointer-events: none;
  color: black;
  transform: rotate(-90deg) translateY(-50%);
  margin-right: -8px;
  width: 32px;
  margin-right: -18px;
}
.iconRight:before {
  zoom: 0.5;
  margin-left: -2px;
}

