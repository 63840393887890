@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

$row-divider-height: 4px;

.headerTitle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  line-height: 18px;
}

.isSelected {
  cursor: pointer;
  padding-right: 20px;
  padding-left: 10px;
  letter-spacing: 2px;
  transition: $transition;
  color: $white;

  &:hover {
    opacity: 0.6;
  }
}

.isNotSelected {
  letter-spacing: 2px;
  padding-right: 15px;
  padding-left: 10px;
  font-family: $font-family-bold;
  font-size: 1.3rem;
  color: $white;
}

.ticketNumber {
  letter-spacing: 2px;
  font-family: $font-family-bold;
  font-size: 1.4rem;
  color: $white;
}

.ticket {
  font-size: 16px;
}

.paper {
  top: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  pointer-events: all;
  max-width: 100% !important;
  max-height: 100% !important;
}

.expanded {
  min-height: 95% !important;
  height: 95% !important;
  min-width: 95% !important;
  width: 95% !important;
  left: 2.5% !important;
  top: 2.5% !important;
  border-radius: $border-radius !important;
}

.header {
  font-size: 1.5rem;
  display: flex;
  width: 100%;
  background-color: $blue;
  border-radius: 0;
  justify-content: space-between;
  align-items: center;
  padding: 7px 7px 7px 12px;
  color: $gray-900;
  height: 55px;
}

.ticketsPopoverContainer {
  position: absolute;
  left: 306px;
  right: 0;
  bottom: 0;
  height: 40%;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
  z-index: 999;
}

.fullTicketBlockWidth {
  left: $header-height;
}

.ticketsPopoverContainerExpanded {
  position: absolute;
  z-index: 999;
  background: rgba(0, 0, 0, 0.8);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.ticketsPopoverContainerRouteHistory {
  position: absolute;
  left: 62px;
  right: 0;
  bottom: 0;
  height: 40%;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
  z-index: 999;
}

.expandedPopover {
  width: 95% !important;
  height: 95% !important;
  left: 2.5%;
  top: 2.5%;

  .header {
    border-radius: $border-radius $border-radius 0 0 !important;
  }

  :global(.MuiPaper-root) {
    border-radius: 0 0 $border-radius $border-radius !important;
  }
}

.ticketsPopover {
  position: relative !important;
  width: 100%;
  height: 100%;

  .datablock-info-close {
    padding: 0 1rem;

    i {
      font-size: 1.6rem;
    }
  }

  .datablock-info-close:hover {
    opacity: 0.5;
    transition-duration: 0.3s;
  }

  .ticketHeader {
    background-color: $white;
    font-weight: 900;
  }

  :global(.-sort-desc.-cursor-pointer .rt-resizable-header-content:after) {
    width: 0;
    height: 0;
    font-family: 'icomoon';
    content: '\e939'; // icomoon - icon-expand-more
    color: $orange;
    position: relative;
    top: 0;
    margin-left: 10px;
    font-size: 8px;
    overflow: hidden;
  }

  :global(.-sort-asc.-cursor-pointer .rt-resizable-header-content:after) {
    width: 0;
    height: 0;
    font-family: 'icomoon';
    content: '\e938'; // icomoon - icon-expand-less
    color: $orange;
    position: relative;
    top: 0;
    margin-left: 10px;
    font-size: 8px;
    overflow: hidden;
  }

  :global(.-cursor-pointer .rt-resizable-header-content:after) {
    width: 0;
    height: 0;
    font-family: 'icomoon';
    content: '\e939'; // icomoon - icon-expand-more
    color: $gray-400;
    position: relative;
    top: 0;
    margin-left: 10px;
    font-size: 8px;
    overflow: hidden;
  }

  :global(.driver-cell),
  :global(.vehicle-cell) {
    border-bottom: solid $row-divider-height white;
  }

  :global(.vehicle-header) {
    border-right: 1px solid $gray-200 !important;
  }

  :global(.driver-cell:after) {
    height: calc(100% + 4px);
    top: 0;
    right: -15px;
    position: absolute;
    width: 15px;
    content: ' ';
  }

  :global(.driver-header:after) {
    height: calc(100% + 1px);
    bottom: -1px;
    right: -15px;
    box-shadow: 6px 0 6px -6px inset rgba(0, 0, 0, 0.4);
    position: absolute;
    width: 15px;
    content: ' ';
  }

  :global(.rt-tr--add) {
    animation-name: rowAnim;
    animation-duration: 0.4s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-direction: reverse;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-play-state: running;
  }

  :global(.rt-tr--remove) {
    animation-name: rowAnim;
    animation-duration: 0.4s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-direction: normal;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    animation-play-state: running;
  }
}

@keyframes rowAnim {
  0% {
    height: 31px;
  }

  100% {
    height: 0px;
  }
}