@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';

.container {
  padding-top: 0.5rem;
  padding-right: 2.2rem;
  padding-bottom: 0.5rem;
  padding-left: 2.2rem;
}

.bubble {
  background: $blue;
  border-radius: .3rem;
  padding: 1.2rem 1.6rem 1.4rem 1.6rem;
  position: relative;
  display: flex;
  margin-left: 40%;

  &.sent {
    margin-left: auto;
    margin-right: 40%;
  }

  &.longMessage {
    margin-left: auto;
    margin-right: auto;
  }

  &.shrinkAndKeepLeft {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 0px;
    width: 55px;
  }
}

.longMessage {
  margin-left: 100%;
  margin-right: 100%;
}

.sent {
  background: $gray-200;
}

.content {
  text-align: left;
  position: relative;
  z-index: 2;
  color: white;
  font-size: 1.6rem;
  line-height: 1.3;
  hyphens: auto;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;

  &.sent {
    color: black;
  }
}

.arrow {
  position: absolute;
  bottom: -0.8rem;
  border: solid transparent;
  content: " ";
  border-radius: .3rem;
  transform: rotate(45deg);
  background-color: $blue;
  width: 2rem;
  height: 2rem;
  z-index: 1;

  &.left {
    left: 1.5rem;
  }

  &.right {
    right: 1.5rem;
  }
}

.author {
  color: $gray-600;
  font-size: 1.3rem;

  &.left {
    text-align: left;
  }

  &.right {
    text-align: right;
  }
}