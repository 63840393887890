@import "../../../node_modules/@trucktrax/trucktrax-common/src/styles/tt_vars.scss";
@import "../../../node_modules/@trucktrax/trucktrax-common/src/styles/tt_layout.scss";

.unreadNotice {
  position: absolute;
  top: 4px;
  right: 4px;
  width: 1rem;
  height: 1rem;
  @extend %flex;
  @extend %flexCenter;
  border-radius: 2rem;
  background: $error;

  span {
    font-size: 1rem !important;
    color: $white !important;
  }
}