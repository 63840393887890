@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.topHeader {
  padding: 10px 31px;
  height: auto !important;
  margin: auto;
  position: relative;
  flex: 0 1 auto;
  z-index: 1;
  width: 100%;
  border-radius: 0 !important;
  box-shadow: none !important;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15),
    0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 3px 1px -2px rgba(0, 0, 0, 0.07) !important;
}

:global(.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded) {
  min-width: 265px !important;
  width: 528px;
  max-width: 100%;

  span.DropDownList_secondaryLabel {
    color: black;
  }
}

h3 {
  margin: 0;
}

.topHeader h4 {
  font-size: 14px;
  font-weight: bold;
  line-height: 21px;
  margin: 0 0 -2px 0;
}

.leftHeader {
  width: 50%;
  padding: 14px 0px;

  @media (max-width: 1250px) {
    width: 38%;
  }
}

.rightHeader {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 0 !important;
  flex: 1 !important;
  width: 60% !important;
  padding-left: 24px;
}

.header h3 {
  margin: 3px 0 0 0;
}

.innerHeaderStyle {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 48px;
  padding-right: 48px;
  display: flex;
}

.header {
  margin: 0;
}

.bodyWrapper {
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

.cardWrapper {
  padding: 20px 31px;
  position: relative;
  background: $gray-100;
  margin-right: -20px;
}

.cardContents {
  grid-gap: 28px;
  padding: 16px 32px 24px;
}

.productAddressBox {
  width: 100%;
  height: 350px;
  margin: 17px 0px 0 0;
}

.city {
  width: 100%;
}

.state {
  padding: 0 0 0 10px;
  margin: 0;
  width: 100%;
}

.zip {
  min-width: 122px;
  padding: 0 0 0 10px;
}

.VehicleScaleLabel {
  width: 74px;
  height: 20px;
  margin: 0 156px 7px 0;
  font-family: 'PublicSans Regular';
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 2px;
  color: var(--gray-600);
}

.scaleSectionInput {
  padding-right: 32px;
}

.leftLine {
  width: 1px;
  height: 306px;
  margin: 15px 19px 0 0;
  border-left: solid 1px #e1e4e7;
}

.scaleSection {
  flex: 1;
  padding-left: 24px;
}

.vehicleSection {
  padding-right: 24px;
  flex: 1;
  vertical-align: top;
}

.orderJobSection {
  padding-left: 24px;
  flex: 1;
}

.customerSection {
  padding-right: 24px;
  flex: 1;
}

.buttonsContainer {
  display: flex;
  margin-top: 17px;

  .sectionLeftPaddingFullWidth {
    flex: 1;
    width: 100%;
    padding-left: 8px;
  }

  .sectionRightPaddingFullWidth {
    flex: 1;
    width: 100%;
  }
}

.container {
  display: flex;

  .section {
    flex: 1;
    padding: 0 10px;
  }

  .sectionNoPadding {
    flex: 1;
  }
}

.previousTicket {
  width: 100%;
}

.importAuto {
  width: 100%;
}

.printerDropdown {
  display: inline-block;
}

.addQtyBtn {
  border-radius: 100px !important;
  background-color: #f4f7f9 !important;
  min-width: 0px !important;
  min-height: 0px !important;
  width: 16px !important;
  height: 16px !important;
  margin: 0px 0px 0px 0px !important;
  padding: 0px 0px 0px 0px !important;
  line-height: 16px;
}

.addQtyBtn i {
  font-size: 8px !important;
  color: blue !important;
  display: block;
}

.printQuantityDiv {
  display: flex;
  margin-top: 10px;
  align-items: center;
}

.printCountQty {
  height: 11px !important;
  font-size: 11px;
  font-weight: 800;
  line-height: 1;
  margin-right: 6px;
}

.printCount {
  height: 11px !important;
  margin-left: 6px;
  font-size: 11px;
  font-weight: 800;
  line-height: 1;
  margin-right: 6px;
}

.errorLink {
  color: white;
  text-decoration: underline;
  text-decoration-thickness: 2px;
}

.printButtonTicketing {
  background-color: $orange !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.printButtonTicketing:hover {
  background-color: $orange-hover !important;
}

.printButtonTicketing:disabled {
  background-color: #f4f7f9 !important;
  color: #878e93 !important;
  box-shadow: none !important;
  cursor: not-allowed !important;
}

.printButtonTicketing:disabled span {
  color: #878e93 !important;
}

.printButtonTicketing:disabled i {
  color: #878e93 !important;
}

.scaleTraxCard {
  max-width: 1200px !important;
  padding-bottom: 20px;
}

.formGroup {
  margin: 20px 0;
}

.formCity {
  margin: 0;
  width: 100%;
}

.saveAddQueueBtnGroup {
  display: flex;
}

.printSection {
  border-left: 1px solid #c8cdd0;
  padding-left: 16px;
  margin-left: 16px;
}

.labelText {
  margin: 0 0 0.8rem 0;
  line-height: 1;
  font-size: 1.3rem;
  font-family: $font-family-bold;
  color: $gray-900;
  white-space: normal !important;
  flex-basis: 6em;
}

.scaleLabelText {
  margin: 0.5em;
}

.tareExpireText {
  color: $darkred;
  font-family: $font-family-bold !important;
  font-size: 1.3rem !important;
}

.asterisk {
  color: $red;
  font-family: $font-family-bold;
  font-size: 1.8rem;
  display: inline-block;
  transform: translateY(3px);
  margin-left: 0.3rem;
  line-height: 0.5;
}

.cardRadio {
  display: flex;
  align-items: flex-end;
}

.cardRadioLabel {
  margin-right: 5px;
}

.disabledClickIcon {
  cursor: not-allowed !important;
}

.lockPopper {
  z-index: 9999 !important;
  opacity: 1 !important;
  margin-left: -20px;
}

.lockTooltip {
  background: $info !important;
  padding: 0.6rem 1rem !important;
  font-size: 1.3rem !important;
  line-height: 1.3 !important;
  white-space: nowrap;
  border-radius: $border-radius !important;
  pointer-events: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.tabsContainer {
  grid-column: 1 / span 4;
  overflow-x: auto;
  border-bottom: 1px solid $gray-300;

  .notesTabButton {
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      font-family: $font-family-bold;
    }

    & img {
      margin-left: 4px;
      width: 14px !important;
      height: 14px !important;
    }
  }
}

.orderAutocomplete {
  margin-bottom: 0;
  height: 143px;
}

.paymentTypeCertSection {
  flex: 1;
  width: 50%;
}

.deliveryTypeJobNumberSection {
  flex: 1;
  padding-right: 24px;
  width: 50%;
}

.orderAutocomplete .searchAutocomplete>div> :nth-child(2) {
  width: 820px !important;
  position: absolute;
  right: 0;
}

.orderAutocompleteTableHeader {
  pointer-events: none;

  div {
    font-weight: bold;
    font-family: $font-family-bold;
  }
}

.orderAutocompleteTableLine {
  display: flex;
  padding: 8px 12px;
  cursor: pointer;

  &:hover {
    background-color: $gray-100;

    &.selected {
      // match color to what is applied in default MUI autocompletes (those which do not use custom render function)
      background-color: rgba(0, 142, 243, 0.12);
    }
  }

  &.selected {
    // match color to what is applied in default MUI autocompletes (those which do not use custom render function)
    background-color: rgba(0, 142, 243, 0.08);
  }
}

.orderAutocompleteTableLine>div {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 198px;
  margin: 0;
  padding: 0 10px 0 0;
}

.orderAutocompleteTableLine>div:nth-child(1) {
  flex: 1;
}

.orderAutocompleteTableLine>div:nth-child(2),
.orderAutocompleteTableLine>div:nth-child(6) {
  flex: 2;
}

.orderAutocompleteTableLine>div:nth-child(3),
.orderAutocompleteTableLine>div:nth-child(4) {
  flex: 5;
}

.orderAutocompleteTableLine>div:nth-child(5) {
  flex: 4;
}

.autocompleteGroupTitle {
  color: $gray-600;
  font-size: 12px;
  border-bottom: 1px solid $gray-200;
  padding: 10px 10px 0;
}

.loading {
  min-width: 300px;
  min-height: 200px;
  overflow: hidden;
  align-items: center;
}

.loadingText {
  overflow: hidden;
  font-size: 24px;
  font-weight: bolder;
  text-align: center;
  padding-bottom: 25px;
}

.spinner {
  overflow: hidden;
  padding-bottom: 15px;
  margin-left: 105px;
}

.floatingButton {
  position: fixed !important;
  right: 20px;
  bottom: 55px;
  z-index: 100;
  color: $darkblue !important;

  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;

  text-transform: initial !important;

  background-color: $gray-100 !important;

  i {
    margin-right: 5px;
    margin-top: 2px;
  }
}

.newOrderAlert {
  margin: 5px 0 0px 0px;
  padding: 0 6px 4px 4px;
  font-size: 14px;
  color: $gray-600;
}

.revertOrder {
  font-size: 14px;
  font-weight: bold;
  border: none;
  background-color: white;
  color: #008ef3;
  cursor: pointer;
}

.fieldValidationError {
  color: $darkred;
  font-size: 14px;
  display: block;
  margin: 10px 0;
}

.deliveryTypeDropDown {
  padding-bottom: 12px;
}

.fieldValidationErrorDeliveryType {
  color: $darkred;
  font-size: 14px;
  display: block;
  margin: -10px 0px 0px 0px;
}

.validationError {
  background-color: $xlightred;
  border-color: $red !important;
}

.fieldValidationErrorLarge {
  @extend .fieldValidationError;
  font-size: 20px;
  margin: 5px 0 10px 0;
}

.footer {
  height: 93% !important;
}

.printerList {
  max-width: 180px;
  width: 180px;
}