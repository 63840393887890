@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';

.container {
  flex-grow: 1;
  position: relative;
}

.inputRoot {
  flex-wrap: wrap;
}

.inputInput {
  width: 100%;
  flex-grow: 1;

  &::placeholder {
    opacity: 1 !important;
  }
}

.popover {
  position: absolute;
  display: flex !important;
  flex-direction: column !important;
  align-items: flex-start !important;
  flex-wrap: wrap !important;
  width: 100%;
  overflow: auto;
  z-index: 1;
  margin-top: 5px !important;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.30) !important;
  border-radius: $border-radius !important;
}

.cssFocus,
.notchedOutline {
  border-color: $blue !important;
}

.inputBase fieldset {
  border: none !important;
  background: transparent !important;
}

.segmented {
  border-radius: 0 $border-radius $border-radius 0 !important;
  background: #fff !important;
}

.menuItem {
  transition: $transition !important;
  background: transparent !important;
  font-size: 1.6rem !important;
  height: 40px !important;
  display: flex !important;
  justify-content: flex-start !important;
  width: 100% !important;
  flex-grow: 1 !important;
  padding-left: 11px !important;

  &:hover {
    background: $gray-100 !important;
  }
}

.clearIcon {
  transition: $transition;

  &:hover {
    color: $gray-500;
  }
}