.wrapper > div p:empty::after {
  content: "N/A";
  color: #a5abaf;
}
.wrapper > div p[title="N/A"] {
  color: #a5abaf;
}
.wrapper > div p[placeholder="N/A"] {
  color: #a5abaf;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-center,
.flex-horizontal-center,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
}
.wrapper > div {
  flex-grow: 1 !important;
  height: auto !important;
  width: 33% !important;
}
.wrapper > div:first-of-type, .wrapper > div:last-of-type {
  width: 100% !important;
}
.wrapper > div:first-of-type p {
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif !important;
}
.wrapper .buttonClass {
  margin-bottom: -2rem !important;
  transform: translateY(-0.4rem) !important;
  text-align: left !important;
}
.wrapper.editWrapper > div:not(:last-of-type), .wrapper.editWrapper > div:first-of-type, .wrapper.editWrapper > div:last-of-type {
  width: 100% !important;
  max-width: 500px;
}
.wrapper.editWrapper .externalId {
  display: none !important;
}
.wrapper.editWrapper .tareExpirationDays > div {
  width: 170px !important;
  max-width: 170px !important;
}

.unassignButton:hover {
  color: #0A63F3 !important;
  text-decoration: underline !important;
}

.label {
  display: block;
  margin: 0 0 0.8rem 0;
  line-height: 1;
  font-size: 1.3rem;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  color: #151F28;
  white-space: nowrap;
}

.asterisk {
  color: #E92851;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  font-size: 1.8rem;
  display: inline-block;
  transform: translateY(3px);
  margin-left: 0.3rem;
  line-height: 0.5;
}

@media (max-width: 1440px) {
  .wrapper > div:not(:last-of-type), .wrapper > div:first-of-type, .wrapper > div:last-of-type {
    width: 100% !important;
  }
}
.ownership [role=radiogroup] {
  flex-direction: row;
}

