@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.ticketBtn {
  border-radius: 3px 0 0 3px !important;
  height: 4.2rem;
  padding: 0.3rem 1.3rem 0 1.3rem !important;
}

.ticketBtnDisabled {
  background-color: $gray-400 !important;
  color: white !important;
  box-shadow: inset 0 0 0 1px $gray-500 !important;
  cursor: not-allowed !important;
}

.ticketBtnIcon {
  font-size: 19px !important;
}

.ticketBtnIconDisabled {
  color: white !important;
}

.ticketListContainer {
  height: calc(100% - 55px) !important;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  :global(.ReactTable) {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 30px);
    background-color: $gray-100 !important;
  }

  :global(.ReactTable *) {
    box-sizing: border-box;
  }

  :global(.ReactTable .rt-table) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    border-collapse: collapse;
    overflow: auto;
    flex: auto 0;
  }

  :global(.ReactTable .rt-thead) {
    flex: 0 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    user-select: none;
  }

  :global(.ReactTable .rt-thead.-headerGroups) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  }

  :global(.ReactTable .rt-thead.-header) {
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15),
      0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 3px 1px -2px rgba(0, 0, 0, 0.07);
  }

  :global(.ReactTable .rt-thead .rt-tr) {
    text-align: center;
  }

  :global(.ReactTable .rt-thead .rt-th) {
    border-right: 1px solid $gray-200;
    background-color: $gray-100 !important;
  }

  :global(.ReactTable .rt-thead .rt-th),
  :global(.ReactTable .rt-thead .rt-td) {
    padding: 5px 5px;
    line-height: normal;
    transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    box-shadow: inset 0 0 0 0 transparent;
  }

  :global(.ReactTable .rt-thead .rt-th.-cursor-pointer),
  :global(.ReactTable .rt-thead .rt-td.-cursor-pointer) {
    cursor: pointer;
  }

  :global(.ReactTable .rt-thead .rt-th:last-child),
  :global(.ReactTable .rt-thead .rt-td:last-child) {
    border-right: 0;
  }

  :global(.ReactTable .rt-thead .rt-resizable-header) {
    padding: 10px 0 10px 15px;
    outline: 0;
    text-align: left;
  }

  :global(.ReactTable .rt-thead .rt-resizable-header:last-child) {
    overflow: hidden;
  }

  :global(.ReactTable .rt-thead .rt-resizable-header-content) {
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: $font-family-bold;
  }

  :global(.ReactTable .rt-thead .rt-header-pivot) {
    border-right-color: $gray-100;
  }

  :global(.ReactTable .rt-thead .rt-header-pivot:after),
  :global(.ReactTable .rt-thead .rt-header-pivot:before) {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  :global(.ReactTable .rt-thead .rt-header-pivot:after) {
    border-color: rgba(255, 255, 255, 0);
    border-left-color: $gray-100;
    border-width: 8px;
    margin-top: -8px;
  }

  :global(.ReactTable .rt-thead .rt-header-pivot:before) {
    border-color: rgba(102, 102, 102, 0);
    border-left-color: $gray-100;
    border-width: 10px;
    margin-top: -10px;
  }

  :global(.ReactTable .rt-tbody) {
    flex: 99999 1 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    overflow: visible;
  }

  :global(.ReactTable .rt-tbody .rt-tr-group) {
    background: $white;
    cursor: pointer;
  }

  :global(.ReactTable .rt-tbody .rt-tr:hover .rt-td) {
    background-color: $gray-200;
  }

  :global(.ReactTable .rt-tbody .rt-td) {
    transition-duration: 0.3s;
    padding: 3px 0 5px 15px;
    background: $white;
    cursor: pointer;
    line-height: 2.9em !important;
  }

  :global(.ReactTable .rt-tbody .rt-tr-group.selected) {
    :global(.rt-td) {
      background-color: $xlightblue;
    }
  }

  :global(.ReactTable .rt-tbody .rt-td) {
    padding: 3px 0 5px 15px;
    cursor: pointer;
  }

  :global(.ReactTable .rt-tbody .rt-expandable) {
    cursor: pointer;
    text-overflow: clip;
  }

  :global(.ReactTable .rt-tr-group) {
    flex: 1 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  :global(.ReactTable .rt-tr) {
    flex: 1 0 auto;
    display: inline-flex;
  }

  :global(.ReactTable .rt-th),
  :global(.ReactTable .rt-td) {
    flex: 1 0 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 7px 5px;
    transition: 0.3s ease;
    transition-property: width, min-width, padding, opacity, background-color;
    min-width: 11px !important;
  }

  :global(.ReactTable .rt-th.-hidden),
  :global(.ReactTable .rt-td.-hidden) {
    width: 0 !important;
    min-width: 0 !important;
    padding: 0 !important;
    border: 0 !important;
    opacity: 0 !important;
  }

  :global(.ReactTable .rt-expander) {
    display: inline-block;
    position: relative;
    color: transparent;
    margin: 0 10px;
  }

  :global(.ReactTable .rt-expander:after) {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-90deg);
    border-left: 5.04px solid transparent;
    border-right: 5.04px solid transparent;
    border-top: 7px solid rgba(0, 0, 0, 0.8);
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    cursor: pointer;
  }

  :global(.ReactTable .rt-expander.-open:after) {
    transform: translate(-50%, -50%) rotate(0);
  }

  :global(.ReactTable .rt-resizer) {
    display: inline-block;
    position: absolute;
    width: 36px;
    top: 0;
    bottom: 0;
    right: -18px;
    cursor: col-resize;
    z-index: 10;
    overflow: hidden;
  }

  :global(.ReactTable .rt-tfoot) {
    flex: 1 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
  }

  :global(.ReactTable .rt-tfoot .rt-td:last-child) {
    border-right: 0;
  }

  :global(.ReactTable.-striped .rt-tr.-odd) {
    background: $gray-100;
  }

  :global(.ReactTable .rt-noData) {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: rgba(255, 255, 255, 0.8);
    transition: all 0.3s ease;
    z-index: 1;
    pointer-events: none;
    padding: 20px;
    color: rgba(0, 0, 0, 0.5);
    font-size: 16px;
    font-weight: bold;
  }

  :global(.ReactTable .-loading) {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.8);
    transition: all 0.3s ease;
    z-index: -1;
    opacity: 0;
    pointer-events: none;
  }

  :global(.ReactTable .-loading > div) {
    position: absolute;
    display: block;
    text-align: center;
    width: 100%;
    top: 50%;
    left: 0;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.6);
    transform: translateY(-52%);
    transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  :global(.ReactTable .-loading.-active) {
    opacity: 1;
    z-index: 2;
    pointer-events: all;
  }

  :global(.ReactTable .-loading.-active > div) {
    transform: translateY(50%);
  }

  :global(.ReactTable .rt-resizing .rt-th),
  :global(.ReactTable .rt-resizing .rt-td) {
    transition: none !important;
    cursor: col-resize;
    user-select: none;
  }

  :global(.ReactTable .rt-thead .rt-th.-sort-asc),
  :global(.ReactTable .rt-thead .rt-td.-sort-asc),
  :global(.ReactTable .rt-thead .rt-th.-sort-desc),
  :global(.ReactTable .rt-thead .rt-td.-sort-desc) {
    box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.6);
  }
}

.leftSearchContainer {
  display: flex;
  flex-direction: row;
}

.searchDiv {
  position: relative;
  height: $tickets-header-height !important;
  z-index: 5;

  >div {
    >div:first-child {
      height: $tickets-header-height !important;
      border-radius: 0px !important;
      padding-right: 37px;
    }
  }
}

.searchDivSpace {
  height: 105px !important;

  >div {
    >div:first-child {
      height: 105px !important;
      padding-left: 35px;
    }
  }
}

.dateRangePicker {
  margin-left: 30px;
  margin-right: 12px;
  font-family: $font-family-bold;
  font-size: 1.3rem;
  line-height: 1.3;
  color: $gray-900;
  text-align: right;
}

.dateRangePickerBlock {
  margin-top: 8px;
}

.refreshBlock {
  margin-top: 26px;
}

.paper {
  height: calc(100% - 55px);
  border-radius: 3px 3px 0 0 !important;
  box-shadow: none !important;
  background-color: $gray-100 !important;
}

.backendTicketsTable {
  height: 100%;

  >div {
    height: 100%;
    min-height: 250px;

    >div {
      /*geotraxTable*/
      max-width: 100% !important;
      width: 100% !important;
      height: 100% !important;
      padding: 0 !important;

      >div>div:nth-child(2)>div {
        /*pagination*/
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }
}

.orderLink {
  font-family: $font-family-bold !important;
}

.refreshCurrentTicketList {
  display: flex !important;
  flex-direction: row-reverse;
  justify-content: flex-start;
}

.refreshCurrentTicketList button {
  margin: 0 -30px 0 7px;
  cursor: pointer;
  background: transparent;
  border: transparent;
  font-size: 1.3rem;
  transition: $transition;
  border-radius: $border-radius;
  padding: 1.1rem 1.3rem;
  height: 4rem;
  width: 12.3rem;

  .loadingCircleContainer {
    margin: 0 -1px 0 0;
  }

  i {
    font-size: 1.6rem;
  }

  &:hover {
    background: $gray-100;
  }

  &:active {
    background: $gray-200;
  }

  &:focus {
    outline: none;
  }

  &.fetchingList {
    background: transparent;
    cursor: default;
  }
}

.refreshCurrentTicketList button i {
  display: inline-block;
  vertical-align: middle;
  color: $darkblue;
  transform: translateY(-3px);
}

.refreshCurrentTicketList button span {
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
  transition: $transition;
  display: inline-block;
  transform: translateY(-3px);
}

.loadingText {
  color: $gray-400;
  font-family: 'PublicSans Bold' !important;
}

.boldText {
  font-family: 'PublicSans Bold' !important;
}

.dateRangePicker {
  text-align: left;
}

.voidedCellStyle {
  position: relative;
  color: $gray-600 !important;

  >img {
    position: absolute;
    margin-left: 5px;
    height: 20px;
    top: 10px;
    margin-left: 6px;
  }
}