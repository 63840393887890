@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.container {
  :global(.ReactTable) {
    padding-bottom: 39px;
  }

  .tabsContainer {
    border-bottom: 1px solid $gray-300;
    margin-top: -10px;
  }

  .headerContainer {
    position: relative;
  }

  .checkboxContainer {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    top: 3px;
  }

  .checkboxTitle {
    line-height: 1;
    color: $gray-900;
    font-weight: normal;
    font-stretch: normal;
    text-transform: capitalize;
    font-family: $font-family-bold;
    font-size: 1.2rem;
  }

  .checkboxLabel {
    line-height: 1;
    color: $gray-900;
    font-weight: bolder;
    font-stretch: normal;
    text-transform: capitalize;
    font-family: $font-family-regular;
    font-size: 1.4rem;
    margin-left: -5px;
  }

  .checkboxLabelContainer {
    margin: 0 0 0 1px !important;
  }
}

@media (max-width: 1455px) {
  .checkboxTitle {
    display: none;
  }

  .checkboxLabel {
    margin-left: -8px !important;
  }
}