@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.flexGroup {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.h5 {
  width: 100%;
  margin: 5px 20px !important;
  font-size: 1.3rem !important;
  font-family: $font-family-bold;
  text-transform: uppercase;
  line-height: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: 0.2rem;
}