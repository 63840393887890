.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.layoutContainer,
.flex-center,
.flex-horizontal-center,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.main,
.mainWithTickets {
  height: 100%;
  flex-grow: 1;
  width: 0;
  z-index: 100;
  position: relative;
}
.main > div,
.mainWithTickets > div {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.main > div:first-of-type {
  height: 100%;
}

.mainWithTickets > div:first-of-type {
  height: 60% !important;
  position: relative;
}

.btn-primary {
  background: rgb(96, 96, 97);
  color: #ffffff;
}

.layoutContainer {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: #f4f7f9;
}

.MuiTypography-root .MuiTypography-caption .MuiDayCalendar-weekDayLabel .css-1brs47w-MuiTypography-root-MuiDayCalendar-weekDayLabel {
  color: green;
  font-size: 5000px;
}

