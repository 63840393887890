@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.docContainer {
  padding: 22px;
  width: 522px;
}

.pdfLabel {
  font-weight: bold;
  font-size: 13px;
  color: $gray-900;
  margin: 0 0 13px -1px;
  padding: 0;
  font-family: $font-family-bold;
}

.pdfDoc {
  padding: 0;
  display: flex;
  align-items: center;
}

.pdfDoc i {
  font-size: 16px;
  line-height: 16px;
  margin-right: 7px;
}

.pdfDoc span {
  font-size: 16px;
  line-height: 16px;
  color: $gray-900;
  transform: translateY(-1px);
}

.errorPdfDoc {
  padding: 0;
  display: flex;
  align-items: center;
}

.errorPdfDoc i {
  font-size: 16px;
  line-height: 16px;
  margin-right: 7px;
}

.errorPdfDoc span {
  font-size: 16px;
  line-height: 16px;
  color: $darkred;
  transform: translateY(-1px);
}

.errorList {
  display: flex;
  flex-direction: column;
  margin: 2px 0 -2px 0;
}

.listWarning {
  font-size: 1px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.listWarningText {
  font-size: 14px !important;
  font-family: $font-family-bold;
  margin-top: 10px;
  transform: translateY(-1px);
}

.listWarningTextNextLine {
  font-size: 14px !important;
  font-family: $font-family-regular;
  margin: 7px 3px 0 -1px;
}

.warningIcon {
  font-size: 16px;
  margin: 10px 6px 0 0 !important;
  color: $darkred;
}

.textRed {
  margin-bottom: 3px;
  color: $darkred;
  font-family: $font-family-regular;
  font-size: 13px;
}

.chooseFileButton {
  padding: 0.1rem 1.3rem 0.35rem 1.3rem;
  min-height: 4rem;
  min-width: 4rem;
  font-family: $font-family-regular;
  font-size: 1.6rem;
  border-radius: $border-radius;
  border: none;
  cursor: pointer;
  background: $gray-100;
  color: $darkblue;
  box-shadow: $button-shadow-secondary !important;
  transition: $transition;
  margin-top: -1px;

  &:hover {
    background: $xlightblue !important;
  }

  &:focus {
    outline: none;
  }
}

.chooseNewFileButton {
  padding: 0.1rem 1.3rem 0.35rem 1.3rem;
  min-height: 4rem;
  min-width: 4rem;
  font-family: $font-family-regular;
  font-size: 1.6rem;
  border-radius: $border-radius;
  border: none;
  cursor: pointer;
  background: $gray-100;
  color: $darkblue;
  box-shadow: $button-shadow-secondary !important;
  transition: $transition;
  margin-top: 17px;

  &:hover {
    background: $xlightblue !important;
  }

  &:focus {
    outline: none;
  }
}

.chooseNewFileButtonWhenHasError {
  padding: 0.1rem 1.3rem 0.35rem 1.3rem;
  min-height: 4rem;
  min-width: 4rem;
  font-family: $font-family-regular;
  font-size: 1.6rem;
  border-radius: $border-radius;
  border: none;
  cursor: pointer;
  background: $gray-100;
  color: $darkblue;
  transition: $transition;
  margin-top: 17px;
  box-shadow: $button-shadow-secondary !important;

  &:hover {
    background: $xlightblue !important;
  }

  &:focus {
    outline: none;
  }
}

.chooseFileButton:disabled {
  background-color: $gray-100;
  color: $gray-500;
  cursor: not-allowed;
  box-shadow: none;
}

.chooseNewFileButton:disabled {
  background-color: $gray-100;
  color: $gray-500;
  cursor: not-allowed;
  box-shadow: none;
}

.actions {
  border-top: 1px solid $gray-200;
  padding: 11px 22px;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.actions span {
  font-family: $font-family-bold;
  font-weight: bold;
}

.actions span i {
  margin-right: 5px;
  color: $orange;
  font-size: 1.6rem;
  padding-bottom: 2px;
}

.actions span span {
  color: $gray-900;
}

.cancelButton {
  margin-right: 22px;
}

.cancelButton:disabled {
  color: $gray-400;
  cursor: default;
}

.addReleaseDocButton:disabled {
  background-color: $gray-400;
  color: white;
  box-shadow: inset 0 0 0 1px $gray-500;
  cursor: not-allowed;
}

.fileUploader {
  display: none;
  visibility: hidden;
}

.fileUploadError {
  color: $darkred;
}