.footerMessageContainer {
  display: inline-block;
  transform: translateY(-3px);
}

.text {
  font-size: 1.3rem;
  font-family: "PublicSans Regular", Helvetica, Arial, sans-serif;
  color: #151F28;
  display: inline-block;
}

.asterisk {
  color: #E92851;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  font-size: 1.8rem;
  display: inline-block;
  transform: translateY(3px);
  margin-right: 0.3rem;
}

.errorMessageText {
  color: #BD0029;
}

