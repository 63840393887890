.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-center,
.flex-horizontal-center,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.margin-0 {
  margin: 0 !important;
}

.margin-top-0 {
  margin-top: 0;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-right-20 {
  margin-right: 20px;
}

.margin-right-30 {
  margin-right: 30px;
}

.margin-right-40 {
  margin-right: 40px;
}

.margin-left-5 {
  margin-left: 5px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-left-30 {
  margin-left: 30px;
}

.margin-left-40 {
  margin-left: 40px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

.wrap-words {
  word-break: initial;
  line-height: 1.1;
}

.nowrap-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

.pre-wrap {
  white-space: pre-wrap;
}

.height-100 {
  height: 100%;
}

.icon-md {
  font-size: 1.8rem;
}

.icon-lg {
  font-size: 2rem;
}

.icon-xl {
  font-size: 3rem;
}

.icon-people-margin {
  position: relative;
  top: 2px;
  margin-right: 3px;
}

.underline {
  text-decoration: underline;
}

.clickable {
  cursor: pointer;
}

.contentContainer {
  padding: 20px 36px 27px 24px;
  background-color: white;
  width: 43.4rem;
  cursor: auto;
}
.contentContainer input[type=text].tt-input {
  min-height: 2rem;
}

.dropPinMode {
  cursor: url(../../../assets/img/mapPin.svg), auto;
}

.title {
  margin: 0 0 2rem 0;
  font-size: 18px;
  font-weight: bold;
  color: #151F28;
}

.checkboxUnchecked {
  color: #636b71 !important;
  font-size: 16px !important;
  height: 28px !important;
}

.checkboxChecked {
  color: #008EF3 !important;
  font-size: 16px !important;
  height: 28px !important;
}

.checkboxInvalid {
  color: #e92851 !important;
  font-size: 16px !important;
}

.searchLoadingIcon {
  margin-top: 8px;
}

.searchAutocomplete {
  margin-top: 20px;
}

.searchButton {
  float: right;
}

.productLineTabsContainer {
  padding: 0 3.2rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.label {
  color: #151F28;
  font-size: 13px;
  line-height: 19px;
}

.dateLabel {
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  font-weight: bold;
  color: #151F28;
  font-size: 13px;
  line-height: 19px;
}

.required {
  color: red;
  font-size: 13px;
  line-height: 19px;
}

.productContainer {
  margin-bottom: 12px;
  padding-top: 20px;
}

.productLabel {
  font-size: 13px;
  margin-bottom: -2px;
  height: 20px;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto;
}
.productLabel > :first-child {
  grid-column: span 3;
  margin-top: 20px;
  margin-bottom: 0;
}
.productLabel label > span:first-child {
  padding: 9px;
}
.productLabel > :last-child {
  grid-column: span 3;
}

.dateTimeError input:not([type])[readonly],
.dateTimeError input[type=text][readonly],
.dateTimeError input[type=password][readonly],
.dateTimeError input[type=email][readonly],
.dateTimeError input[type=url][readonly],
.dateTimeError input[type=time][readonly],
.dateTimeError input[type=date][readonly],
.dateTimeError input[type=datetime][readonly],
.dateTimeError input[type=datetime-local][readonly],
.dateTimeError input[type=tel][readonly],
.dateTimeError input[type=number][readonly],
.dateTimeError input[type=search][readonly],
.dateTimeError textarea[readonly] {
  background-color: #ffecf0 !important;
  font-size: 16px;
  height: 36px;
  padding-bottom: 8px;
  padding-top: 6px;
  height: 22px;
}

.dateTimeCalendar input:not([type])[readonly],
.dateTimeCalendar input[type=text][readonly],
.dateTimeCalendar input[type=password][readonly],
.dateTimeCalendar input[type=email][readonly],
.dateTimeCalendar input[type=url][readonly],
.dateTimeCalendar input[type=time][readonly],
.dateTimeCalendar input[type=date][readonly],
.dateTimeCalendar input[type=datetime][readonly],
.dateTimeCalendar input[type=datetime-local][readonly],
.dateTimeCalendar input[type=tel][readonly],
.dateTimeCalendar input[type=number][readonly],
.dateTimeCalendar input[type=search][readonly],
.dateTimeCalendar textarea[readonly] {
  font-size: 16px;
  padding-bottom: 8px;
  padding-top: 6px;
  padding-left: 9px;
  height: 22px;
  border: 2px solid #e1e4e7 !important;
}

.errorMessage {
  width: 330px;
  height: 16px;
  color: red;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 24px;
  margin-top: 10px;
}

[class*=weekDayLabel] {
  color: #878e93 !important;
}

.innerDefaultMessage {
  display: flex;
}

.message {
  margin-left: 4px;
}

.defaultMessage {
  font-family: "TeX Gyre Heros";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 4px;
  color: #878e93;
  width: 330px;
  height: 30px;
}

.autocompletePlace {
  width: 102%;
  height: 38px;
  padding-left: 16px;
  font-family: "PublicSans Regular", Helvetica, Arial, sans-serif !important;
  font-size: 16px;
  text-overflow: ellipsis;
  border: 2px solid;
  border-color: #e1e4e7;
  background-color: #f4f7f9;
}
.autocompletePlace:focus-visible {
  border-color: #008EF3 !important;
  outline: none;
}
.autocompletePlace.hasError {
  border: 1px solid #E92851;
  background-color: #FFECF0;
}

.timeLabel {
  display: flex;
}

.timeSearch {
  display: flex;
}
.timeSearch > div > label {
  display: none;
}
.timeSearch > div > div > div > div > button {
  padding: 3px;
  padding-left: 12px !important;
}

.timePicker {
  flex: 1 1 auto;
  margin-right: 15px;
}

.input {
  font-family: "PublicSans Regular";
  box-shadow: none;
  transition-property: box-shadow, border;
  transition-duration: 0.3s !important;
  border-radius: 3px !important;
  padding: 0.2rem 0 0.3rem 0 !important;
  height: 4rem;
  line-height: 1;
  border: 1px solid #e1e4e7;
  background: #f4f7f9;
  font-size: 1.6rem;
  color: #151F28;
  overflow: hidden;
}

.inputFocused {
  border: 1px solid #008EF3 !important;
  box-shadow: inset 0 0 0 1px #008EF3 !important;
}

.calendarIconCt > div {
  margin-right: 0 !important;
}

.clockIcon {
  font-size: 1.6rem !important;
  color: #0A63F3 !important;
  transition: 0.3s !important !important;
  margin: 0 !important;
  cursor: pointer !important;
  border: 0;
}

.miles {
  position: relative;
  top: -29px;
  right: -56px;
  margin-left: 5px;
}

.address {
  display: flex;
  margin-bottom: -24px;
}

.addressInput {
  width: 69%;
}

.time {
  display: flex;
  column-gap: 16px;
}

.dropPin {
  font-size: 13px;
  font-weight: bold;
  border: none;
  background-color: white;
  color: #0A63F3;
  cursor: pointer;
  padding-left: 10px;
}

.radius {
  width: 55px;
  margin-left: 21px;
  margin-top: -2px;
}

.radiusContent > div input {
  min-height: 38px !important;
  border: 2px solid #e1e4e7 !important;
}

.radiusContent > div label {
  margin-top: 0.6rem;
  margin-bottom: 1rem;
}

.date {
  display: flex;
}

.dateSearchContainer {
  width: 0;
  flex: 1 1 0;
}

.dateSearch {
  margin-left: 0px;
}

.description {
  height: 80px;
  margin: 100px 0 0 0;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  color: #a5abaf;
}

.descriptionHide {
  display: none;
}

.timePickerError {
  flex: 1 1 auto;
  margin-right: 15px;
}

.timePickerError div {
  border-radius: 4px;
  background-color: #ffecf0;
}

.timePickerError > div > div {
  border: solid 1px #e92851;
}

.deactivatedText {
  padding-left: 8px;
  color: #636b71;
  font-size: 12px;
}

.errorMessageSpan {
  display: block;
  width: 200px;
  word-wrap: break-word;
  white-space: normal;
  color: #e92851;
  font-size: 13px;
}

.dateField {
  margin: 0 auto;
  overflow: hidden;
  width: 135px;
}
.dateField > div > label {
  display: none;
}
.dateField > div > div > div > div > button {
  padding: 3px !important;
  padding-left: 12px !important;
}
.dateField > div > div > div > input {
  font-size: 16px !important;
}

