.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-center,
.flex-horizontal-center,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.docContainer {
  padding: 22px;
  width: 535px;
}

.loading {
  text-align: center;
  color: #151F28;
}

.pdfLabel {
  font-weight: bold;
  font-size: 13px;
  color: #151F28;
  margin: 0px 0 13px -1px;
  padding: 0;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
}

.pdfDoc {
  padding: 0;
  display: flex;
  align-items: center;
}

.pdfDoc i {
  font-size: 16px;
  line-height: 16px;
  margin-right: 7px;
}

.pdfDoc span {
  font-size: 16px;
  line-height: 16px;
  color: #151F28;
  transform: translateY(-1px);
}

.chooseNewFileButton {
  padding: 0.1rem 1.3rem 0.35rem 1.3rem;
  min-height: 4rem;
  min-width: 4rem;
  font-family: "PublicSans Regular", Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  background: #E2F8FF;
  color: #0A63F3;
  box-shadow: inset 0 0 0 1px rgba(35, 169, 246, 0);
  transition: 0.3s !important;
  margin-top: 17px;
}
.chooseNewFileButton:hover {
  box-shadow: inset 0 0px 8px 2px rgba(35, 169, 246, 0.2);
}

.chooseNewFileButton:disabled {
  background-color: #f4f7f9;
  color: #878e93;
  cursor: not-allowed;
  box-shadow: none;
}

.actions {
  border-top: 1px solid #e1e4e7;
  padding: 11px 22px;
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.warning {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  height: 4rem;
}

.warning span {
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  font-weight: bold;
}

.warning span i {
  margin-right: 5px;
  color: #F66A00;
  font-size: 1.6rem;
  padding-bottom: 2px;
}

.warning span span {
  color: #151F28;
  display: inline-block;
  font-size: 1.3rem;
  transform: translateY(-2px) !important;
}

.cancelButton {
  padding: 0.1rem 1.3rem 0.35rem 1.3rem;
  min-height: 4rem;
  min-width: 4rem;
  font-family: "PublicSans Regular", Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  background: transparent !important;
  box-shadow: none !important;
  color: #0A63F3;
  transition: 0.3s !important;
  margin-right: 12px;
}
.cancelButton:hover {
  color: #008EF3;
}

.cancelButton:disabled {
  color: #a5abaf;
  cursor: default;
}

.updateDocButton {
  padding: 0.1rem 1.3rem 0.35rem 1.3rem;
  min-height: 4rem;
  min-width: 4rem;
  font-family: "PublicSans Regular", Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  transition: 0.3s !important;
  background: #00AC4A;
  color: white;
  box-shadow: inset 0 0 0 1px #00884F;
}

.updateDocButton:disabled {
  background-color: #a5abaf;
  color: white;
  box-shadow: inset 0 0 0 1px #878e93;
  cursor: not-allowed;
}

.fileUploader {
  display: none;
  visibility: hidden;
}

.caption {
  font-size: 13px;
  color: #151f28;
}
.caption b {
  font-weight: bolder;
}

.invalid {
  color: #BD0029 !important;
}

