.csvContainer .modalContent {
  padding: 25px;
}
.csvContainer .modalContent .csvForm {
  display: flex;
  flex-direction: column;
}
.csvContainer .modalContent .csvInputTitle {
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  font-size: 13px;
  color: #151F28;
  font-weight: bold;
}
.csvContainer .modalContent .cardRadio {
  margin-bottom: 10px;
}
.csvContainer .modalContent .fileNameHeader {
  margin: 10px 0 0 0;
  padding: 0;
  line-height: 1;
}
.csvContainer .modalContent .fileName {
  margin: 5px 0 13px 0 !important;
  color: #151F28;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.csvContainer .modalContent .fileNameError {
  margin: 5px 0 15px 0 !important;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.csvContainer .modalContent .fileNameError * {
  color: #BD0029 !important;
}
.csvContainer .modalContent .fileNameText {
  line-height: 1;
  margin-bottom: 4px;
}
.csvContainer .featureFooter {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 12px 24px;
  border-top: 1px solid #e1e4e7;
}
.csvContainer .textRed {
  margin-bottom: 3px;
  color: #BD0029;
  font-family: "PublicSans Regular", Helvetica, Arial, sans-serif;
  font-size: 13px;
}

.input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputLabel {
  width: fit-content;
  margin: 0;
  padding: 7px 15px;
  height: 4rem;
  font-size: 1.6rem;
  cursor: pointer;
  border-radius: 3px;
}

.errorList {
  display: flex;
  flex-direction: column;
  margin: -13px 0 4px 0;
}

.listWarning {
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.listWarningText {
  font-size: 13px;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  color: #151F28;
  margin-bottom: 3px;
}

.unorderedList {
  font-size: 13px;
  list-style: outside disc;
  padding-left: 18px;
  color: #151F28;
  margin-top: 3px;
}

.errorItem:not(:first-child) {
  margin-top: 30px;
}

.csvIcon {
  margin: 9px 6px 10px 1px;
  color: #636b71;
}

.warningIcon {
  font-size: 16px;
  margin: 0 3px 0 1px;
  color: #BD0029;
}

