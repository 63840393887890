.margin-0 {
  margin: 0 !important;
}

.margin-top-0 {
  margin-top: 0;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-right-20 {
  margin-right: 20px;
}

.margin-right-30 {
  margin-right: 30px;
}

.margin-right-40 {
  margin-right: 40px;
}

.margin-left-5 {
  margin-left: 5px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-left-30 {
  margin-left: 30px;
}

.margin-left-40 {
  margin-left: 40px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

.wrap-words {
  word-break: initial;
  line-height: 1.1;
}

.nowrap-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

.pre-wrap {
  white-space: pre-wrap;
}

.height-100 {
  height: 100%;
}

.icon-md {
  font-size: 1.8rem;
}

.icon-lg {
  font-size: 2rem;
}

.icon-xl {
  font-size: 3rem;
}

.icon-people-margin {
  position: relative;
  top: 2px;
  margin-right: 3px;
}

.underline {
  text-decoration: underline;
}

.clickable {
  cursor: pointer;
}

.mapPanelContent {
  overflow: hidden;
  margin-top: -13px;
  display: flex;
  flex-flow: column;
  height: calc(100vh - 150px);
  width: 24rem;
}

.regionSelect {
  padding: 0 3.2rem 0.3rem 3.2rem;
  margin-bottom: 1.3rem;
  display: flex;
  flex-direction: column;
}
.regionSelect .label {
  font-size: 1.3rem;
  color: #a5abaf;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  margin: 0 0 6px 0 !important;
  padding: 0;
  line-height: 1;
}
.regionSelect .singleRegion {
  color: white;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

.selectContainer {
  width: 100% !important;
}

.selectField {
  height: 4rem !important;
  box-sizing: border-box !important;
  padding: 0.4rem 3.3rem 0.2rem 1.3rem !important;
  background: #2A353E !important;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.45), 0px 2px 2px 0px rgba(0, 0, 0, 0.39), 0px 3px 1px -2px rgba(0, 0, 0, 0.37);
  border-radius: 3px !important;
  transition: 0.3s !important;
  color: white !important;
  font-size: 1.6rem !important;
  line-height: 3.25rem !important;
}
.selectField:hover {
  background: #434c53 !important;
}
.selectField.disabled:hover {
  background-color: #2A353E !important;
}
.selectField:focus, .selectField:global(.open) {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.45), 0px 2px 2px 0px rgba(0, 0, 0, 0.39), 0px 3px 1px -2px rgba(0, 0, 0, 0.37), inset 0 0 0 2px #008EF3 !important;
  background: #2A353E !important;
}
.selectField svg {
  display: none;
}

.arrowIcon {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 2rem;
  line-height: 3.8rem;
  pointer-events: none;
  color: white;
}

.arrowHidden {
  display: none !important;
}

.disabled {
  -webkit-text-fill-color: white !important;
  background: #2A353E !important;
  box-shadow: none !important;
  cursor: not-allowed !important;
}
.disabled * {
  cursor: not-allowed !important;
}
.disabled svg {
  display: none;
}

.menuStyle {
  margin-top: 5px !important;
  background: #2A353E !important;
  border-radius: 3px !important;
  transform-origin: 50% 0 !important;
  box-shadow: 0 0 9px #101010 !important;
}

.menuItem {
  color: #f4f7f9 !important;
  display: flex !important;
  padding: 6px 16px !important;
  justify-content: flex-start !important;
  transition: 0.3s !important;
}
.menuItem:hover {
  cursor: pointer;
  background: #434c53 !important;
}

.menuItemSelected {
  background: #004478 !important;
}
.menuItemSelected:hover {
  background: #004478 !important;
  cursor: default;
}

.folder {
  background: #2A353E;
  border-bottom: solid 1px #434c53;
}

.reportsBtn {
  width: 100%;
  font-size: 1.6rem;
  background: transparent;
  color: white;
  border: transparent;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  line-height: 1;
  height: 4rem;
  transition: 0.3s !important;
  margin: -7px 0 -5px 0;
}
.reportsBtn:hover {
  cursor: pointer;
  color: #008EF3;
}
.reportsBtn:active {
  color: #0A63F3;
}
.reportsBtn:active .reportsBtnIcon {
  color: #0A63F3;
}

.reportsBtnIcon {
  padding-right: 6px;
  color: #008EF3;
  transform: translateX(-1px);
}

.reportsModal {
  width: 26%;
  min-width: 500px !important;
}

.reportsBtnContainer {
  margin: 2px 0 17px 0;
  padding: 0 3.2rem;
}

.productLineTabsContainer {
  padding: 0 3.2rem;
  margin-bottom: 1.5rem;
}

.mapPanelLowerContent {
  overflow-y: auto;
  flex: 1;
}
.mapPanelLowerContent::-webkit-scrollbar {
  background-color: #151F28 !important;
  border-left: 1px solid transparent;
}
.mapPanelLowerContent::-webkit-scrollbar-track {
  border-top: 1px solid #2A353E;
  border-bottom: 1px solid #2A353E;
}
.mapPanelLowerContent::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background-color: #434c53 !important;
  width: 1rem !important;
  border: 0.3rem solid transparent;
  border-left: 0.4rem solid transparent;
  background-clip: content-box;
}

.productLinesContainer {
  margin-left: 31px;
  margin-bottom: 5px;
}

.productLines {
  color: #a5abaf !important;
}

.radioBtnSize, .radioBtnSelected, .radioBtn {
  height: 10px;
  width: 10px;
  margin-left: 13px !important;
}

.radioBtn {
  color: #636b71 !important;
  padding: 12px !important;
  margin-left: 10px !important;
}
.radioBtn :global(.MuiSvgIcon-root) {
  font-size: 2.4rem !important;
}

.radioBtnSelected {
  color: #008EF3 !important;
}

.radioLabel {
  font-size: 16px !important;
  font-weight: 400 !important;
  margin: 4px !important;
  color: white;
}

