$panelContentMarginOffset: -13px;

@mixin niceScrollbar {
    &::-webkit-scrollbar {
      background-color: $gray-900 !important;
      border-left: 1px solid transparent;
    }
    &::-webkit-scrollbar-track {
      border-top: 1px solid $gray-800;
      border-bottom: 1px solid $gray-800;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 1rem;
      background-color: $gray-700 !important;
      width: 1rem !important;
      border: .3rem solid transparent;
      border-left: .4rem solid transparent;
      background-clip: content-box;
    }
  }