.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-center,
.flex-horizontal-center,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.messageList {
  height: 100vh;
  overflow: auto;
  position: relative;
  z-index: 1;
  margin-top: 0.5rem;
  padding-bottom: 30px;
}

.selected {
  background: #E2F8FF !important;
  cursor: auto !important;
}

.messageItem {
  width: 100%;
  padding: 1.2rem 2.2rem 1.4rem 4rem;
  border-bottom: 1px solid #e1e4e7;
  cursor: pointer;
  transition: 0.3s !important;
  position: relative;
}
.messageItem:hover {
  background: #f4f7f9;
}
.messageItem:focus {
  outline: none;
}
.messageItem:last-child {
  border-bottom: 0px;
}
.messageItem .time {
  font-size: 1.2rem;
  color: #636b71;
  line-height: 1.7rem;
  white-space: nowrap;
}
.messageItem .dot, .messageItem .acknowledgedDot {
  width: 1rem;
  height: 1rem;
  padding: 0.5rem;
  border-radius: 50%;
  background: #E92851;
  position: absolute;
  top: 1.7rem;
  left: 1.5rem;
}
.messageItem .acknowledgedDot {
  background: #f4f7f9;
  border: solid 1px #a5abaf;
  cursor: pointer;
}
.messageItem .acknowledgedDot:hover {
  border: solid 1px #E92851;
  background-color: white;
}
.messageItem .unreadText {
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif !important;
}
.messageItem .header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 0.6rem;
}
.messageItem .header .author {
  font-size: 1.6rem;
  font-weight: 400;
  color: #151F28;
  line-height: 2rem;
  padding: 0;
  margin: 0;
  max-width: 18rem;
}
.messageItem .preview {
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.3;
  color: #636b71;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
}

