@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_helpers.scss';

.highlightText {
  font-family: $font-family-bold;
}

.alertText {
  color: $darkred;
  font-family: $font-family-bold;
}

.centering {
  display: flex;
  align-items: center;
  height: 100%;
}

.pill {
  width: 35px;
  height: 18px;
  padding: 3px 4px;
  gap: 4px;
  border-radius: 4px;

  &Aggregated {
    background: $purple;
  }

  &ReadyMix {
    background: $brown;
  }

  &Cement {
    background: $orange;
  }
}


.pillText {
  width: 27px;
  height: 12px;
  font-family: $font-family-bold;
  font-size: 12px;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0;
  text-align: center;
  color: $white;
}

.headerStyle {
  height: 164px !important;
}

.leftHeader {
  height: 100%;
}

.productLineFilter {
  padding-top: 25px;
}

.filterTitle {
  font-family: $font-family-bold !important;
  font-size: 14px;
}

.searchTermFilter {
  height: 119px;
  display: flex;
  flex-direction: column-reverse;
}

.disabledCloseSection {
  display: flex;
  justify-content: flex-start;
  padding: 8px 0px 0px 30px;
  height: 44px;
  background: $gray-100;
  align-items: center;

  >div {
    width: 130px;
    height: 36px;
    padding: 0px 10px 0px 10px;
    gap: 8px;
    border-radius: 3px;

    >button {
      width: 100%;
      height: 100%;
      background: transparent !important;
      border: 0px !important;
      padding: 0px 0px 0px 0px !important;
      margin-top: 10px;
      font-size: 13px !important;
      color: $gray-400 !important;
      display: flex;
      align-items: center;

      >svg {
        font-size: 16px !important;
        color: $gray-400 !important;
        margin-right: 5px;
      }
    }

  }
}

.enabledCloseSection {
  display: flex;
  justify-content: flex-start;
  padding: 8px 0px 0px 30px;
  height: 44px;
  background: $gray-100;
  align-items: flex-end;

  >span {
    color: $darkred;
  }

  >div {
    width: 130px;
    height: 36px;
    padding: 0px 10px 0px 10px;
    gap: 8px;
    border-radius: 3px;

    >button {
      width: 100%;
      height: 100%;
      background: transparent !important;
      border: 0px !important;
      padding: 0px 0px 0px 0px !important;
      margin-top: 10px;
      font-size: 13px !important;
      color: $gray-900 !important;
      display: flex;
      align-items: center;
      cursor: pointer;


      >svg {
        font-size: 16px !important;
        color: #1C63f3 !important;
        margin-right: 5px;
      }

      >span {
        font-family: $font-family-bold !important;
      }
    }

  }
}

.rowCheckbox {
  padding: 0px !important;
  display: flex;
  justify-content: center;
  margin-left: -10px;

  >span {
    padding: 0px;
  }
}

.headerContainer {
  padding: 0px;
  display: flex;
  justify-content: center;
  margin-left: -15px;

  >span {
    padding: 0px;
    height: 11px !important;
  }
}