.regionsAddModal,
.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-center,
.flex-horizontal-center,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.modal {
  display: grid;
}

.name {
  grid-column: 1;
  grid-row: 1;
}

.description {
  grid-column: 2;
  grid-row: 1;
}

.map {
  margin: 17px 0;
  grid-column: 1/3;
  grid-row: 2;
  height: 350px;
}
.map:has([class*=errorMessageMap]) {
  margin-bottom: 44px;
}

.latitude {
  display: inline-block;
  margin-bottom: 0 !important;
  min-width: 72px;
  margin-right: 30px;
}

.longitude {
  display: inline-block;
  margin-bottom: 0 !important;
  min-width: 72px;
  margin-right: 30px;
}

.cannedMessages {
  margin-top: 0 !important;
}

