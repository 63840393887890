.st0 {
  fill:#FEFEFE;
}

.st1 {
  fill:#FFBF00;
}

.st2 {
  fill:#F08900;
}

.svgStyle {
  enable-background: new 25 25 500 500;
  height: 25px;
  width: 20px;
  margin-bottom: -6px;
}
