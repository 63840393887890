@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_helpers.scss';

.wrapper {
  display: flex;
  flex-wrap: wrap;

  >div {
    flex-grow: 1 !important;
    height: auto !important;

    &:not(:last-of-type) {
      width: 43% !important;
    }

    &:first-of-type,
    &:last-of-type,
    &.map {
      width: 100% !important;
    }

    p:first-of-type {
      margin-bottom: -2rem !important;
      transform: translateY(-.4rem) !important;
    }

    p:not(:first-of-type) {
      margin-top: 1.4rem !important;
    }
  }

  &.editWrapper {
    >div {

      &:not(:last-of-type),
      &:first-of-type,
      &:last-of-type {
        width: 100% !important;
        max-width: 500px;
      }
    }

    p:first-of-type {
      margin-bottom: -2rem !important;
      transform: translateY(-.4rem) !important;
    }

    p:not(:first-of-type) {
      margin-top: 1.4rem !important;
    }

    .name {
      display: none !important;
    }

    .mapEdit {
      max-width: 100% !important;
    }
  }
}

@media (max-width: $breakpoint-admindetails) {
  .wrapper {
    >div {

      &:not(:last-of-type),
      &:first-of-type,
      &:last-of-type {
        width: 100% !important;
      }
    }
  }
}