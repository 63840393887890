.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-center,
.flex-horizontal-center,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.body {
  background-color: white;
  margin: 5px;
  padding: 5px;
  width: 100%;
}

.details {
  display: flex;
  flex-direction: row;
}

.eta {
  align-items: center;
  background-color: #f4f7f9;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  height: 90px;
  white-space: nowrap;
  margin-right: 10px;
}

.metrics {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 0 10px 0;
  width: 100%;
}

.flexItem {
  display: flex;
}

.baseInfoPaneBtns, .plantPaneBtns, .infoPaneBtns {
  display: flex;
  flex-direction: row;
}
.baseInfoPaneBtns :global(button.tt-btn), .plantPaneBtns :global(button.tt-btn), .infoPaneBtns :global(button.tt-btn) {
  text-align: center;
  width: 49%;
}

.infoPaneBtns {
  margin-top: 20px;
  justify-content: flex-start;
}

.plantPaneBtns {
  justify-content: center;
}
.plantPaneBtns :global(button.tt-btn) {
  text-align: center;
  min-width: 233px;
}

.buttonHover {
  color: #151F28 !important;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif !important;
  padding: 7px 12px 8px 13px !important;
  margin: 0 0 -5px 0 !important;
  min-height: 2rem !important;
}
.buttonHover:hover {
  background: #f4f7f9 !important;
}
.buttonHover:active {
  background: #e1e4e7 !important;
}
.buttonHover:focus {
  outline: none !important;
}

.infoPaneBtns > :first-child {
  margin-right: 10px;
}

.orderFooter > .orderDetailsBtn {
  color: #151F28 !important;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif !important;
  padding: 7px 12px 8px 13px !important;
  margin: 0 0 -5px 0 !important;
  min-height: 2rem !important;
}
.orderFooter > .orderDetailsBtn:hover {
  background: #f4f7f9 !important;
}
.orderFooter > .orderDetailsBtn:active {
  background: #e1e4e7 !important;
}
.orderFooter > .orderDetailsBtn:focus {
  outline: none !important;
}

.truckFooter > .truckDetailsBtn {
  color: #151F28 !important;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif !important;
  padding: 7px 12px 8px 13px !important;
  margin: 0 0 -5px 0 !important;
  min-height: 2rem !important;
}
.truckFooter > .truckDetailsBtn:hover {
  background: #f4f7f9 !important;
}
.truckFooter > .truckDetailsBtn:active {
  background: #e1e4e7 !important;
}
.truckFooter > .truckDetailsBtn:focus {
  outline: none !important;
}

.orderFooter > .orderDetailsBtn > i {
  color: #0A63F3 !important;
}

.plantSendMessageBtn {
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif !important;
  font-weight: bold !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.54 !important;
  letter-spacing: normal !important;
  color: #151f28 !important;
  border-radius: 3px !important;
}

.flagIcon {
  font-size: 1.8rem;
  display: inline-block;
}

.dotIcon {
  font-size: 1.6rem;
  display: inline-block;
}

.titleText {
  display: inline-block;
  transform: translateY(4px);
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
}

.orderContainer {
  width: max-content !important;
}

.orderContainer .orderDetailsBtn:last-of-type {
  margin-left: 10px !important;
}

.leftColumn {
  width: 80px;
  font-weight: bold;
  margin-right: 16px;
}

.popupItem {
  display: flex;
  margin-bottom: 8px;
}

.popupItem:last-child {
  margin-bottom: 0px;
}

.details .metrics {
  padding: 0;
}

table.routePopup {
  margin: 0 -10px;
  border-spacing: 20px 1rem;
}

