.view {
  margin-top: 300px;
  text-align: center;
}
.view p {
  margin-top: 20px;
  white-space: pre-wrap;
  color: #151F28;
  line-height: 1.2;
}

.icon {
  font-size: 75px;
  color: #c8cdd0;
}

