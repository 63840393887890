.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-center,
.flex-horizontal-center, .card,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.card,
.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.card {
  display: flex;
  min-height: 100px;
  width: 100%;
  max-width: 1000px;
  margin: 10px 40px;
  padding: 10px 30px;
  border-radius: 3px;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15), 0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 3px 1px -2px rgba(0, 0, 0, 0.07) !important;
  background: white;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.text {
  display: flex;
  flex: 0 1 100%;
  min-width: 40%;
  flex-direction: column;
  overflow-wrap: break-word;
  padding-right: 10px;
}

.primaryText {
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  font-size: 16px;
}

.secondaryText {
  font-family: "PublicSans Regular", Helvetica, Arial, sans-serif;
  font-size: 14px;
}

.paddingLef {
  padding-left: 5px;
}

.featureButtons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
}
.featureButtons :global(.MuiSwitch-colorPrimary.Mui-checked) :global(.MuiSwitch-thumb) {
  color: #008EF3;
}
.featureButtons :global(.MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track) {
  background-color: #008EF3;
}

.switchLabel {
  font-family: "PublicSans Regular", Helvetica, Arial, sans-serif !important;
  font-size: 1.4rem !important;
}

