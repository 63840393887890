.regionTokenButton {
  align-self: start;
  justify-self: end;
  white-space: nowrap;
}

.buttonGroup {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}
@media (max-width: 1140px) {
  .buttonGroup {
    flex-direction: column;
    align-items: flex-end;
    width: 170px;
    margin-bottom: 40px;
  }
  .buttonGroup .buttonClass:first-child {
    margin: 1px 0 10px 0;
  }
}

.buttonClass {
  margin: 1px 5px 0 0;
  width: 160px;
}

.expired {
  width: 55px;
  height: 16px;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #BD0029;
}

.tokenDisplay,
.expirationDateDisplay {
  margin: 0 0 2rem 0 !important;
}

