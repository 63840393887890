// GLOBAL STYLES
// for global modularity create and import sibling sass files
// e.g. @import "styles/vars" (when importing in any file src is the root folder)
//
// common vars and global utilities
@import '@trucktrax/trucktrax-common/src/styles/index.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
// components used in this app
@import '@trucktrax/trucktrax-common/src/styles/components/tt_type.scss';
@import '@trucktrax/trucktrax-common/src/styles/components/tt_forms.scss';
@import '@trucktrax/trucktrax-common/src/styles/components/tt_navigation.scss';
@import '@trucktrax/trucktrax-common/src/styles/components/tt_search.scss';
@import '@trucktrax/trucktrax-common/src/styles/components/tt_buttons.scss';
@import '@trucktrax/trucktrax-common/src/styles/components/tt_icons.scss';

#root {
  height: $window-height;
  width: $window-width;
  overflow: hidden;
}

.navbar-light {
  z-index: 100;
}

.nav navbar-nav {
  display: inline;
}

.fa-stack {
  background-color: $green;
  color: white;
}

.message-mini-menu {
  height: 32px;
  width: 32px;
  font-size: 16px;
}

// removes focused outlines
[role=button],
button {
  outline: none;
}

.skeleton {
  height: 4rem;
  width: 100%;
  border-radius: 5px;
  background: rgba(gray, 0.1);
}

// consider moving to common
.text-center {
  text-align: center;
}

.full-width {
  width: 100%;
}

.transform-center {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}