@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.regionsAddModal {
  @extend %adminModalWidth;
}

.modal {
  display: grid;
}

.name {
  grid-column: 1;
  grid-row: 1;
}

.description {
  grid-column: 2;
  grid-row: 1;
}

.map {
  margin: 17px 0;
  grid-column: 1 / 3;
  grid-row: 2;
  height: 350px;

  &:has([class*="errorMessageMap"]) {
    margin-bottom: 44px;
  }
}

.latitude {
  display: inline-block;
  margin-bottom: 0 !important;
  min-width: 72px;
  margin-right: 30px;
}

.longitude {
  display: inline-block;
  margin-bottom: 0 !important;
  min-width: 72px;
  margin-right: 30px;
}

.cannedMessages {
  margin-top: 0 !important;
}