@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.titleComponent {
  margin: 0 30px 0 0;
  max-width: $title-max-width;
}

.title {
  margin: 5px 0 0 0 !important;
  font-size: 24px !important;
  font-family: $font-family-bold;
  line-height: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  letter-spacing: 0px;
}

.subTitle {
  font-size: 1.3rem;
  margin: 1px 0 6px -2px;
}