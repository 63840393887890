.adminPanelContent {
  margin-top: -13px;
  display: flex;
  flex-flow: column;
  height: calc(100vh - 150px);
}

.reportsBtnContainer {
  margin: 16px 0;
  padding: 0 3.2rem;
}

.reportsBtn {
  width: 100%;
  font-size: 1.6rem;
  background: transparent;
  color: white;
  border: none;
  background: #2A353E;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.45), 0px 2px 2px 0px rgba(0, 0, 0, 0.39), 0px 3px 1px -2px rgba(0, 0, 0, 0.37) !important;
  border-radius: 3px;
  padding: 0.1rem 1.3rem 0.35rem 1.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  height: 4rem;
  transition: 0.3s !important;
}
.reportsBtn:hover {
  cursor: pointer;
  background: #434c53;
}
.reportsBtn:active {
  background: white;
  color: #151F28;
}
.reportsBtn:focus {
  outline: none;
}

.reportsBtnIcon {
  padding-right: 5px;
}

.reportsModal {
  width: 26%;
  min-width: 500px !important;
}

.logoutRegionBody {
  white-space: pre-wrap;
  margin: 0;
  padding: 0;
}
.logoutRegionBody b {
  font-family: "PublicSans Bold";
}

.companyToolHeader {
  width: 100%;
  font-size: 1.3rem;
  color: #a5abaf;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  font-weight: bold;
  padding: 1.3rem 0 0.5rem 0;
  line-height: 1;
  border-bottom: 1px solid #636b71;
  display: flex;
  justify-content: left;
  margin-bottom: 14px;
}

.adminPanelLowerContent {
  flex: 1;
  overflow-y: auto;
}
.adminPanelLowerContent::-webkit-scrollbar {
  background-color: #151F28 !important;
  border-left: 1px solid transparent;
}
.adminPanelLowerContent::-webkit-scrollbar-track {
  border-top: 1px solid #2A353E;
  border-bottom: 1px solid #2A353E;
}
.adminPanelLowerContent::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background-color: #434c53 !important;
  width: 1rem !important;
  border: 0.3rem solid transparent;
  border-left: 0.4rem solid transparent;
  background-clip: content-box;
}

