@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.videoContainer {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  box-shadow: $boxshadow-medium;
  border-radius: 4px;
  overflow: hidden;
}

.video {
  padding: 0;
  margin: 0 0 -3px 0;

  &:focus {
    outline: none;
  }
}

.download {
  width: 4rem;
  height: 3.9rem;
  margin-right: 8px;
  border-radius: $border-radius;
  background: transparent;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: $transition;

  &:hover {
    background: rgba($darkblue, 0.5);
  }

  i {
    font-size: 1.7rem;
    color: $white;
    transition: $transition;
  }
}