@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.docContainer {
  padding: 22px;
  width: 535px;
}

.loading {
  text-align: center;
  color: $txt-body;
}

.pdfLabel {
  font-weight: bold;
  font-size: 13px;
  color: $txt-body;
  margin: 0px 0 13px -1px;
  padding: 0;
  font-family: $font-family-bold;
}

.pdfDoc {
  padding: 0;
  display: flex;
  align-items: center;
}

.pdfDoc i {
  font-size: 16px;
  line-height: 16px;
  margin-right: 7px;
}

.pdfDoc span {
  font-size: 16px;
  line-height: 16px;
  color: $txt-body;
  transform: translateY(-1px);
}

.chooseNewFileButton {
  padding: 0.1rem 1.3rem 0.35rem 1.3rem;
  min-height: 4rem;
  min-width: 4rem;
  font-family: $font-family-regular;
  font-size: 1.6rem;
  border-radius: $border-radius;
  border: none;
  cursor: pointer;
  background: $xlightblue;
  color: $darkblue;
  box-shadow: inset 0 0 0 1px rgba(35, 169, 246, 0);
  transition: $transition;
  margin-top: 17px;

  &:hover {
    box-shadow: inset 0 0px 8px 2px rgba(35, 169, 246, 0.2);
  }
}

.chooseNewFileButton:disabled {
  background-color: $gray-100;
  color: $gray-500;
  cursor: not-allowed;
  box-shadow: none;
}

.actions {
  border-top: 1px solid $gray-200;
  padding: 11px 22px;
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.warning {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  height: 4rem;
}

.warning span {
  font-family: $font-family-bold;
  font-weight: bold;
}

.warning span i {
  margin-right: 5px;
  color: $orange;
  font-size: 1.6rem;
  padding-bottom: 2px;
}

.warning span span {
  color: $gray-900;
  display: inline-block;
  font-size: 1.3rem;
  transform: translateY(-2px) !important;
}

.cancelButton {
  padding: 0.1rem 1.3rem 0.35rem 1.3rem;
  min-height: 4rem;
  min-width: 4rem;
  font-family: $font-family-regular;
  font-size: 1.6rem;
  border-radius: $border-radius;
  border: none;
  cursor: pointer;
  background: transparent !important;
  box-shadow: none !important;
  color: $darkblue;
  transition: $transition;
  margin-right: 12px;

  &:hover {
    color: $blue;
  }
}

.cancelButton:disabled {
  color: $gray-400;
  cursor: default;
}

.updateDocButton {
  padding: 0.1rem 1.3rem 0.35rem 1.3rem;
  min-height: 4rem;
  min-width: 4rem;
  font-family: $font-family-regular;
  font-size: 1.6rem;
  border-radius: $border-radius;
  border: none;
  cursor: pointer;
  transition: $transition;
  background: $green;
  color: white;
  box-shadow: inset 0 0 0 1px $darkgreen;
}

.updateDocButton:disabled {
  background-color: $gray-400;
  color: white;
  box-shadow: inset 0 0 0 1px $gray-500;
  cursor: not-allowed;
}

.fileUploader {
  display: none;
  visibility: hidden;
}

.caption {
  font-size: 13px;
  color: #151f28;

  b {
    font-weight: bolder;
  }
}

.invalid {
  color: $darkred !important;
}