.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-center,
.flex-horizontal-center,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.orderStatus {
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  font-size: 13px;
  line-height: 16px;
  color: #151F28;
  display: inline-block;
  transform: translate(-1px, -4px);
  margin: 0;
  padding: 0;
  white-space: nowrap;
}
.orderStatus:before {
  content: "\a";
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #a5abaf;
  display: inline-block;
  margin-left: 2px;
  margin-right: 4px;
  position: relative;
  top: 1px;
}

.willCall:before {
  background-color: #008EF3 !important;
}

.ship:before {
  background-color: #00AC4A !important;
}

.hold:before {
  background-color: #F1BE00 !important;
}

.fob:before {
  background-color: #984BD2 !important;
}

.canceled:before {
  background-color: #E92851 !important;
}

.wrapper {
  width: 100%;
  border-bottom: solid 1px #e1e4e7;
  background: white;
  padding: 10px 0 10px 2px;
  display: grid;
  grid-template-columns: 60px 1fr 69px;
  transition: 0.3s !important;
}
.wrapper:hover {
  background: #f4f7f9;
}
.wrapper:hover .notificationArrowIcon {
  transform: translateX(7px);
  color: #0A63F3;
}

.icon {
  align-self: center;
  justify-self: center;
  color: #878e93;
}

.notificationTypeIcon {
  font-size: 2.4rem;
}

.notificationArrowIcon {
  transition: 0.3s !important;
  transform: translateX(2px);
}

.title {
  font-size: 16px;
  color: #151F28;
  margin: 1px 0 0 0;
}

.description {
  display: inline-block;
  transform: translateY(-4px);
  font-size: 1.3rem;
  color: #151F28;
  margin: 0;
  margin-right: 5px;
}
.description b {
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  display: inline-block;
  transform: translateY(4px);
  max-width: 36rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 3px;
}

@media (max-width: 1060px) {
  .description b {
    max-width: 31rem;
  }
}
@media (max-width: 980px) {
  .description b {
    max-width: 27.5rem;
  }
}

