@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import "@trucktrax/trucktrax-common/src/styles/tt_layout.scss";

.autocomplete {
  border: 2px solid $gray-700;
  position: absolute !important;
  margin-top: -15px !important;
  border-radius: 10px;
  height: 30px !important;
  margin-left: -265px;
}

.searchWrapper {
  background: $gray-100 !important;
  min-width: 250px;
  width: 100%;
  max-width: 250px;

  >div {
    >div {
      max-width: 100%;

      >div {
        max-width: 100%;
      }
    }
  }
}

@media (max-width: 1450px) {
  .searchWrapper {
    min-width: 105px;
    max-width: 100%;
    flex: 1;
  }
}