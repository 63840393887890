@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_helpers.scss';

.contentContainer {
  padding: 20px 36px 27px 24px;
  background-color: white;
  width: 43.4rem;

  input[type='text'].tt-input {
    min-height: 2rem;
  }

  cursor: auto;
}

.dropPinMode {
  cursor: url(../../../assets/img/mapPin.svg), auto;
}

.title {
  margin: 0 0 2rem 0;
  font-size: 18px;
  font-weight: bold;
  color: $gray-900;
}

.checkboxUnchecked {
  color: $gray-600 !important;
  font-size: 16px !important;
  height: 28px !important;
}

.checkboxChecked {
  color: $blue !important;
  font-size: 16px !important;
  height: 28px !important;
}

.checkboxInvalid {
  color: #e92851 !important;
  font-size: 16px !important;
}

.searchLoadingIcon {
  margin-top: 8px;
}

.searchAutocomplete {
  margin-top: 20px;
}

.searchButton {
  float: right;
}

.productLineTabsContainer {
  padding: 0 3.2rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.label {
  color: $gray-900;
  font-size: 13px;
  line-height: 19px;
}

.dateLabel {
  font-family: $font-family-bold;
  font-weight: bold;
  color: $gray-900;
  font-size: 13px;
  line-height: 19px;
}

.required {
  color: red;
  font-size: 13px;
  line-height: 19px;
}

.productContainer {
  margin-bottom: 12px;
  padding-top: 20px;
}

.productLabel {
  font-size: 13px;
  margin-bottom: -2px;
  height: 20px;
  display: grid;
  grid-template-columns: auto auto auto;
  grid-template-rows: auto auto;

  > :first-child {
    grid-column: span 3;
    margin-top: 20px;
    margin-bottom: 0;
  }

  label>span:first-child {
    padding: 9px;
  }

  > :last-child {
    grid-column: span 3;
  }
}

.dateTimeError {

  input:not([type])[readonly],
  input[type='text'][readonly],
  input[type='password'][readonly],
  input[type='email'][readonly],
  input[type='url'][readonly],
  input[type='time'][readonly],
  input[type='date'][readonly],
  input[type='datetime'][readonly],
  input[type='datetime-local'][readonly],
  input[type='tel'][readonly],
  input[type='number'][readonly],
  input[type='search'][readonly],
  textarea[readonly] {
    background-color: #ffecf0 !important;
    font-size: 16px;
    height: 36px;
    padding-bottom: 8px;
    padding-top: 6px;
    height: 22px;
  }
}

.dateTimeCalendar {

  input:not([type])[readonly],
  input[type='text'][readonly],
  input[type='password'][readonly],
  input[type='email'][readonly],
  input[type='url'][readonly],
  input[type='time'][readonly],
  input[type='date'][readonly],
  input[type='datetime'][readonly],
  input[type='datetime-local'][readonly],
  input[type='tel'][readonly],
  input[type='number'][readonly],
  input[type='search'][readonly],
  textarea[readonly] {
    font-size: 16px;
    padding-bottom: 8px;
    padding-top: 6px;
    padding-left: 9px;
    height: 22px;
    border: 2px solid $gray-200 !important;
  }
}

.errorMessage {
  width: 330px;
  height: 16px;
  color: red;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 24px;
  margin-top: 10px;
}

[class*='weekDayLabel'] {
  color: #878e93 !important;
}

.innerDefaultMessage {
  display: flex;
}

.message {
  margin-left: 4px;
}

.defaultMessage {
  font-family: 'TeX Gyre Heros';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 4px;
  color: #878e93;
  width: 330px;
  height: 30px;
}

.autocompletePlace {
  width: 102%;
  height: 38px;
  padding-left: 16px;
  font-family: $font-family-regular !important;
  font-size: 16px;
  text-overflow: ellipsis;
  border: 2px solid;
  border-color: $gray-200;
  background-color: $gray-100;

  &:focus-visible {
    border-color: $blue !important;
    outline: none;
  }

  &.hasError {
    border: 1px solid $red;
    background-color: $xlightred;
  }
}

.timeLabel {
  display: flex;
}

.timeSearch {
  display: flex;

  >div {
    >label {
      display: none;
    }

    // necessary because issue with material ui Tabs introducing multiple
    // muiButtonBase styles that override muiIconButton styles
    // seems to be occuring when both time pickers are in view
    >div {
      >div {
        >div {
          >button {
            padding: 3px;
            padding-left: 12px !important;
          }
        }
      }
    }
  }
}

.timePicker {
  flex: 1 1 auto;
  margin-right: 15px;
}

.input {
  font-family: 'PublicSans Regular';
  box-shadow: none;
  transition-property: box-shadow, border;
  transition-duration: $transition;
  border-radius: $border-radius !important;
  padding: 0.2rem 0 0.3rem 0 !important;
  height: 4rem;
  line-height: 1;
  border: 1px solid $gray-200;
  background: $gray-100;
  font-size: 1.6rem;
  color: $txt-body;
  overflow: hidden;
}

.inputFocused {
  border: 1px solid $blue !important;
  box-shadow: inset 0 0 0 1px $blue !important;
}

.calendarIconCt>div {
  margin-right: 0 !important;
}

.clockIcon {
  font-size: 1.6rem !important;
  color: $darkblue !important;
  transition: $transition !important;
  margin: 0 !important;
  cursor: pointer !important;
  border: 0;
}

.miles {
  position: relative;
  top: -29px;
  right: -56px;
  margin-left: 5px;
}

.address {
  display: flex;
  margin-bottom: -24px;
}

.addressInput {
  width: 69%;
}

.time {
  display: flex;
  column-gap: 16px;
}

.dropPin {
  font-size: 13px;
  font-weight: bold;
  border: none;
  background-color: $white;
  color: $darkblue;
  cursor: pointer;
  padding-left: 10px;
}

.radius {
  width: 55px;
  margin-left: 21px;
  margin-top: -2px;
}

.radiusContent>div input {
  min-height: 38px !important;
  border: 2px solid $gray-200 !important;
}

.radiusContent>div label {
  margin-top: 0.6rem;
  margin-bottom: 1rem;
}

.date {
  display: flex;
}

.dateSearchContainer {
  width: 0;
  flex: 1 1 0;
}

.dateSearch {
  margin-left: 0px;
}

.description {
  height: 80px;
  margin: 100px 0 0 0;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  text-align: center;
  color: $gray-400;
}

.descriptionHide {
  display: none;
}

.timePickerError {
  flex: 1 1 auto;
  margin-right: 15px;
}

.timePickerError div {
  border-radius: 4px;
  background-color: #ffecf0;
}

.timePickerError>div {
  >div {
    border: solid 1px #e92851;
  }
}

.deactivatedText {
  padding-left: 8px;
  color: $gray-600;
  font-size: 12px;
}

.errorMessageSpan {
  display: block;
  width: 200px;
  word-wrap: break-word;
  white-space: normal;
  color: #e92851;
  font-size: 13px;
}

.dateField {
  margin: 0 auto;
  overflow: hidden;
  width: 135px;

  >div {
    >label {
      display: none;
    }

    // necessary because issue with material ui Tabs introducing multiple
    // muiButtonBase styles that override muiIconButton styles
    // seems to be occuring when both date pickers are in view
    >div {
      >div {
        >div {
          >button {
            padding: 3px !important;
            padding-left: 12px !important;
          }
        }

        >input {
          font-size: 16px !important;
        }
      }
    }
  }
}