@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';

.text {
  font-family: $font-family-bold !important;
  font-weight: 800;
  font-size: 1.15rem;
  line-height: 1;
  padding: 0;
  white-space: nowrap;
  -webkit-font-smoothing: antialiased;
}

.label {
  padding: 0px 3px 1px 3px;
  border-radius: $border-radius;
  letter-spacing: 1px;
  box-shadow: 0 1px 1px 1px rgba(black, 0.5);
  transform: translate(-48%, 20px);
}

.labelTextWhite {
  color: white;
}

.labelTextBlack {
  color: 'black';
}