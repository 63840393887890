.contentContainer {
  margin-top: -13px;
}

.productLineTabsContainer {
  padding: 0 3.2rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

