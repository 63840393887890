@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_helpers.scss';

.bodyWrapper {
  flex-grow: 1;
  overflow-y: auto;
}

.headerAndContainer {
  height: auto;
}

.cardWrapper {
  padding: 60px 31px;
  position: relative;
  background: $gray-100;
}

.card {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15),
    0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 3px 1px -2px rgba(0, 0, 0, 0.07) !important;
  position: relative;
  overflow: visible !important;
}

.cardText,
.cardTextSmall {
  display: grid;
  grid-template-columns: 100%;

  p {
    min-height: 4rem;
    word-wrap: break-word !important;
    margin-bottom: 0;
  }

  :global(.large) p {
    margin: -0.5rem 0 0;
    font-size: 3rem;
    font-family: $font-family-bold !important;
    line-height: 1;
  }

  :global(img) {
    width: 125px;
  }

  p,
  label {
    white-space: normal !important;
  }
}

.cardText {
  grid-gap: 28px;
  padding: 32px 32px 24px;
}

.cardTextSmall {
  grid-gap: 20px;
  padding: 20px 8px;

  :global(.large) {
    margin-bottom: 15px;
  }
}

.switchContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: -7px;
}

.switchLabel {
  font-size: 1.6rem !important;
}

.switchNotAllowed {
  cursor: not-allowed;
}

.additionalComponent {
  transform: $transform-up-40;
}

@media (max-width: $breakpoint-admindetails) {
  .card {
    max-width: 650px;
  }
}