.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-center,
.flex-horizontal-center,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.footer {
  background-color: #151F28;
  color: white;
  position: fixed;
  bottom: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
}

.footerText {
  flex: 1 1 auto;
  text-align: center;
}

.footerTextLeft {
  flex: 1 1 auto;
  text-align: left;
}

.footerTextRight {
  flex: 1 1 auto;
  text-align: right;
}

