@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.vehicleTypesAddModal {
  width: 390px;
}

.name {
  width: 320px !important;
  margin-bottom: 40px !important;
}

.maxWeight {
  width: 160px !important;
  display: inline-block;
  margin-right: 30px;
  margin-bottom: 30px;
  height: 72px;
  vertical-align: top;
}

.uomDropDown {
  width: 130px !important;
  display: inline-block !important;
  margin-top: 6px;
  margin-bottom: 30px;
  vertical-align: top;
}

.required {
  color: $red;
  font-size: 18px;
  margin-left: 3px;
}