@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.infoBoxContainer {
  padding: 20px;
  background-color: white;
  color: $gray-900;
  border-radius: 0 0 $border-radius $border-radius;
}

.infoBoxWrap {
  border-radius: $border-radius;
  font-family: $font-family-regular !important;
  font-size: 1.6rem;
  -webkit-font-smoothing: antialiased;
  cursor: auto;
}

.title {
  display: flex;
  padding: 10px 6px 15px 20px;
  background-color: $gray-800;
  color: white;
  white-space: nowrap;
  border-radius: $border-radius $border-radius 0 0;
  justify-content: space-between;
  @extend %flexVerticalCenter;
}

.transparent {
  opacity: 0;
  height: 20px;
  background-color: transparent;
  border-color: transparent;
}

.padding10 {
  padding: 10px;
}

.actionButtons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.logoutPopoverContainer {
  display: inline-block;
}

.closeBtn {
  display: inline-block;
  transform: translateY(3px);
}

.logoutBtn {
  display: inline-block;
  transform: translate(11px, 4px);
}

.closeBtn:hover>i,
.logoutBtn:hover>i {
  color: $gray-500 !important;
}

.commitLogoutBtn {
  transition: $transition;

  &:hover {
    background: $gray-100 !important;
  }
}