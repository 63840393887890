.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-center,
.flex-horizontal-center,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.autocomplete {
  border: 2px solid #434c53;
  position: absolute !important;
  margin-top: -15px !important;
  border-radius: 10px;
  height: 30px !important;
  margin-left: -265px;
}

.searchWrapper {
  background: #f4f7f9 !important;
  min-width: 250px;
  width: 100%;
  max-width: 250px;
}
.searchWrapper > div > div {
  max-width: 100%;
}
.searchWrapper > div > div > div {
  max-width: 100%;
}

@media (max-width: 1450px) {
  .searchWrapper {
    min-width: 105px;
    max-width: 100%;
    flex: 1;
  }
}

