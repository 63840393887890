@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_helpers.scss';

.container {
  width: 100%;
}

.cannedMessagesLabel {
  font-size: 13px;
  margin: 6px 0 10px 0;
  color: $gray-900;
  font-weight: 700;
}

.cannedMessagesContainer {
  display: flex;
  flex-direction: column;
  background-color: $white;
  height: 360px;
}

.tabsContainer {
  background-color: $gray-200;
  border-bottom: 1px solid $gray-300;
}

.emptyStateContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $gray-100;
  height: 100%;

  img {
    margin-bottom: 16px;
  }

  .emptyStateMessage {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    line-height: 20px;
    ;
  }
}

.messagesContainer {
  height: calc(100% - 54px); // 54 = height of product line tabs header

  &.hasFooter {
    height: calc(100% - 116px); // 116 = 54 pixel tall header (product line tabs) + 62 pixel tall footer (add message button)
  }

  overflow-y: auto;
  background-color: $white;
  border-left: 1px solid $gray-200;
  border-right: 1px solid $gray-200;
  border-bottom: 1px solid $gray-200;

  .messageRow {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 10px 8px 16px;
    border-bottom: 1px solid $gray-200;

    &.readOnly {
      padding: 8px 16px;
    }

    .messageInputContainer {
      position: relative;
      flex-grow: 1;

      &:focus-within {
        .messageInputCharacterCount {
          opacity: 1;
        }

        input {
          padding-right: 100px;
        }
      }
    }

    .messageInputCharacterCount {
      transition: opacity 0.15s ease;
      opacity: 0;
      pointer-events: none;
      height: 20px;
      position: absolute;
      top: calc(50% - 10px); // vertically centers it. 10px is half the height
      right: 8px;
      border-radius: 2px;
      background-color: $gray-200;
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 4px;
    }
  }

  .messageInput {
    border-radius: 4px;
    width: 100%;
    border: 1px solid $gray-200;
    height: 36px;
    padding-left: 6px;

    &:focus {
      border: 2px solid $blue-hover;
    }
  }

  .removeMessageBtn {
    margin-left: 10px;
    padding: 5px;
    background: none;
    cursor: pointer;
    border: 2px solid transparent;
    border-radius: 4px;
    color: $darkblue;
    font-size: 15px;

    :hover {
      color: $gray-900;
    }

    &:focus-visible {
      border: 2px solid $blue;
    }
  }

  .readOnlyMessage {
    width: 100%;
    color: $gray-900;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.14px;
  }
}

.bottomBtnContainer {
  background-color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-left: 1px solid $gray-200;
  border-right: 1px solid $gray-200;
  border-bottom: 1px solid $gray-200;
}