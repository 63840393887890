@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.rightSidebar {
  width: 0;
  height: calc(100vh - 55px);
  pointer-events: none;
  overflow: hidden;
  background: $white;
  @extend %flex;
  flex-direction: column;

  &>div,
  form {
    width: $message-center-sidebar-width;
    flex: none;
  }

  &.open {
    pointer-events: all;
    width: $message-center-sidebar-width;
  }
}

.rightSidebarWindow {
  width: 0;
  pointer-events: none;
  overflow: hidden;
  background: $white;
  @extend %flex;
  flex-direction: column;
  height: calc(100vh - 55px);

  &>div,
  form {
    width: 100%;
    flex: none;
  }

  &.open {
    pointer-events: all;
    width: 100%;
  }
}

.bubbleContainer {
  flex: 1 !important;
  overflow-y: auto;
}

.icon {
  height: 25px;
  width: 25px;
}

.dropDownList-chev {
  transform: rotate(-90deg);
  display: inline-block;
  font-size: 11px;

  &:hover {
    cursor: pointer;
    opacity: $opacity-7;
  }
}

.dropDownList-chev-up {
  transform: rotate(90deg);
  display: inline-block;
  font-size: 11px;

  &:hover {
    cursor: pointer;
    opacity: $opacity-7;
  }
}

.listContainer {
  width: 325px;
  position: absolute;
  display: inline-block;
  background-color: #ffffff;
  opacity: 0.95;
  transform-origin: top;
  transform: scaleY(1);
  z-index: 10;
  overflow: auto;
  max-height: 319px; // Nine list items in height, plus margin
  box-shadow: 0 10px 10px -3px rgba(147, 147, 147, 0.35);
}

.listContent {
  font-size: 16px;
}

.listItemStyle {
  margin-top: 10px;
}

.listItemStyle:first-child {
  margin-top: -8px;
}

.listStyle {
  list-style-type: none;
  margin-left: -19px;
}