.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-center,
.flex-horizontal-center, .title,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.title,
.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.infoBoxContainer {
  padding: 20px;
  background-color: white;
  color: #151F28;
  border-radius: 0 0 3px 3px;
}

.infoBoxWrap {
  border-radius: 3px;
  font-family: "PublicSans Regular", Helvetica, Arial, sans-serif !important;
  font-size: 1.6rem;
  -webkit-font-smoothing: antialiased;
  cursor: auto;
}

.title {
  display: flex;
  padding: 10px 6px 15px 20px;
  background-color: #2A353E;
  color: white;
  white-space: nowrap;
  border-radius: 3px 3px 0 0;
  justify-content: space-between;
}

.transparent {
  opacity: 0;
  height: 20px;
  background-color: transparent;
  border-color: transparent;
}

.padding10 {
  padding: 10px;
}

.actionButtons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.logoutPopoverContainer {
  display: inline-block;
}

.closeBtn {
  display: inline-block;
  transform: translateY(3px);
}

.logoutBtn {
  display: inline-block;
  transform: translate(11px, 4px);
}

.closeBtn:hover > i,
.logoutBtn:hover > i {
  color: #878e93 !important;
}

.commitLogoutBtn {
  transition: 0.3s !important;
}
.commitLogoutBtn:hover {
  background: #f4f7f9 !important;
}

