@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.autoCompleteWithChipContainer {
  padding-bottom: 10px;
}

.chipInput {
  margin-right: 50px;
}

.chip {
  color: $gray-600 !important;
  background-color: $gray-100 !important;
  border: 1px solid $gray-200 !important;
  border-radius: $border-radius !important;
  height: 2.6rem !important;
  display: inline-flex !important;
  margin-bottom: 0.5rem;
  margin-right: 10px;
  margin-top: 4px;
  padding: 0 !important;
}

.chip:first-of-type {
  margin-left: 33px;
}

.chipRemove {
  width: 2.5rem !important;
  height: 2.6rem !important;
  background-color: transparent;
  border-radius: 0 $border-radius $border-radius 0;
  border: none;
  outline: none;
  margin: 0 !important;
  transition: $transition;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    color: $gray-600;
    font-size: 1.2rem;
    line-height: 1.2rem;
  }

  &:hover {
    background-color: $gray-200 !important;

    i {
      color: $gray-900 !important;
    }
  }
}

.menuItemBodyContainer {
  display: flex;
  flex-direction: row;
}

.suggestedMenuItemIcon {
  color: $gray-600;
  width: 20px !important;
  height: 13px !important;
  font-size: 1.7rem;
  margin-right: 5px;
}

.inputRoot {
  font-size: 16px !important;

  &:before {
    border-bottom: 1px solid $gray-200 !important;
  }

  &:after {
    border-bottom: 2px solid $blue !important;
  }
}

.inputIndent input {
  padding-left: 33px !important;
}

.chipLabel {
  font-family: $font-family-bold;
  font-size: 1.3rem;
  line-height: 2.6rem;
  padding: 0 6px 0 7px !important;
  margin: 0 !important;
  display: block !important;
  max-width: 195px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.popper {
  z-index: 1300;
  overflow: hidden !important;
  box-shadow: $boxshadow-medium;
  margin-top: 5px !important;
  border-radius: $border-radius;
}

.popperPaper {
  max-height: 365px;
  overflow-y: auto;
  overflow-x: hidden;
}

.menuItem {
  transition: $transition !important;
  font-size: 16px !important;
  padding: 10px 14px !important;
  font-family: $font-family-regular !important;
  display: block !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;

  &:hover {
    background: $gray-100 !important;
  }

  &:focus {
    outline: none !important;
  }
}

.productLineMenuItem {
  @extend .menuItem;
  border-bottom: solid $gray-200 1px !important;
}