.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-center,
.flex-horizontal-center,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.paper {
  height: 100%;
  border-radius: 3px 3px 0 0 !important;
}

.h4 {
  margin: 10px 10px 10px 15px;
  letter-spacing: 2px;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  font-size: 1.4rem;
}

.ticketNumberLink {
  background-color: transparent;
  border: transparent;
  color: #0A63F3;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  transition: 0.3s !important;
}

.receiptsButton {
  background: transparent;
  border: none;
  position: absolute;
  right: 5px;
  cursor: pointer;
  transition: 0.3s !important;
  height: 30px;
  width: 30px;
  border-radius: 3px;
}
.receiptsButton i {
  display: inline-block;
  transform: translateY(1px);
  font-size: 1.6rem;
  transition: 0.3s !important;
}
.receiptsButton:hover {
  background: #a5abaf;
}
.receiptsButton:active {
  background: #e1e4e7;
}

.wrapper {
  margin: 0 31px 40px 31px;
}

.ticketListHeader {
  background-color: #c8cdd0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  position: relative;
  border-radius: 3px 3px 0 0;
  width: 100%;
  overflow-x: auto;
}

.ticketListContainer {
  box-shadow: none !important;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15), 0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 3px 1px -2px rgba(0, 0, 0, 0.07) !important;
  overflow: hidden;
}
.ticketListContainer :global(.ReactTable) {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border: none !important;
}
.ticketListContainer :global(.ReactTable *) {
  box-sizing: border-box;
}
.ticketListContainer :global(.ReactTable .rt-table) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
}
.ticketListContainer :global(.ReactTable .rt-thead) {
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  user-select: none;
}
.ticketListContainer :global(.ReactTable .rt-thead.-header) {
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15), 0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 3px 1px -2px rgba(0, 0, 0, 0.07) !important;
}
.ticketListContainer :global(.ReactTable .rt-thead.-headerGroups) {
  background: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.ticketListContainer :global(.ReactTable .rt-thead .rt-tr) {
  text-align: center;
}
.ticketListContainer :global(.ReactTable .rt-thead .rt-th),
.ticketListContainer :global(.ReactTable .rt-thead .rt-td) {
  padding: 5px 5px;
  line-height: normal;
  position: relative;
  transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: inset 0 0 0 0 transparent;
}
.ticketListContainer :global(.ReactTable .rt-thead .rt-th:last-child),
.ticketListContainer :global(.ReactTable .rt-thead .rt-td:last-child) {
  border-right: 0;
}
.ticketListContainer :global(.ReactTable .rt-thead .rt-resizable-header) {
  padding: 10px 0 10px 15px;
  outline: 0;
  text-align: left;
}
.ticketListContainer :global(.ReactTable .rt-thead .rt-resizable-header:last-child) {
  overflow: hidden;
}
.ticketListContainer :global(.ReactTable .rt-thead .rt-resizable-header-content) {
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
}
.ticketListContainer :global(.ReactTable .rt-thead .rt-header-pivot) {
  border-right-color: #f4f7f9;
}
.ticketListContainer :global(.ReactTable .rt-thead .rt-header-pivot:after),
.ticketListContainer :global(.ReactTable .rt-thead .rt-header-pivot:before) {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ticketListContainer :global(.ReactTable .rt-thead .rt-header-pivot:after) {
  border-color: rgba(255, 255, 255, 0);
  border-left-color: #f4f7f9;
  border-width: 8px;
  margin-top: -8px;
}
.ticketListContainer :global(.ReactTable .rt-thead .rt-header-pivot:before) {
  border-color: rgba(102, 102, 102, 0);
  border-left-color: #f4f7f9;
  border-width: 10px;
  margin-top: -10px;
}
.ticketListContainer :global(.ReactTable .rt-tbody) {
  flex: 99999 1 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
.ticketListContainer :global(.ReactTable .rt-tbody .rt-tr) {
  border-bottom: 1px solid #e1e4e7;
}
.ticketListContainer :global(.ReactTable .rt-tbody .rt-tr:hover .rt-td) {
  background-color: #f4f7f9;
  cursor: pointer;
}
.ticketListContainer :global(.ReactTable .rt-tbody .rt-tr:hover .rt-td):nth-child(2) {
  text-decoration: underline;
}
.ticketListContainer :global(.ReactTable .rt-tbody .rt-tr:active .rt-td) {
  background-color: #e1e4e7;
}
.ticketListContainer :global(.ReactTable .rt-tbody .rt-td) {
  transition-duration: 0.3s;
  padding: 3px 0 5px 15px;
  border-bottom: none !important;
  border-radius: 0 !important;
}
.ticketListContainer :global(.ReactTable .rt-tbody .rt-tr-group.selected) :global(.rt-td) {
  background-color: #E2F8FF;
}
.ticketListContainer :global(.ReactTable .rt-tbody .rt-td) {
  padding: 3px 0 0 15px;
  border-bottom: none !important;
}
.ticketListContainer :global(.ReactTable .rt-tbody .rt-expandable) {
  text-overflow: clip;
}
.ticketListContainer :global(.ReactTable .rt-tr-group) {
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-bottom: none !important;
}
.ticketListContainer :global(.ReactTable .rt-tr-group:last-of-type .rt-tr) {
  border-bottom: 1px solid transparent !important;
}
.ticketListContainer :global(.ReactTable .rt-tr) {
  flex: 1 0 auto;
  display: inline-flex;
}
.ticketListContainer :global(.ReactTable .rt-tr .rt-td):nth-child(2):hover {
  text-decoration: underline !important;
}
.ticketListContainer :global(.ReactTable .rt-th),
.ticketListContainer :global(.ReactTable .rt-td) {
  flex: 1 0 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 7px 5px;
  transition: 0.3s ease;
  transition-property: width, min-width, padding, opacity, background-color;
  min-width: 11px !important;
}
.ticketListContainer :global(.ReactTable .rt-th.-hidden),
.ticketListContainer :global(.ReactTable .rt-td.-hidden) {
  width: 0 !important;
  min-width: 0 !important;
  padding: 0 !important;
  border: 0 !important;
  opacity: 0 !important;
}
.ticketListContainer :global(.ReactTable .rt-expander) {
  display: inline-block;
  position: relative;
  color: transparent;
  margin: 0 10px;
}
.ticketListContainer :global(.ReactTable .rt-expander:after) {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  border-left: 5.04px solid transparent;
  border-right: 5.04px solid transparent;
  border-top: 7px solid rgba(0, 0, 0, 0.8);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.ticketListContainer :global(.ReactTable .rt-expander.-open:after) {
  transform: translate(-50%, -50%) rotate(0);
}
.ticketListContainer :global(.ReactTable .rt-resizer) {
  display: none;
}
.ticketListContainer :global(.ReactTable .rt-tfoot) {
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
}
.ticketListContainer :global(.ReactTable .rt-tfoot .rt-td:last-child) {
  border-right: 0;
}
.ticketListContainer :global(.ReactTable.-striped .rt-tr.-odd) {
  background: #f4f7f9;
}
.ticketListContainer :global(.ReactTable .rt-noData) {
  display: block;
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: 1;
  pointer-events: none;
  padding: 20px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
  font-weight: bold;
}
.ticketListContainer :global(.ReactTable .-loading) {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}
.ticketListContainer :global(.ReactTable .-loading > div) {
  position: absolute;
  display: block;
  text-align: center;
  width: 100%;
  top: 50%;
  left: 0;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
  transform: translateY(-52%);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.ticketListContainer :global(.ReactTable .-loading.-active) {
  opacity: 1;
  z-index: 2;
  pointer-events: all;
}
.ticketListContainer :global(.ReactTable .-loading.-active > div) {
  transform: translateY(50%);
}
.ticketListContainer :global(.ReactTable .rt-resizing .rt-th),
.ticketListContainer :global(.ReactTable .rt-resizing .rt-td) {
  transition: none !important;
  cursor: col-resize;
  user-select: none;
}
.ticketListContainer :global(.ReactTable .rt-thead .rt-th.-sort-asc),
.ticketListContainer :global(.ReactTable .rt-thead .rt-td.-sort-asc) {
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0.6);
}
.ticketListContainer :global(.-sort-desc.-cursor-pointer .rt-resizable-header-content:after) {
  width: 0;
  height: 0;
  font-family: "icomoon";
  content: "\e939";
  color: #F66A00;
  position: relative;
  top: 0;
  margin-left: 10px;
  font-size: 8px;
  overflow: hidden;
}
.ticketListContainer :global(.-sort-asc.-cursor-pointer .rt-resizable-header-content:after) {
  width: 0;
  height: 0;
  font-family: "icomoon";
  content: "\e938";
  color: #F66A00;
  position: relative;
  top: 0;
  margin-left: 10px;
  font-size: 8px;
  overflow: hidden;
}
.ticketListContainer :global(.-cursor-pointer .rt-resizable-header-content:after) {
  width: 0;
  height: 0;
  font-family: "icomoon";
  content: "\e939";
  color: #a5abaf;
  position: relative;
  top: 0;
  margin-left: 10px;
  font-size: 8px;
  overflow: hidden;
}
.ticketListContainer :global(.driver-cell),
.ticketListContainer :global(.vehicle-cell) {
  border-bottom: solid 4px white;
}
.ticketListContainer :global(.css-9za7aq:after) {
  box-shadow: 6px 0 6px -6px inset rgba(0, 0, 0, 0.4);
  position: absolute;
  width: 15px;
  content: " ";
}
.ticketListContainer :global(.css-9za7aq) {
  border-right: none !important;
}
.ticketListContainer :global(.driver-cell.css-9za7aq:after) {
  height: calc(100% + 4px);
  top: 0;
  right: -15px;
}
.ticketListContainer :global(.driver-header.css-9za7aq:after) {
  height: calc(100% + 1px);
  bottom: -1px;
  right: -15px;
}
.ticketListContainer :global(.rt-tr--add) {
  animation-name: rowAnim;
  animation-duration: 0.4s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-direction: reverse;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-play-state: running;
}
.ticketListContainer :global(.rt-tr--remove) {
  animation-name: rowAnim;
  animation-duration: 0.4s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-play-state: running;
}

.noTicketsContainer {
  height: 8rem;
  background: #f4f7f9;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

.noTicketsText {
  color: #878e93;
  font-size: 1.3rem;
  font-family: "PublicSans Regular", Helvetica, Arial, sans-serif;
  display: inline-block;
  transform: translateY(-2px);
}

.noTicketsShadow {
  position: absolute;
  top: -2rem;
  left: 0;
  right: 0;
  height: 2rem;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

