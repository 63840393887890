.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-center,
.flex-horizontal-center,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.dropDownListContainer {
  width: 120px;
}
.dropDownListContainer > div > div[role=button] {
  font-size: 1.6rem;
}

.row {
  height: 49px;
}

.cellText {
  font-size: 16px;
  padding-left: 13px;
}

.marginLeft {
  margin-left: 5px;
}

.paddingLeft {
  padding-left: 16px;
}

.emptyState {
  width: 100%;
  height: 580px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.circleLoader {
  border: 8px solid #e1e4e7;
  border-left-color: #00AC4A;
  animation: loader-spin 1.2s infinite linear;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  transform: translateX(8px);
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.headerContainer {
  background: white;
  padding: 0;
}

.headerRow {
  padding: 0;
  border-bottom: 1px solid #e1e4e7;
}

.headerStyle {
  font-size: 1.3rem;
  color: #151F28;
  text-align: left;
  border: none;
  background: white;
  line-height: 1 !important;
  padding-top: 0 !important;
  padding-bottom: 10px !important;
}
.headerStyle span {
  line-height: 1;
}

.headerAccessMargin {
  transform: translateX(-2px) !important;
}

.borderLeft {
  border: 1px solid #e1e4e7;
  border-right: none;
}

.borderRight {
  border: 1px solid #e1e4e7;
  border-left: none;
}

.closeButton {
  font-size: 12px;
  padding-right: 10;
  text-align: right;
}

