@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';

.main,
.mainWithTickets {
  height: 100%;
  flex-grow: 1; // allows the map to occupy the rest of the space
  width: 0; // fixes horizontal scroll issue for admin table
  z-index: 100;
  position: relative;

  >div {
    display: flex;
    flex-flow: column;
    height: 100%;
  }
}

.main>div:first-of-type {
  height: 100%;
}

.mainWithTickets>div:first-of-type {
  height: 60% !important;
  position: relative;
}

.btn-primary {
  background: rgb(96, 96, 97);
  color: #ffffff;
}

.layoutContainer {
  @extend %flex;
  height: $window-height;
  width: $window-width;
  overflow: hidden;
  background: $gray-100;
}

.MuiTypography-root .MuiTypography-caption .MuiDayCalendar-weekDayLabel .css-1brs47w-MuiTypography-root-MuiDayCalendar-weekDayLabel {
  color: green;
  font-size: 5000px;
}