@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';

.footerMessageContainer {
  display: inline-block;
  transform: translateY(-3px);
}

.text {
  font-size: 1.3rem;
  font-family: $font-family-regular;
  color: $gray-900;
  display: inline-block;
}

.asterisk {
  color: $red;
  font-family: $font-family-bold;
  font-size: 1.8rem;
  display: inline-block;
  transform: translateY(3px);
  margin-right: 0.3rem;
}

.errorMessageText {
  color: $darkred;
}