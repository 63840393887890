@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.detailHeader {
  background: white;
  display: flex;
  justify-content: space-between;
  box-shadow:
    0px 1px 5px 0px rgba(0, 0, 0, 0.15),
    0px 2px 2px 0px rgba(0, 0, 0, 0.09),
    0px 3px 1px -2px rgba(0, 0, 0, 0.07) !important;
  clip-path: inset(0px 0px -20px 0px) !important;
  padding-left: 20px;
  width: 100%;
  z-index: 4;
  height: $tickets-header-height !important;
}

.receiptBtn {
  margin: 7px 17px 7px 0;
  white-space: nowrap;
}

.receiptBtnDisabled {
  background-color: $gray-100 !important;
  color: $gray-500 !important;
  box-shadow: none !important;
  cursor: not-allowed !important;
}

.receiptBtnIcon {
  font-size: 22px !important;
  vertical-align: text-top !important;
}

.receiptBtnIconDisabled {
  color: $gray-500 !important;
}

.paper {
  max-height: calc(100% - 55px) !important;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  transition: none !important;
  transform: unset !important;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.tabsCt {
  height: 55px;
  width: 35%;
  max-width: 330px;
  min-width: 220px;
}

.printBtn,
.printBtnClicked {
  display: flex;
  flex-direction: row;
  align-items: center;
  background: none;
  border: none;
  border-radius: $border-radius;
  cursor: pointer;
  padding: 0.1rem 1.3rem 0.35rem 1.3rem;
  min-height: 4rem;
  min-width: 4rem;
  margin: 7px 7px;
  transition: $transition;
}

.printBtnClicked,
.printBtn:hover {
  background-color: $gray-100;
}

.printBtnClicked span,
.printBtn span {
  margin-left: 7px;
  margin-right: 5px;
  font-family: $font-family-bold;
}

.printBtnClicked span {
  color: $darkblue !important;
}

.printIcon,
.printIcon img {
  height: 18px;
  width: 18px;
}

.printBtn i {
  font-weight: bold;
  color: #000;
}

.printBtnClicked i {
  font-weight: bold;
  color: $darkblue !important;
}

.printPopover {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.printPopover button {
  background: none;
  border: none;
  color: $gray-900;
  font-size: 16px;
  padding: 14px;
  width: 165px;
  cursor: pointer;
  text-align: start;
}

.printPopover button:hover {
  background-color: $gray-100;
}

.printVisibleOnly,
.hidden {
  height: 0;
  width: 0;
  visibility: hidden;
  display: none;
}

.printHeader {
  font-size: 0;
  visibility: hidden;
  display: none;
}

.pageBreak {
  display: none;
  height: 0;
  margin-top: 0;
}

.enabledCloseSection {
  display: flex;
  justify-content: flex-start;
  padding: 0px 0px 0px 30px;
  height: 40px;
  background: $white;
  align-items: center;

  >div {
    width: 121px;
    height: 36px;
    padding: 0px 10px 0px 10px;
    gap: 8px;
    border-radius: 3px;
    margin: 8px 5px 0px 0px;

    >button {
      width: 100%;
      height: 100%;
      background: transparent !important;
      border: 0px !important;
      padding: 0px 0px 0px 0px !important;
      font-size: 13px !important;
      color: $gray-900 !important;
      display: flex;
      align-items: center;
      cursor: pointer;

      >svg {
        font-size: 16px !important;
        color: #1C63f3 !important;
        margin-right: 5px;
      }

      >span {
        font-family: $font-family-bold !important;
      }
    }
  }

  >div:hover {
    background: $gray-100 !important;
  }
}

@media print {
  @page {
    size: portrait;
  }

  * {
    box-shadow: none !important;
    font-size: 10px !important;
  }

  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }

  .paper {
    max-height: 100% !important;
    overflow: visible !important;
    width: 100%;
    height: 100%;
  }

  h2 {
    font-size: 14px !important;
  }

  .printVisibleOnly {
    height: auto;
    width: 100%;
    visibility: visible;
    display: block;
  }

  .detailHeader {
    height: 0;
    width: 0;
    visibility: hidden;
    display: none;
  }

  .printHeader {
    visibility: visible;
    display: block;
    margin: 0;
    padding: 26px 0 5px 28px;
    font-size: 22px !important;
    font-family: $font-family-bold;
    color: $gray-900;
  }

  .pageBreak {
    display: block;
    margin-top: 180px;
    page-break-before: always !important;
    break-before: always !important;
  }
}