@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_helpers.scss';
@import '../../shared/admin/AdminCard.module.scss';

.wrapper {
  display: flex;
  flex-wrap: wrap;

  >div {
    flex-grow: 1 !important;
    height: auto !important;
    @extend %ifValueEmpty;

    &:not(:last-of-type) {
      width: 25% !important;
    }

    &:first-of-type {
      width: 100% !important;

      p {
        font-family: $font-family-bold !important;
      }
    }

    &.plants {
      order: 2;

      label {
        margin-bottom: 12px;
      }

      p {
        height: 24px !important;
        line-height: 24px !important;
        vertical-align: top !important;
        margin: 2px 0 4px 0 !important;
        padding: 2px 0 4px 0 !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap !important;
      }
    }
  }

  .spacer {
    width: 25% !important;
    order: 3;
  }

  .buttonClass {
    margin-bottom: -2rem !important;
    transform: translateY(-0.4rem) !important;
    text-align: left !important;
  }

  p:first-of-type {
    margin-bottom: -2rem !important;
    transform: translateY(-0.4rem) !important;
  }

  p:not(:first-of-type) {
    margin-top: 1.4rem !important;
  }

  &.editWrapper {
    >div {

      &:not(:last-of-type),
      &:first-of-type,
      &:last-of-type {
        width: 100% !important;
        max-width: 500px;
      }
    }

    .fullName {
      display: none !important;
    }
  }
}

.resetPasswordBody {
  white-space: pre-wrap;
  order: 1;

  b {
    font-family: $font-family-bold !important;
  }
}

@media (max-width: $breakpoint-admindetails) {
  .wrapper {
    >div {

      &:not(:last-of-type),
      &:first-of-type,
      &:last-of-type {
        width: 100% !important;
      }
    }
  }
}

.asterisk {
  color: #e92851;
  font-family: $font-family-bold;
  font-size: 1.8rem;
  display: inline-block;
  transform: translateY(3px);
  margin-left: 0.3rem;
  line-height: 0.5;
}

.productLines {
  font-size: 16px !important;
  font-weight: 400 !important;
  margin-bottom: 2rem;
  font-family: $font-family-regular !important;
  color: #151f28;
}

.radioBtn {
  color: $gray-900 !important;
  padding: 12px !important;
  margin-left: 10px !important;
  @extend .radioBtnSize;

  :global(.MuiSvgIcon-root) {
    font-size: 2.4rem !important;
  }
}

.radioBtnSize {
  height: 10px;
  width: 10px;
  margin-left: 13px !important;
}

.radioBtnSelected {
  color: $blue !important;
  @extend .radioBtnSize;
}

.radioLabel {
  font-size: 16px !important;
  font-weight: 400 !important;
  margin: 4px !important;
  font-family: $font-family-regular !important;
  color: $gray-900;
}

.checkboxLabel {
  color: $gray-900 !important;
  font-size: 16px !important;
}

.checkboxLabel span,
.checkboxLabel span input {
  padding-top: 2px !important;
  padding-bottom: 2px !important;

  &:hover {
    background: none !important;
  }
}

.checkboxUnchecked {
  color: $gray-900 !important;
  font-size: 16px !important;
}

.checkboxChecked {
  color: $blue !important;
  font-size: 16px !important;
}

.weighmaster {
  margin-top: -30px;
}