@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

$arrowSize: 16px;
$arrowRounding: 3px;

/* READ STYLES */
.wrapper {
  display: flex;
  flex-wrap: wrap;

  >div {
    flex-grow: 1 !important;
    height: auto !important;
    @extend %ifValueEmpty;
    width: 100% !important;
  }
}

.marginLeft {
  margin-left: 5px !important;
}

.inputNameContainer {
  align-self: center;
  overflow: hidden;
}

.regionsTabContainer {
  overflow-x: auto;
  border-bottom: 1px solid $gray-300;
  margin-top: -2rem;

  .btnContainer {
    opacity: 1 !important;
  }

  .btnContainerDisabled {
    opacity: 0.4 !important;
  }
}

.paper {
  overflow: visible !important; // this allows the arrow to stick out above the Popover element
  top: 278px !important;
  box-shadow: $boxshadow-heavy !important;
  transform: translateY(-35px) !important;
}

.editPopover {
  display: flex;
  padding: 6px;
  width: auto;
}

.nameLabel {
  margin-left: 2px;
}

.inputName {
  font-size: 2.4rem;
  word-wrap: break-word;
  white-space: normal;
  line-height: 1;
  margin-bottom: 0 !important;

  span {
    font-family: $font-family-bold !important;
  }
}

.inputStyle {
  margin-right: 6px;
  font-size: 16px;
}

.editText {
  border-bottom: dashed $blue 1px !important;
}

.editNameIcon {
  font-size: 2rem !important;
  color: $blue !important;
  background: none !important;
  box-shadow: none !important;
  transition: $transition !important;

  &:hover {
    cursor: pointer !important;
    color: $blue-hover !important;
  }

  &:active {
    filter: brightness(1.2) !important;
  }
}

.buttonStyle {
  width: 10rem;

  &:last-of-type {
    margin-left: 6px;
  }
}

.arrowClass {
  display: block;
  height: $arrowSize;
  width: $arrowSize;
  background-color: $white;
  border: inherit;
  position: absolute;
  top: -$arrowSize * 0.5;
  left: 20px;
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  transform: rotate(135deg);
  border-radius: 0 0 0 $arrowRounding;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
}

.permissionsLabel {
  grid-column: 1 / span 4;
  overflow-x: auto;
  padding: 3px 2px;
  font-size: 16px;
  color: $gray-600;
  border-bottom: 1px solid $gray-300;
}

.lockPopper {
  z-index: 9999 !important;
  opacity: 1 !important;
  margin-left: -20px;
}

.lockTooltip {
  background: $info !important;
  padding: 0.6rem 1rem !important;
  font-size: 1.3rem !important;
  line-height: 1.3 !important;
  white-space: nowrap;
  border-radius: $border-radius !important;
  pointer-events: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 6px;
    pointer-events: none;
    border-right-color: $info;
    top: 100%;
    right: 44%;
    transform: translateY(0%) rotate(-90deg);
  }
}

.disabledRegionName {
  font-weight: bolder;
  font-family: $font-family-bold;
}

.disabledClickIcon {
  pointer-events: auto !important;
  cursor: not-allowed !important;
}