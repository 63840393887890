@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';

.categoryLink {
  transition: $transition;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: none;

    p {
      outline: none;
    }
  }
}