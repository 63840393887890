@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.controlledSubjectField {
  width: 100% !important;
}

.controlledMessageField {
  width: 100% !important;
}

.container {
  display: flex;
  flex-direction: column;
  height: 90vh;
}

.chipsContainer {
  padding: 0 2.2rem 0 2.2rem;
}

.recipientsContainer {
  width: 100%;
}

.textFieldContainer {
  margin-top: auto;
  position: relative;
  z-index: 2;

  &:before {
    content: '';
    width: 100%;
    height: 5%;
    position: absolute;
    top: -13px;
    left: 0;
    background:
      /* Shadow covers */
      linear-gradient(transparent 0%,
        transparent),
      linear-gradient(transparent, transparent 100%) 0 100%,
      /* Shadows */
      radial-gradient(farthest-side at 50% 100%, transparent, transparent),
      radial-gradient(farthest-side at 50% 100%,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0)) 0 50%;
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  }
}

.hideBottomShadow {
  &:before {
    display: none;
  }
}

.newMessageContainer {
  overflow: auto;
  position: relative;
  z-index: 1;
  height: 100%;
}

.chipInputContainer {
  position: relative;

  >div>div>div {
    >label[class*="MuiInputLabel-formControl"] {
      top: -8px;
    }

    >div {
      >input {
        position: relative;
        top: -13px;
        border-bottom: 1px solid $gray-200;
        border-radius: 0px !important;
        padding-bottom: 4px;

        &:focus {
          border-bottom: 1px solid $blue;
        }
      }

      >fieldset {
        display: none;
      }
    }
  }
}

.label {
  position: absolute;
  left: 0;
  top: 1rem;
  font-size: 1.3rem;
  letter-spacing: 0.2rem;
  font-family: $font-family-bold;
  text-transform: uppercase;
  color: $txt-body;
  line-height: 1;
  z-index: 1;
}

.title {
  font-size: 1.3rem;
  letter-spacing: 0.15rem;
  font-family: $font-family-bold;
  text-transform: uppercase;
  color: $txt-body;
  line-height: 1;
  padding: 1.4rem 2.2rem 1rem 2.2rem;
  position: relative;
  z-index: 2;

  &:after {
    content: '';
    width: 100%;
    height: 34%;
    position: absolute;
    bottom: -13px;
    left: 0;
    background:
      /* Shadow covers */
      linear-gradient(transparent 0%,
        transparent),
      linear-gradient(transparent, transparent 100%) 0 100%,
      /* Shadows */
      radial-gradient(farthest-side at 50% 100%, transparent, transparent),
      radial-gradient(farthest-side at 50% 0%,
        rgba(0, 0, 0, 0.3),
        rgba(0, 0, 0, 0)) 0 50%;
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  }
}

.hideTopShadow {
  &:after {
    display: none;
  }
}

.clearChips {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -0.7rem;
  top: 0.3rem;
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 3px;

  i {
    font-size: 2rem;
    color: $gray-600;
    transition: $transition;
    line-height: 1;
  }

  &:hover>i {
    color: $gray-500;
  }
}


.popper {
  z-index: 1300;
  overflow: hidden !important;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15), 0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 3px 1px -2px rgba(0, 0, 0, 0.07) !important;
  border-radius: 3px;
  margin-bottom: 1px !important;
}