.wrapper > div p:empty::after {
  content: "N/A";
  color: #a5abaf;
}
.wrapper > div p[title="N/A"] {
  color: #a5abaf;
}
.wrapper > div p[placeholder="N/A"] {
  color: #a5abaf;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-center,
.flex-horizontal-center,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

/* READ STYLES */
.wrapper {
  display: flex;
  flex-wrap: wrap;
}
.wrapper > div {
  flex-grow: 1 !important;
  height: auto !important;
  width: 100% !important;
}

.marginLeft {
  margin-left: 5px !important;
}

.inputNameContainer {
  align-self: center;
  overflow: hidden;
}

.regionsTabContainer {
  overflow-x: auto;
  border-bottom: 1px solid #c8cdd0;
  margin-top: -2rem;
}
.regionsTabContainer .btnContainer {
  opacity: 1 !important;
}
.regionsTabContainer .btnContainerDisabled {
  opacity: 0.4 !important;
}

.paper {
  overflow: visible !important;
  top: 278px !important;
  box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.3), 0 6px 10px 0 rgba(0, 0, 0, 0.24), 0 1px 18px 0 rgba(0, 0, 0, 0.22) !important !important;
  transform: translateY(-35px) !important;
}

.editPopover {
  display: flex;
  padding: 6px;
  width: auto;
}

.nameLabel {
  margin-left: 2px;
}

.inputName {
  font-size: 2.4rem;
  word-wrap: break-word;
  white-space: normal;
  line-height: 1;
  margin-bottom: 0 !important;
}
.inputName span {
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif !important;
}

.inputStyle {
  margin-right: 6px;
  font-size: 16px;
}

.editText {
  border-bottom: dashed #008EF3 1px !important;
}

.editNameIcon {
  font-size: 2rem !important;
  color: #008EF3 !important;
  background: none !important;
  box-shadow: none !important;
  transition: 0.3s !important !important;
}
.editNameIcon:hover {
  cursor: pointer !important;
  color: #17A9FF !important;
}
.editNameIcon:active {
  filter: brightness(1.2) !important;
}

.buttonStyle {
  width: 10rem;
}
.buttonStyle:last-of-type {
  margin-left: 6px;
}

.arrowClass {
  display: block;
  height: 16px;
  width: 16px;
  background-color: white;
  border: inherit;
  position: absolute;
  top: -8px;
  left: 20px;
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  transform: rotate(135deg);
  border-radius: 0 0 0 3px;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
}

.permissionsLabel {
  grid-column: 1/span 4;
  overflow-x: auto;
  padding: 3px 2px;
  font-size: 16px;
  color: #636b71;
  border-bottom: 1px solid #c8cdd0;
}

.lockPopper {
  z-index: 9999 !important;
  opacity: 1 !important;
  margin-left: -20px;
}

.lockTooltip {
  background: #2A353E !important;
  padding: 0.6rem 1rem !important;
  font-size: 1.3rem !important;
  line-height: 1.3 !important;
  white-space: nowrap;
  border-radius: 3px !important;
  pointer-events: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}
.lockTooltip:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 6px;
  pointer-events: none;
  border-right-color: #2A353E;
  top: 100%;
  right: 44%;
  transform: translateY(0%) rotate(-90deg);
}

.disabledRegionName {
  font-weight: bolder;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
}

.disabledClickIcon {
  pointer-events: auto !important;
  cursor: not-allowed !important;
}

