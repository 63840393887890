.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-center,
.flex-horizontal-center,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.headerAndContainer {
  height: 100%;
}

.tableContainer {
  height: 100%;
  position: relative;
}

.exportButtonContainer {
  position: absolute;
  right: 0;
  top: -52px;
}

.ordersHeaderAndContainer {
  flex-direction: column !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
}

.geotraxTableNoData {
  width: 100%;
  height: calc(100% - 10.5rem);
  background: #f4f7f9;
  position: relative;
  padding: 20px 30px;
}
.geotraxTableNoData :global(.ReactTable) {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  border: none;
}

.geotraxTable960 {
  max-width: 960px;
  width: 100%;
  height: calc(100% - 10.5rem);
  background: #f4f7f9;
  position: relative;
  padding: 20px 0px 10px 0px;
  margin-left: auto;
  margin-right: auto;
}
.geotraxTable960 :global(.ReactTable) {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.geotraxTable960 :global(.ReactTable *) {
  box-sizing: border-box;
}
.geotraxTable960 :global(.ReactTable .rt-table) {
  flex: auto 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
  position: relative;
}
.geotraxTable960 :global(.ReactTable .rt-thead) {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  user-select: none;
}
.geotraxTable960 :global(.ReactTable .rt-tbody) {
  overflow: visible !important;
}
.geotraxTable960 :global(.ReactTable .rt-table .rt-tbody) {
  height: fit-content;
  max-height: fit-content;
  min-height: fit-content;
}
.geotraxTable960 :global(.ReactTable .rt-thead.-headerGroups) {
  background: rgba(0, 0, 0, 0.03);
}
.geotraxTable960 :global(.ReactTable .rt-thead.-filters) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.geotraxTable960 :global(.ReactTable .rt-thead.-filters input),
.geotraxTable960 :global(.ReactTable .rt-thead.-filters select) {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline: none;
}
.geotraxTable960 :global(.ReactTable .rt-thead.-filters .rt-th) {
  border-right: 1px solid rgba(0, 0, 0, 0.02);
}
.geotraxTable960 :global(.ReactTable .rt-thead.-header) {
  position: sticky;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15), 0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 3px 1px -2px rgba(0, 0, 0, 0.07);
}
.geotraxTable960 :global(.ReactTable .rt-thead .rt-tr) {
  text-align: center;
}
.geotraxTable960 :global(.ReactTable .rt-thead .rt-th),
.geotraxTable960 :global(.ReactTable .rt-thead .rt-td) {
  background: #f4f7f9;
  padding: 5px 5px;
  line-height: normal;
  transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.geotraxTable960 :global(.ReactTable .rt-thead .rt-th.-cursor-pointer),
.geotraxTable960 :global(.ReactTable .rt-thead .rt-td.-cursor-pointer) {
  cursor: pointer;
}
.geotraxTable960 :global(.ReactTable .rt-thead .rt-resizable-header) {
  padding: 10px 0 10px 15px;
  overflow: visible;
  outline: 0;
  text-align: left;
  box-shadow: none;
}
.geotraxTable960 :global(.-sort-desc.-cursor-pointer .rt-resizable-header-content:after) {
  width: 0;
  height: 0;
  font-family: "icomoon";
  content: "\e939";
  color: #F66A00;
  position: absolute;
  top: 15px;
  margin-left: 10px;
  font-size: 8px;
}
.geotraxTable960 :global(.-sort-asc.-cursor-pointer .rt-resizable-header-content:after) {
  width: 0;
  height: 0;
  font-family: "icomoon";
  content: "\e938";
  color: #F66A00;
  position: absolute;
  top: 15px;
  margin-left: 10px;
  font-size: 8px;
}
.geotraxTable960 :global(.-cursor-pointer .rt-resizable-header-content:after) {
  width: 0;
  height: 0;
  font-family: "icomoon";
  content: "\e939";
  color: #a5abaf;
  position: absolute;
  top: 15px;
  margin-left: 10px;
  font-size: 8px;
}
.geotraxTable960 :global(.rt-resizable-header-content) {
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
}
.geotraxTable960 :global(.header) {
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
}
.geotraxTable960 :global(.ReactTable .rt-thead .rt-resizable-header:last-child) {
  overflow: hidden;
}
.geotraxTable960 :global(.ReactTable .rt-thead .rt-resizable-header-content) {
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 900;
  font-size: 12px;
  display: inline;
}
.geotraxTable960 :global(.ReactTable .rt-thead .rt-header-pivot) {
  border-right-color: #f4f7f9;
}
.geotraxTable960 :global(.ReactTable .rt-thead .rt-header-pivot:after),
.geotraxTable960 :global(.ReactTable .rt-thead .rt-header-pivot:before) {
  left: 100%;
  top: 50%;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.geotraxTable960 :global(.ReactTable .rt-thead .rt-header-pivot:after) {
  margin-top: -8px;
}
.geotraxTable960 :global(.ReactTable .rt-thead .rt-header-pivot:before) {
  margin-top: -10px;
}
.geotraxTable960 :global(.ReactTable .rt-tbody) {
  flex: 99999 1 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  max-height: 800px;
  min-height: 50px;
}
.geotraxTable960 :global(.ReactTable .rt-tbody .rt-tr-group) {
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
  flex: 0 0 auto;
}
.geotraxTable960 :global(.ReactTable .rt-tbody .rt-tr-group:hover) {
  cursor: pointer;
}
.geotraxTable960 :global(.ReactTable .rt-tbody .rt-tr-group:hover .rt-td) {
  background: #f4f7f9;
}
.geotraxTable960 :global(.ReactTable .rt-tbody .rt-td) {
  text-align: left;
  padding-left: 15px;
  background: white;
  font-size: 15px;
  line-height: 2;
  transition: 0.3s;
}
.geotraxTable960 :global(.ReactTable .rt-tbody .rt-tr-group:has(input[type="checkbox"]:checked) .rt-td) {
  background: #f4f7f9;
}
.geotraxTable960 :global(.new-highlight) {
  background: #E5FCF5 !important;
}
.geotraxTable960 :global(.ReactTable .rt-tbody .rt-td .pin) {
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
}
.geotraxTable960 :global(.ReactTable .rt-tbody .rt-expandable) {
  cursor: pointer;
  text-overflow: clip;
}
.geotraxTable960 :global(.ReactTable .rt-tr-group) {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.geotraxTable960 :global(.ReactTable .rt-tr) {
  flex: 1 0 auto;
  display: inline-flex;
}
.geotraxTable960 :global(.ReactTable .rt-th),
.geotraxTable960 :global(.ReactTable .rt-td) {
  flex: 1 0 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 7px 5px;
  overflow: hidden;
  transition: 0.3s ease;
  transition-property: width, min-width, padding, opacity;
}
.geotraxTable960 :global(.ReactTable .rt-th.-hidden),
.geotraxTable960 :global(.ReactTable .rt-td.-hidden) {
  width: 0 !important;
  min-width: 0 !important;
  padding: 0 !important;
  border: 0 !important;
  opacity: 0 !important;
}
.geotraxTable960 :global(.ReactTable .rt-expander) {
  display: inline-block;
  position: relative;
  margin: 0;
  color: transparent;
  margin: 0 10px;
}
.geotraxTable960 :global(.ReactTable .rt-expander:after) {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  border-left: 5.04px solid transparent;
  border-right: 5.04px solid transparent;
  border-top: 7px solid rgba(0, 0, 0, 0.8);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer;
}
.geotraxTable960 :global(.ReactTable .rt-expander.-open:after) {
  transform: translate(-50%, -50%) rotate(0);
}
.geotraxTable960 :global(.ReactTable .rt-resizer) {
  display: inline-block;
  position: absolute;
  width: 36px;
  top: 0;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  z-index: 10;
}
.geotraxTable960 :global(.ReactTable .rt-tfoot) {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
}
.geotraxTable960 :global(.ReactTable.-striped .rt-tr.-odd) {
  background: rgba(0, 0, 0, 0.03);
}
.geotraxTable960 :global(.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover) {
  background: rgba(0, 0, 0, 0.05);
}
.geotraxTable960 :global(.ReactTable .-pagination input),
.geotraxTable960 :global(.ReactTable .-pagination select) {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline: none;
}
.geotraxTable960 :global(.ReactTable .-pagination .-btn) {
  appearance: none;
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 3px;
  padding: 6px;
  font-size: 1em;
  color: rgba(0, 0, 0, 0.6);
  background: rgba(0, 0, 0, 0.1);
  transition: all 0.1s ease;
  cursor: pointer;
  outline: none;
}
.geotraxTable960 :global(.ReactTable .-pagination .-btn[disabled]) {
  opacity: 0.5;
  cursor: default;
}
.geotraxTable960 :global(.ReactTable .-pagination .-btn:not([disabled]):hover) {
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
}
.geotraxTable960 :global(.ReactTable .-pagination .-previous),
.geotraxTable960 :global(.ReactTable .-pagination .-next) {
  flex: 1;
  text-align: center;
}
.geotraxTable960 :global(.ReactTable .-pagination .-center) {
  flex: 1.5;
  text-align: center;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}
.geotraxTable960 :global(.ReactTable .-pagination .-pageInfo) {
  display: inline-block;
  margin: 3px 10px;
  white-space: nowrap;
}
.geotraxTable960 :global(.ReactTable .-pagination .-pageJump) {
  display: inline-block;
}
.geotraxTable960 :global(.ReactTable .-pagination .-pageJump input) {
  width: 70px;
  text-align: center;
}
.geotraxTable960 :global(.ReactTable .-pagination .-pageSizeOptions) {
  margin: 3px 10px;
}
.geotraxTable960 :global(.ReactTable .rt-noData) {
  display: block;
  position: absolute;
  left: 50%;
  top: 36%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: 1;
  pointer-events: none;
  padding: 16px;
  color: rgba(0, 0, 0, 0.5);
}
.geotraxTable960 :global(.ReactTable .-loading) {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 85px;
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}
.geotraxTable960 :global(.ReactTable .-loading > div) {
  position: absolute;
  display: block;
  text-align: center;
  width: 100%;
  top: 50%;
  left: 0;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
  transform: translateY(-52%);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.geotraxTable960 :global(.ReactTable .-loading.-active) {
  opacity: 1;
  z-index: 0;
  pointer-events: all;
}
.geotraxTable960 :global(.ReactTable .-loading.-active > div) {
  transform: translateY(50%);
}
.geotraxTable960 :global(.ReactTable .rt-resizing .rt-th),
.geotraxTable960 :global(.ReactTable .rt-resizing .rt-td) {
  transition: none !important;
  cursor: col-resize;
  user-select: none;
}

.geotraxTable, .geotraxTableCustom {
  width: 100%;
  height: calc(100% - 10.5rem);
  background: #f4f7f9;
  position: relative;
  padding: 20px 30px 10px 30px;
}
.geotraxTable :global(.ReactTable), .geotraxTableCustom :global(.ReactTable) {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.geotraxTable :global(.ReactTable *), .geotraxTableCustom :global(.ReactTable *) {
  box-sizing: border-box;
}
.geotraxTable :global(.ReactTable .rt-table), .geotraxTableCustom :global(.ReactTable .rt-table) {
  flex: auto 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
  position: relative;
}
.geotraxTable :global(.ReactTable .rt-thead), .geotraxTableCustom :global(.ReactTable .rt-thead) {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  user-select: none;
}
.geotraxTable :global(.ReactTable .rt-tbody), .geotraxTableCustom :global(.ReactTable .rt-tbody) {
  overflow: visible !important;
}
.geotraxTable :global(.ReactTable .rt-table .rt-tbody), .geotraxTableCustom :global(.ReactTable .rt-table .rt-tbody) {
  height: fit-content;
  max-height: fit-content;
  min-height: fit-content;
}
.geotraxTable :global(.ReactTable .rt-thead.-headerGroups), .geotraxTableCustom :global(.ReactTable .rt-thead.-headerGroups) {
  background: rgba(0, 0, 0, 0.03);
}
.geotraxTable :global(.ReactTable .rt-thead.-filters), .geotraxTableCustom :global(.ReactTable .rt-thead.-filters) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.geotraxTable :global(.ReactTable .rt-thead.-filters input), .geotraxTableCustom :global(.ReactTable .rt-thead.-filters input),
.geotraxTable :global(.ReactTable .rt-thead.-filters select),
.geotraxTableCustom :global(.ReactTable .rt-thead.-filters select) {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline: none;
}
.geotraxTable :global(.ReactTable .rt-thead.-filters .rt-th), .geotraxTableCustom :global(.ReactTable .rt-thead.-filters .rt-th) {
  border-right: 1px solid rgba(0, 0, 0, 0.02);
}
.geotraxTable :global(.ReactTable .rt-thead.-header), .geotraxTableCustom :global(.ReactTable .rt-thead.-header) {
  position: sticky;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15), 0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 3px 1px -2px rgba(0, 0, 0, 0.07);
}
.geotraxTable :global(.ReactTable .rt-thead .rt-tr), .geotraxTableCustom :global(.ReactTable .rt-thead .rt-tr) {
  text-align: center;
}
.geotraxTable :global(.ReactTable .rt-thead .rt-th), .geotraxTableCustom :global(.ReactTable .rt-thead .rt-th),
.geotraxTable :global(.ReactTable .rt-thead .rt-td),
.geotraxTableCustom :global(.ReactTable .rt-thead .rt-td) {
  background: #f4f7f9;
  padding: 5px 5px;
  line-height: normal;
  transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.geotraxTable :global(.ReactTable .rt-thead .rt-th.-cursor-pointer), .geotraxTableCustom :global(.ReactTable .rt-thead .rt-th.-cursor-pointer),
.geotraxTable :global(.ReactTable .rt-thead .rt-td.-cursor-pointer),
.geotraxTableCustom :global(.ReactTable .rt-thead .rt-td.-cursor-pointer) {
  cursor: pointer;
}
.geotraxTable :global(.ReactTable .rt-thead .rt-resizable-header), .geotraxTableCustom :global(.ReactTable .rt-thead .rt-resizable-header) {
  padding: 10px 0 10px 15px;
  overflow: visible;
  outline: 0;
  text-align: left;
  box-shadow: none;
}
.geotraxTable :global(.-sort-desc.-cursor-pointer .rt-resizable-header-content:after), .geotraxTableCustom :global(.-sort-desc.-cursor-pointer .rt-resizable-header-content:after) {
  width: 0;
  height: 0;
  font-family: "icomoon";
  content: "\e939";
  color: #F66A00;
  position: absolute;
  top: 15px;
  margin-left: 10px;
  font-size: 8px;
}
.geotraxTable :global(.-sort-asc.-cursor-pointer .rt-resizable-header-content:after), .geotraxTableCustom :global(.-sort-asc.-cursor-pointer .rt-resizable-header-content:after) {
  width: 0;
  height: 0;
  font-family: "icomoon";
  content: "\e938";
  color: #F66A00;
  position: absolute;
  top: 15px;
  margin-left: 10px;
  font-size: 8px;
}
.geotraxTable :global(.-cursor-pointer .rt-resizable-header-content:after), .geotraxTableCustom :global(.-cursor-pointer .rt-resizable-header-content:after) {
  width: 0;
  height: 0;
  font-family: "icomoon";
  content: "\e939";
  color: #a5abaf;
  position: absolute;
  top: 15px;
  margin-left: 10px;
  font-size: 8px;
}
.geotraxTable :global(.rt-resizable-header-content), .geotraxTableCustom :global(.rt-resizable-header-content) {
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
}
.geotraxTable :global(.header), .geotraxTableCustom :global(.header) {
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
}
.geotraxTable :global(.ReactTable .rt-thead .rt-resizable-header:last-child), .geotraxTableCustom :global(.ReactTable .rt-thead .rt-resizable-header:last-child) {
  overflow: hidden;
}
.geotraxTable :global(.ReactTable .rt-thead .rt-resizable-header-content), .geotraxTableCustom :global(.ReactTable .rt-thead .rt-resizable-header-content) {
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 900;
  font-size: 12px;
  display: inline;
}
.geotraxTable :global(.ReactTable .rt-thead .rt-header-pivot), .geotraxTableCustom :global(.ReactTable .rt-thead .rt-header-pivot) {
  border-right-color: #f4f7f9;
}
.geotraxTable :global(.ReactTable .rt-thead .rt-header-pivot:after), .geotraxTableCustom :global(.ReactTable .rt-thead .rt-header-pivot:after),
.geotraxTable :global(.ReactTable .rt-thead .rt-header-pivot:before),
.geotraxTableCustom :global(.ReactTable .rt-thead .rt-header-pivot:before) {
  left: 100%;
  top: 50%;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.geotraxTable :global(.ReactTable .rt-thead .rt-header-pivot:after), .geotraxTableCustom :global(.ReactTable .rt-thead .rt-header-pivot:after) {
  margin-top: -8px;
}
.geotraxTable :global(.ReactTable .rt-thead .rt-header-pivot:before), .geotraxTableCustom :global(.ReactTable .rt-thead .rt-header-pivot:before) {
  margin-top: -10px;
}
.geotraxTable :global(.ReactTable .rt-tbody), .geotraxTableCustom :global(.ReactTable .rt-tbody) {
  flex: 99999 1 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
  max-height: 800px;
  min-height: 50px;
}
.geotraxTable :global(.ReactTable .rt-tbody .rt-tr-group), .geotraxTableCustom :global(.ReactTable .rt-tbody .rt-tr-group) {
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
  flex: 0 0 auto;
}
.geotraxTable :global(.ReactTable .rt-tbody .rt-tr-group:hover), .geotraxTableCustom :global(.ReactTable .rt-tbody .rt-tr-group:hover) {
  cursor: pointer;
}
.geotraxTable :global(.ReactTable .rt-tbody .rt-tr-group:hover .rt-td), .geotraxTableCustom :global(.ReactTable .rt-tbody .rt-tr-group:hover .rt-td) {
  background: #f4f7f9;
}
.geotraxTable :global(.ReactTable .rt-tbody .rt-td), .geotraxTableCustom :global(.ReactTable .rt-tbody .rt-td) {
  text-align: left;
  padding-left: 15px;
  background: white;
  font-size: 15px;
  line-height: 2;
  transition: 0.3s;
}
.geotraxTable :global(.ReactTable .rt-tbody .rt-tr-group:has(input[type="checkbox"]:checked) .rt-td), .geotraxTableCustom :global(.ReactTable .rt-tbody .rt-tr-group:has(input[type="checkbox"]:checked) .rt-td) {
  background: #f4f7f9;
}
.geotraxTable :global(.new-highlight), .geotraxTableCustom :global(.new-highlight) {
  background: #E5FCF5 !important;
}
.geotraxTable :global(.ReactTable .rt-tbody .rt-td .pin), .geotraxTableCustom :global(.ReactTable .rt-tbody .rt-td .pin) {
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
}
.geotraxTable :global(.ReactTable .rt-tbody .rt-expandable), .geotraxTableCustom :global(.ReactTable .rt-tbody .rt-expandable) {
  cursor: pointer;
  text-overflow: clip;
}
.geotraxTable :global(.ReactTable .rt-tr-group), .geotraxTableCustom :global(.ReactTable .rt-tr-group) {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.geotraxTable :global(.ReactTable .rt-tr), .geotraxTableCustom :global(.ReactTable .rt-tr) {
  flex: 1 0 auto;
  display: inline-flex;
}
.geotraxTable :global(.ReactTable .rt-th), .geotraxTableCustom :global(.ReactTable .rt-th),
.geotraxTable :global(.ReactTable .rt-td),
.geotraxTableCustom :global(.ReactTable .rt-td) {
  flex: 1 0 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 7px 5px;
  overflow: hidden;
  transition: 0.3s ease;
  transition-property: width, min-width, padding, opacity;
}
.geotraxTable :global(.ReactTable .rt-th.-hidden), .geotraxTableCustom :global(.ReactTable .rt-th.-hidden),
.geotraxTable :global(.ReactTable .rt-td.-hidden),
.geotraxTableCustom :global(.ReactTable .rt-td.-hidden) {
  width: 0 !important;
  min-width: 0 !important;
  padding: 0 !important;
  border: 0 !important;
  opacity: 0 !important;
}
.geotraxTable :global(.ReactTable .rt-expander), .geotraxTableCustom :global(.ReactTable .rt-expander) {
  display: inline-block;
  position: relative;
  margin: 0;
  color: transparent;
  margin: 0 10px;
}
.geotraxTable :global(.ReactTable .rt-expander:after), .geotraxTableCustom :global(.ReactTable .rt-expander:after) {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  border-left: 5.04px solid transparent;
  border-right: 5.04px solid transparent;
  border-top: 7px solid rgba(0, 0, 0, 0.8);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  cursor: pointer;
}
.geotraxTable :global(.ReactTable .rt-expander.-open:after), .geotraxTableCustom :global(.ReactTable .rt-expander.-open:after) {
  transform: translate(-50%, -50%) rotate(0);
}
.geotraxTable :global(.ReactTable .rt-resizer), .geotraxTableCustom :global(.ReactTable .rt-resizer) {
  display: inline-block;
  position: absolute;
  width: 36px;
  top: 0;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  z-index: 10;
}
.geotraxTable :global(.ReactTable .rt-tfoot), .geotraxTableCustom :global(.ReactTable .rt-tfoot) {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
}
.geotraxTable :global(.ReactTable.-striped .rt-tr.-odd), .geotraxTableCustom :global(.ReactTable.-striped .rt-tr.-odd) {
  background: rgba(0, 0, 0, 0.03);
}
.geotraxTable :global(.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover), .geotraxTableCustom :global(.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover) {
  background: rgba(0, 0, 0, 0.05);
}
.geotraxTable :global(.ReactTable .-pagination input), .geotraxTableCustom :global(.ReactTable .-pagination input),
.geotraxTable :global(.ReactTable .-pagination select),
.geotraxTableCustom :global(.ReactTable .-pagination select) {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: normal;
  outline: none;
}
.geotraxTable :global(.ReactTable .-pagination .-btn), .geotraxTableCustom :global(.ReactTable .-pagination .-btn) {
  appearance: none;
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 3px;
  padding: 6px;
  font-size: 1em;
  color: rgba(0, 0, 0, 0.6);
  background: rgba(0, 0, 0, 0.1);
  transition: all 0.1s ease;
  cursor: pointer;
  outline: none;
}
.geotraxTable :global(.ReactTable .-pagination .-btn[disabled]), .geotraxTableCustom :global(.ReactTable .-pagination .-btn[disabled]) {
  opacity: 0.5;
  cursor: default;
}
.geotraxTable :global(.ReactTable .-pagination .-btn:not([disabled]):hover), .geotraxTableCustom :global(.ReactTable .-pagination .-btn:not([disabled]):hover) {
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
}
.geotraxTable :global(.ReactTable .-pagination .-previous), .geotraxTableCustom :global(.ReactTable .-pagination .-previous),
.geotraxTable :global(.ReactTable .-pagination .-next),
.geotraxTableCustom :global(.ReactTable .-pagination .-next) {
  flex: 1;
  text-align: center;
}
.geotraxTable :global(.ReactTable .-pagination .-center), .geotraxTableCustom :global(.ReactTable .-pagination .-center) {
  flex: 1.5;
  text-align: center;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}
.geotraxTable :global(.ReactTable .-pagination .-pageInfo), .geotraxTableCustom :global(.ReactTable .-pagination .-pageInfo) {
  display: inline-block;
  margin: 3px 10px;
  white-space: nowrap;
}
.geotraxTable :global(.ReactTable .-pagination .-pageJump), .geotraxTableCustom :global(.ReactTable .-pagination .-pageJump) {
  display: inline-block;
}
.geotraxTable :global(.ReactTable .-pagination .-pageJump input), .geotraxTableCustom :global(.ReactTable .-pagination .-pageJump input) {
  width: 70px;
  text-align: center;
}
.geotraxTable :global(.ReactTable .-pagination .-pageSizeOptions), .geotraxTableCustom :global(.ReactTable .-pagination .-pageSizeOptions) {
  margin: 3px 10px;
}
.geotraxTable :global(.ReactTable .rt-noData), .geotraxTableCustom :global(.ReactTable .rt-noData) {
  display: block;
  position: absolute;
  left: 50%;
  top: 36%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: 1;
  pointer-events: none;
  padding: 16px;
  color: rgba(0, 0, 0, 0.5);
}
.geotraxTable :global(.ReactTable .-loading), .geotraxTableCustom :global(.ReactTable .-loading) {
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 85px;
  background: rgba(255, 255, 255, 0.8);
  transition: all 0.3s ease;
  z-index: -1;
  opacity: 0;
  pointer-events: none;
}
.geotraxTable :global(.ReactTable .-loading > div), .geotraxTableCustom :global(.ReactTable .-loading > div) {
  position: absolute;
  display: block;
  text-align: center;
  width: 100%;
  top: 50%;
  left: 0;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.6);
  transform: translateY(-52%);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.geotraxTable :global(.ReactTable .-loading.-active), .geotraxTableCustom :global(.ReactTable .-loading.-active) {
  opacity: 1;
  z-index: 0;
  pointer-events: all;
}
.geotraxTable :global(.ReactTable .-loading.-active > div), .geotraxTableCustom :global(.ReactTable .-loading.-active > div) {
  transform: translateY(50%);
}
.geotraxTable :global(.ReactTable .rt-resizing .rt-th), .geotraxTableCustom :global(.ReactTable .rt-resizing .rt-th),
.geotraxTable :global(.ReactTable .rt-resizing .rt-td),
.geotraxTableCustom :global(.ReactTable .rt-resizing .rt-td) {
  transition: none !important;
  cursor: col-resize;
  user-select: none;
}

.geotraxTableCustom {
  height: calc(100% - 21rem);
}

.headerText {
  color: #151F28;
  line-height: 1.1;
}

.noItemContentCt {
  margin: 0 auto;
  text-align: center;
  position: relative;
  top: 50%;
}

.icon {
  color: #F66A00;
  margin-left: 5px;
  margin-right: 5px;
}

