.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-center,
.flex-horizontal-center,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-row,
.flex-column,
.flex-start,
.flex-end,
.flex-space-between,
.flex-vertical-center,
.flex-horizontal-center,
.flex-center,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-row,
.flex-column,
.flex-start,
.flex-end,
.flex-space-between,
.flex-vertical-center,
.flex-horizontal-center,
.flex-center,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.margin-0 {
  margin: 0 !important;
}

.margin-top-0 {
  margin-top: 0;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-20 {
  margin-top: 20px;
}

.margin-top-30 {
  margin-top: 30px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-right-5 {
  margin-right: 5px;
}

.margin-right-10 {
  margin-right: 10px;
}

.margin-right-20 {
  margin-right: 20px;
}

.margin-right-30 {
  margin-right: 30px;
}

.margin-right-40 {
  margin-right: 40px;
}

.margin-left-5 {
  margin-left: 5px;
}

.margin-left-10 {
  margin-left: 10px;
}

.margin-left-20 {
  margin-left: 20px;
}

.margin-left-30 {
  margin-left: 30px;
}

.margin-left-40 {
  margin-left: 40px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-30 {
  margin-bottom: 30px;
}

.margin-bottom-40 {
  margin-bottom: 40px;
}

.wrap-words {
  word-break: initial;
  line-height: 1.1;
}

.nowrap-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

.pre-wrap {
  white-space: pre-wrap;
}

.height-100 {
  height: 100%;
}

.icon-md {
  font-size: 1.8rem;
}

.icon-lg {
  font-size: 2rem;
}

.icon-xl {
  font-size: 3rem;
}

.icon-people-margin {
  position: relative;
  top: 2px;
  margin-right: 3px;
}

.underline {
  text-decoration: underline;
}

.clickable {
  cursor: pointer;
}

html {
  font-size: 10px;
}

body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  font-family: sans-serif;
}

* {
  box-sizing: border-box;
}

/*----------  TYPOGRAPHY  ----------*/
body * {
  font-family: "PublicSans Regular", Helvetica, Arial, sans-serif;
}

h1,
.h1 {
  font-size: 4.5rem;
}

h2,
.h2 {
  font-size: 3rem;
}

h3,
.h3 {
  font-size: 2.4rem;
}

h4,
.h4 {
  font-size: 2rem;
}

h5,
.h5 {
  font-size: 1.6rem;
}

h6,
.h6 {
  font-size: 1.3rem;
}

p,
.p {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  margin: 0 0 2rem 0;
}

a,
.a {
  color: #0A63F3;
  text-decoration: none;
}
a:hover,
.a:hover {
  text-decoration: underline;
}

.txt-primary {
  color: #008EF3 !important;
}

.txt-spaced {
  letter-spacing: 0.1rem !important;
}

.txt-spaced--wide {
  letter-spacing: 0.3rem !important;
  text-transform: uppercase !important;
}

.txt-italic {
  font-style: italic;
}

.txt-center {
  text-align: center !important;
}

.txt-right {
  text-align: right !important;
}

.txt-regular {
  font-family: "PublicSans Regular", Helvetica, Arial, sans-serif;
}

.txt-italic {
  font-family: "PublicSans Italic", Helvetica, Arial, sans-serif;
}

.txt-bold {
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
}

/*----------  FORMS  ----------*/
::-webkit-input-placeholder {
  color: #878e93 !important;
}

::-moz-placeholder {
  color: #878e93 !important;
}

:-ms-input-placeholder {
  color: #878e93 !important;
}

:-moz-placeholder {
  color: #878e93 !important;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 4rem #FFECF0 inset; /* Change the color to your own background color */
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px #FFECF0 inset, inset 0 0 0 1px #E92851 !important;
  box-shadow: inset 0 0 0 1px #E92851 !important;
}

input,
textarea {
  font-family: "PublicSans Regular", Helvetica, Arial, sans-serif !important;
  font-weight: 400;
  box-shadow: none;
  -webkit-transition-property: border, -webkit-box-shadow;
  transition-property: border, -webkit-box-shadow;
  -o-transition-property: border, box-shadow;
  transition-property: border, box-shadow;
  transition-property: border, box-shadow, -webkit-box-shadow;
  -webkit-transition-duration: 0.3s !important;
  -o-transition-duration: 0.3s !important;
  transition-duration: 0.3s !important;
  border-radius: 3px !important;
  -webkit-appearance: none !important;
}

label.tt-label--help {
  display: block !important;
  margin: 0.8rem 0 0 0;
  line-height: 1;
  font-size: 1.3rem;
}

label.tt-label--character-count {
  display: block !important;
  margin: 0.8rem 0 0 0;
  line-height: 1;
  font-size: 1.3rem;
  font-weight: 400;
  color: #636b71;
}

input[type=text].tt-input,
textarea.tt-input,
input[type=password].tt-input,
input[type=email].tt-input,
input[type=url].tt-input,
input[type=time].tt-input,
input[type=date].tt-input,
input[type=datetime].tt-input,
input[type=datetime-local].tt-input,
input[type=tel].tt-input,
input[type=number].tt-input,
input[type=search].tt-input {
  font-family: "PublicSans Regular", Helvetica, Arial, sans-serif !important;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0.1rem 1.3rem 0.35rem 1.3rem;
  min-height: 4rem;
  line-height: 1;
  border: 1px solid #e1e4e7;
  background: #f4f7f9;
  border-radius: 3px;
  font-size: 1.6rem;
  color: #151F28;
  outline: none;
  -webkit-transition-property: border, -webkit-box-shadow;
  transition-property: border, -webkit-box-shadow;
  -o-transition-property: border, box-shadow;
  transition-property: border, box-shadow;
  transition-property: border, box-shadow, -webkit-box-shadow;
  -webkit-transition-duration: 0.3s !important;
  -o-transition-duration: 0.3s !important;
  transition-duration: 0.3s !important;
}

input[type=text]::-ms-clear {
  display: none;
}

textarea {
  display: block;
  margin-bottom: 0 !important;
  vertical-align: bottom !important;
}

textarea.tt-input {
  padding: 1.3rem 1.5rem 1.4rem 1.5rem;
  line-height: 1.3;
}

input[type=text].tt-input,
input[type=password].tt-input,
input[type=email].tt-input,
input[type=url].tt-input,
input[type=time].tt-input,
input[type=date].tt-input,
input[type=datetime].tt-input,
input[type=datetime-local].tt-input,
input[type=tel].tt-input,
input[type=number].tt-input,
input[type=search].tt-input {
  min-height: 4rem;
}

input[type=text].tt-input:focus,
textarea.tt-input:focus,
input[type=password].tt-input:focus,
input[type=email].tt-input:focus,
input[type=url].tt-input:focus,
input[type=time].tt-input:focus,
input[type=date].tt-input:focus,
input[type=datetime].tt-input:focus,
input[type=datetime-local].tt-input:focus,
input[type=tel].tt-input:focus,
input[type=number].tt-input:focus,
input[type=search].tt-input:focus {
  border: 1px solid #008EF3 !important;
  -webkit-box-shadow: inset 0 0 0 1px #008EF3 !important;
  box-shadow: inset 0 0 0 1px #008EF3 !important;
}

input[type=text].tt-input:disabled,
textarea.tt-input:disabled,
input[type=password].tt-input:disabled,
input[type=email].tt-input:disabled,
input[type=url].tt-input:disabled,
input[type=time].tt-input:disabled,
input[type=date].tt-input:disabled,
input[type=datetime].tt-input:disabled,
input[type=datetime-local].tt-input:disabled,
input[type=tel].tt-input:disabled,
input[type=number].tt-input:disabled,
input[type=search].tt-input:disabled {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background-color: #e1e4e7 !important;
  border: none;
  cursor: not-allowed;
}

input:not([type])[readonly],
input[type=text][readonly],
input[type=password][readonly],
input[type=email][readonly],
input[type=url][readonly],
input[type=time][readonly],
input[type=date][readonly],
input[type=datetime][readonly],
input[type=datetime-local][readonly],
input[type=tel][readonly],
input[type=number][readonly],
input[type=search][readonly],
textarea[readonly] {
  color: #151F28 !important;
  border: 1px solid #e1e4e7 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  background-color: #f4f7f9 !important;
  outline: none !important;
}

input:not([type]):focus:not([readonly]) + label,
input[type=text]:focus:not([readonly]) + label,
input[type=password]:focus:not([readonly]) + label,
input[type=email]:focus:not([readonly]) + label,
input[type=url]:focus:not([readonly]) + label,
input[type=time]:focus:not([readonly]) + label,
input[type=date]:focus:not([readonly]) + label,
input[type=datetime]:focus:not([readonly]) + label,
input[type=datetime-local]:focus:not([readonly]) + label,
input[type=tel]:focus:not([readonly]) + label,
input[type=number]:focus:not([readonly]) + label,
input[type=search]:focus:not([readonly]) + label,
textarea:focus:not([readonly]) + label {
  color: #151F28;
}

input:not([type]):disabled + label,
input:not([type])[readonly=readonly] + label,
input[type=text]:disabled + label,
input[type=text][readonly=readonly] + label,
input[type=password]:disabled + label,
input[type=password][readonly=readonly] + label,
input[type=email]:disabled + label,
input[type=email][readonly=readonly] + label,
input[type=url]:disabled + label,
input[type=url][readonly=readonly] + label,
input[type=time]:disabled + label,
input[type=time][readonly=readonly] + label,
input[type=date]:disabled + label,
input[type=date][readonly=readonly] + label,
input[type=datetime]:disabled + label,
input[type=datetime][readonly=readonly] + label,
input[type=datetime-local]:disabled + label,
input[type=datetime-local][readonly=readonly] + label,
input[type=tel]:disabled + label,
input[type=tel][readonly=readonly] + label,
input[type=number]:disabled + label,
input[type=number][readonly=readonly] + label,
input[type=search]:disabled + label,
input[type=search][readonly=readonly] + label,
textarea:disabled + label,
textarea[readonly=readonly] + label {
  color: #636b71;
}

input.tt-input--read-only::-webkit-input-placeholder {
  color: #151F28 !important;
}

input.tt-input--read-only:-moz-placeholder {
  /* Firefox 18- */
  color: #151F28 !important;
}

input.tt-input--read-only::-moz-placeholder {
  /* Firefox 19+ */
  color: #151F28 !important;
}

input.tt-input--read-only:-ms-input-placeholder {
  color: #151F28 !important;
}

input.tt-input--read-only:focus {
  border: 1px solid #e1e4e7;
  -webkit-box-shadow: none;
  box-shadow: none;
}

input[type=text].tt-input-success,
input[type=text].tt-input-success:focus {
  border: 1px solid #00AC4A;
  -webkit-box-shadow: inset 0 0 0 1px #00AC4A !important, inset 0 1px 2px 0 rgba(102, 113, 123, 0.21);
  box-shadow: inset 0 0 0 1px #00AC4A !important, inset 0 1px 2px 0 rgba(102, 113, 123, 0.21);
}

i.tt-input-success {
  font-size: 1.6rem;
  color: #00AC4A;
  margin-left: 6px;
}

input[type=text].tt-input.tt-input-error,
textarea.tt-input.tt-input-error,
input[type=password].tt-input.tt-input-error,
input[type=email].tt-input.tt-input-error,
input[type=url].tt-input.tt-input-error,
input[type=time].tt-input.tt-input-error,
input[type=date].tt-input.tt-input-error,
input[type=datetime].tt-input.tt-input-error,
input[type=datetime-local].tt-input.tt-input-error,
input[type=tel].tt-input.tt-input-error,
input[type=number].tt-input.tt-input-error,
input[type=search].tt-input.tt-input-error {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  border: 1px solid #E92851 !important;
  background-color: #ffecf0 !important;
}

input[type=text].tt-input-error:focus,
textarea.tt-input-error:focus,
input[type=password].tt-input-error:focus,
input[type=email].tt-input-error:focus,
input[type=url].tt-input-error:focus,
input[type=time].tt-input-error:focus,
input[type=date].tt-input-error:focus,
input[type=datetime].tt-input-error:focus,
input[type=datetime-local].tt-input-error:focus,
input[type=tel].tt-input-error:focus,
input[type=number].tt-input-error:focus,
input[type=search].tt-input-error:focus {
  border: 1px solid #E92851 !important;
  -webkit-box-shadow: inset 0 0 0 1px #E92851 !important;
  box-shadow: inset 0 0 0 1px #E92851 !important;
}

input[type=text].tt-input-error + label.tt-label--help,
textarea.tt-input-error + label.tt-label--help,
input[type=password].tt-input-error + label.tt-label--help,
input[type=email].tt-input-error + label.tt-label--help,
input[type=url].tt-input-error + label.tt-label--help,
input[type=time].tt-input-error + label.tt-label--help,
input[type=date].tt-input-error + label.tt-label--help,
input[type=datetime].tt-input-error + label.tt-label--help,
input[type=datetime-local].tt-input-error + label.tt-label--help,
input[type=tel].tt-input-error + label.tt-label--help,
input[type=number].tt-input-error + label.tt-label--help,
input[type=search].tt-input-error + label.tt-label--help,
input[type=text].tt-input-error:focus + label,
textarea.tt-input-error:focus + label.tt-label--help,
input[type=password].tt-input-error:focus + label.tt-label--help,
input[type=email].tt-input-error:focus + label.tt-label--help,
input[type=url].tt-input-error:focus + label.tt-label--help,
input[type=time].tt-input-error:focus + label.tt-label--help,
input[type=date].tt-input-error:focus + label.tt-label--help,
input[type=datetime].tt-input-error:focus + label.tt-label--help,
input[type=datetime-local].tt-input-error:focus + label.tt-label--help,
input[type=tel].tt-input-error:focus + label.tt-label--help,
input[type=number].tt-input-error:focus + label.tt-label--help,
input[type=search].tt-input-error:focus + label.tt-label--help {
  font-weight: 200;
  color: red;
  display: block;
  margin-top: 6px;
}

.tt-password--container {
  position: relative;
}
.tt-password--container button {
  position: absolute;
  right: 0;
  top: 0;
  height: 4rem;
}
.tt-password--container input[type=password].tt-input {
  padding-right: 4rem;
}

.tt-btn--show-hide {
  height: 100%;
  width: 3rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  outline: none;
  border: none;
  cursor: pointer;
}
.tt-btn--show-hide i {
  color: #636b71;
  font-size: 1.8rem;
  transition: 0.3s !important;
}
.tt-btn--show-hide:hover > i {
  color: #151F28;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-row,
.flex-column,
.flex-start,
.flex-end,
.flex-space-between,
.flex-vertical-center,
.flex-horizontal-center,
.flex-center,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

/*--------- MAIN NAVIGATION ---------*/
.tt-nav-main--container {
  background: #2A353E;
  -webkit-box-shadow: inset 0 -1px 0 0 #202228, 0 0 9px #101010;
  box-shadow: inset 0 -1px 0 0 #202228, 0 0 9px #101010;
  width: 6.2rem;
  height: 100%;
  position: relative;
  z-index: 100;
}
.tt-nav-main--container .tt-nav-main--buttons {
  position: absolute;
  top: 0;
  left: 0;
}
.tt-nav-main--container .tt-nav-main--actions {
  position: absolute;
  bottom: 0;
  left: 0;
}
.tt-nav-main--container .tt-nav-main--btn,
.tt-nav-main--container .tt-nav-main--logout-btn,
.tt-nav-main--container .action-btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2A353E;
  border: none;
  cursor: pointer;
  transition: 0.3s !important;
}
.tt-nav-main--container .tt-nav-main--btn:hover,
.tt-nav-main--container .tt-nav-main--logout-btn:hover,
.tt-nav-main--container .action-btn:hover {
  background: #434c53;
}
.tt-nav-main--container .tt-nav-main--btn:hover > i, .tt-nav-main--container .tt-nav-main--btn:hover > span,
.tt-nav-main--container .tt-nav-main--logout-btn:hover > i,
.tt-nav-main--container .tt-nav-main--logout-btn:hover > span,
.tt-nav-main--container .action-btn:hover > i,
.tt-nav-main--container .action-btn:hover > span {
  color: white;
}
.tt-nav-main--container .tt-nav-main--btn i,
.tt-nav-main--container .tt-nav-main--logout-btn i,
.tt-nav-main--container .action-btn i {
  font-size: 2.2rem;
  -webkit-transition: 0.3s !important;
  -o-transition: 0.3s !important;
  transition: 0.3s !important;
}
.tt-nav-main--container .tt-nav-main--btn span,
.tt-nav-main--container .tt-nav-main--logout-btn span,
.tt-nav-main--container .action-btn span {
  font-size: 1.2rem;
  text-transform: uppercase;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
}
.tt-nav-main--container .tt-nav-main--btn:focus,
.tt-nav-main--container .tt-nav-main--logout-btn:focus,
.tt-nav-main--container .action-btn:focus {
  outline: none;
}
.tt-nav-main--container .tt-nav-main--btn i,
.tt-nav-main--container .tt-nav-main--btn span,
.tt-nav-main--container .tt-nav-main--logout-btn i,
.tt-nav-main--container .tt-nav-main--logout-btn span,
.tt-nav-main--container .action-btn i,
.tt-nav-main--container .action-btn span {
  color: #a5abaf;
}
.tt-nav-main--container .tt-nav-main--btn,
.tt-nav-main--container .tt-nav-main--logout-btn {
  width: 6.2rem;
  height: 6.2rem;
  box-shadow: inset 0 -1px 0 0 #202228, inset 0 1px 0 0 #44464e;
}
.tt-nav-main--container .tt-nav-main--logout-btn {
  box-shadow: none;
}
.tt-nav-main--container .action-btn {
  width: 40px;
  height: 40px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.37), 0 2px 2px 0 rgba(0, 0, 0, 0.39), 0 1px 5px 0 rgba(0, 0, 0, 0.5);
  padding: 8px;
  border-radius: 3px;
  margin: 0 auto 20px auto;
}
.tt-nav-main--container .selected {
  background: #151F28 !important;
  box-shadow: inset 0 -1px 0 0 #202228, inset 0 1px 0 0 #44464e, inset -1px 0 0 #151F28;
}
.tt-nav-main--container .selected .btn-marker {
  opacity: 1 !important;
  width: 0.4rem;
}
.tt-nav-main--container .btn-marker {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 1;
  opacity: 0;
  width: 0;
  transition: 0.6s;
  background: #F66A00;
}
.tt-nav-main--container .action-btn--selected {
  background: #e1e4e7 !important;
  box-shadow: inset 0 -1px 0 0 #050506, inset 0 1px 0 0 #050506;
  pointer-events: auto;
  cursor: pointer;
}
.tt-nav-main--container .action-btn--selected i {
  color: #008EF3;
}
.tt-nav-main--container .action-btn--selected:hover {
  background: #f4f7f9;
}

/*--------- SECONDARY NAVIGATION ---------*/
.tt-nav-secondary--container {
  transition: width 0.3s ease;
  height: 100%;
  position: relative;
  white-space: nowrap;
  flex-shrink: 0;
  box-shadow: 0 0 9px 0 rgba(38, 41, 48, 0.5);
  z-index: 99;
}
.tt-nav-secondary--container .tt-nav-secondary--content {
  height: 100%;
  background: #151F28;
  box-shadow: inset 0 -1px 0 0 #202228;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  z-index: 99;
}
.tt-nav-secondary--container .tt-nav-secondary--content .page-title {
  color: white;
  font-weight: 400;
  line-height: 0.9em;
  margin: 0 0 2rem 0;
  padding: 0;
}

.tt-nav-secondary--feature {
  height: 45px;
  color: white;
  padding-left: 3.2rem;
  margin: 0;
  display: flex;
  align-items: center;
  transition: 0.3s !important;
}
.tt-nav-secondary--feature i {
  color: #a5abaf;
  transition: 0.3s !important;
}
.tt-nav-secondary--feature.selected {
  position: relative;
  background: #2A353E;
}
.tt-nav-secondary--feature.selected:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 2px;
  width: 4px;
  background: #F66A00;
  z-index: 1000;
}
.tt-nav-secondary--feature.selected i {
  color: white;
}
.tt-nav-secondary--feature.selected:hover {
  background: #2A353E;
  cursor: default;
}
.tt-nav-secondary--feature:hover {
  cursor: pointer;
  background: #2A353E;
}

.tt-nav-secondary--title {
  padding-left: 10px;
}

.tt-nav-secondary--docs {
  height: 45px;
  color: white;
  padding-left: 1.6rem;
  margin: 0;
  display: flex;
  align-items: center;
  transition: 0.3s !important;
}
.tt-nav-secondary--docs span {
  font-family: "PublicSans Regular", Helvetica, Arial, sans-serif !important;
}
.tt-nav-secondary--docs.selected {
  background: #434c53;
}
.tt-nav-secondary--docs.selected:hover {
  cursor: default;
}
.tt-nav-secondary--docs.selected span {
  font-family: "PublicSans Regular", Helvetica, Arial, sans-serif !important;
  color: white;
}
.tt-nav-secondary--docs:hover {
  cursor: pointer;
  color: #17A9FF;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-row,
.flex-column,
.flex-start,
.flex-end,
.flex-space-between,
.flex-vertical-center,
.flex-horizontal-center,
.flex-center,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

/*----------  SEARCH  ----------*/
.tt-autocomplete-error, .tt-autocomplete {
  height: 4rem !important;
  background: #f4f7f9;
  border-radius: 3px;
  line-height: 1rem !important;
}

.tt-autocomplete div {
  margin: 0.2rem 0 0.2rem 3.8rem !important;
  color: #636b71 !important;
  bottom: 14px !important;
}

.tt-autocomplete-error div {
  margin: 0.2rem 0 0.2rem 3.8rem !important;
  color: #636b71 !important;
}
.tt-autocomplete-error div:nth-child(3) {
  background: white;
}

.tt-search {
  position: relative;
}

.tt-search input {
  height: 3.8rem !important;
  padding: 0rem 1.3rem 0.2rem 3.8rem !important;
  font-size: 1.6rem;
  color: #151F28 !important;
}

.tt-search i {
  color: #636b71;
  transition: 0.3s !important;
}

.tt-search i.icon-search {
  position: absolute;
  top: 1.1rem;
  left: 1.2rem;
  font-size: 1.8rem;
}

.tt-search i.icon-cancel {
  position: absolute;
  top: 1.1rem;
  right: 1.2rem;
  font-size: 1.8rem;
}
.tt-search i.icon-cancel:hover {
  cursor: pointer;
}

/*----------  BUTTONS  ----------*/
.tt-btn {
  padding: 0.1rem 1.3rem 0.35rem 1.3rem;
  min-height: 4rem;
  min-width: 4rem;
  font-family: "PublicSans Regular", Helvetica, Arial, sans-serif;
  font-size: 1.6rem;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  transition: 0.3s !important;
  color: white;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.28), 0px 2px 2px 0px rgba(0, 0, 0, 0.22), 0px 3px 1px -2px rgba(0, 0, 0, 0.2);
  background: #008EF3;
}
.tt-btn span {
  font-family: "PublicSans Regular", Helvetica, Arial, sans-serif;
  color: white;
}
.tt-btn i {
  color: white;
  font-size: 1.4rem;
  line-height: 1;
  padding-top: 0;
  padding-bottom: 0.1rem;
}
.tt-btn:hover {
  background: #17A9FF;
}
.tt-btn:focus {
  outline: none;
}
.tt-btn--accent {
  background: #F66A00;
  color: white;
}
.tt-btn--accent span,
.tt-btn--accent i {
  color: white;
}
.tt-btn--accent:hover {
  background: #FF8C12;
}
.tt-btn--submit {
  background: #00AC4A;
  color: white;
}
.tt-btn--submit span,
.tt-btn--submit i {
  color: white;
}
.tt-btn--submit:hover {
  background: #00C46C;
}
.tt-btn--error {
  background: #E92851;
  color: white;
}
.tt-btn--error span,
.tt-btn--error i {
  color: white;
}
.tt-btn--error:hover {
  background: #FF3B64;
}
.tt-btn:disabled, .tt-btn--accent:disabled, .tt-btn--submit:disabled {
  background-color: #a5abaf;
  color: white;
  cursor: not-allowed;
  box-shadow: none !important;
}
.tt-btn:disabled i, .tt-btn--accent:disabled i, .tt-btn--submit:disabled i {
  color: white;
}
.tt-btn-secondary {
  background: #f4f7f9;
  color: #0A63F3;
  transition: 0.3s !important;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15), 0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 3px 1px -2px rgba(0, 0, 0, 0.07) !important;
}
.tt-btn-secondary i {
  color: #0A63F3;
}
.tt-btn-secondary:hover {
  background: #E2F8FF;
}
.tt-btn-secondary:focus {
  outline: none;
}
.tt-btn-secondary--accent {
  background: #f4f7f9;
  color: #C85000;
  transition: 0.3s !important;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15), 0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 3px 1px -2px rgba(0, 0, 0, 0.07) !important;
}
.tt-btn-secondary--accent i {
  color: #C85000;
}
.tt-btn-secondary--accent:hover {
  background: #FDF3E2;
}
.tt-btn-secondary--accent:focus {
  outline: none;
}
.tt-btn-secondary--submit {
  background: #f4f7f9;
  color: #00884F !important;
  transition: 0.3s !important;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15), 0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 3px 1px -2px rgba(0, 0, 0, 0.07) !important;
}
.tt-btn-secondary--submit i {
  color: #00884F;
}
.tt-btn-secondary--submit:hover {
  background: #E5FCF5;
}
.tt-btn-secondary--submit:focus {
  outline: none;
}
.tt-btn-secondary--error {
  background: #f4f7f9;
  color: #BD0029;
  transition: 0.3s !important;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15), 0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 3px 1px -2px rgba(0, 0, 0, 0.07) !important;
}
.tt-btn-secondary--error i {
  color: #BD0029;
}
.tt-btn-secondary--error:hover {
  background: #FFECF0;
}
.tt-btn-secondary--error:focus {
  outline: none;
}
.tt-btn-secondary:disabled, .tt-btn-secondary--accent:disabled, .tt-btn-secondary--error:disabled, .tt-btn-secondary--submit:disabled {
  background-color: #f4f7f9;
  color: #878e93;
  cursor: not-allowed;
  box-shadow: none !important;
}
.tt-btn-secondary:disabled i, .tt-btn-secondary--accent:disabled i, .tt-btn-secondary--error:disabled i, .tt-btn-secondary--submit:disabled i {
  color: #878e93;
}
.tt-btn.tt-btn-basic {
  background: transparent !important;
  box-shadow: none !important;
  color: #0A63F3;
  padding: 0;
  min-height: auto;
}
.tt-btn.tt-btn-basic i,
.tt-btn.tt-btn-basic span {
  color: #0A63F3;
  transition: 0.3s !important;
}
.tt-btn.tt-btn-basic:hover, .tt-btn.tt-btn-basic:hover > i, .tt-btn.tt-btn-basic:hover > span {
  color: #008EF3;
}
.tt-btn.tt-btn-basic:focus {
  outline: none;
}
.tt-btn.tt-btn-basic--accent {
  background: transparent !important;
  box-shadow: none !important;
  color: #C85000;
}
.tt-btn.tt-btn-basic--accent i,
.tt-btn.tt-btn-basic--accent span {
  color: #C85000;
}
.tt-btn.tt-btn-basic--accent:hover, .tt-btn.tt-btn-basic--accent:hover > i, .tt-btn.tt-btn-basic--accent:hover > span {
  color: #F66A00;
}
.tt-btn.tt-btn-basic--submit {
  background: transparent !important;
  box-shadow: none !important;
  color: #00884F;
}
.tt-btn.tt-btn-basic--submit i,
.tt-btn.tt-btn-basic--submit span {
  color: #00884F;
}
.tt-btn.tt-btn-basic--submit:hover, .tt-btn.tt-btn-basic--submit:hover > i, .tt-btn.tt-btn-basic--submit:hover > span {
  color: #00AC4A;
}
.tt-btn.tt-btn-basic--error {
  background: transparent !important;
  box-shadow: none !important;
  color: #BD0029;
}
.tt-btn.tt-btn-basic--error i,
.tt-btn.tt-btn-basic--error span {
  color: #BD0029;
}
.tt-btn.tt-btn-basic--error:hover, .tt-btn.tt-btn-basic--error:hover > i, .tt-btn.tt-btn-basic--error:hover > span {
  color: #E92851;
}
.tt-btn.tt-btn-basic--white {
  background: transparent !important;
  box-shadow: none !important;
  color: white;
  transition: 0.3s !important;
}
.tt-btn.tt-btn-basic--white i,
.tt-btn.tt-btn-basic--white span {
  color: white;
  transition: 0.3s !important;
}
.tt-btn.tt-btn-basic--white:hover, .tt-btn.tt-btn-basic--white:hover > i, .tt-btn.tt-btn-basic--white:hover > span {
  color: rgba(255, 255, 255, 0.7);
}
.tt-btn.tt-btn-basic:disabled, .tt-btn.tt-btn-basic--accent:disabled, .tt-btn.tt-btn-basic--error:disabled, .tt-btn.tt-btn-basic--submit:disabled {
  color: #a5abaf;
  box-shadow: none;
}
.tt-btn.tt-btn-basic:disabled i,
.tt-btn.tt-btn-basic:disabled span, .tt-btn.tt-btn-basic--accent:disabled i,
.tt-btn.tt-btn-basic--accent:disabled span, .tt-btn.tt-btn-basic--error:disabled i,
.tt-btn.tt-btn-basic--error:disabled span, .tt-btn.tt-btn-basic--submit:disabled i,
.tt-btn.tt-btn-basic--submit:disabled span {
  color: #a5abaf !important;
}
.tt-btn.tt-btn-text {
  background: transparent !important;
  box-shadow: none !important;
  color: #636b71;
}
.tt-btn.tt-btn-text i,
.tt-btn.tt-btn-text span {
  color: #0A63F3;
  transition: 0.3s !important;
}
.tt-btn.tt-btn-text:hover, .tt-btn.tt-btn-text:hover > i, .tt-btn.tt-btn-text:hover > span {
  color: #008EF3 !important;
}
.tt-btn.tt-btn-text--small {
  background: transparent;
  box-shadow: none;
  color: #636b71;
  font-size: 1.3rem;
  line-height: 1;
  padding: 0;
  min-height: 0;
  min-width: 0;
}
.tt-btn.tt-btn-text--small i,
.tt-btn.tt-btn-text--small span {
  color: #0A63F3;
  transition: 0.3s !important;
}
.tt-btn.tt-btn-text--small span {
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif !important;
}
.tt-btn.tt-btn-text--small:hover, .tt-btn.tt-btn-text--small:hover > i, .tt-btn.tt-btn-text--small:hover > span {
  color: #008EF3;
}
.tt-btn.tt-btn-text-dark {
  background: transparent !important;
  box-shadow: none !important;
  color: #151F28;
}
.tt-btn.tt-btn-text-dark i,
.tt-btn.tt-btn-text-dark span {
  color: #008EF3;
  transition: 0.3s !important;
}
.tt-btn.tt-btn-text-dark:hover, .tt-btn.tt-btn-text-dark:hover > i, .tt-btn.tt-btn-text-dark:hover > span {
  background-color: #f4f7f9 !important;
}
.tt-btn-selection {
  background: #f4f7f9;
  box-shadow: 0 2px 5px 0 rgba(76, 88, 98, 0.3);
  padding: 2.4rem;
}
.tt-btn-selection i,
.tt-btn-selection span {
  display: block;
}
.tt-btn-selection i {
  font-size: 8rem;
  line-height: 1;
  margin: -1.8rem 0 0 0;
  padding: 0;
  color: #008EF3;
  opacity: 0.4;
}
.tt-btn-selection .tt-btn-selection--label {
  color: #151F28;
  font-size: 2.8rem;
  line-height: 2.8rem;
  margin: 0;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
}
.tt-btn-selection:hover {
  box-shadow: 0 3px 8px 0 rgba(76, 88, 98, 0.4);
}
.tt-btn-selection:disabled {
  background: #f4f7f9;
  box-shadow: none;
  cursor: not-allowed;
}
.tt-btn-selection:disabled i {
  color: #c8cdd0;
  opacity: 0.8;
}
.tt-btn-selection:disabled .tt-btn-selection--label {
  color: #a5abaf;
}
.tt-btn-selection.btn-selected {
  background: #dae0e5;
  box-shadow: inset 0 2px 8px 0 #b4b9c9;
  cursor: default;
}
.tt-btn-selection.btn-selected i {
  opacity: 0.9;
  color: #0A63F3;
}
.tt-btn.tt-btn-bottom {
  border-radius: 0 0 3px 3px !important;
}

/*----------  ICONS  ----------*/
.icon-blue {
  color: #008EF3 !important;
}

.icon-orange {
  color: #F66A00 !important;
}

.icon-green {
  color: #00AC4A !important;
}

.icon-red {
  color: #E92851 !important;
}

.icon-yellow {
  color: #F1BE00 !important;
}

.icon-purple {
  color: #984BD2 !important;
}

.icon-gray-100 {
  color: #f4f7f9 !important;
}

.icon-gray-200 {
  color: #e1e4e7 !important;
}

.icon-gray-300 {
  color: #c8cdd0 !important;
}

.icon-gray-400 {
  color: #a5abaf !important;
}

.icon-gray-500 {
  color: #878e93 !important;
}

.icon-gray-600 {
  color: #636b71 !important;
}

.icon-gray-700 {
  color: #434c53 !important;
}

.icon-gray-800 {
  color: #2A353E !important;
}

.icon-gray-900 {
  color: #151F28 !important;
}

.icon-white {
  color: white !important;
}

.icon-xsmall {
  font-size: 12px !important;
}

.icon-small {
  font-size: 18px !important;
}

.icon-regular {
  font-size: 24px !important;
}

.icon-large {
  font-size: 30px !important;
}

.icon-xlarge {
  font-size: 60px !important;
}

#root {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.navbar-light {
  z-index: 100;
}

.nav navbar-nav {
  display: inline;
}

.fa-stack {
  background-color: #00AC4A;
  color: white;
}

.message-mini-menu {
  height: 32px;
  width: 32px;
  font-size: 16px;
}

[role=button],
button {
  outline: none;
}

.skeleton {
  height: 4rem;
  width: 100%;
  border-radius: 5px;
  background: rgba(128, 128, 128, 0.1);
}

.text-center {
  text-align: center;
}

.full-width {
  width: 100%;
}

.transform-center {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.mapContainer {
  width: 100%;
}

.containerElement {
  height: 100%;
}

.mapPane {
  width: 100%;
  height: calc(100vh - 6.2rem);
  background: #434c53;
}

.mapElement,
.map,
.mapPane,
.cardMap {
  /**
  * The way Google Maps styles each of the
  * controls in the map is by inline styles
  * which forces us to add "!important"
  * in order to override the inline style
  * specificity.
  */
}
.mapElement div[title="Show street map"],
.map div[title="Show street map"],
.mapPane div[title="Show street map"],
.cardMap div[title="Show street map"] {
  height: 20px !important;
  font-size: 12px !important;
}
.mapElement div[title="Show satellite imagery"],
.map div[title="Show satellite imagery"],
.mapPane div[title="Show satellite imagery"],
.cardMap div[title="Show satellite imagery"] {
  height: 20px !important;
  font-size: 12px !important;
}
.mapElement div[class^=RecenterMapBtn_recenterButtonContainer],
.mapElement div[class^=RecenterMapBtn_recenterButtonContainer] button,
.map div[class^=RecenterMapBtn_recenterButtonContainer],
.map div[class^=RecenterMapBtn_recenterButtonContainer] button,
.mapPane div[class^=RecenterMapBtn_recenterButtonContainer],
.mapPane div[class^=RecenterMapBtn_recenterButtonContainer] button,
.cardMap div[class^=RecenterMapBtn_recenterButtonContainer],
.cardMap div[class^=RecenterMapBtn_recenterButtonContainer] button {
  width: 24px !important;
  height: 24px !important;
  margin: 0;
  padding: 0;
}
.mapElement div[class^=RecenterMapBtn_recenterButtonContainer] i[class=icon-reset-map],
.map div[class^=RecenterMapBtn_recenterButtonContainer] i[class=icon-reset-map],
.mapPane div[class^=RecenterMapBtn_recenterButtonContainer] i[class=icon-reset-map],
.cardMap div[class^=RecenterMapBtn_recenterButtonContainer] i[class=icon-reset-map] {
  font-size: 14px !important;
}
.mapElement div[class=gm-style] > div[class=gmnoprint]:last-child,
.map div[class=gm-style] > div[class=gmnoprint]:last-child,
.mapPane div[class=gm-style] > div[class=gmnoprint]:last-child,
.cardMap div[class=gm-style] > div[class=gmnoprint]:last-child {
  margin: 5px 10px !important;
}
.mapElement .mapInput,
.map .mapInput,
.mapPane .mapInput,
.cardMap .mapInput {
  background: white !important;
  border: 1px solid white !important;
  box-shadow: inset 0 0 0 1px transparent, 0 1px 4px rgba(0, 0, 0, 0.2) !important;
}
.mapElement .mapInput::-webkit-input-placeholder,
.map .mapInput::-webkit-input-placeholder,
.mapPane .mapInput::-webkit-input-placeholder,
.cardMap .mapInput::-webkit-input-placeholder {
  color: #a5abaf !important;
}
.mapElement .mapInput:focus,
.map .mapInput:focus,
.mapPane .mapInput:focus,
.cardMap .mapInput:focus {
  border: 1px solid #008EF3 !important;
  box-shadow: inset 0 0 0 1px #008EF3, 0 1px 4px rgba(0, 0, 0, 0.2) !important;
}

.mapPane {
  width: 100%;
  flex: 1 1 auto;
  background: #434c53;
  cursor: none;
}

.containerElement {
  height: 100% !important;
  position: block !important;
}

.mapElement {
  height: 100%;
}
.mapElement div[title="Show street map"] {
  top: 7px !important;
}
.mapElement div[title="Show satellite imagery"] {
  top: 7px !important;
}

button[title="Zoom in"] img {
  height: 10px !important;
  width: 10px !important;
}

button[title="Zoom out"] img {
  height: 10px !important;
  width: 10px !important;
}

