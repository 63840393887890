@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';

.geotraxPagination {
  width: 400px;
  height: 45px;
  z-index: 1;
  display: flex;
  align-items: stretch;
  justify-content: center;
  padding: 3px;
  margin: 20px auto;

  :global(.-center) {
    margin: 0;
    display: flex;
  }
}

.paginationPrevLabel::after {
  font-family: 'icomoon';
  content: '\e909'; // icomoon - icon-arrow-back
  color: $darkblue;
  font-size: 12px;
}

.paginationNextLabel::after {
  font-family: 'icomoon';
  content: '\e90d'; // icomoon - icon-arrow-forward
  color: $darkblue;
  font-size: 12px;
}

.geotraxPaginationBtn[disabled].paginationNextLabel::after {
  color: $darkred;
}

.geotraxPaginationBtn[disabled] {
  background: $gray-300;
  cursor: default;
}

.geotraxPageBtn {
  font-size: 1.6rem;
  outline: none;
  border: none;
  background-color: transparent;
  border-radius: $border-radius;
  cursor: pointer;
  padding: 7px 12px 11px 12px;
  width: 4rem !important;
  height: 4rem !important;
  transition: $transition;
  background: $gray-100;
  color: $darkblue;

  &:hover {
    background: $gray-200;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: default !important;

    &:hover {
      background-color: transparent !important;
      cursor: default !important;
    }

    .paginationNextLabel::after,
    .paginationPrevLabel::after {
      display: none !important;
    }
  }
}

.geotraxPageBtnActive {
  font-family: $font-family-bold;
  font-size: 1.6rem;
  outline: none;
  border-left: none;
  border-top: none;
  border-right: none;
  background-color: transparent;
  border-bottom: 2px solid $orange;
  padding: 7px 12px 9px 12px;
  background: $gray-100;
  width: 100%;
  height: 100%;
  position: relative;
}

.paginationButton {
  display: inline-block;
  height: 4rem;
  width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.paginationEllipse {
  display: inline-block;
}

.ellipse {
  display: inline-block;
  font-size: 15px;
  outline: none;
  border: none;
  background-color: transparent;
  margin: 4px 0;
  padding: 4px 12px;
}