@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.featureFooter {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 12px 22px 12px 23px;
  border-top: 1px solid $gray-200;
}

.dropDownListDiv {
  margin-top: -1px;
}

.dropDownListDiv.hasError>div {
  >div {
    border: 1px solid $red !important;
    background-color: $xlightred !important;
  }

  >fieldset {
    display: none;
  }
}

.multiSelectErrorMessage {
  margin-top: 3px;
  font-size: 13px;
  color: red; // intentionally use red instead of $red because that's what's used in other error messages
}

.menuContainer {
  margin-top: 4px !important;

  div {
    max-height: 136px !important;
  }
}

.forms {
  margin: 30px 0 0 0;
  padding: 0 34px;
}

// Removing the default bottom padding of Material UI dialog cards
// This is necessary for the features footer to be placed at the bottom of the form
:global(.MuiTypography-root.MuiDialogContentText-root.MuiTypography-body1.MuiTypography-colorTextSecondary) {
  margin-bottom: 0 !important;
}

.addModal {
  :global(.quarterWidth) {
    width: 25%;
  }

  :global(.baseMargin) {
    margin: 25px 0 30px 0;
  }

  :global(.smallMargin) {
    margin: 10px 0 20px 0;
  }

  .baseWidthSpace {
    margin: 32px 0 30px 0;

    &:first-of-type {
      margin: 0 0 30px 0;
    }
  }

  .bottomSpace {
    margin-bottom: 40px;
  }

  :global(.widthSpace) {
    width: 75%;
    @extend .baseWidthSpace;
  }

  :global(.fullWidthSpace) {
    width: 100%;
    @extend .baseWidthSpace;
  }

  :global(.halfWidthSpace) {
    width: 50%;
    @extend .baseWidthSpace;
  }

  :global(.quarterWidthSpace) {
    width: 25%;
    @extend .baseWidthSpace;
  }

  :global(.baseDropDown) {
    width: 62.5%;
    vertical-align: top;
  }

  :global(.bottomDropDown) {
    vertical-align: top;
  }

  :global(.topWidthSpace) {
    @extend .baseWidthSpace;
    margin-top: 40px;
  }

  :global(.gBottomSpace) {
    @extend .bottomSpace;
  }

  :global(.bottomWidthSpace) {
    @extend .baseWidthSpace;
    @extend .bottomSpace;
  }

  :global(.flexInput) {
    margin-bottom: 10px;
    width: 100%;
  }

  :global(.fontFam) {
    font-family: $font-family-bold;
  }

  :global(.flexHorizontalRadio) {
    >div>div>div {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
    }
  }

  :global(.margin-top) {
    margin-top: 32px;
  }

  :global(.tt-label--help) {
    // overwrites character count for clear separation from inputs bellow
    // from UI review for gtx-3258
    display: flex;
    justify-content: flex-end;
    margin-top: 4px;
    line-height: 16px;
    font-size: 13px;
    font-weight: 200;

    span {
      margin-bottom: -10px;
    }
  }

  :global(.tt-label--character-count) {
    display: flex;
    justify-content: flex-end;
    margin-top: 3px;

    span {
      margin-bottom: -10px;
    }
  }
}

.errorFieldMargin {
  margin: 20px 0 10px !important;
}

.radioBtnSize {
  height: 10px;
  width: 10px;
  margin-left: 13px !important;
}

.radioBtn {
  color: $gray-900 !important;
  padding: 12px !important;
  margin-left: 10px !important;
  @extend .radioBtnSize;

  :global(.MuiSvgIcon-root) {
    font-size: 2.4rem !important;
  }
}

.radioBtnSelected {
  color: $blue !important;
  @extend .radioBtnSize;
}

.radioBtnDisabled {
  color: $gray-300 !important;
  @extend .radioBtnSize;
}

.radioBtnError {
  color: $darkred !important;
  background-color: $xlightred !important;
  @extend .radioBtnSize;
}

.radioLabel {
  font-size: 16px !important;
  font-weight: 400 !important;
  margin: 4px !important;
  font-family: $font-family-regular !important;
  color: $gray-900;
}

.radioLabelDisabled {
  opacity: 0.4;
}

.textRed {
  color: $darkred;
}

.checkboxLabel {
  color: $gray-900 !important;
  font-size: 16px !important;
}

.checkboxLabel span,
.checkboxLabel span input {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-left: 13px !important;
  padding-right: 0px !important;
  font-size: 16px !important;

  &:hover {
    background: none !important;
  }

  svg {
    height: 24px;
    width: 24px;
  }
}

.checkboxUnchecked {
  color: $gray-900 !important;
  font-size: 16px !important;
}

.checkboxRequired {
  svg {
    color: $red;
  }
}

.checkboxChecked {
  color: $blue !important;
  font-size: 16px !important;
}

.selectContainer {
  width: 100% !important;

  :global(.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiInputBase-input.MuiInput-input) {
    font-size: 1.6rem;
  }
}

.select {
  height: 4rem !important;
  box-sizing: border-box !important;
  padding: 0.8rem 3.3rem 0.2rem 1.3rem !important;
  box-shadow: inset 0 0 0 1px $gray-200;
  background: $gray-100 !important;
  border-radius: $border-radius !important;
  transition: $transition;
  line-height: 3.25rem !important;

  &:hover {
    box-shadow: inset 0 0 8px 2px $gray-200;
  }

  &:focus,
  &:global(.open) {
    box-shadow: inset 0 0 0 2px $blue !important;
  }
}

.placeholderText {
  color: $gray-500 !important;
}

.groupHeader {
  font-weight: bold;
}

.groupItem {
  margin-left: 10px;
}

.labelDisabled {
  opacity: 0.4;
}

.subLabel {
  margin-top: 5px;
  font-family: 'PublicSans Regular';
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
}

.labelWithTooltip {
  display: flex;

  .tooltipSpacer {
    padding-left: 4px;

    span {
      font-size: 12px;
      display: block;
      color: $gray-400;
    }
  }
}