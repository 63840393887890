@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.active {
  background-color: white;
  color: gray;
  height: 37px;
  margin-top: -1px;
}

.modalRoot {
  z-index: 200;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .35) !important;
}

.messageCenterTab {
  font-size: 17px;
}

.titleHeader {
  letter-spacing: 2px;
  padding-right: 15px;
  padding-left: 10px;
  font-family: $font-family-bold;
  font-size: 1.3rem;
  color: $white;
}

.header {
  font-size: 1.5rem;
  display: flex;
  width: 100%;
  background-color: $blue;
  border-radius: 0;
  justify-content: space-between;
  align-items: center;
  padding: 7px 7px 7px 12px;
  color: $gray-900;
  height: 55px;
  flex-direction: row;

  .headerTitle {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    line-height: 18px;
  }

  .headerActions {
    display: flex;
    align-items: center;
  }
}

.datablock-info-close {
  padding: 0 1rem;

  i {
    font-size: 1.6rem;
  }
}

.datablock-info-close:hover {
  opacity: 0.5;
  transition-duration: 0.3s;
}

.expendedButton {
  border: none;
  cursor: pointer;
  transition: $transition;
  box-shadow: none !important;
  background: transparent !important;
  padding: 0;
  min-height: auto;

  &:hover,
  &:hover>i,
  &:hover>span {
    color: rgba(255, 255, 255, 0.7);
  }
}

.newWindowIcon {
  color: $white;
  font-size: 2.2rem;
  line-height: 1.1;
  text-align: left;
}