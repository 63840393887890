.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-center,
.flex-horizontal-center,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.container {
  transform: translateY(10px) !important;
  margin-top: -10px !important;
}

.btnGroup {
  width: auto;
  height: 6.2rem;
  display: flex;
  padding: 20px 0;
  margin-top: -10px !important;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.btnGroup .editBtn {
  display: inline-block;
  align-self: center;
  justify-self: center;
  width: auto;
}
.btnGroup .saveBtn {
  display: inline-block;
}
.btnGroup .cancelBtn {
  display: inline-block;
  width: auto;
  margin: 0 12px 0 0;
}

.disabledEditText {
  color: #636b71;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: 1.2;
  letter-spacing: normal;
  max-width: 13rem;
  margin: 0 0 0.1rem 1.3rem;
}

.logOutBtnDisabled {
  background-color: #f0f3f5 !important;
  color: rgb(194, 189, 189) !important;
  box-shadow: none !important;
  cursor: not-allowed !important;
}

.logOutModalBody {
  white-space: pre-wrap;
}
.logOutModalBody b {
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif !important;
}

