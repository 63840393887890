@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.checklistAddModal {
  @extend %adminModalWidthSmall;
}

.modal {
  display: grid;
  grid-gap: 28px;
  grid-template-columns: 1fr 1fr;
}

.modalRow {
  grid-column-start: 1;
  grid-column-end: 3;
  margin: 0 0 10px 0 !important;
}

.name {
  grid-row: 1;
  margin: 0 !important;
}

.regions {
  grid-row: 2;
}

.productLines {
  grid-row: 3;
}

.optionsFormats {
  grid-row: 4;
}

.whenToPresent {
  grid-row: 5;
  margin: 0 0 40px !important;
}

.iconClose {
  color: $red !important;
  font-size: 13px;
}

.iconCheck {
  color: $green !important;
  font-size: 14px;
  font-weight: bold;
}

.addOptionsCtr {
  margin-left: 4px;
}