@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_helpers.scss';

.contentContainer {
  overflow: hidden;
  overflow-y: hidden;
  overflow-x: hidden;
  background: $white;
  margin-top: -113px;
  height: calc(95vh);
  width: 43.4rem;
}