.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-center,
.flex-horizontal-center,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.controlledSubjectField {
  width: 100% !important;
}

.controlledMessageField {
  width: 100% !important;
}

.container {
  display: flex;
  flex-direction: column;
  height: 90vh;
}

.chipsContainer {
  padding: 0 2.2rem 0 2.2rem;
}

.recipientsContainer {
  width: 100%;
}

.textFieldContainer {
  margin-top: auto;
  position: relative;
  z-index: 2;
}
.textFieldContainer:before {
  content: "";
  width: 100%;
  height: 5%;
  position: absolute;
  top: -13px;
  left: 0;
  background: linear-gradient(transparent 0%, transparent), linear-gradient(transparent, transparent 100%) 0 100%, radial-gradient(farthest-side at 50% 100%, transparent, transparent), radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0)) 0 50%;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
}

.hideBottomShadow:before {
  display: none;
}

.newMessageContainer {
  overflow: auto;
  position: relative;
  z-index: 1;
  height: 100%;
}

.chipInputContainer {
  position: relative;
}
.chipInputContainer > div > div > div > label[class*=MuiInputLabel-formControl] {
  top: -8px;
}
.chipInputContainer > div > div > div > div > input {
  position: relative;
  top: -13px;
  border-bottom: 1px solid #e1e4e7;
  border-radius: 0px !important;
  padding-bottom: 4px;
}
.chipInputContainer > div > div > div > div > input:focus {
  border-bottom: 1px solid #008EF3;
}
.chipInputContainer > div > div > div > div > fieldset {
  display: none;
}

.label {
  position: absolute;
  left: 0;
  top: 1rem;
  font-size: 1.3rem;
  letter-spacing: 0.2rem;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  color: #151F28;
  line-height: 1;
  z-index: 1;
}

.title {
  font-size: 1.3rem;
  letter-spacing: 0.15rem;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  color: #151F28;
  line-height: 1;
  padding: 1.4rem 2.2rem 1rem 2.2rem;
  position: relative;
  z-index: 2;
}
.title:after {
  content: "";
  width: 100%;
  height: 34%;
  position: absolute;
  bottom: -13px;
  left: 0;
  background: linear-gradient(transparent 0%, transparent), linear-gradient(transparent, transparent 100%) 0 100%, radial-gradient(farthest-side at 50% 100%, transparent, transparent), radial-gradient(farthest-side at 50% 0%, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0)) 0 50%;
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
}

.hideTopShadow:after {
  display: none;
}

.clearChips {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -0.7rem;
  top: 0.3rem;
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 3px;
}
.clearChips i {
  font-size: 2rem;
  color: #636b71;
  transition: 0.3s !important;
  line-height: 1;
}
.clearChips:hover > i {
  color: #878e93;
}

.popper {
  z-index: 1300;
  overflow: hidden !important;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.15), 0px 2px 2px 0px rgba(0, 0, 0, 0.09), 0px 3px 1px -2px rgba(0, 0, 0, 0.07) !important;
  border-radius: 3px;
  margin-bottom: 1px !important;
}

