@import '@trucktrax/trucktrax-common/src/styles/tt_vars.scss';
@import '@trucktrax/trucktrax-common/src/styles/tt_layout.scss';

.dropDownListContainer {
  width: 120px;

  >div>div[role="button"] {
    font-size: 1.6rem;
  }
}

.row {
  height: 49px;
}

.cellText {
  font-size: 16px;
  padding-left: 13px;
}

.marginLeft {
  margin-left: 5px;
}

.paddingLeft {
  padding-left: 16px;
}

.emptyState {
  width: 100%;
  height: 580px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.circleLoader {
  border: 8px solid $gray-200;
  border-left-color: $green;
  animation: loader-spin 1.2s infinite linear;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  transform: translateX(8px);
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.headerContainer {
  background: $white;
  padding: 0;
}

.headerRow {
  padding: 0;
  border-bottom: 1px solid $gray-200;
}

.headerStyle {
  font-size: 1.3rem;
  color: $gray-900;
  text-align: left;
  border: none;
  background: $white;
  line-height: 1 !important;
  padding-top: 0 !important;
  padding-bottom: 10px !important;

  span {
    line-height: 1;
  }
}

.headerAccessMargin {
  transform: translateX(-2px) !important;
}

.borderLeft {
  border: 1px solid $gray-200;
  border-right: none;
}

.borderRight {
  border: 1px solid $gray-200;
  border-left: none;
}

.closeButton {
  font-size: 12px;
  padding-right: 10;
  text-align: right;
}