.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.checklistAddModal,
.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-center,
.flex-horizontal-center,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.modal {
  display: grid;
  grid-gap: 28px;
  grid-template-columns: 1fr 1fr;
}

.modalRow {
  grid-column-start: 1;
  grid-column-end: 3;
  margin: 0 0 10px 0 !important;
}

.name {
  grid-row: 1;
  margin: 0 !important;
}

.regions {
  grid-row: 2;
}

.productLines {
  grid-row: 3;
}

.optionsFormats {
  grid-row: 4;
}

.whenToPresent {
  grid-row: 5;
  margin: 0 0 40px !important;
}

.iconClose {
  color: #E92851 !important;
  font-size: 13px;
}

.iconCheck {
  color: #00AC4A !important;
  font-size: 14px;
  font-weight: bold;
}

.addOptionsCtr {
  margin-left: 4px;
}

