.wrapper > div p:empty::after {
  content: "N/A";
  color: #a5abaf;
}
.wrapper > div p[title="N/A"] {
  color: #a5abaf;
}
.wrapper > div p[placeholder="N/A"] {
  color: #a5abaf;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 671px !important;
  max-height: calc(100% - 64px) !important;
}

.adminModalWidth {
  position: relative;
  width: 100%;
  max-width: 800px !important;
  max-height: calc(100% - 42px) !important;
}

.adminModalWidthSmall {
  position: relative;
  width: 100%;
  max-width: 500px !important;
  max-height: calc(100% - 100px) !important;
}

.tabsAutoWidth {
  min-width: auto !important;
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.flex-center,
.flex-horizontal-center,
.flex-vertical-center,
.flex-space-between,
.flex-end,
.flex-start,
.flex-column,
.flex-row,
.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-start {
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-end {
  justify-content: flex-end;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-vertical-center {
  align-items: center;
}

.flex-horizontal-center {
  justify-content: center;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

input[type=text].tt-input:disabled {
  background-color: #F4F7F9 !important;
  border: #E1E4E7 1px solid;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
}
.wrapper > div {
  flex-grow: 1 !important;
  height: auto !important;
  width: 100% !important;
}
.wrapper > div:last-of-type {
  overflow: auto !important;
}

.btnContainer {
  opacity: 1 !important;
}

.btnContainerDisabled {
  opacity: 0.4 !important;
}

.regionContainer {
  overflow: visible;
  border-bottom: solid 1px #c8cdd0;
}

.belongsToGroupsContainer {
  word-wrap: break-word;
  overflow: hidden;
}

.text {
  font-size: 16px;
  word-wrap: break-word;
  white-space: normal;
  line-height: 1;
}

.disabledText {
  font-size: 16px;
  color: #636b71;
}

.marginLeft {
  margin-left: 5px !important;
}

.permissionsLabel {
  overflow-x: auto;
  padding: 3px 2px;
  font-size: 16px;
  color: #636b71;
  border-bottom: 1px solid #c8cdd0;
}

.headerContainer {
  background: white;
  padding: 0;
}

.headerRow {
  padding: 0 !important;
  border-bottom: 1px solid #e1e4e7 !important;
}

.headerStyle {
  font-size: 1.3rem !important;
  color: #151F28 !important;
  font-weight: inherit !important;
  text-align: left !important;
  border: none !important;
  background: white !important;
  line-height: 1 !important;
  padding: 0 0 13px 17px !important;
}

.borderLeft {
  border: 1px solid #e1e4e7;
  border-right: none;
}

.borderRight {
  border: 1px solid #e1e4e7;
  border-left: none;
}

.column {
  padding: 0 0 0 16px !important;
}

.row {
  height: 45px;
}

.cellText {
  font-size: 16px;
  line-height: 1;
  letter-spacing: normal;
  word-wrap: break-word;
  white-space: normal;
}

.emptyState {
  width: 100%;
  height: 580px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.circleLoader {
  border: 8px solid #e1e4e7;
  border-left-color: #00AC4A;
  animation: loader-spin 1.2s infinite linear;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  transform: translateX(8px);
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.redBoldText {
  color: #E92851;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  font-size: 16px;
}

.blueText {
  color: #0A63F3;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  font-size: 16px;
}

.greenText {
  color: #00884F;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
  font-size: 16px;
}

.disabledRegionName {
  font-weight: bolder;
  font-family: "PublicSans Bold", Helvetica, Arial, sans-serif;
}

.disabledClickIcon {
  pointer-events: auto !important;
  cursor: not-allowed !important;
}

.regionsTabContainer {
  overflow-x: auto;
  border-bottom: 1px solid #c8cdd0;
  margin-top: -2rem;
}
.regionsTabContainer .btnContainer {
  opacity: 1 !important;
}
.regionsTabContainer .btnContainerDisabled {
  opacity: 0.4 !important;
}

.lockPopper {
  z-index: 9999 !important;
  opacity: 1 !important;
  margin-left: -20px;
}

.lockTooltip {
  background: #2A353E !important;
  padding: 0.6rem 1rem !important;
  font-size: 1.3rem !important;
  line-height: 1.3 !important;
  white-space: nowrap;
  border-radius: 3px !important;
  pointer-events: none;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}
.lockTooltip:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
  border-width: 6px;
  pointer-events: none;
  border-right-color: #2A353E;
  top: 100%;
  right: 44%;
  transform: translateY(0%) rotate(-90deg);
}

